import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { useAuth } from "../../Providers/UserProvider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function AdLinksDateByDate() {
  const { adlinksdatebydate, adlinksdata } = useAuth();
  const colorScale = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#f0a78f",
    "#c79ef3",
    "#ff6b6b",
    "#7cd6fd",
    "#ffab85",
    "#99e2b4",
    "#ffb2b2",
  ]; // Define your color scale with 10 colors

  // Initialize selected categories with "raman0007" and "ielts50" by default
  const initialSelectedCategories = ["raman0007", "ielts50"];
  const [selectedCategories, setSelectedCategories] = useState(
    initialSelectedCategories,
  );

  // Function to handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedCategories((prevSelected) => [...prevSelected, name]);
    } else {
      setSelectedCategories((prevSelected) =>
        prevSelected.filter((category) => category !== name),
      );
    }
  };

  return (
    <div>
      {/* Material UI checkboxes for each category */}
      {adlinksdata.map((category) => (
        <FormControlLabel
          key={category}
          control={
            <Checkbox
              checked={selectedCategories.includes(category)}
              onChange={handleCheckboxChange}
              name={category}
            />
          }
          label={`Show ${category}`}
        />
      ))}

      <ResponsiveContainer width="99%" height={300}>
        <BarChart data={adlinksdatebydate}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />

          {/* Conditionally render bars based on selected categories */}
          {adlinksdata.map(
            (category, index) =>
              selectedCategories.includes(category) && (
                <Bar
                  key={category}
                  dataKey={category}
                  name={category}
                  fill={colorScale[index % colorScale.length]}
                />
              ),
          )}

          <YAxis />
          <Tooltip />
          <Legend />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
