import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import CompanyGraphTab from "./CompanyGraphTab";
import { makeStyles } from "@material-ui/core";
import ExpenseGraph from "../Charts/ExpenseGraph";
import AllExpenseGraph from "../Charts/ExpenseGraphs/AllExpenseGraph";
import PersonalExpenseGraph from "../Charts/ExpenseGraphs/PersonalExpenseGraph";

const useStyles = makeStyles((theme) => ({
  style: {
    fontSize: "12px !important",
    padding: "0px 4px !important",
    minHeight: "16px !important",

    [theme.breakpoints.down("sm")]: {
      fontSize: "8px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
  },
  roots: {
    marginTop: "5px !important",
    minHeight: "22px !important",
  },
}));

const ExpenseGraphTabs = () => {
  const [value, setValue] = useState("one");

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        centered
        className={classes.roots}
      >
        <Tab value="one" label="All" className={classes.style} />
        <Tab value="two" label="Company" className={classes.style} />
        <Tab value="three" label="Personal" className={classes.style} />
      </Tabs>
      <TabPanel value="one" style={{ padding: "0" }}>
        {" "}
        <AllExpenseGraph />
      </TabPanel>
      <TabPanel value="two" style={{ padding: "0" }}>
        {" "}
        <CompanyGraphTab />
      </TabPanel>
      <TabPanel value="three" style={{ padding: "0" }}>
        {" "}
        <PersonalExpenseGraph />
      </TabPanel>
    </TabContext>
  );
};

export default ExpenseGraphTabs;
