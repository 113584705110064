import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import { useAuth } from "../../Providers/UserProvider";
import CashBookCard from "../CashBookTranscation/CashBookCard";

const CouponSpentDetailComp = ({ handleFunction }) => {
  const [value, setValue] = useState("one");
  const {
    getCouponSpendDetails,
    couponspenddetail,
    getCouponSpendCredit,
    getCouponSpendWithdraw,
  } = useAuth();

  const couponData = JSON.parse(sessionStorage.getItem("selectedCouponId"));

  var styles = {
    root: {
      marginTop: "10px",
    },
  };

  const handleClick2 = () => {
    handleFunction();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "one") {
      const couponData = sessionStorage.getItem("selectedCouponId");
      const userData = localStorage.getItem("userData");
      getCouponSpendDetails(JSON.parse(userData), JSON.parse(couponData));
    }
    if (newValue === "two") {
      const couponData = sessionStorage.getItem("selectedCouponId");
      const userData = localStorage.getItem("userData");
      getCouponSpendCredit(JSON.parse(userData), JSON.parse(couponData));
    }
    if (newValue === "three") {
      const couponData = sessionStorage.getItem("selectedCouponId");
      const userData = localStorage.getItem("userData");
      getCouponSpendWithdraw(JSON.parse(userData), JSON.parse(couponData));
    }
  };
  return (
    <>
      <h2 style={{ textAlign: "-webkit-center" }}>
        Coupon Spend Detail ({couponData?.couponCodeText}){" "}
      </h2>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          centered
          style={styles.root}
        >
          <Tab value="one" label="All" />
          <Tab value="two" label="Credit" />
          <Tab value="three" label="Withdraw" />
        </Tabs>
        <TabPanel value="one">
          {couponspenddetail?.map((res) => (
            <CashBookCard res={res} handleClick={handleClick2} />
          ))}
        </TabPanel>
        <TabPanel value="two">
          {couponspenddetail?.map((res) => (
            <CashBookCard res={res} handleClick={handleClick2} />
          ))}
        </TabPanel>
        <TabPanel value="three">
          {couponspenddetail?.map((res) => (
            <CashBookCard res={res} handleClick={handleClick2} />
          ))}
        </TabPanel>
      </TabContext>
    </>
  );
};

export default CouponSpentDetailComp;
