import React, { useEffect } from "react";
import SidebarComp from "../../Components/Sidebar";
import "../../CSSFiles/home.css";

import SidebarMob from "../../Components/SidebarMob";
import Navbar from "../../Components/Navbar/Navbar";
import { useAuth } from "../../Providers/UserProvider";
import SpeakingQuesComp from "../../Components/SpeakingQuesComp/SpeakingQuesComp";
import { useNavigate } from "react-router-dom";
import { Fab, Typography } from "@material-ui/core";
import { RiAddFill } from "react-icons/ri";

const Speaking = () => {
  const {
    getSpeakingTestData,
    updateSpeakingTestList,
    timetorefresh,
    closeSidebar,
  } = useAuth();

  useEffect(() => {
    StartFeedBackPage();
  }, []);

  const navigate = useNavigate();

  const StartFeedBackPage = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    const SpeakingListQues = sessionStorage.getItem("SpeakingListQues");
    if (SpeakingListQues) {
      updateSpeakingTestList();
    }
    getSpeakingTestData(Data);
  };

  return (
    <>
      <>
        {timetorefresh === true && (
          <Typography
            style={{
              margin: 0,
              top: 15,
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
              zIndex: "1",
            }}
          >
            Refreshing...
          </Typography>
        )}
        {!closeSidebar && <Navbar />}
        <div className="home">
          {closeSidebar && <SidebarMob />}
          <SidebarComp />
          <SpeakingQuesComp />
          {/* <ListeningTestTable /> */}
          <Fab
            aria-label="add"
            style={{
              margin: 0,
              right: 50,
              bottom: 80,
              position: "fixed",
              zIndex: "1",
              backgroundColor: "#051e34",
              color: "white",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/uploadspeakingTest");
            }}
          >
            <RiAddFill size={30} style={{ color: "white" }} />
          </Fab>
        </div>
      </>
    </>
  );
};

export default Speaking;
