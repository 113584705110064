import { React, useState, useContext, createContext, useRef } from "react";

import CryptoJS from "crypto-js";
import axios from "axios";
import {
  api_url,
  dateFormate,
  dummy_url,
  // dummy_url,
  getLast30daysFromCurr,
  new_api_url,
} from "../Services/Constants";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { functionConvertIntegerToArray } from "../Components/LastDaysUsersComp/AdminUserDetailsCard";
import { toast } from "react-toastify";
import { auth } from "../Services/Firebase";

const AuthContext = createContext({
  user: null,
  createjwt: () => Promise,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider(props) {
  const [jwtval, setjwt] = useState(null);
  const [userConvertRateData, setuserConvertRateData] = useState([]);
  const [dailyAciveUserRateData, setdailyActiveUserRateData] = useState([]);
  const [dailyRevenueData, setdailyRevenueData] = useState([]);
  const [notifications, setnotifications] = useState([]);
  const [feedbacks, setfeedbacks] = useState([]);
  const [paymentbehaviourgraph, setpaymentbehaviourgraph] = useState([]);
  const [razorPayWalletAmount, setrazorPayWalletAmount] = useState("");
  const [totalMoneyGetInBackDates, settotalMoneyGetInBackDates] = useState("");
  const [
    totalSuccessfullPaymentInBackDates,
    settotalSuccessfullPaymentInBackDates,
  ] = useState("");
  const [
    totalProfitgetInBackDatesBeforeRazorPayText,
    settotalProfitgetInBackDatesBeforeRazorPayText,
  ] = useState("");
  const [
    totalAmountSettleBeforeRazorPayTaxInBackDates,
    settotalAmountSettleBeforeRazorPayTaxInBackDates,
  ] = useState("");
  const [
    totalAmountSettleAfterRazorPayTaxInBackDates,
    settotalAmountSettleAfterRazorPayTaxInBackDates,
  ] = useState("");
  const [userComeOnAppInLast30Minute, setuserComeOnAppInLast30Minute] =
    useState("");
  const [totalAmount, settotalAmount] = useState("");
  const [iciciCurrentAccount, seticiciCurrentAccount] = useState("");
  const [kotakCurrentAccount, setkotakCurrentAccount] = useState("");
  const [profitAfterExpense, setprofitAfterExpense] = useState("");
  const [couponcodedata, setcouponcodedata] = useState([]);
  const [timetorefresh, settimetorefresh] = useState(false);
  const [onClickNavLoader, setonClickNavLoader] = useState(false);
  const [paymentcontrolData, setPayMentControl] = useState([]);
  const [speakingtestcontrolData, setspeakingtestcontrolData] = useState([]);
  const [bandcalculationData, setbandcalculationData] = useState([]);
  const [errorlogData, seterrorlogData] = useState([]);
  const [listeningtestData, setlisteningtestData] = useState([]);
  const [readingtestData, setreadingtestData] = useState({
    academicTest: [],
    generalTest: [],
  });
  const [speakingtestData, setspeakingtestData] = useState({
    intro: [],
    general: [],
    queCard: [],
    followUpQues: [],
  });
  const [feedbackControlData, setfeedbackControlData] = useState([]);
  const [daysAppUsers, setdaysAppUsers] = useState([]);
  const [daysAdminUsers, setdaysAdminUsers] = useState([]);
  const [iciciSavingAccount, seticiciSavingAccount] = useState("");
  const [settlePercentageByRazorPay, setsettlePercentageByRazorPay] =
    useState("");
  const [razorPayXAccount, setrazorPayXAccount] = useState("");
  const [
    totalProfitgetInBackDatesBeforeRazorPayTextAfterGST,
    settotalProfitgetInBackDatesBeforeRazorPayTextAfterGST,
  ] = useState("");
  const [averageConvertRateForMonth, setaverageConvertRateForMonth] =
    useState("");
  const [isClearSearch, setisClearSearch] = useState(false);
  const [isSearchButtonClicked, setisSearchButtonClicked] = useState(false);
  const [serverInfodata, setserverInfodata] = useState([]);
  const [paidunpaidretentiondata, setpaidunpaidretentiondata] = useState([]);
  const [androidretentiondata, setandroidretentiondata] = useState([]);
  const [iosretentiondata, setiosretentiondata] = useState([]);
  const [paidunpaidavgtimespend, setpaidunpaidavgtimespend] = useState([]);
  const [androidavgtimespend, setandroidavgtimespend] = useState([]);
  const [iosavgtimespend, setiosavgtimespend] = useState([]);
  const [paidunpaidallfeaturesusage, setpaidunpaidallfeaturesusage] = useState(
    [],
  );
  const [androidallfeaturesusage, setandroidallfeaturesusage] = useState([]);
  const [iosallfeaturesusage, setiosallfeaturesusage] = useState([]);
  const [paidunpaidpaidfeaturesusage, setpaidunpaidpaidfeaturesusage] =
    useState([]);
  const [androidpaidfeaturesusage, setandroidpaidfeaturesusage] = useState([]);
  const [iospaidfeaturesusage, setiospaidfeaturesusage] = useState([]);
  const [paidunpaidunpaidfeaturesusage, setpaidunpaidunpaidfeaturesusage] =
    useState([]);
  const [androidunpaidfeaturesusage, setandroidunpaidfeaturesusage] = useState(
    [],
  );
  const [iosunpaidfeaturesusage, setiosunpaidfeaturesusage] = useState([]);
  const [combinedretentiondata, setcombinedretentiondata] = useState("");
  const [combinedavgtimespend, setcombinedavgtimespend] = useState("");
  const [androidConvertRateForMonth, setandroidConvertRateForMonth] =
    useState("");
  const [ConvertRateTillNow, setConvertRateTillNow] = useState("");
  const [iosConvertRateForMonth, setiosConvertRateForMonth] = useState("");
  const [testingData, settestingData] = useState([]);
  const [transcationData, settranscationData] = useState([]);
  const [cashbooktranscation, setcashbooktranscation] = useState([]);
  const [clickedtrans, setclickedtrans] = useState("");
  const [prodserverdetails, setprodserverdetails] = useState([]);
  const [scheduleserverdetails, setscheduleserverdetails] = useState([]);
  const [speechTotextserverdetails, setspeechtexttodetails] = useState([]);
  const [serverInstancedetails, setserverInstancedetails] = useState([]);
  const [isfailednoti, setisfailednoti] = useState(false);
  const [closeSidebar, setcloseSidebar] = useState(false);
  const [notititle, setnotititle] = useState("");
  const [notidesc, setnotidesc] = useState("");
  const [paymentbehaviourday, setpaymentbehaviourday] = useState([]);
  const [maxiTickValue, setmaxiTickValue] = useState("");
  const homedata = localStorage.getItem("homedata");
  const data = JSON.parse(homedata);
  const [totalExpense, settotalExpense] = useState(data?.totalExpense);
  const [expensesdata, setexpensesdata] = useState({
    personalExpense: data?.personalExpense,
    companyExpense: data?.companyExpense,
  });
  const [noticategory, setnoticategory] = useState([]);
  const [notistoreddata, setnotistoreddata] = useState([]);
  const [notistoredcategory, setnotistoredcategory] = useState([]);
  const [allappleaveuserfeaturesusage, setallappleaveuserfeaturesusage] =
    useState([]);
  const [
    androidappleaveuserfeaturesusage,
    setandroidappleaveuserfeaturesusage,
  ] = useState([]);
  const [iosappleaveuserfeaturesusage, setiosappleaveuserfeaturesusage] =
    useState([]);

  const [isterminatesuccess, setisterminatesuccess] = useState(false);
  const [isterminateerror, setisterminateerror] = useState(false);
  const [environmentName, setenvname] = useState("");
  const [addinstancesuccess, setaddinstancesuccess] = useState(false);
  const [addinstanceerror, setaddinstanceerror] = useState(false);
  const [removeinstancesuccess, setremoveinstancesuccess] = useState(false);
  const [removeinstanceerror, setremoveinstanceerror] = useState(false);
  const [singleCoupounDetail, setsingleCoupounDetail] = useState({
    couponCodeDetail: "",
    couponcodedataDetails: "",
    lastWeekDataForCouponCodeOwner: [],
    lastWeekDataForAdmin: [],
  });

  const [width, setwidth] = useState(window.innerWidth);
  const [homeapierr, sethomeapierr] = useState(false);
  const [couponspenddetail, setcouponspentdetail] = useState([]);
  const [selectedUserdetail, setselectedUserdetail] = useState([]);
  const [activeData, setactiveData] = useState([]);

  const [storednotititle, setstorednotititle] = useState("");
  const [storednotidesc, setstorednotidesc] = useState("");
  const [storedcategoryvalue, setstoredcategoryvalue] = useState("");
  const [storedstatusvalue, setstoredstatusvalue] = useState("");
  const [Rawsuggvalue, RawsuggsetValue] = useState("1");
  const [RawsuggfilteredValue, RawsuggsetfilteredValue] = useState(0);
  const [inapppurchasenoOfPayment, setinapppurchasenoOfPayment] = useState("");
  const [usergotDeleted, setusergotDeleted] = useState(false);
  const [adminUserDetail, setadminUserDetail] = useState([]);
  const [checkserverstatusclick, setcheckserverstatusclick] = useState(false);
  const [checkserverstatuserr, setcheckserverstatuserr] = useState(false);
  const [PaidUnpaidfeedbacktabs, PaidUnpaidsetfeedbacktabs] = useState("1");
  const [checkingStatus, setcheckingStatus] = useState(false);
  const [checkingTextMsgCode, setcheckTextMsgCode] = useState(0);
  const [countdown, setCountdown] = useState(5 * 60);
  const [planSellData, setplanSellData] = useState([]);
  const [advertisementLinkData, setadvertisementLinkData] = useState([]);
  const [monthlysaleData, setmonthlysaleData] = useState([]);
  const [loader, setloader] = useState(false);
  const [pdfloader, setpdfloader] = useState(true);
  const [classesCategory, setclassesCategory] = useState([]);
  const [selectclassesCategory, setselectclassesCategory] = useState([]);
  const [classesData, setclassesData] = useState([]);
  const [classDetailedData, setclassDetailedData] = useState([]);
  const [classRatingsData, setclassRatingData] = useState([]);
  const [classCommentsData, setclassCommentData] = useState([]);
  const [adlinksdatebydate, setadlinksdatebydate] = useState([]);
  const [adlinksdata, setadlinks] = useState([]);
  const [writingListData, setwritingListData] = useState([]);
  const [singlewritinglistData, setsinglewritingListData] = useState(JSON.parse(sessionStorage.getItem('singlewritingdata')) || []);
  const [questionlistData, setquestionlistData] = useState([]);
  const [writingquestion, setwritingquestion] = useState("");
  const [writinganswer1, setwritinganswer1] = useState("");
  const [writinganswer2, setwritinganswer2] = useState("");
  const [singlewritingquestion, setsinglewritingquestion] = useState("");
  const [selectedsubcategoryname, setselectedsubcategoryname] = useState("")
  const [classesGrp, setclassesGrp] = useState([])





  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const navigate = useNavigate();

  const changeDate = (value) => {
    var date = new Date(value);
    var monthName = "";
    var day = ("0" + date.getDate()).slice(-2);

    // if (day == 1) monthName = monthNames[date.getMonth()];

    const newstr = monthName + day;

    return newstr;
  };

  const handleFeedbackDetail = (feedbackid, question) => {
    setonClickNavLoader(true);
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    const jwt = createjwt3(user.uid, feedbackid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/feedbackDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setonClickNavLoader(false);
        if (res.status === 200) {
          navigate("/feedbackdetails", {
            state: {
              feedbackdetails: res.data.data.feedbackDetail,
              question: question,
            },
          });
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        setonClickNavLoader(false);
        console.log(err);
      });
  };

  function formatDate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    date = yyyy + "-" + mm + "-" + dd;
    return date;
  }

  const handleCouponDetail = (coupondata) => {
    console.log(coupondata);
    setonClickNavLoader(true);
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    const jwt = createjwt4(user.uid, coupondata.id);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/couponCodeDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setonClickNavLoader(false);
        if (res.status === 200) {
          console.log(res, "po");
          // get coupon table data for last week
          const entries =
            res.data.data?.lastWeekData?.lastWeekDataForCouponCodeOwner &&
            Object.entries(
              res.data.data?.lastWeekData?.lastWeekDataForCouponCodeOwner,
            );

          var result = [];

          for (var i = 0; i < entries?.length; i++) {
            var d = new Date();
            d.setDate(d.getDate() - (i + 1));
            var formattedDate = formatDate(d);
            var obj = {
              Date: formattedDate,
              PaymentCount: entries[i][1],
              Price: entries[i][1] * coupondata.amountThatInfluncerWillGet,
            };
            result.push(obj);
          }

          // Admin View

          const Adminentries =
            res.data.data.lastWeekData.lastWeekDataForAdmin &&
            Object.entries(res.data.data.lastWeekData.lastWeekDataForAdmin);

          var result1 = [];

          for (var i = 0; i < Adminentries?.length; i++) {
            var d1 = new Date();
            d1.setDate(d1.getDate() - (i + 1));
            var formattedDate1 = formatDate(d1);
            var obj = {
              Date: formattedDate1,
              PaymentCount: Adminentries[i][1],
              Price: Adminentries[i][1] * coupondata.amountThatInfluncerWillGet,
            };
            result1.push(obj);
          }

          // navigate("/couponcodedetails", {
          //   state: {
          //     couponcodedataDetails: res.data.data,
          //     coupondata: coupondata,
          //     coupontable: result,
          //     couponCodeDetail: res.data.data.couponCodeDetail,
          //   },
          // });
          setsingleCoupounDetail({
            couponcodedataDetails: res.data.data,
            couponCodeRawData: res.data.data.couponCodeRawData,
            lastWeekDataForCouponCodeOwner: result,
            lastWeekDataForAdmin: result1,
          });
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        setonClickNavLoader(false);
        console.log(err);
      });
  };

  const verifyUserAccountValid = () => {
    const userData = localStorage.getItem("userData");
    const data = JSON.parse(userData);
    if (data.accountStatus === 1) {
      return true;
    }
    return false;
  };

  const updateViewData = () => {
    const homedata = localStorage.getItem("homedata");
    const data = JSON.parse(homedata);
    const userData = JSON.stringify(data.userData);

    localStorage.setItem("userData", userData);

    if (verifyUserAccountValid()) {
      setaverageConvertRateForMonth(data.averageConvertRateForMonth);

      const newObjArr = data.userConvertRateData.map((obj) => {
        return { ...obj, date: changeDate(obj.date) };
      });

      var revnewObjArr = [...newObjArr].reverse();

      const newDailyActiveRate = data.dailyAciveUserData.map((obj) => {
        return {
          ...obj,
          date: changeDate(obj.date),
          totalAmountSettle: Number(obj.totalAmountSettle),
        };
      });
      var revnewDailyActiveRate = [...newDailyActiveRate].reverse();

      // Server Info

      const serverInfo = [];
      serverInfo.push(data?.serverInformation?.ieltsProductionServer);
      serverInfo.push(data?.serverInformation?.speechToTextServer);

      serverInfo.push(data?.serverInformation?.scheduleTaskServer);

      var res = [],
        ans = [];
      for (var i = 0; i < serverInfo.length; i++) {
        res.push(serverInfo[i]?.InstancesHealth);
      }

      console.log(serverInfo);
      res.forEach((obj) => {
        let sum = 0;

        for (let property in obj) {
          if (property !== "Ok") sum += obj[property];
        }

        ans.push(sum);
      });

      const names = ["Prod", "Speech", "Scheduled"];

      for (var j = 0; j < serverInfo.length; j++) {
        var text = serverInfo[j].Causes.toString();
        serverInfo[j].first = serverInfo[j].InstancesHealth.Ok;
        serverInfo[j].second = ans[i];
        serverInfo[j].causesTxt = text;
        serverInfo[j].EnvironmentName = names[j];
      }

      const objec = {
        EnvironmentName: "DB",
        first: data?.serverInformation?.RDSCpuUtility,
        second: data?.serverInformation?.RDSFreeSpace,
      };

      serverInfo.push(objec);
      console.log(serverInfo, "hi");

      setserverInfodata(serverInfo);

      // Set particular server details

      const prodserver = [];

      prodserver.push(data?.serverInformation?.ieltsProductionServer);

      var InstanceHealth1 = [],
        ans1 = [];

      for (var i = 0; i < prodserver.length; i++) {
        InstanceHealth1.push(prodserver[i]?.InstancesHealth);
      }

      InstanceHealth1.forEach((obj) => {
        let sum = 0;

        for (let property in obj) {
          if (property !== "Ok") sum += obj[property];
        }

        ans1.push(sum);
      });

      for (var j = 0; j < prodserver.length; j++) {
        var text1 = prodserver[j].Causes.toString();
        prodserver[j].first = prodserver[j].InstancesHealth.Ok;
        prodserver[j].second = ans1[j];
        prodserver[j].causesTxt = text1;
        prodserver[j].EnvironmentName = names[j];
      }

      setprodserverdetails(prodserver);

      // Schedule

      const scheduleserver = [],
        ans2 = [];

      scheduleserver.push(data?.serverInformation?.scheduleTaskServer);

      var InstanceHealth2 = [];

      for (var i = 0; i < scheduleserver.length; i++) {
        InstanceHealth2.push(scheduleserver[i]?.InstancesHealth);
      }

      InstanceHealth2.forEach((obj) => {
        let sum = 0;

        for (let property in obj) {
          if (property !== "Ok") sum += obj[property];
        }

        ans2.push(sum);
      });

      for (var j = 0; j < scheduleserver.length; j++) {
        var text2 = scheduleserver[j].Causes.toString();
        scheduleserver[j].first = scheduleserver[j].InstancesHealth.Ok;
        scheduleserver[j].second = ans2[j];
        scheduleserver[j].causesTxt = text2;
        scheduleserver[j].EnvironmentName = "Scheduled";
      }

      setscheduleserverdetails(scheduleserver);

      // Speech To Text

      const speechTotext = [];

      speechTotext.push(data?.serverInformation?.speechToTextServer);

      var InstanceHealth3 = [],
        ans3 = [];

      for (var i = 0; i < speechTotext.length; i++) {
        InstanceHealth3.push(speechTotext[i]?.InstancesHealth);
      }

      InstanceHealth3.forEach((obj) => {
        let sum = 0;

        for (let property in obj) {
          if (property !== "Ok") sum += obj[property];
        }

        ans3.push(sum);
      });

      for (var j = 0; j < speechTotext.length; j++) {
        var text3 = speechTotext[j].Causes.toString();
        speechTotext[j].first = speechTotext[j].InstancesHealth.Ok;
        speechTotext[j].second = ans3[j];
        speechTotext[j].causesTxt = text3;
        speechTotext[j].EnvironmentName = "Speech";
      }

      setspeechtexttodetails(speechTotext);

      const newDailyRevenueRate = data?.dailyRevenue.map((obj, keys) => {
        return {
          ...obj,
          date: changeDate(obj.date),
          todayExpense: data?.dailyExpense[keys]?.todayExpense,
          personalExpense: data?.dailyExpense[keys]?.personalExpense,
          companyExpense: data?.dailyExpense[keys]?.companyExpense,
          companySalaryExpense: data?.dailyExpense[keys]?.companySalaryExpense,
          companyServerExpense: data?.dailyExpense[keys]?.companyServerExpense,
          companyUnknownExpense:
            data?.dailyExpense[keys]?.companyUnknownExpense,
          companyAdvertisementExpense:
            data?.dailyExpense[keys]?.companyAdvertisementExpense,
        };
      });

      var revnewDailyRevenueRate = [...newDailyRevenueRate].reverse();
      var maxi = 0;
      for (var i = 0; i < newDailyRevenueRate.length; i++) {
        maxi = Math.max(maxi, newDailyRevenueRate[i].totalAmount);
      }
      maxi = Math.ceil(maxi / 10000) * 10000;
      maxi = maxi / 10000 + 1;
      setmaxiTickValue(maxi);

      // Payment Behaviour in a Day
      var onedaypaymentbehav = Object.keys(
        data?.userPaymentBehaviouData?.oneDayPaymentBehaviour,
      ).map((key) => ({
        key: key,
        data: data?.userPaymentBehaviouData?.oneDayPaymentBehaviour[key]
          ?.paymentData.paymentAmount,
      }));

      var a1 = [];
      var temp_str = 12;
      for (var ii = 1; ii <= 24; ii++) {
        var txt = `${ii - 1}-${ii}`;
        var xaxis =
          ii <= 12 ? `${ii - 1}-${ii}` : `${temp_str} - ${(ii % 13) + 1}`;
        if (ii > 12) temp_str = (ii % 13) + 1;
        // eslint-disable-next-line
        var obj = onedaypaymentbehav.find((obj) => obj.key === txt);
        if (obj === undefined) {
          var nw_obj = {
            xaxis: xaxis,
            oneDayPaymentAmt: 0,
          };
          a1.push(nw_obj);
        } else {
          var nw_obj1 = {
            xaxis: xaxis,
            oneDayPaymentAmt: obj.data,
          };
          a1.push(nw_obj1);
        }
      }
      var twodaypaymentbehav = Object.keys(
        data?.userPaymentBehaviouData?.twoDayPaymentBehaviour,
      ).map((key) => ({
        key: key,
        data: data?.userPaymentBehaviouData?.twoDayPaymentBehaviour[key]
          ?.paymentData.paymentAmount,
      }));

      var a2 = [];
      for (var jj = 1; jj <= 24; jj++) {
        var txt1 = `${jj - 1}-${jj}`;
        // eslint-disable-next-line
        var obj = twodaypaymentbehav.find((obj) => obj.key === txt1);
        if (obj === undefined) {
          var nw_ob = {
            twoDayPaymentAmt: 0,
          };
          a2.push(nw_ob);
        } else {
          var nw_ob1 = {
            twoDayPaymentAmt: obj.data,
          };
          a2.push(nw_ob1);
        }
      }
      var threedaypaymentbehav = Object.keys(
        data?.userPaymentBehaviouData?.threeDayPaymentBehaviour,
      ).map((key) => ({
        key: key,
        data: data?.userPaymentBehaviouData?.threeDayPaymentBehaviour[key]
          ?.paymentData.paymentAmount,
      }));

      var a3 = [];
      for (var kk = 1; kk <= 24; kk++) {
        var txt2 = `${kk - 1}-${kk}`;
        // eslint-disable-next-line
        var obj = threedaypaymentbehav.find((obj) => obj.key === txt2);
        if (obj === undefined) {
          var nw_obj14 = {
            threeDayPaymentAmt: 0,
          };
          a3.push(nw_obj14);
        } else {
          var nw_obj144 = {
            threeDayPaymentAmt: obj.data,
          };
          a3.push(nw_obj144);
        }
      }
      var todaypaymentbehav = Object.keys(
        data?.userPaymentBehaviouData?.todaypaymentBehaviour,
      ).map((key) => ({
        key: key,
        data: data?.userPaymentBehaviouData?.todaypaymentBehaviour[key]
          ?.paymentData.paymentAmount,
      }));

      var a4 = [];
      for (var ll = 1; ll <= 24; ll++) {
        var txt4 = `${ll - 1}-${ll}`;
        // eslint-disable-next-line
        var obj = todaypaymentbehav.find((obj) => obj.key === txt4);
        if (obj === undefined) {
          var nw_obj8 = {
            toDayPaymentAmt: 0,
          };
          a4.push(nw_obj8);
        } else {
          var nw_obj18 = {
            toDayPaymentAmt: obj.data,
          };
          a4.push(nw_obj18);
        }
      }

      var storearr = [];

      for (var l6 = 0; l6 < a1.length; l6++) {
        const obj = {
          str: a1[l6].xaxis,
          oneDay: a1[l6].oneDayPaymentAmt,
          twoDay: a2[l6].twoDayPaymentAmt,
          threeDay: a3[l6].threeDayPaymentAmt,
          today: a4[l6].toDayPaymentAmt,
        };
        storearr.push(obj);
      }

      setpaymentbehaviourday(storearr);

      // console.log(() , "maxi");
      setrazorPayWalletAmount(data.razorPayWalletAmount);
      settotalMoneyGetInBackDates(data.totalMoneyGetInBackDates);
      settotalSuccessfullPaymentInBackDates(
        data.totalSuccessfullPaymentInBackDates,
      );
      settotalProfitgetInBackDatesBeforeRazorPayText(
        data.totalProfitgetInBackDatesBeforeRazorPayText,
      );
      settotalAmountSettleBeforeRazorPayTaxInBackDates(
        data.totalAmountSettleBeforeRazorPayTaxInBackDates,
      );
      settotalAmountSettleAfterRazorPayTaxInBackDates(
        data.totalAmountSettleAfterRazorPayTaxInBackDates,
      );
      seticiciSavingAccount(data.iciciSavingAccount);
      setsettlePercentageByRazorPay(data.settlePercentageByRazorPay);
      setuserComeOnAppInLast30Minute(data.userComeOnAppInLast30Minute);
      setprofitAfterExpense(data.profitAfterExpense);
      settotalProfitgetInBackDatesBeforeRazorPayTextAfterGST(
        data.totalProfitgetInBackDatesBeforeRazorPayTextAfterGST,
      );
      setinapppurchasenoOfPayment(data?.userBuyMembershipUsingInAppPurchase);
      settotalExpense(data.totalExpense);
      setrazorPayXAccount(data.razorPayXAccount);
      settotalAmount(data.totalAmount);
      setandroidConvertRateForMonth(data?.androidConvertRateForMonth);
      setConvertRateTillNow(data?.averageConvertRateTillNow);
      seticiciCurrentAccount(data.iciciCurrentAccount);
      setkotakCurrentAccount(data.kotakCurrentAccount);
      setuserConvertRateData(revnewObjArr);
      setdailyActiveUserRateData(revnewDailyActiveRate);
      setdailyRevenueData(revnewDailyRevenueRate);
      setiosConvertRateForMonth(data.iosConvertRateForMonth);
      setexpensesdata({
        personalExpense: data.personalExpense,
        companyExpense: data.companyExpense,
      });
    } else {
      navigate("/waiting");
    }
  };

  var home_page_intervalId = "";
  var home_page_countdownId = "";
  var isMounted = useRef();
  // var intervalId = "";

  const getHomeData = (user, forceCalculationFromScratch = false) => {
    settimetorefresh(true);
    setcheckingStatus(true);

    const jwt = createjwt39(user?.uid, Date.now(), forceCalculationFromScratch);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/websiteDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        isMounted.current = true;
        setcheckingStatus(false);
        console.log(res.data.data, "response of home website");
        if (res.status === 200) {
          const homedata = JSON.stringify(res.data.data);

          localStorage.setItem("homedata", homedata);

          // if (value === "waiting" && res.data.data.userData.accountStatus === 1) {
          //   navigate("/home")
          // }
          updateViewData();

          const userExistsOnHomePage = localStorage.getItem(
            "userExistsOnHomePage",
          );
          if (userExistsOnHomePage === "true") {
            home_page_intervalId = setInterval(
              () => {
                console.log("Starting refresh now");

                console.log("deleting prev id");
                // clearInterval(home_page_intervalId);
                stophomepageIntervalId();

                const userData = localStorage.getItem("userData");
                const Data = JSON.parse(userData);
                getHomeData(Data);
                //
              },
              5 * 60 * 1000,
            );

            localStorage.setItem("homepageintervalId", home_page_intervalId);
            // localStorage.setItem("countdownId", intervalId)
            console.log(home_page_intervalId);
          } else {
            console.log(userExistsOnHomePage);
          }
        } else {
          console.log("Api code is not 200");
        }
      })
      .catch((err) => {
        sethomeapierr(true);
        console.log(err);
        // setloader(false);
        settimetorefresh(false);
        setcheckingStatus(false);
      });
  };

  // LogOut User
  const handleLogout = () => {
    auth.signOut().then(() => {
      setcloseSidebar(false);
      navigate("/login");
      localStorage.removeItem("userData");
      localStorage.removeItem("homedata");
      localStorage.removeItem("dataEntryPageAccessibility");
      localStorage.removeItem("values");
    });
  };

  const handlecheckAccountAccessibility = (user) => {
    setcheckingStatus(true);

    const jwt = createjwt21(user?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/adminUserAccountDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setcheckingStatus(false);
        if (res.status === 200) {
          let userData = res.data.data.userData;
          let prevUserData = JSON.parse(localStorage.getItem("userData"));
          if (prevUserData.accountLevel !== userData.accountLevel)
            localStorage.removeItem("values");
          localStorage.setItem("userData", JSON.stringify(userData));

          if (userData.accountStatus === 1) {
            if (userData.accountLevel == 3) {
              if (userData.dataEntryPermission == 0) {
                setcheckTextMsgCode(2);
              } else {
                let arr = functionConvertIntegerToArray(
                  userData.dataEntryPermission,
                );
                localStorage.setItem(
                  "dataEntryPageAccessibility",
                  JSON.stringify(arr),
                );
                if (arr[0] == 1) navigate("/speaking");
                else if (arr[0] == 2) navigate("/listeningtest");
                else if (arr[0] == 3) navigate("/reading");
                else if (arr[0] == 4) navigate("/writing");
              }
            } else if (userData.accountLevel === 4) {
              navigate("/ieltsClasses");
            } else if (userData.accountLevel === 43) {
              let arr = functionConvertIntegerToArray(
                userData.dataEntryPermission,
              );
              localStorage.setItem(
                "dataEntryPageAccessibility",
                JSON.stringify(arr),
              );
              navigate("/ieltsClasses");
            } else if (
              userData.accountLevel === 5 ||
              userData.accountLevel === 6
            ) {
              navigate("/feedback");
            } else {
              navigate("/home");
            }
          } else {
            setcheckTextMsgCode(1);
          }
        } else {
          console.log("Api code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        // setloader(false);
        setcheckingStatus(false);
      });
  };
  useEffect(() => {
    if (isMounted.current) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [isMounted.current]);

  useEffect(() => {
    if (countdown === 0) {
      // Refresh the page
      // window.location.reload();
      setCountdown(5 * 60);
      isMounted.current = false;
    }
  }, [countdown]);

  const stophomepageIntervalId = () => {
    const home_page_intervalIds = localStorage.getItem("homepageintervalId");
    // const intervalId = localStorage.getItem("intervalId");

    console.log(home_page_intervalIds, "Homepage interval id stop func call");
    clearInterval(home_page_intervalIds);
    setCountdown(5 * 60);
    isMounted.current = false;
    // clearInterval(intervalId)
  };

  // Get transcation List Data

  const getTranscationList = (user) => {
    settimetorefresh(true);

    const jwt = createjwt9(user?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/transactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        console.log(res.data.data);
        if (res.status === 200) {
          settranscationData(res.data.data);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // Get Cash Book Data

  const getCashBook = (user) => {
    settimetorefresh(true);
    const userdata = JSON.parse(user);
    const jwt = createjwt9(userdata?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/cashBookTransactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        // console.log(res.data.data);
        if (res.status === 200) {
          setcashbooktranscation(res.data.data.TransactionList);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // Cashbook All Company
  const getCashBookAllCompany = (user) => {
    settimetorefresh(true);
    const userdata = JSON.parse(user);
    const jwt = createjwt9(userdata?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/cashBookTransactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        // console.log(res.data.data);
        if (res.status === 200) {
          var nw_arr = res.data.data.TransactionList.filter(
            (obj) => obj.paymentCategory === 1,
          );
          setcashbooktranscation(nw_arr);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // CashBook All Personal

  const getCashBookAllPersonal = (user) => {
    settimetorefresh(true);
    const userdata = JSON.parse(user);
    const jwt = createjwt9(userdata?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/cashBookTransactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        // console.log(res.data.data);
        if (res.status === 200) {
          var nw_arr = res.data.data.TransactionList.filter(
            (obj) => obj.paymentCategory === 0,
          );
          setcashbooktranscation(nw_arr);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // CashBook Credit All

  const getCashBookCredit = (user) => {
    settimetorefresh(true);
    const userdata = JSON.parse(user);
    const jwt = createjwt9(userdata?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/cashBookTransactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        // console.log(res.data.data);
        if (res.status === 200) {
          var nw_arr = res.data.data.TransactionList.filter(
            (obj) => obj.transactionStatus === 1,
          );
          setcashbooktranscation(nw_arr);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };
  // Cashbook Credit Company
  const getCashBookCreditCompany = (user) => {
    settimetorefresh(true);
    const userdata = JSON.parse(user);
    const jwt = createjwt9(userdata?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/cashBookTransactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        // console.log(res.data.data);
        if (res.status === 200) {
          var nw_arr = res.data.data.TransactionList.filter(
            (obj) => obj.transactionStatus === 1 && obj.paymentCategory === 1,
          );
          setcashbooktranscation(nw_arr);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // CashBook Credit Personal
  const getCashBookCreditPersonal = (user) => {
    settimetorefresh(true);
    const userdata = JSON.parse(user);
    const jwt = createjwt9(userdata?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/cashBookTransactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        // console.log(res.data.data);
        if (res.status === 200) {
          var nw_arr = res.data.data.TransactionList.filter(
            (obj) => obj.transactionStatus === 1 && obj.paymentCategory === 0,
          );
          setcashbooktranscation(nw_arr);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // CashBook Debit All
  const getCashBookDebit = (user) => {
    settimetorefresh(true);
    const userdata = JSON.parse(user);
    const jwt = createjwt9(userdata?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/cashBookTransactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        // console.log(res.data.data);
        if (res.status === 200) {
          var nw_arr = res.data.data.TransactionList.filter(
            (obj) => obj.transactionStatus === 0,
          );
          setcashbooktranscation(nw_arr);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };
  // Cashbook Debit Company
  const getCashBookDebitCompany = (user) => {
    settimetorefresh(true);
    const userdata = JSON.parse(user);
    const jwt = createjwt9(userdata?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/cashBookTransactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        // console.log(res.data.data);
        if (res.status === 200) {
          var nw_arr = res.data.data.TransactionList.filter(
            (obj) => obj.transactionStatus === 0 && obj.paymentCategory === 1,
          );
          setcashbooktranscation(nw_arr);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // CashBook Debit Personal
  const getCashBookDebitPersonal = (user) => {
    settimetorefresh(true);
    const userdata = JSON.parse(user);
    const jwt = createjwt9(userdata?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/cashBookTransactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        // console.log(res.data.data);
        if (res.status === 200) {
          var nw_arr = res.data.data.TransactionList.filter(
            (obj) => obj.transactionStatus === 0 && obj.paymentCategory === 0,
          );
          setcashbooktranscation(nw_arr);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // get Testing Data for Speech To Text Control

  const getQuestions = (user, category) => {
    settimetorefresh(true);
    const jwt = createjwt8(user?.uid, category);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/feedbacgetTestingDataForSpeechToTextTechnologykControl/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          settestingData(res.data.data.testingData);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // Handle Notifications Category

  const handleNotificationsCategory = (category) => {
    const notificationData = sessionStorage.getItem("notificationData");
    const Data = JSON.parse(notificationData);
    var nw_arr =
      Data?.Notifications &&
      Data?.Notifications.filter((res) => res.category === category);
    setnotifications(nw_arr);
  };

  const handleNotificationsStoredCategory = (category) => {
    const notificationData = sessionStorage.getItem("notificationStoredData");
    const Data = JSON.parse(notificationData);
    const userData = localStorage.getItem("userData");

    if (!notificationData)
      getNotificationStoredData(JSON.parse(userData), "Category");
    var nw_arr =
      Data?.Notifications &&
      Data?.Notifications.filter((res) => res.category === category);
    setnotistoreddata(nw_arr);
    const data1 = nw_arr?.filter((data) => data.status === 1);

    setactiveData(data1);
  };

  // Handle Notifications Stored Update
  const NotificationStoredUpdate = (action, notiId, newValue, side) => {
    const jwt = createjwt18(action, notiId);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/notificationUpdate/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // settimetorefresh(false);
        // if (res.status === 200) {
        //   const notificationData = JSON.stringify(res.data.data);
        //   sessionStorage.setItem("notificationData", notificationData);
        //   updateNotificationView();
        // } else {
        //   console.log("Api Status is not 200");
        // }
        // console.log(res);
        // if (newValue !== "all") handleNotificationsStoredCategory(newValue);
        // else updateNotificationStoredView();
        const userData = localStorage.getItem("userData");

        const Data = JSON.parse(userData);
        getNotificationStoredData(Data, "update", newValue, side);
      })
      .catch((err) => {
        // settimetorefresh(false);
        console.log(err);
      });
  };

  // Handle Stored Notification Edit And Add
  const NotificationStoredEditAndAdd = (
    action,
    notiId,
    notiTitle,
    notiDesc,
    notiCategory,
    notiStatus,
    type,
  ) => {
    const jwt = createjwt19(
      action,
      notiId,
      notiTitle,
      notiDesc,
      notiCategory,
      notiStatus,
      type,
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/notificationUpdate/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // settimetorefresh(false);
        // if (res.status === 200) {
        //   const notificationData = JSON.stringify(res.data.data);
        //   sessionStorage.setItem("notificationData", notificationData);
        //   updateNotificationView();
        // } else {
        //   console.log("Api Status is not 200");
        // }
        // console.log(res);
        // if (newValue !== "all") handleNotificationsStoredCategory(newValue);
        // else updateNotificationStoredView();
        console.log(res);
        navigate("/notifications");
      })
      .catch((err) => {
        // settimetorefresh(false);
        console.log(err);
      });
  };

  //  Get Notification Data from Api

  const updateNotificationView = () => {
    const notificationData = sessionStorage.getItem("notificationData");
    const Data = JSON.parse(notificationData);
    var nw_arr = [];
    Data?.Notifications &&
      Data?.Notifications?.slice(0, 50)?.map((res) =>
        nw_arr.push(res?.category),
      );
    var categories = nw_arr.filter(
      (value, index, array) => array.indexOf(value) === index,
    );
    const sortedCategories = [
      "Morning",
      "Brunch",
      "Afternoon",
      "PaidUser",
      "EveningUnpaid",
      "Night",
      "Custom",
    ];
    categories.sort((a, b) => {
      const aIndex = sortedCategories.indexOf(a);
      const bIndex = sortedCategories.indexOf(b);
      return aIndex - bIndex;
    });
    setnoticategory(categories);
    setnotifications(Data?.Notifications);
  };

  const getNotificationData = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/notificationHistoryFetch/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const notificationData = JSON.stringify(res.data.data);
          sessionStorage.setItem("notificationData", notificationData);
          updateNotificationView();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // Get NotificationsStored Data

  const updateNotificationStoredView = (type) => {
    const notificationData = sessionStorage.getItem("notificationStoredData");
    const Data = JSON.parse(notificationData);
    var nw_arr = [];
    Data?.Notifications &&
      Data?.Notifications?.map((res) => nw_arr.push(res?.category));
    var categories = nw_arr.filter(
      (value, index, array) => array.indexOf(value) === index,
    );
    console.log(categories);
    var sortedCategories = [
      "Morning",
      "Brunch",
      "Afternoon",
      "Evening",
      "PaidUser",
      "Night",
      "LastTrack",
      "inspirationToBuy",
      "Custom",
    ];
    categories.sort((a, b) => {
      var aIndex = sortedCategories.indexOf(a);
      var bIndex = sortedCategories.indexOf(b);
      return aIndex - bIndex;
    });
    setnotistoredcategory(categories);

    if (type === "category") {
      handleNotificationsStoredCategory("Morning");
      return;
    }
    setnotistoreddata(Data?.Notifications);
    const data1 = Data?.Notifications?.filter((data) => data.status === 1);

    setactiveData(data1);
  };

  const getNotificationStoredData = (user, type, newValue, side) => {
    settimetorefresh(true);
    const jwt = createjwt2(user.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/notificationStoredFetch/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const notificationData = JSON.stringify(res.data.data);
          sessionStorage.setItem("notificationStoredData", notificationData);
          if (type === "update") {
            var nw_arr = res?.data?.data?.Notifications;
            if (newValue !== "all") {
              nw_arr = res?.data?.data?.Notifications?.filter(
                (notification) => notification.category === newValue,
              );
            }
            console.log(nw_arr);
            setnotistoreddata(nw_arr);
            const filteredData = nw_arr?.filter(
              (data) => data.status === Number(side),
            );
            console.log(filteredData);
            //   setfilteredData(data)
            setactiveData(filteredData);
            return;
          }
          updateNotificationStoredView(type);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // Send Custom Notifications

  const handleCustomNoti = (
    user,
    notificationTitle,
    notificationDescription,
    notificationFilter,
    userBackDate,
  ) => {
    const jwt = createjwt11(
      user?.uid,
      notificationTitle,
      notificationDescription,
      notificationFilter,
      userBackDate,
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/sendCustomNotification/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          navigate("/notifications");
        } else {
          setisfailednoti(true);

          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        setisfailednoti(true);
        console.log(err);
      });
  };

  const sortingFunc = (categories, sortedCategories) => {
    categories.sort((a, b) => {
      const aIndex = sortedCategories.indexOf(a);
      const bIndex = sortedCategories.indexOf(b);
      return aIndex - bIndex;
    });
    return categories;
  };

  const updateClassesCategory = (result, category, data, type) => {
    if (type === 'live') {
      let categoryWiseData = result?.filter((res) => res === category);
      setclassesData(data?.[categoryWiseData]);
    }
    else {
      let categoryWiseData = data?.filter((res) => res.category === category);
      setclassesData(categoryWiseData);
    }
  };

  // Get Classes Category

  const setDataAccordingToType = (result, category, type) => {
    let categoryArr = [];

    let results = Object.keys(result)
    if (type !== 'live') {
      result.map((res) => categoryArr.push(res.category));
      categoryArr = Array.from(new Set(categoryArr));
    }
    const sortedCategories = ["Speaking", "Reading", "Listening", "Writing"];
    let resultCategory = type === 'live' ? sortingFunc(results, sortedCategories) : sortingFunc(categoryArr, sortedCategories);
    console.log(results)
    setclassesCategory(resultCategory);
    let tempArr = [];
    for (let i = 0; i < sortedCategories.length; i++) {
      let obj = {
        value: i,
        label: sortedCategories[i],
      };
      tempArr.push(obj);
    }

    setselectclassesCategory(tempArr);

    if (category) {
      updateClassesCategory(results, category, result, type);
    } else {
      updateClassesCategory(results, "Speaking", result, type);
    }
    settimetorefresh(false);
  };

  const addClassGrp = (user, classGroupName, thumbnail) => {
    settimetorefresh(true);
    const jwt = createjwt48(user.uid, classGroupName);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    bodyFormData.append("thumbnail", thumbnail)
    axios({
      method: "post",
      url: `${api_url}/addLiveClassesType/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {

          settimetorefresh(false);
          getClassesGrp(user, "webuid", "webview")

        } else {
          settimetorefresh(false);

          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  }

  // Get Classes Grp
  const getClassesGrp = (user, uniqueDeviceId, platform) => {
    settimetorefresh(true);
    const jwt = createjwt46(user.uid, uniqueDeviceId, platform);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/fetchAllLiveClassType/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {

          settimetorefresh(false);
          setclassesGrp(res.data.data.LiveClassesTypeData)

        } else {
          settimetorefresh(false);

          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  const getClassesData = (user, category, typeOfClass, classGroupUniqueId) => {
    settimetorefresh(true);
    const jwt = createjwt47(user?.uid, classGroupUniqueId)
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/fetchAllLiveClassesRecord/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(typeOfClass);
          // let result = res.data.data.liveClasses;
          sessionStorage.setItem(
            "classesData",
            JSON.stringify(res.data.data.liveClasses),
          );
          sessionStorage.setItem(
            "scheduleClassesData",
            JSON.stringify(res.data.data.scheduleClassData),
          );
          if (typeOfClass === "one") {
            setDataAccordingToType(res.data.data.liveClasses, category, "live");
          } else if (typeOfClass === "two")
            setDataAccordingToType(res.data.data.scheduleClassData, category, "schedule");
        } else {
          settimetorefresh(false);

          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // Get Detail Of a Class

  const getDetailsOfAClass = (user, classFile, classUniqueId) => {
    const jwt = createjwt36(user?.uid, classFile, classUniqueId);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/liveClassDetailFetch/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          console.log(res.data.data.classData);
          setclassDetailedData(res.data.data.classData);
          setclassRatingData(res.data.data.rating);
          setclassCommentData(res.data.data.comments);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // Schedule Classes Data
  const scheduleClassesData = (
    user,
    classFileUrl,
    classTitle,
    classCategory,
    classStatingTime,
    classEndTime,
    classNumber,
    classGroupUniqueId
  ) => {
    const jwt = createjwt37(
      user?.uid,
      classFileUrl,
      classTitle,
      classCategory,
      classStatingTime,
      classEndTime,
      classNumber,
      classGroupUniqueId
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/addScheduleClassRecord/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          console.log(res);
          navigate(`/ieltsClasses/${classGroupUniqueId}`, {
            state: {
              classGrp: classGroupUniqueId
            }
          })
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
        toast.error("Error Occured While Performing the Action.");
      });
  };

  // Edit Class data
  const handleEditClassData = (
    user,
    classUniqueId,
    valueId,
    newValue,
    classFile,
    type,
  ) => {
    const jwt = createjwt38(user?.uid, classUniqueId, valueId, newValue);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    if (type === "ScheduleClass") {
      axios({
        method: "post",
        url: `${api_url}/updateScheduleClassRecord/`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          settimetorefresh(false);
          if (res.status === 200) {
            console.log(res);
            const classGroupUniqueId = JSON.parse(sessionStorage.getItem("classGroupUniqueId"))
            if (valueId == 2) navigate(`/ieltsClasses/${classGroupUniqueId}`, {
              state: {
                classGrp: classGroupUniqueId
              }
            });
            else {
              if (valueId == 7)
                getDetailsOfAClass(user, newValue, classUniqueId);
              else getDetailsOfAClass(user, classFile, classUniqueId);
            }
          } else {
            console.log("Api Status is not 200");
          }
        })
        .catch((err) => {
          settimetorefresh(false);
          console.log(err);
          toast.error("Error Occured While Performing the Action.");
        });
      // console.log(valueId);
    } else {
      axios({
        method: "post",
        url: `${api_url}/updateLiveClassesRecord/`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          settimetorefresh(false);
          if (res.status === 200) {
            console.log(res);
            if (valueId == 1) getDetailsOfAClass(user, newValue, classUniqueId);
            else getDetailsOfAClass(user, classFile, classUniqueId);
          } else {
            console.log("Api Status is not 200");
          }
        })
        .catch((err) => {
          settimetorefresh(false);
          console.log(err);
          toast.error("Error Occured While Performing the Action.");
        });
    }
  };

  // Add Classes Data

  const addClassesData = (
    user,
    classFileUrl,
    classTitle,
    classCategory,
    classDuration,
    classGroupUniqueId
  ) => {
    const jwt = createjwt35(
      user?.uid,
      classFileUrl,
      classTitle,
      classCategory,
      classDuration,
      classGroupUniqueId
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/addLiveClassesRecord/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          console.log(res);
          navigate(`/ieltsClasses/${classGroupUniqueId}`, {
            state: {
              classGrp: classGroupUniqueId
            }
          })
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
        toast.error("Error Occured While Performing the Action.");
      });
  };

  // Get FeedBack Data From Api

  const updateFeedBackData = () => {
    const feedbackData = sessionStorage.getItem("feedbackData");
    const Data = JSON.parse(feedbackData);
    setfeedbacks(Data);
  };

  const getFeedBackData = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/userFeedbackFetch/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const feedbackData = JSON.stringify(res.data.data);
          console.log(res.data.data);
          sessionStorage.setItem("feedbackData", feedbackData);
          updateFeedBackData();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // Add Feedbacks Handle Api

  const HandleAddFeedbacks = (
    question,
    questionType,
    feedbackGroup,
    feedbackAnsOpt,
  ) => {
    const jwt = createjwt26(
      question,
      questionType,
      feedbackGroup,
      feedbackAnsOpt,
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/savedFeedbackQuestions/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          navigate("/feedback");
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HandleEditOnlyFeedbacks = (
    question,
    questionNumber,
    questionType,
    feedbackGroup,
    feedbackId,
    optionalFeedbackAnswerOptions,
  ) => {
    const jwt = createjwt28(
      question,
      questionNumber,
      questionType,
      feedbackGroup,
      optionalFeedbackAnswerOptions,
      feedbackId,
      2,
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/editFeedbackQuestion/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          navigate("/feedback");
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //  Activate,DeActivate And Delete Feedbacks Data

  const HandleEditFeedbacks = (feedbackAction, feedbackId) => {
    const jwt = createjwt27(feedbackAction, feedbackId);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/editFeedbackQuestion/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          const userData = localStorage.getItem("userData");

          const Data = JSON.parse(userData);
          getfeedbackControl(Data);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRawSuggFeedbackActions = (action, uniqueId) => {
    sessionStorage.setItem("lastfilter", JSON.stringify(Rawsuggvalue));
    sessionStorage.setItem(
      "lastsubfilter",
      JSON.stringify(RawsuggfilteredValue),
    );
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);

    const jwt = createjwt20(Data?.uid, action, uniqueId);

    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/actionOnUserFeedback/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          getFeedBackData(Data);
          const lastfilter = JSON.parse(sessionStorage.getItem("lastfilter"));
          const lastsubfilter = JSON.parse(
            sessionStorage.getItem("lastsubfilter"),
          );
          RawsuggsetValue(lastfilter);
          RawsuggsetfilteredValue(lastsubfilter);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get Coupon Code Data

  const updateCouponCodeData = () => {
    const couponCodeData = sessionStorage.getItem("couponCodeData");
    const Data = JSON.parse(couponCodeData);
    setcouponcodedata(Data);
  };

  const getCouponCodeData = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/couponCodeList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          console.log(res.data.data);
          const couponCodeData = JSON.stringify(res.data.data);
          sessionStorage.setItem("couponCodeData", couponCodeData);
          updateCouponCodeData();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // get UserBehaviour data from Api

  const updateUserBehaviourData = () => {
    const userBehaviourData = sessionStorage.getItem("userBehaviourData");
    const Data = JSON.parse(userBehaviourData);

    // const newObjArr = data.map((obj, key) => {
    //   return { ...obj, xaxis: key };
    // });

    if (Data) {
      var alluserpaymentdata = Object.keys(Data?.paymentBehaviourData?.all).map(
        (key) => {
          return Data?.paymentBehaviourData?.all[key];
        },
      );
      var androiduserpaymentdata = Object.keys(
        Data?.paymentBehaviourData?.android,
      ).map((key) => {
        return Data?.paymentBehaviourData?.android[key];
      });
      var iOSuserpaymentdata = Object.keys(Data?.paymentBehaviourData?.iOS).map(
        (key) => {
          return Data?.paymentBehaviourData?.iOS[key];
        },
      );

      // var paymentbehavday = Object.keys(
      //   Data?.paymentBehaviourData?.paymentBehaviourInADay
      // ).map((key) => {
      //   return Data?.paymentBehaviourData?.paymentBehaviourInADay[key];
      // });

      // var paymentbehavdaykeys = Object.keys(
      //   Data?.paymentBehaviourData?.paymentBehaviourInADay
      // );

      console.log(Data?.paymentBehaviourData?.paymentBehaviourInADay);

      var onedaypaymentbehav = Object.keys(
        Data?.paymentBehaviourData?.paymentBehaviourInADay
          ?.oneDayPaymentBehaviour,
      ).map((key) => ({
        key: key,
        data: Data?.paymentBehaviourData?.paymentBehaviourInADay
          ?.oneDayPaymentBehaviour[key]?.paymentData.paymentAmount,
      }));

      var a1 = [];
      var temp_str = 12;
      for (var ii = 1; ii <= 24; ii++) {
        var txt = `${ii - 1}-${ii}`;
        var xaxis =
          ii <= 12 ? `${ii - 1}-${ii}` : `${temp_str} - ${(ii % 13) + 1}`;
        if (ii > 12) temp_str = (ii % 13) + 1;
        // eslint-disable-next-line
        var obj = onedaypaymentbehav.find((obj) => obj.key === txt);
        if (obj === undefined) {
          var nw_obj = {
            xaxis: xaxis,
            oneDayPaymentAmt: 0,
          };
          a1.push(nw_obj);
        } else {
          var nw_obj1 = {
            xaxis: xaxis,
            oneDayPaymentAmt: obj.data,
          };
          a1.push(nw_obj1);
        }
      }
      var twodaypaymentbehav = Object.keys(
        Data?.paymentBehaviourData?.paymentBehaviourInADay
          ?.twoDayPaymentBehaviour,
      ).map((key) => ({
        key: key,
        data: Data?.paymentBehaviourData?.paymentBehaviourInADay
          ?.twoDayPaymentBehaviour[key]?.paymentData.paymentAmount,
      }));

      var a2 = [];
      for (var jj = 1; jj <= 24; jj++) {
        var txt1 = `${jj - 1}-${jj}`;
        // eslint-disable-next-line
        var obj = twodaypaymentbehav.find((obj) => obj.key === txt1);
        if (obj === undefined) {
          var nw_ob = {
            twoDayPaymentAmt: 0,
          };
          a2.push(nw_ob);
        } else {
          var nw_ob1 = {
            twoDayPaymentAmt: obj.data,
          };
          a2.push(nw_ob1);
        }
      }
      var threedaypaymentbehav = Object.keys(
        Data?.paymentBehaviourData?.paymentBehaviourInADay
          ?.threeDayPaymentBehaviour,
      ).map((key) => ({
        key: key,
        data: Data?.paymentBehaviourData?.paymentBehaviourInADay
          ?.threeDayPaymentBehaviour[key]?.paymentData.paymentAmount,
      }));

      var a3 = [];
      for (var kk = 1; kk <= 24; kk++) {
        var txt2 = `${kk - 1}-${kk}`;
        // eslint-disable-next-line
        var obj = threedaypaymentbehav.find((obj) => obj.key === txt2);
        if (obj === undefined) {
          var nw_obj14 = {
            threeDayPaymentAmt: 0,
          };
          a3.push(nw_obj14);
        } else {
          var nw_obj144 = {
            threeDayPaymentAmt: obj.data,
          };
          a3.push(nw_obj144);
        }
      }
      var todaypaymentbehav = Object.keys(
        Data?.paymentBehaviourData?.paymentBehaviourInADay
          ?.todaypaymentBehaviour,
      ).map((key) => ({
        key: key,
        data: Data?.paymentBehaviourData?.paymentBehaviourInADay
          ?.todaypaymentBehaviour[key]?.paymentData.paymentAmount,
      }));

      var a4 = [];
      for (var ll = 1; ll <= 24; ll++) {
        var txt4 = `${ll - 1}-${ll}`;
        // eslint-disable-next-line
        var obj = todaypaymentbehav.find((obj) => obj.key === txt4);
        if (obj === undefined) {
          var nw_obj8 = {
            toDayPaymentAmt: 0,
          };
          a4.push(nw_obj8);
        } else {
          var nw_obj18 = {
            toDayPaymentAmt: obj.data,
          };
          a4.push(nw_obj18);
        }
      }

      var storearr = [];

      for (var l6 = 0; l6 < a1.length; l6++) {
        const obj = {
          str: a1[l6].xaxis,
          oneDay: a1[l6].oneDayPaymentAmt,
          twoDay: a2[l6].twoDayPaymentAmt,
          threeDay: a3[l6].threeDayPaymentAmt,
          today: a4[l6].toDayPaymentAmt,
        };
        storearr.push(obj);
      }

      setpaymentbehaviourday(storearr);

      var len = Math.max(
        alluserpaymentdata.length,
        androiduserpaymentdata.length,
        iOSuserpaymentdata.length,
      );

      var newObjArr = [];
      for (var l1 = 0; l1 < len; l1++) {
        var obj = {
          xaxis: l1,
          alluserconvert: alluserpaymentdata[l1]?.convertUserPercentage,
          androiduserconvert: androiduserpaymentdata[l1]?.convertUserPercentage,
          iOSuserconvert: iOSuserpaymentdata[l1]?.convertUserPercentage,
        };
        newObjArr.push(obj);
      }

      setpaymentbehaviourgraph(newObjArr);

      // Paid/Unpaid Retention Data

      const alluserretentiondataconst = Object.values(
        Data?.allUserRetentionData,
      );
      const paiduserretentiondataconst = Object.values(
        Data?.paidUserRetentionData,
      );
      const unpaiduserretentiondataconst = Object.values(
        Data?.unpaidUserRetentionData,
      );

      const appleaveuserretentiondataconstkeys = Object.keys(
        Data?.allAppleaveUserRetensionData,
      );
      var lastkey;
      lastkey =
        appleaveuserretentiondataconstkeys[
        appleaveuserretentiondataconstkeys.length - 1
        ];
      var appleaveuserretentiondataconst = [];
      for (var t1 = 0; t1 <= lastkey; t1++) {
        var obj = {
          data: Data?.allAppleaveUserRetensionData[t1]?.activeUserPercentage,
        };
        appleaveuserretentiondataconst.push(obj);
      }

      const retentiondataconstlen = Math.max(
        alluserretentiondataconst.length,
        paiduserretentiondataconst.length,
        unpaiduserretentiondataconst.length,
        appleaveuserretentiondataconst.length,
      );

      var newres = [];
      for (var i = 0; i < retentiondataconstlen; i++) {
        const obj = {
          xaxis: i,
          alluseractivepercentage: Number(
            alluserretentiondataconst[i]?.activeUserPercentage,
          ),
          paiduseractivepercentage: Number(
            paiduserretentiondataconst[i]?.activeUserPercentage,
          ),
          unpaiduseractivepercentage: Number(
            unpaiduserretentiondataconst[i]?.activeUserPercentage,
          ),
          appuserleaveactivepercentage: Number(
            appleaveuserretentiondataconst[i]?.data,
          ),
        };
        newres.push(obj);
      }
      setpaidunpaidretentiondata(newres);

      // Android Retention Data

      const alluserretentiondataconstandroid = Object.values(
        Data?.androidAppRetentionData,
      );
      const paiduserretentiondataconstandroid = Object.values(
        Data?.androidAppPaidUserRetentionData,
      );
      const unpaiduserretentiondataconstandroid = Object.values(
        Data?.androidAppUnpaidUserRetentionData,
      );

      const androidappleaveuserretentiondataconstkeys = Object.keys(
        Data?.androidAppleaveUserRetensionData,
      );
      lastkey =
        androidappleaveuserretentiondataconstkeys[
        androidappleaveuserretentiondataconstkeys.length - 1
        ];
      var androidappleaveuserretentiondataconst = [];
      for (var t2 = 0; t2 <= lastkey; t2++) {
        var ob = {
          data: Data?.androidAppleaveUserRetensionData[t2]
            ?.activeUserPercentage,
        };
        androidappleaveuserretentiondataconst.push(ob);
      }
      const androidretentiondataconstlen = Math.max(
        alluserretentiondataconstandroid.length,
        paiduserretentiondataconstandroid.length,
        unpaiduserretentiondataconstandroid.length,
        androidappleaveuserretentiondataconst.length,
      );
      var newres1 = [];
      for (var i11 = 0; i11 < androidretentiondataconstlen; i11++) {
        const obj = {
          xaxis: i11,
          alluseractivepercentage: Number(
            alluserretentiondataconstandroid[i11]?.activeUserPercentage,
          ),
          paiduseractivepercentage: Number(
            paiduserretentiondataconstandroid[i11]?.activeUserPercentage,
          ),
          unpaiduseractivepercentage: Number(
            unpaiduserretentiondataconstandroid[i11]?.activeUserPercentage,
          ),
          appuserleaveactivepercentage: Number(
            androidappleaveuserretentiondataconst[i11]?.data,
          ),
        };
        newres1.push(obj);
      }
      setandroidretentiondata(newres1);

      // Ios Retention Data

      const alluserretentiondataconstios = Object.values(
        Data?.iosAppRetentionData,
      );
      const paiduserretentiondataconstios = Object.values(
        Data?.iosAppPaidUserRetentionData,
      );
      const unpaiduserretentiondataconstios = Object.values(
        Data?.iosAppUnpaidUserRetentionData,
      );
      const iosappleaveuserretentiondataconstkeys = Object.keys(
        Data?.iosAppleaveUserRetensionData,
      );
      lastkey =
        iosappleaveuserretentiondataconstkeys[
        iosappleaveuserretentiondataconstkeys.length - 1
        ];
      var iosappleaveuserretentiondataconst = [];
      for (var t3 = 0; t3 <= lastkey; t3++) {
        var o = {
          data: Data?.iosAppleaveUserRetensionData[t3]?.activeUserPercentage,
        };
        iosappleaveuserretentiondataconst.push(o);
      }
      const iosretentiondataconstlen = Math.max(
        alluserretentiondataconstios.length,
        paiduserretentiondataconstios.length,
        unpaiduserretentiondataconstios.length,
        iosappleaveuserretentiondataconst.length,
      );
      var newres22 = [];
      for (var i20 = 0; i20 < iosretentiondataconstlen; i20++) {
        const obj = {
          xaxis: i20,
          alluseractivepercentage: Number(
            alluserretentiondataconstios[i20]?.activeUserPercentage,
          ),
          paiduseractivepercentage: Number(
            paiduserretentiondataconstios[i20]?.activeUserPercentage,
          ),
          unpaiduseractivepercentage: Number(
            unpaiduserretentiondataconstios[i20]?.activeUserPercentage,
          ),
          appuserleaveactivepercentage: Number(
            iosappleaveuserretentiondataconst[i20]?.data,
          ),
        };
        newres22.push(obj);
      }
      setiosretentiondata(newres22);

      // Paid/Unpaid Avg Time Spent By User

      const temp1 = Object.values(Data?.allUserAverageTimeSpendData);
      const paidaveTimeSpendconst = Object.values(
        Data?.paidUserAverageTimeSpendData,
      );
      const unpaidaveTimeSpendconst = Object.values(
        Data?.unpaidUserAverageTimeSpendData,
      );
      const avgTimespendconstkeys = Object.keys(
        Data?.allAppleaveUserAverageTimeSpendData,
      );
      lastkey = avgTimespendconstkeys[avgTimespendconstkeys.length - 1];
      var allavgtimespendtimespendappuserleaveconst = [];
      for (var t4 = 0; t4 <= lastkey; t4++) {
        var op = {
          data:
            Number(
              Data?.allAppleaveUserAverageTimeSpendData[t4]?.averageTimeSpend,
            ) / 60,
        };
        allavgtimespendtimespendappuserleaveconst.push(op);
      }

      var arr1 = [];

      for (var i1 = 0; i1 < temp1.length; i1++) {
        temp1[i1].averageTimeSpend = Number(temp1[i1].averageTimeSpend) / 60;
      }
      for (var i3 = 0; i3 < paidaveTimeSpendconst.length; i3++) {
        paidaveTimeSpendconst[i3].averageTimeSpend =
          Number(paidaveTimeSpendconst[i3].averageTimeSpend) / 60;
      }
      for (var i2 = 0; i2 < unpaidaveTimeSpendconst.length; i2++) {
        unpaidaveTimeSpendconst[i2].averageTimeSpend =
          Number(unpaidaveTimeSpendconst[i2].averageTimeSpend) / 60;
      }

      const avgtimespendlen = Math.max(
        temp1.length,
        paidaveTimeSpendconst.length,
        unpaidaveTimeSpendconst.length,
        allavgtimespendtimespendappuserleaveconst.length,
      );

      for (var i1 = 0; i1 < avgtimespendlen; i1++) {
        const obj = {
          xaxis: i1,
          alluseravgTimeSpend: temp1[i1]?.averageTimeSpend,
          paiduseravgTimeSpend: paidaveTimeSpendconst[i1]?.averageTimeSpend,
          unpaiduseravgTimeSpend: unpaidaveTimeSpendconst[i1]?.averageTimeSpend,
          appleaveuseravgTimeSpend:
            allavgtimespendtimespendappuserleaveconst[i1]?.data,
        };
        arr1.push(obj);
      }
      setpaidunpaidavgtimespend(arr1);

      // Android Avg time spend

      const temp2 = Object.values(Data?.androidAppAverageTimeSpendData);
      const paidaveTimeSpendconstandroid = Object.values(
        Data?.androidAppPaidUserAverageTimeSpendData,
      );
      const unpaidaveTimeSpendconstandroid = Object.values(
        Data?.androidAppUnpaidUserAverageTimeSpendData,
      );
      const androidavgTimespendconstkeys = Object.keys(
        Data?.androidAppleaveUserAverageTimeSpendData,
      );
      lastkey =
        androidavgTimespendconstkeys[androidavgTimespendconstkeys.length - 1];
      var androidavgtimespendtimespendappuserleaveconst = [];
      for (var t5 = 0; t5 <= lastkey; t5++) {
        var opp = {
          data:
            Number(
              Data?.androidAppleaveUserAverageTimeSpendData[t5]
                ?.averageTimeSpend,
            ) / 60,
        };
        androidavgtimespendtimespendappuserleaveconst.push(opp);
      }

      var arr2 = [];

      for (var i12 = 0; i12 < temp2.length; i12++) {
        temp2[i12].averageTimeSpend = Number(temp2[i12].averageTimeSpend) / 60;
      }
      for (var i13 = 0; i13 < paidaveTimeSpendconstandroid.length; i13++) {
        paidaveTimeSpendconstandroid[i13].averageTimeSpend =
          Number(paidaveTimeSpendconstandroid[i13].averageTimeSpend) / 60;
      }
      for (var i21 = 0; i21 < unpaidaveTimeSpendconstandroid.length; i21++) {
        unpaidaveTimeSpendconstandroid[i21].averageTimeSpend =
          Number(unpaidaveTimeSpendconstandroid[i21].averageTimeSpend) / 60;
      }

      const androidavgtimespendlen = Math.max(
        temp2.length,
        paidaveTimeSpendconstandroid.length,
        unpaidaveTimeSpendconstandroid.length,
        androidavgtimespendtimespendappuserleaveconst.length,
      );

      for (var i15 = 0; i15 < androidavgtimespendlen; i15++) {
        const obj = {
          xaxis: i15,
          alluseravgTimeSpend: temp2[i15]?.averageTimeSpend,
          paiduseravgTimeSpend:
            paidaveTimeSpendconstandroid[i15]?.averageTimeSpend,
          unpaiduseravgTimeSpend:
            unpaidaveTimeSpendconstandroid[i15]?.averageTimeSpend,
          appleaveuseravgTimeSpend:
            androidavgtimespendtimespendappuserleaveconst[i15]?.data,
        };
        arr2.push(obj);
      }

      setandroidavgtimespend(arr2);

      // Ios Avg Time Spend

      const temp3 = Object.values(Data?.iosAppAverageTimeSpendDataa);
      const paidaveTimeSpendconstios = Object.values(
        Data?.iosAppPaidUserAverageTimeSpendData,
      );
      const unpaidaveTimeSpendconstios = Object.values(
        Data?.iosAppUnpaidUserAverageTimeSpendData,
      );
      const iosavgTimespendconstkeys = Object.keys(
        Data?.iosAppleaveUserAverageTimeSpendData,
      );
      lastkey = iosavgTimespendconstkeys[iosavgTimespendconstkeys.length - 1];
      var iosavgtimespendtimespendappuserleaveconst = [];
      for (var t6 = 0; t6 <= lastkey; t6++) {
        var oppp = {
          data:
            Number(
              Data?.iosAppleaveUserAverageTimeSpendData[t6]?.averageTimeSpend,
            ) / 60,
        };
        iosavgtimespendtimespendappuserleaveconst.push(oppp);
      }

      var arr3 = [];

      for (var i24 = 0; i24 < temp3.length; i24++) {
        temp3[i24].averageTimeSpend = Number(temp3[i24].averageTimeSpend) / 60;
      }
      for (var i25 = 0; i25 < paidaveTimeSpendconstios.length; i25++) {
        paidaveTimeSpendconstios[i25].averageTimeSpend =
          Number(paidaveTimeSpendconstios[i25].averageTimeSpend) / 60;
      }
      for (var i26 = 0; i26 < unpaidaveTimeSpendconstios.length; i26++) {
        unpaidaveTimeSpendconstios[i26].averageTimeSpend =
          Number(unpaidaveTimeSpendconstios[i26].averageTimeSpend) / 60;
      }
      const iosavgtimespendlen = Math.max(
        temp3.length,
        paidaveTimeSpendconstios.length,
        unpaidaveTimeSpendconstios.length,
        iosavgtimespendtimespendappuserleaveconst.length,
      );

      for (var i27 = 0; i27 < iosavgtimespendlen; i27++) {
        const obj = {
          xaxis: i27,
          alluseravgTimeSpend: temp3[i27]?.averageTimeSpend,
          paiduseravgTimeSpend: paidaveTimeSpendconstios[i27]?.averageTimeSpend,
          unpaiduseravgTimeSpend:
            unpaidaveTimeSpendconstios[i27]?.averageTimeSpend,
          appleaveuseravgTimeSpend:
            iosavgtimespendtimespendappuserleaveconst[i27]?.data,
        };
        arr3.push(obj);
      }

      setiosavgtimespend(arr3);

      // Paid/Unpaid Features Usage

      const alluserspeakingtestconst = Object.values(
        Data?.allUserSpeakingTestData,
      );
      const alluserspeakingcallconst = Object.values(
        Data?.allUserSpeakingCallData,
      );
      const alluserlisteningtestconst = Object.values(
        Data?.allUserListeningTestData,
      );

      var res = [];
      for (var i1 = 0; i1 < alluserspeakingtestconst.length; i1++) {
        const obj = {
          averagSpeakingTestPerUser:
            alluserspeakingtestconst[i1].averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            alluserspeakingtestconst[i1].userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            alluserspeakingcallconst[i1].averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            alluserspeakingcallconst[i1].userSpeakingCallPercentage,
          averagListeningTestPerUser:
            alluserlisteningtestconst[i1].averagListeningTestPerUser,
          userListeningTestPercentage:
            alluserlisteningtestconst[i1].userListeningTestPercentage,
          xaxis: i1,
        };
        res.push(obj);
      }

      const paiduserspeakingtestconst = Object.values(
        Data?.paidUserSpeakingTestData,
      );
      const paiduserspeakingcallconst = Object.values(
        Data?.paidUserSpeakingCallData,
      );
      const paiduserlisteningtestconst = Object.values(
        Data?.paidUserListeningTestData,
      );

      var res1 = [];

      for (var i5 = 0; i5 < paiduserspeakingtestconst.length; i5++) {
        const obj = {
          averagSpeakingTestPerUser:
            paiduserspeakingtestconst[i5].averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            paiduserspeakingtestconst[i5].userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            paiduserspeakingcallconst[i5].averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            paiduserspeakingcallconst[i5].userSpeakingCallPercentage,
          averagListeningTestPerUser:
            paiduserlisteningtestconst[i5].averagListeningTestPerUser,
          userListeningTestPercentage:
            paiduserlisteningtestconst[i5].userListeningTestPercentage,
          xaxis: i5,
        };
        res1.push(obj);
      }

      const unpaiduserspeakingtestconst = Object.values(
        Data?.unpaidUserSpeakingTestData,
      );
      const unpaiduserspeakingcallconst = Object.values(
        Data?.unpaidUserSpeakingCallData,
      );
      const unpaiduserlisteningtestconst = Object.values(
        Data?.unpaidUserListeningTestData,
      );

      var res2 = [];

      for (var i6 = 0; i6 < unpaiduserspeakingtestconst.length; i6++) {
        const obj = {
          averagSpeakingTestPerUser:
            unpaiduserspeakingtestconst[i6].averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            unpaiduserspeakingtestconst[i6].userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            unpaiduserspeakingcallconst[i6].averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            unpaiduserspeakingcallconst[i6].userSpeakingCallPercentage,
          averagListeningTestPerUser:
            unpaiduserlisteningtestconst[i6].averagListeningTestPerUser,
          userListeningTestPercentage:
            unpaiduserlisteningtestconst[i6].userListeningTestPercentage,
          xaxis: i6,
        };
        res2.push(obj);
      }

      // Appleave User
      const allappleaveuserspeakingtestconstkey = Object.keys(
        Data?.allAppleaveUserSpeakingTestData,
      );
      lastkey =
        allappleaveuserspeakingtestconstkey[
        allappleaveuserspeakingtestconstkey.length - 1
        ];
      var allappleaveuserspeakingtestconst = [];
      for (var t7 = 0; t7 <= lastkey; t7++) {
        var oppp1 = {
          data: Data?.allAppleaveUserSpeakingTestData[t7],
        };
        allappleaveuserspeakingtestconst.push(oppp1);
      }
      const allappleaveuserspeakingcallconstkey = Object.keys(
        Data?.allAppleaveUserSpeakingCallData,
      );
      lastkey =
        allappleaveuserspeakingcallconstkey[
        allappleaveuserspeakingcallconstkey.length - 1
        ];
      var allappleaveuserspeakingcallconst = [];
      for (var t8 = 0; t8 <= lastkey; t8++) {
        var oppp2 = {
          data: Data?.allAppleaveUserSpeakingCallData[t8],
        };
        allappleaveuserspeakingcallconst.push(oppp2);
      }
      const allappleaveuserlisteningtestconstkey = Object.keys(
        Data?.allAppleaveUserListeningTestData,
      );
      lastkey =
        allappleaveuserlisteningtestconstkey[
        allappleaveuserlisteningtestconstkey.length - 1
        ];
      var allappleaveuserlisteningtestconst = [];
      for (var t9 = 0; t9 <= lastkey; t9++) {
        var oppp3 = {
          data: Data?.allAppleaveUserListeningTestData[t9],
        };
        allappleaveuserlisteningtestconst.push(oppp3);
      }

      const allappleaveuserfeatureusagelen = Math.max(
        allappleaveuserlisteningtestconst.length,
        allappleaveuserspeakingcallconst.length,
        allappleaveuserspeakingtestconst.length,
      );

      var res27 = [];

      for (var t10 = 0; t10 < allappleaveuserfeatureusagelen; t10++) {
        const obj = {
          averagSpeakingTestPerUser:
            allappleaveuserspeakingtestconst[t10]?.data
              ?.averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            allappleaveuserspeakingtestconst[t10]?.data
              ?.userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            allappleaveuserspeakingcallconst[t10]?.data
              ?.averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            allappleaveuserspeakingcallconst[t10]?.data
              ?.userSpeakingCallPercentage,
          averagListeningTestPerUser:
            allappleaveuserlisteningtestconst[t10]?.data
              ?.averagListeningTestPerUser,
          userListeningTestPercentage:
            allappleaveuserlisteningtestconst[t10]?.data
              ?.userListeningTestPercentage,
          xaxis: t10,
        };
        res27.push(obj);
      }

      setpaidunpaidallfeaturesusage(res);
      setpaidunpaidpaidfeaturesusage(res1);
      setpaidunpaidunpaidfeaturesusage(res2);
      setallappleaveuserfeaturesusage(res27);

      // Android Features Usage

      const alluserspeakingtestconstandroid = Object.values(
        Data?.androidAppSpeakingTestData,
      );
      const alluserspeakingcallconstandroid = Object.values(
        Data?.androidAppSpeakingCallData,
      );
      const alluserlisteningtestconstandroid = Object.values(
        Data?.androidAppListeningTestData,
      );

      var res12 = [];

      for (var i16 = 0; i16 < alluserspeakingtestconstandroid.length; i16++) {
        const obj = {
          averagSpeakingTestPerUser:
            alluserspeakingtestconstandroid[i16].averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            alluserspeakingtestconstandroid[i16].userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            alluserspeakingcallconstandroid[i16].averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            alluserspeakingcallconstandroid[i16].userSpeakingCallPercentage,
          averagListeningTestPerUser:
            alluserlisteningtestconstandroid[i16].averagListeningTestPerUser,
          userListeningTestPercentage:
            alluserlisteningtestconstandroid[i16].userListeningTestPercentage,
          xaxis: i16,
        };
        res12.push(obj);
      }

      const paiduserspeakingtestconstandroid = Object.values(
        Data?.androidAppPaidUserSpeakingTestData,
      );
      const paiduserspeakingcallconstandroid = Object.values(
        Data?.androidAppPaidUserSpeakingCallData,
      );
      const paiduserlisteningtestconstandroid = Object.values(
        Data?.androidAppPaidUserListeningTestData,
      );

      var res13 = [];

      for (var i5 = 0; i5 < paiduserspeakingtestconstandroid.length; i5++) {
        const obj = {
          averagSpeakingTestPerUser:
            paiduserspeakingtestconstandroid[i5].averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            paiduserspeakingtestconstandroid[i5].userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            paiduserspeakingcallconstandroid[i5].averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            paiduserspeakingcallconstandroid[i5].userSpeakingCallPercentage,
          averagListeningTestPerUser:
            paiduserlisteningtestconstandroid[i5].averagListeningTestPerUser,
          userListeningTestPercentage:
            paiduserlisteningtestconstandroid[i5].userListeningTestPercentage,
          xaxis: i5,
        };
        res13.push(obj);
      }

      const unpaiduserspeakingtestconstandroid = Object.values(
        Data?.androidAppUnpaidUserSpeakingTestData,
      );
      const unpaiduserspeakingcallconstandroid = Object.values(
        Data?.androidAppUnpaidUserSpeakingCallData,
      );
      const unpaiduserlisteningtestconstandroid = Object.values(
        Data?.androidAppUnpaidUserListeningTestData,
      );

      var res21 = [];

      for (
        var i61 = 0;
        i61 < unpaiduserspeakingtestconstandroid.length;
        i61++
      ) {
        const obj = {
          averagSpeakingTestPerUser:
            unpaiduserspeakingtestconstandroid[i61].averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            unpaiduserspeakingtestconstandroid[i61].userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            unpaiduserspeakingcallconstandroid[i61].averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            unpaiduserspeakingcallconstandroid[i61].userSpeakingCallPercentage,
          averagListeningTestPerUser:
            unpaiduserlisteningtestconstandroid[i61].averagListeningTestPerUser,
          userListeningTestPercentage:
            unpaiduserlisteningtestconstandroid[i61]
              .userListeningTestPercentage,
          xaxis: i61,
        };
        res21.push(obj);
      }

      // Android App Leave Users

      const androidappleaveuserspeakingtestconstkey = Object.keys(
        Data?.androidAppleaveUserSpeakingTestData,
      );
      lastkey =
        androidappleaveuserspeakingtestconstkey[
        androidappleaveuserspeakingtestconstkey.length - 1
        ];
      var androidappleaveuserspeakingtestconst = [];
      for (var t11 = 0; t11 <= lastkey; t11++) {
        var oppp1 = {
          data: Data?.androidAppleaveUserSpeakingTestData[t11],
        };
        androidappleaveuserspeakingtestconst.push(oppp1);
      }
      const androidappleaveuserspeakingcallconstkey = Object.keys(
        Data?.androidAppleaveUserSpeakingCallData,
      );
      lastkey =
        androidappleaveuserspeakingcallconstkey[
        androidappleaveuserspeakingcallconstkey.length - 1
        ];
      var androidappleaveuserspeakingcallconst = [];
      for (var t12 = 0; t12 <= lastkey; t12++) {
        var oppp2 = {
          data: Data?.androidAppleaveUserSpeakingCallData[t12],
        };
        androidappleaveuserspeakingcallconst.push(oppp2);
      }
      const androidappleaveuserlisteningtestconstkey = Object.keys(
        Data?.androidAppleaveUserListeningTestData,
      );
      lastkey =
        androidappleaveuserlisteningtestconstkey[
        androidappleaveuserlisteningtestconstkey.length - 1
        ];
      var androidappleaveuserlisteningtestconst = [];
      for (var t13 = 0; t13 <= lastkey; t13++) {
        var oppp3 = {
          data: Data?.androidAppleaveUserListeningTestData[t13],
        };
        androidappleaveuserlisteningtestconst.push(oppp3);
      }

      const androidappleaveuserfeatureusagelen = Math.max(
        androidappleaveuserlisteningtestconst.length,
        androidappleaveuserspeakingcallconst.length,
        androidappleaveuserspeakingtestconst.length,
      );

      var res273 = [];

      for (var t14 = 0; t14 < androidappleaveuserfeatureusagelen; t14++) {
        const obj = {
          averagSpeakingTestPerUser:
            androidappleaveuserspeakingtestconst[t14]?.data
              ?.averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            androidappleaveuserspeakingtestconst[t14]?.data
              ?.userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            androidappleaveuserspeakingcallconst[t14]?.data
              ?.averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            androidappleaveuserspeakingcallconst[t14]?.data
              ?.userSpeakingCallPercentage,
          averagListeningTestPerUser:
            androidappleaveuserlisteningtestconst[t14]?.data
              ?.averagListeningTestPerUser,
          userListeningTestPercentage:
            androidappleaveuserlisteningtestconst[t14]?.data
              ?.userListeningTestPercentage,
          xaxis: t14,
        };
        res273.push(obj);
      }

      setandroidallfeaturesusage(res12);
      setandroidpaidfeaturesusage(res13);
      setandroidunpaidfeaturesusage(res21);
      setandroidappleaveuserfeaturesusage(res273);

      // Set Ios Features Usage

      const alluserspeakingtestconstios = Object.values(
        Data?.iosAppSpeakingTestData,
      );
      const alluserspeakingcallconstios = Object.values(
        Data?.iosAppSpeakingCallData,
      );
      const alluserlisteningtestconstios = Object.values(
        Data?.iosAppListeningTestData,
      );

      var res133 = [];

      for (var i28 = 0; i28 < alluserspeakingtestconstios.length; i28++) {
        const obj = {
          averagSpeakingTestPerUser:
            alluserspeakingtestconstios[i28].averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            alluserspeakingtestconstios[i28].userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            alluserspeakingcallconstios[i28].averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            alluserspeakingcallconstios[i28].userSpeakingCallPercentage,
          averagListeningTestPerUser:
            alluserlisteningtestconstios[i28].averagListeningTestPerUser,
          userListeningTestPercentage:
            alluserlisteningtestconstios[i28].userListeningTestPercentage,
          xaxis: i28,
        };
        res133.push(obj);
      }

      const paiduserspeakingtestconstios = Object.values(
        Data?.iosAppPaidUserSpeakingTestData,
      );
      const paiduserspeakingcallconstios = Object.values(
        Data?.iosAppPaidUserSpeakingCallData,
      );
      const paiduserlisteningtestconstios = Object.values(
        Data?.iosAppPaidUserListeningTestData,
      );

      var res135 = [];

      for (var i51 = 0; i51 < paiduserspeakingtestconstios.length; i51++) {
        const obj = {
          averagSpeakingTestPerUser:
            paiduserspeakingtestconstios[i51].averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            paiduserspeakingtestconstios[i51].userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            paiduserspeakingcallconstios[i51].averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            paiduserspeakingcallconstios[i51].userSpeakingCallPercentage,
          averagListeningTestPerUser:
            paiduserlisteningtestconstios[i51].averagListeningTestPerUser,
          userListeningTestPercentage:
            paiduserlisteningtestconstios[i51].userListeningTestPercentage,
          xaxis: i51,
        };
        res135.push(obj);
      }

      const unpaiduserspeakingtestconstios = Object.values(
        Data?.iosAppUnpaidUserSpeakingTestData,
      );
      const unpaiduserspeakingcallconstios = Object.values(
        Data?.iosAppUnpaidUserSpeakingCallData,
      );
      const unpaiduserlisteningtestconstios = Object.values(
        Data?.iosAppUnpaidUserListeningTestData,
      );

      var res134 = [];

      for (var i62 = 0; i62 < unpaiduserspeakingtestconstios.length; i62++) {
        const obj = {
          averagSpeakingTestPerUser:
            unpaiduserspeakingtestconstios[i62].averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            unpaiduserspeakingtestconstios[i62].userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            unpaiduserspeakingcallconstios[i62].averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            unpaiduserspeakingcallconstios[i62].userSpeakingCallPercentage,
          averagListeningTestPerUser:
            unpaiduserlisteningtestconstios[i62].averagListeningTestPerUser,
          userListeningTestPercentage:
            unpaiduserlisteningtestconstios[i62].userListeningTestPercentage,
          xaxis: i62,
        };
        res134.push(obj);
      }

      // Ios App Leave Users Features Usage

      const iosappleaveuserspeakingtestconstkey = Object.keys(
        Data?.iosAppleaveUserSpeakingTestData,
      );
      lastkey =
        iosappleaveuserspeakingtestconstkey[
        iosappleaveuserspeakingtestconstkey.length - 1
        ];
      var iosappleaveuserspeakingtestconst = [];
      for (var t15 = 0; t15 <= lastkey; t15++) {
        var oppp1 = {
          data: Data?.iosAppleaveUserSpeakingTestData[t15],
        };
        iosappleaveuserspeakingtestconst.push(oppp1);
      }
      const iosappleaveuserspeakingcallconstkey = Object.keys(
        Data?.iosAppleaveUserSpeakingCallData,
      );
      lastkey =
        iosappleaveuserspeakingcallconstkey[
        iosappleaveuserspeakingcallconstkey.length - 1
        ];
      var iosappleaveuserspeakingcallconst = [];
      for (var t16 = 0; t16 <= lastkey; t16++) {
        var oppp2 = {
          data: Data?.iosAppleaveUserSpeakingCallData[t16],
        };
        iosappleaveuserspeakingcallconst.push(oppp2);
      }
      const iosappleaveuserlisteningtestconstkey = Object.keys(
        Data?.iosAppleaveUserListeningTestData,
      );
      lastkey =
        iosappleaveuserlisteningtestconstkey[
        iosappleaveuserlisteningtestconstkey.length - 1
        ];
      var iosappleaveuserlisteningtestconst = [];
      for (var t17 = 0; t17 <= lastkey; t17++) {
        var oppp3 = {
          data: Data?.iosAppleaveUserListeningTestData[t17],
        };
        iosappleaveuserlisteningtestconst.push(oppp3);
      }

      const iosappleaveuserfeatureusagelen = Math.max(
        iosappleaveuserlisteningtestconst.length,
        iosappleaveuserspeakingcallconst.length,
        iosappleaveuserspeakingtestconst.length,
      );

      var res274 = [];

      for (var t18 = 0; t18 < iosappleaveuserfeatureusagelen; t18++) {
        const obj = {
          averagSpeakingTestPerUser:
            iosappleaveuserspeakingtestconst[t18]?.data
              ?.averagSpeakingTestPerUser,
          userSpeakingTestPercentage:
            iosappleaveuserspeakingtestconst[t18]?.data
              ?.userSpeakingTestPercentage,
          averagSpeakingCallPerUser:
            iosappleaveuserspeakingcallconst[t18]?.data
              ?.averagSpeakingCallPerUser,
          userSpeakingCallPercentage:
            iosappleaveuserspeakingcallconst[t18]?.data
              ?.userSpeakingCallPercentage,
          averagListeningTestPerUser:
            iosappleaveuserlisteningtestconst[t18]?.data
              ?.averagListeningTestPerUser,
          userListeningTestPercentage:
            iosappleaveuserlisteningtestconst[t18]?.data
              ?.userListeningTestPercentage,
          xaxis: t18,
        };
        res274.push(obj);
      }

      setiosallfeaturesusage(res133);
      setiospaidfeaturesusage(res135);
      setiosunpaidfeaturesusage(res134);
      setiosappleaveuserfeaturesusage(res274);

      // Combined Retention Data

      var n = Math.max(newres.length, newres1.length, newres22.length);
      var n1 = Math.max(arr1.length, arr2.length, arr3.length);

      var result = [];
      for (var i7 = 0; i7 < n; i7++) {
        const obj = {
          xaxis: i7,
          allalluseractivepercentage: Number(
            newres[i7]?.alluseractivepercentage,
          ),
          allpaiduseractivepercentage: Number(
            newres[i7]?.paiduseractivepercentage,
          ),
          allunpaiduseractivepercentage: Number(
            newres[i7]?.unpaiduseractivepercentage,
          ),
          allappuserleaveactivepercentage: Number(
            newres[i7]?.appuserleaveactivepercentage,
          ),
          androidalluseractivepercentage: Number(
            newres1[i7]?.alluseractivepercentage,
          ),
          androidpaiduseractivepercentage: Number(
            newres1[i7]?.paiduseractivepercentage,
          ),
          androidunpaiduseractivepercentage: Number(
            newres1[i7]?.unpaiduseractivepercentage,
          ),
          androidappuserleaveactivepercentage: Number(
            newres1[i7]?.appuserleaveactivepercentage,
          ),
          iosalluseractivepercentage: Number(
            newres22[i7]?.alluseractivepercentage,
          ),
          iospaiduseractivepercentage: Number(
            newres22[i7]?.paiduseractivepercentage,
          ),
          iosunpaiduseractivepercentage: Number(
            newres22[i7]?.unpaiduseractivepercentage,
          ),
          iosappuserleaveactivepercentage: Number(
            newres22[i7]?.appuserleaveactivepercentage,
          ),
        };
        result.push(obj);
      }

      var result1 = [];
      for (var i8 = 0; i8 < n1; i8++) {
        const obj = {
          xaxis: i8,
          allalluseruseravgTimeSpend: Number(arr1[i8]?.alluseravgTimeSpend),
          allpaiduseruseravgTimeSpend: Number(arr1[i8]?.paiduseravgTimeSpend),
          allunpaiduseruseravgTimeSpend: Number(
            arr1[i8]?.unpaiduseravgTimeSpend,
          ),
          allappleaveuseravgTimeSpend: Number(
            arr1[i8]?.appleaveuseravgTimeSpend,
          ),
          androidalluseruseravgTimeSpend: Number(arr2[i8]?.alluseravgTimeSpend),
          androidpaiduseruseravgTimeSpend: Number(
            arr2[i8]?.paiduseravgTimeSpend,
          ),
          androidunpaiduseruseravgTimeSpend: Number(
            arr2[i8]?.unpaiduseravgTimeSpend,
          ),
          androidappleaveuseravgTimeSpend: Number(
            arr2[i8]?.appleaveuseravgTimeSpend,
          ),
          iosalluseruseravgTimeSpend: Number(arr3[i8]?.alluseravgTimeSpend),
          iospaiduseruseravgTimeSpend: Number(arr3[i8]?.paiduseravgTimeSpend),
          iosunpaiduseruseravgTimeSpend: Number(
            arr3[i8]?.unpaiduseravgTimeSpend,
          ),
          iosappleaveuseravgTimeSpend: Number(
            arr3[i8]?.appleaveuseravgTimeSpend,
          ),
        };
        result1.push(obj);
      }
      setcombinedavgtimespend(result1);

      setcombinedretentiondata(result);
    }
  };

  const getUserBehaviourData = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user?.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/fetchUserBehaviour/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const userBehaviourData = JSON.stringify(res?.data?.data);
          sessionStorage.setItem("userBehaviourData", userBehaviourData);
          updateUserBehaviourData();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // get payment Control data from Api
  const updatePayMentControl = () => {
    const PayMentControl = sessionStorage.getItem("PayMentControl");
    const Data = JSON.parse(PayMentControl);
    const entrieskeys = Object.keys(Data);
    const entriesvalues = Object.values(Data);
    var result = [];
    for (var i = 1; i < entrieskeys.length; i++) {
      var text = entrieskeys[i];
      var obj;
      if (typeof false === "boolean") {
        obj = {
          [text]: entriesvalues[i].toString(),
        };
      } else {
        obj = {
          [text]: entriesvalues[i], // setting dynamic key
        };
      }
      result.push(obj);
    }

    setPayMentControl(result);
  };

  const getPayMentControl = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/paymentControl/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const PayMentControl = JSON.stringify(res.data.data);
          sessionStorage.setItem("PayMentControl", PayMentControl);
          updatePayMentControl();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // getSpeaking Test Control Data from Api

  const updatespeakingTestControl = () => {
    const speakingTestControl = sessionStorage.getItem("speakingTestControl");
    const Data = JSON.parse(speakingTestControl);
    const entrieskeys = Object.keys(Data);
    const entriesvalues = Object.values(Data);
    var result = [];
    for (var i = 1; i < entrieskeys.length; i++) {
      var text = entrieskeys[i];
      var obj;
      if (typeof false === "boolean") {
        obj = {
          [text]: entriesvalues[i].toString(),
        };
      } else {
        obj = {
          [text]: entriesvalues[i], // setting dynamic key
        };
      }
      result.push(obj);
    }

    setspeakingtestcontrolData(result);
  };

  const getspeakingTestControl = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/speakingTestControl/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const speakingTestControl = JSON.stringify(res.data.data);
          sessionStorage.setItem("speakingTestControl", speakingTestControl);
          updatespeakingTestControl();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // get band calculation control data from api

  const updatebandCalculationControl = () => {
    const bandCalculationControl = sessionStorage.getItem(
      "bandCalculationControl",
    );
    const Data = JSON.parse(bandCalculationControl);
    const entrieskeys = Object.keys(Data);
    const entriesvalues = Object.values(Data);
    var result = [];
    for (var i = 1; i < entrieskeys.length; i++) {
      var text = entrieskeys[i];
      var obj;
      if (typeof false === "boolean") {
        obj = {
          [text]: entriesvalues[i].toString(),
        };
      } else {
        obj = {
          [text]: entriesvalues[i], // setting dynamic key
        };
      }
      result.push(obj);
    }

    setbandcalculationData(result);
  };

  const getbandCalculationControl = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/bandCalculationControl/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const bandCalculationControl = JSON.stringify(res.data.data);
          sessionStorage.setItem(
            "bandCalculationControl",
            bandCalculationControl,
          );
          updatebandCalculationControl();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // get error log data from api

  const updateerrorLogList = () => {
    const errorLogList = sessionStorage.getItem("errorLogList");
    const Data = JSON.parse(errorLogList);
    seterrorlogData(Data);
  };

  const geterrorLogList = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/errorLogList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const errorLogList = JSON.stringify(res.data.data);
          sessionStorage.setItem("errorLogList", errorLogList);
          updateerrorLogList();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // Clear error logs

  const clearErrorlogs = (user) => {
    const jwt = createjwt21(user?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/clearErrorLog/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          geterrorLogList(user);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get Listening Test Data from Api

  const updatelistenigTestList = () => {
    const listenigTestList = sessionStorage.getItem("listenigTestList");
    const Data = JSON.parse(listenigTestList);

    setlisteningtestData(Data);
  };

  const getlistenigTestList = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/listenigTestList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const listenigTestList = JSON.stringify(res.data.data);
          sessionStorage.setItem("listenigTestList", listenigTestList);
          updatelistenigTestList();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  //  Upload Listening Test Data

  const uploadListeningTestData = (testFile, thumbnail, testDate) => {
    settimetorefresh(true);
    const jwt = createjwt29(testFile, thumbnail, testDate);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/uploadListeningTest/`,
      data: {
        testFile: testFile,
        thumbnail: thumbnail,
        testDate: testDate,
      },
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          console.log(res);
          navigate("/listeningtest");
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
        toast.error("Error Occured While Performing the Action.");
      });
  };

  //  Get Reading List Data

  const updateReadingTestList = () => {
    const ReadingList = sessionStorage.getItem("ReadingList");
    const Data = JSON.parse(ReadingList);

    setreadingtestData({
      academicTest: Data?.academicTests,
      generalTest: Data?.generalTests,
    });
  };

  const getReadingTestList = (user) => {
    settimetorefresh(true);
    const jwt = createjwt21(user.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/ReadingTestList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const ReadingList = JSON.stringify(res.data.data);
          sessionStorage.setItem("ReadingList", ReadingList);
          updateReadingTestList();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  //  Get Reading List Data

  const updateSpeakingTestList = () => {
    const SpeakingListQues = sessionStorage.getItem("SpeakingListQues");
    const Data = JSON.parse(SpeakingListQues);

    setspeakingtestData({
      intro: Data?.introductionQuestions,
      general: Data?.genralQuestions,
      queCard: Data?.queCardQuestions,
      followUpQues: Data?.followUpQuestions,
    });
  };

  const getSpeakingTestData = (user) => {
    settimetorefresh(true);
    const jwt = createjwt21(user.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/fetchAllSpeakingQuestions/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const SpeakingListQues = JSON.stringify(res.data.data);
          sessionStorage.setItem("SpeakingListQues", SpeakingListQues);
          updateSpeakingTestList();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // add Speaking Ques

  const addIntroSpeakingTest = (
    user,
    questionSection,
    introductionQuestion,
    introductionBestAnswer,
    introductionVideoFile,
  ) => {
    setloader(true);
    const jwt = createjwt33(
      user.uid,
      questionSection,
      introductionQuestion,
      introductionBestAnswer,
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    bodyFormData.append("introductionVideoFile", introductionVideoFile);
    makeApiCall(bodyFormData);
  };

  const addGeneralSpeakingTest = (
    user,
    questionSection,
    firstGenralQuestion,
    firstGenralQuestionBestAnswer,
    firstGenralQuestionVideoFile,
    secondGenralQuestion,
    secondGenralQuestionBestAnswer,
    secondGenralQuestionVideoFile,
    thirdGenralQuestion,
    thirdGenralQuestionBestAnswer,
    thirdGenralQuestionVideoFile,
    forthGenralQuestion,
    forthGenralQuestionBestAnswer,
    forthGenralQuestionVideoFile,
  ) => {
    setloader(true);
    const jwt = createjwt32(
      user.uid,
      questionSection,
      firstGenralQuestion,
      firstGenralQuestionBestAnswer,
      secondGenralQuestion,
      secondGenralQuestionBestAnswer,
      thirdGenralQuestion,
      thirdGenralQuestionBestAnswer,
      forthGenralQuestion,
      forthGenralQuestionBestAnswer,
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    bodyFormData.append(
      "firstGenralQuestionVideoFile",
      firstGenralQuestionVideoFile,
    );
    bodyFormData.append(
      "secondGenralQuestionVideoFile",
      secondGenralQuestionVideoFile,
    );
    bodyFormData.append(
      "thirdGenralQuestionVideoFile",
      thirdGenralQuestionVideoFile,
    );
    bodyFormData.append(
      "forthGenralQuestionVideoFile",
      forthGenralQuestionVideoFile,
    );
    makeApiCall(bodyFormData);
  };

  const addQueCardSpeakingTest = (
    user,
    questionSection,
    cueCardQuestion,
    cueCardQuestionBestAnswer,
    cueCardQuestionVideoFile,
    firstFollowUpQuestion,
    firstFollowUpQuestionBestAnswer,
    firstFollowUpQuestionVideoFile,
    secondFollowUpQuestion,
    secondFollowUpQuestionBestAnswer,
    secondFollowUpQuestionVideoFile,
    thirdFollowUpQuestion,
    thirdFollowUpQuestionBestAnswer,
    thirdFollowUpQuestionVideoFile,
    forthFollowUpQuestion,
    forthFollowUpQuestionBestAnswer,
    forthFollowUpQuestionVideoFile,
  ) => {
    const jwt = createjwt34(
      user.uid,
      questionSection,
      cueCardQuestion,
      cueCardQuestionBestAnswer,
      firstFollowUpQuestion,
      firstFollowUpQuestionBestAnswer,
      secondFollowUpQuestion,
      secondFollowUpQuestionBestAnswer,
      thirdFollowUpQuestion,
      thirdFollowUpQuestionBestAnswer,
      forthFollowUpQuestion,
      forthFollowUpQuestionBestAnswer,
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    bodyFormData.append("cueCardQuestionVideoFile", cueCardQuestionVideoFile);
    bodyFormData.append(
      "firstFollowUpQuestionVideoFile",
      firstFollowUpQuestionVideoFile,
    );
    bodyFormData.append(
      "secondFollowUpQuestionVideoFile",
      secondFollowUpQuestionVideoFile,
    );
    bodyFormData.append(
      "thirdFollowUpQuestionVideoFile",
      thirdFollowUpQuestionVideoFile,
    );
    bodyFormData.append(
      "forthFollowUpQuestionVideoFile",
      forthFollowUpQuestionVideoFile,
    );
    makeApiCall(bodyFormData);
  };

  const makeApiCall = (bodyFormData) => {
    axios({
      method: "post",
      url: `${api_url}/UploadSpeakingQuestions/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setloader(false);
        if (res.status === 200) {
          console.log(res);
          navigate("/speaking");
        } else {
          toast.error("Api Status is not 200");
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const addReadingTest = (testFile, testType) => {
    settimetorefresh(true);
    axios({
      method: "post",
      url: `${api_url}/uploadReadingTest/`,
      data: {
        testFile: testFile,
        testType: testType,
      },
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          console.log(res);
          navigate("/reading");
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
        toast.error("Error Occured While Performing the Action.");
      });
  };

  // Writing Test Data Entry

  const updatewritingTest = () => {
    const writingTest = sessionStorage.getItem("writingTest");
    const Data = JSON.parse(writingTest);
    setwritingListData(Data);
  };

  const getwritingTest = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/fetchWritingCategory/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          console.log(res.data.data)
          const writingTest = JSON.stringify(res.data.data);
          sessionStorage.setItem("writingTest", writingTest);
          updatewritingTest();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  const EditCategoryWriting = (user, WritingCategoryModel, NewWritingCategoryModel) => {
    const jwt = createjwt44(user?.uid, WritingCategoryModel, NewWritingCategoryModel);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/editWritingCategory/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          // sessionStorage.setItem("singlewritingdata", JSON.stringify(res.data.data))
          // setsinglewritingListData(res.data.data);
          navigate("/writing")
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const editsubcategoryWriting = (user, WritingCategoryName, WritingSubCategoryName, WritingNewSubCategoryName) => {
    const jwt = createjwt45(user?.uid, WritingCategoryName, WritingSubCategoryName, WritingNewSubCategoryName);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/editWritingSubCategory/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          // sessionStorage.setItem("singlewritingdata", JSON.stringify(res.data.data))

          setselectedsubcategoryname(res.data.data.WritingSubCategoryName)
          // window.location.reload()
          // setsingleSubcategoryNameData(obj)
          // setsinglewritingListData(res.data.data);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const addsubcategoryWriting = (user, CategoryName, SubCategoryName) => {
    const jwt = createjwt40(user?.uid, CategoryName, SubCategoryName);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/uploadWritingSubCategory/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("singlewritingdata", JSON.stringify(res.data.data))
          setsinglewritingListData(res.data.data);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addcategoryWriting = (user, CategoryName, thumbnail, task, type) => {
    const jwt = createjwt41(user?.uid, CategoryName, task, type);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    bodyFormData.append("thumbnail", thumbnail);
    axios({
      method: "post",
      url: `${api_url}/uploadWritingCategory/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          getwritingTest(user);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addSubCategoryWritingQues = (
    user,
    CategoryName,
    SubCategoryName,
    Question,
    AnswerFirst,
    AnswerSecond,
    task, image, type
  ) => {
    const jwt = createjwt42(
      user?.uid,
      CategoryName,
      SubCategoryName,
      Question,
      AnswerFirst,
      AnswerSecond,
      task,
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    task === 1 && type === "academic" && bodyFormData.append("image", image)
    axios({
      method: "post",
      url: `${api_url}/uploadWritingQuestionAnswer/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          getQuestionsWritingOfSubCategory(user, CategoryName, SubCategoryName);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editSubCategoryWritingQues = (
    user,
    CategoryName,
    SubCategoryName,
    QuestionUniqueId,
    Question,
    AnswerFirst,
    AnswerSecond,
    image
  ) => {
    const jwt = createjwt43(
      user?.uid,
      CategoryName,
      SubCategoryName,
      QuestionUniqueId,
      Question,
      AnswerFirst,
      AnswerSecond,
    );
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    image && bodyFormData.append("image", image);
    axios({
      method: "post",
      url: `${api_url}/editWritingQuestionAnswer/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          setsinglewritingquestion(res.data.data.WritingQuestionAnswerData);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuestionsWritingOfSubCategory = (
    user,
    CategoryName,
    SubCategoryName,
  ) => {
    settimetorefresh(true);
    const jwt = createjwt40(user.uid, CategoryName, SubCategoryName);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/getWritingQuestionAnswer/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          console.log(res)
          setquestionlistData(res.data.data);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // Get FeedBack Controls Data from Api

  const updatefeedbackControl = () => {
    const feedbackControl = sessionStorage.getItem("feedbackControl");
    const Data = JSON.parse(feedbackControl);
    setfeedbackControlData(Data);
  };

  const getfeedbackControl = (user) => {
    settimetorefresh(true);

    const jwt = createjwt2(user?.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/feedbackControl/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        if (res.status === 200) {
          const feedbackControl = JSON.stringify(res.data.data);
          sessionStorage.setItem("feedbackControl", feedbackControl);
          updatefeedbackControl();
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  // Get Last 30 days App users

  const getuserslastdays = (user) => {
    settimetorefresh(true);
    const jwt = createjwt2(user?.uid, Date.now());
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/last30DayAppUsers/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        setisClearSearch(false);
        if (res.status === 200) {
          setdaysAppUsers(res.data.data.thirtyDayAppUserDataSerializer);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        setisClearSearch(false);

        console.log(err);
      });
  };

  //  Get Admin Users

  const getAdminusers = (user) => {
    settimetorefresh(true);
    const jwt = createjwt21(user?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/adminSiteUsersList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);
        setisClearSearch(false);
        if (res.status === 200) {
          setdaysAdminUsers(res.data.data.adminSiteUserData);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        setisClearSearch(false);

        console.log(err);
      });
  };

  // Get the searched users

  const getSearchusers = (searchKeyword, user) => {
    const jwt = createjwt5(user?.uid, searchKeyword);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/searchAppUser/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setisSearchButtonClicked(false);
        settimetorefresh(false);
        if (res.status === 200) {
          setdaysAppUsers(res.data.data.searchUserDataSerializer);
          setisClearSearch(true);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        setisSearchButtonClicked(false);
        settimetorefresh(false);
        console.log(err);
      });
  };

  // Get Coupon Search Users
  const getSearchCouponUsers = (searchKeyword, user) => {
    const jwt = createjwt15(user?.uid, searchKeyword);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/couponCodeSearch/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setisSearchButtonClicked(false);
        settimetorefresh(false);
        if (res.status === 200) {
          console.log(res.data.data);
          setcouponcodedata(res.data.data.couponCodeList);
          setisClearSearch(true);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        setisSearchButtonClicked(false);
        settimetorefresh(false);
        console.log(err);
      });
  };

  // get TranscationList Search Data
  const getTranscationListSearchData = (searchKeyword, user) => {
    const jwt = createjwt5(user?.uid, searchKeyword);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/searchTransactionList/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setisSearchButtonClicked(false);
        settimetorefresh(false);
        if (res.status === 200) {
          console.log(res.data.data);
          settranscationData(res.data.data.searchPayentDataSerializer);
          setisClearSearch(true);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        setisSearchButtonClicked(false);
        settimetorefresh(false);
        console.log(err);
      });
  };

  const getDaysAgoDate = (data, days_req) => {
    const currentDate = new Date();
    const last30DaysDate = new Date(
      currentDate.setDate(currentDate.getDate() - days_req),
    );
    const filtered_arr = data?.filter(
      (res) => new Date(res.date) >= last30DaysDate,
    );
    return filtered_arr;
  };

  // Get the details of the last 7 days app users
  const handleAppUserDetails = (appUserUid, user) => {
    setonClickNavLoader(true);
    const jwt = createjwt6(user?.uid, appUserUid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/appUserDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setonClickNavLoader(false);
        if (res.status === 200) {
          const lastthirtydayappUserActivity = res.data.data.appUserActivity;
          var nw_arr = getDaysAgoDate(lastthirtydayappUserActivity, 30);
          var result = [];
          if (nw_arr.length > 0) {
            var dateArr = getLast30daysFromCurr();
            for (var k = 0; k < dateArr.length; k++) {
              // eslint-disable-next-line
              // var find_Date = nw_arr.some((res) => {
              //   var date1 = new Date(res.date);
              //   var date2 = new Date(dateArr[k]);

              //   return +date1 === +date2;
              // });
              var isPresent = false;
              var data = "";
              for (var k1 = 0; k1 < nw_arr.length; k1++) {
                var date1 = new Date(nw_arr[k1].date);

                var date2 = new Date(dateArr[k]);
                if (date1.toDateString() === date2.toDateString()) {
                  isPresent = true;
                  data = nw_arr[k1];
                  break;
                }
              }
              var obj;
              if (isPresent) {
                obj = {
                  date: dateFormate(dateArr[k]),
                  speakingCall: data.speakingCall,
                  listeningTest: data.listeningTest,
                  speakingTest: data.speakingTest,
                };
              } else {
                obj = {
                  date: dateFormate(dateArr[k]),
                  speakingCall: 0,
                  listeningTest: 0,
                  speakingTest: 0,
                };
              }
              result.push(obj);
            }
          }
          console.log(result);
          navigate("/usersDetails", {
            state: {
              appUserDetail: res.data.data.appUserDetail,
              userAllAcountCreatedBy: res.data.data.userAllAcountCreatedBy,
              paymentAttemptByUser: res.data.data.paymentAttemptByUser,
              appUserActivity:
                nw_arr.length > 0 ? result : res.data.data.appUserActivity,
              payoutAttemptByUser: res.data.data.payoutAttemptByUser,
            },
          });
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        setonClickNavLoader(false);
        console.log(err);
      });
  };

  const handleAdminUserDetails = (appUserUid, user) => {
    setonClickNavLoader(true);
    settimetorefresh(true);
    const jwt = createjwt24(user?.uid, appUserUid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/adminUserDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setonClickNavLoader(false);
        settimetorefresh(false);
        if (res.status === 200) {
          setadminUserDetail(res.data.data);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        setonClickNavLoader(false);
        settimetorefresh(false);
        console.log(err);
      });
  };

  //  Clear Particular User details
  const clearUserDetails = (user, appUserUid) => {
    const jwt = createjwt22(user?.uid, appUserUid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/clearAppUserData/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          setusergotDeleted(true);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUsersDetailsForEdit = (appUserUid, user) => {
    setonClickNavLoader(true);
    const jwt = createjwt6(user?.uid, appUserUid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/appUserDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setonClickNavLoader(false);

        if (res.status === 200) {
          setselectedUserdetail(res.data.data.appUserDetail);
        } else {
          console.log("Api Status is not 200");
        }
      })
      .catch((err) => {
        setonClickNavLoader(false);
        console.log(err);
      });
  };

  // Detect Grammar Mistake Func
  const handleGrammarMistakefunc = (user, text) => {
    const jwt = createjwt7(user?.uid, text);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${new_api_url}/detectGrammerMistake/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get Server Details Of a particular server

  const getServerDetails = (envName) => {
    const user = localStorage.getItem("userData");
    const userdata = JSON.parse(user);
    const jwt = createjwt10(userdata?.uid, envName);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/getServerApplicationDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setserverInstancedetails(res?.data?.data?.appInstanceInfo);
        setenvname(res?.data?.data?.appInfo?.EnvironmentName);
        console.log(res.data.data, "papue");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Terminate particular instance of a server

  const terminateInstance = (instanceId) => {
    const user = localStorage.getItem("userData");
    const userdata = JSON.parse(user);
    const jwt = createjwt12(userdata?.uid, instanceId);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/terminateApplicationInstance/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          console.log(res);
          setisterminatesuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setisterminateerror(true);
      });
  };

  // Add Instance of a particular server

  const addInstanceOfaParticularServer = (applicationName) => {
    const user = localStorage.getItem("userData");
    const userdata = JSON.parse(user);
    const jwt = createjwt13(userdata?.uid, applicationName, environmentName, 0);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/changeAppInstaces/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          console.log(res);
          setaddinstancesuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setaddinstanceerror(true);
      });
  };

  // Check Server Load Of a particular server
  const checkServerLoadOfAParticularServer = (applicationName) => {
    const user = localStorage.getItem("userData");
    const userdata = JSON.parse(user);
    const jwt = createjwt25(applicationName, environmentName);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/checkServeStatus/`,
      data: {
        applicationName: applicationName,
        environmentName: environmentName,
      },
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          console.log(res);
          // setaddinstancesuccess(true);
          setcheckserverstatusclick(true);
        }
      })
      .catch((err) => {
        console.log(err);
        // setaddinstanceerror(true);
        setcheckserverstatuserr(true);
      });
  };

  // Remove Instance of a particular server

  const removeInstanceOfaParticularServer = (applicationName) => {
    const user = localStorage.getItem("userData");
    const userdata = JSON.parse(user);
    const jwt = createjwt13(userdata?.uid, applicationName, environmentName, 1);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/changeAppInstaces/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          console.log(res);
          setremoveinstancesuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setremoveinstanceerror(true);
      });
  };

  // Get Payment Behaviour Details Graphs

  const getPaymentBehaviourDetailsGraphs = (user, startingDate, endingDate) => {
    settimetorefresh(true);
    const jwt = createjwt31(user?.uid, startingDate, endingDate);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/RevenueDetails/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        settimetorefresh(false);

        if (res.status === 200) {
          console.log(res);

          let plans = ["planA", "planB", "planC", "totalResult"];

          let paymentDetail = res?.data?.data?.paymentDetail;
          let advertisementDetail = res?.data?.data?.advertisementLinkData;
          let monthlySaleRevenueData = res?.data?.data?.revenueData;
          let adlinks = res?.data?.data?.adsLink;
          let adLinksDayByDay = res?.data?.data?.advertisementLinkDataDayByDay;
          setadvertisementLinkData(advertisementDetail);

          let finalPaymentArr = [];
          for (let i = 0; i < plans.length; i++) {
            let searchTxt = plans[i];
            let tempArr = [];
            for (let key in paymentDetail) {
              if (key.includes(searchTxt)) {
                tempArr.push({
                  xaxis: searchTxt,
                  key: key,
                  data: paymentDetail[key],
                });
              }
            }
            finalPaymentArr.push(tempArr);
          }
          let finalPaymentData = [];
          for (let i = 0; i < finalPaymentArr.length; i++) {
            let tempArr = finalPaymentArr[i];
            let dynamicObj = {};
            for (let j = 0; j < tempArr?.length; j++) {
              let k = j;
              if (tempArr[j]?.xaxis === "planA") k = k + 1;
              dynamicObj[`key${k}`] = tempArr[j]?.key;
              dynamicObj[`data${k}`] = tempArr[j]?.data;
              dynamicObj[`percent${k}`] = parseFloat(
                tempArr[j]?.data?.percentage,
              );
              dynamicObj["xaxis"] = tempArr[j]?.xaxis;
            }
            finalPaymentData.push(dynamicObj);
          }

          let monthlyrevenueData = [];

          for (let i = 0; i < monthlySaleRevenueData?.length; i++) {
            let obj = {
              date: changeDate(monthlySaleRevenueData[i]?.date),
              totalAmount: monthlySaleRevenueData[i]?.totalAmount,
              couponcodeSellDataget50:
                monthlySaleRevenueData[i]?.couponCodeSellData?.get50,
              couponcodeSellDataielts50:
                monthlySaleRevenueData[i]?.couponCodeSellData?.ielts50,
              couponcodeSellDataraman0007:
                monthlySaleRevenueData[i]?.couponCodeSellData?.raman0007,
              basic_Code:
                monthlySaleRevenueData[i]?.couponCodeSellData?.basic_Code,
              withoutCouponCode: monthlySaleRevenueData[i]?.wihtoutCouponCode,
            };
            monthlyrevenueData.push(obj);
          }

          let revData = monthlyrevenueData.reverse();

          //  Ad Links data
          let temp = [];
          for (let i = 0; i < adLinksDayByDay.length; i++) {
            let propertyValueObj = {};
            propertyValueObj["date"] = changeDate(adLinksDayByDay[i]?.date);
            for (let j = 0; j < adlinks.length; j++) {
              let couponCode = adlinks[j];
              propertyValueObj[couponCode] =
                adLinksDayByDay[i]?.linkClickData?.[couponCode];
            }
            temp.push(propertyValueObj);
          }

          let revtemp = temp.reverse();

          setmonthlysaleData(revData);
          setadlinksdatebydate(revtemp);
          setadlinks(adlinks);

          setplanSellData(finalPaymentData);
        }
      })
      .catch((err) => {
        settimetorefresh(false);
        console.log(err);
      });
  };

  //  Get Coupon Spend Details

  const getCouponSpendDetails = (userdata, coupondata) => {
    const jwt = createjwt14(userdata?.uid, coupondata?.id);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/couponCodeWithdrawDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          setcouponspentdetail(res.data.data.totalSpendMoneyOnCouponCode);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get Coupon Spend Credit Data

  const getCouponSpendCredit = (userdata, coupondata) => {
    const jwt = createjwt14(userdata?.uid, coupondata?.id);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/couponCodeWithdrawDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          var nw_arr = res.data.data.totalSpendMoneyOnCouponCode.filter(
            (obj) => obj.transactionStatus === 1,
          );
          setcouponspentdetail(nw_arr);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // Get Coupon Data Withdraw

  const getCouponSpendWithdraw = (userdata, coupondata) => {
    const jwt = createjwt14(userdata?.uid, coupondata?.id);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/couponCodeWithdrawDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          var nw_arr = res.data.data.totalSpendMoneyOnCouponCode.filter(
            (obj) => obj.transactionStatus === 0,
          );
          setcouponspentdetail(nw_arr);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // useEffect(()=>{
  //    const jwt = createjwt15('8zpayRJ5hqa9DrOeAFz9iwE3PMv2','raman0007')
  //    console.log(jwt,"hello demo")
  // },[])

  const handleCouponEditSubmit = (user, couponId, valueId, newValue) => {
    const jwt = createjwt16(user?.uid, couponId, valueId, newValue);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/couponCodeDetailUpdate/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          console.log(res.data);
          const couponCodeId = sessionStorage.getItem("selectedCouponId");
          if (couponCodeId) {
            handleCouponDetail(JSON.parse(couponCodeId));
          }
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  //  Handle Edit Payment Controls

  const handleEditPaymentControl = (user, valueId, newValue) => {
    const jwt = createjwt23(user?.uid, valueId, newValue);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/updateAppControll/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          console.log(res);
          // console.log(res.data);
          // const selectedUser = sessionStorage.getItem("selectedUser");
          const userData = localStorage.getItem("userData");
          const Data = JSON.parse(userData);
          getPayMentControl(Data);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  //  Handle Edit Admin Details

  const handleEditAdminDetails = (
    user,
    appUserUid,
    valueId,
    newValue,
    thumbnail,
  ) => {
    setloader(true);

    const jwt = createjwt17(user?.uid, appUserUid, valueId, newValue);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    if (thumbnail) {
      bodyFormData.append("profileImage", thumbnail);
    }
    axios({
      method: "post",
      url: `${api_url}/adminUserDetailUpdate/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        setloader(false);

        if (res.status === 200) {
          console.log(res.data);
          // const selectedUser = sessionStorage.getItem("selectedUser");
          const selectedAdminUser = JSON.parse(
            sessionStorage.getItem("selectedAdminUser"),
          );
          const userData = localStorage.getItem("userData");
          const Data = JSON.parse(userData);

          // handleUsers(JSON.parse(selectedUser), Data);
          handleAdminUserDetails(selectedAdminUser, Data);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };

  const updateLocalUserData = (user) => {
    const jwt = createjwt21(user?.uid);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/adminUserAccountDetail/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          let userData = res.data.data.userData;
          localStorage.setItem("userData", JSON.stringify(userData));
        } else {
          console.log("Api code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        // setloader(false);
      });
  };

  const handleEditDataEntryPermission = (
    user,
    appUserUid,
    valueId,
    newValue,
  ) => {
    const jwt = createjwt30(user?.uid, appUserUid, valueId, parseInt(newValue));
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);

    axios({
      method: "post",
      url: `${api_url}/adminUserDetailUpdate/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          console.log(res.data);
          // const selectedUser = sessionStorage.getItem("selectedUser");
          const selectedAdminUser = JSON.parse(
            sessionStorage.getItem("selectedAdminUser"),
          );
          const userData = localStorage.getItem("userData");
          const Data = JSON.parse(userData);
          // handleUsers(JSON.parse(selectedUser), Data);
          handleAdminUserDetails(selectedAdminUser, Data);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  // Handle Edit User

  const handleEditUsers = (user, appUserUid, valueId, newValue) => {
    const jwt = createjwt17(user?.uid, appUserUid, valueId, newValue);
    var bodyFormData = new FormData();
    bodyFormData.append("encrptData", jwt);
    axios({
      method: "post",
      url: `${api_url}/appUserDetailUpdate/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // setserverInstancedetails(
        //   res?.data?.data?.appInstanceInfo
        // );
        // console.log(res.data.data,'papue')
        if (res.status === 200) {
          console.log(res.data);
          const selectedUser = sessionStorage.getItem("selectedUser");
          const userData = localStorage.getItem("userData");
          const Data = JSON.parse(userData);
          handleUsersDetailsForEdit(JSON.parse(selectedUser), Data);
        } else {
          console.log("Api Code is not 200");
        }
      })
      .catch((err) => {
        console.log(err);
        settimetorefresh(false);
      });
  };

  const getBase64Enc = (e) => {
    var WordArray = CryptoJS.enc.Utf8.parse(e);
    var result = CryptoJS.enc.Base64.stringify(WordArray);
    // Remove padding equal characters
    result = result.replace(/=+$/, "");
    //Replace characters according to base64url specifications
    result = result.replace(/\+/g, "-");
    result = result.replace(/\//g, "_");

    return result;
  };

  const createjwt = (uid, email, time, timestamp) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      time: time,
      email: email,
      timestamp: timestamp,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt39 = (uid, time, forceCalculationFromScratch) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      time: time,
      forceCalculationFromScratch,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt2 = (uid, time) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      time: time,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt12 = (uid, instanceId) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      instanceId: instanceId,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt21 = (uid) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt13 = (
    uid,
    applicationName,
    environmentName,
    instaceAction,
  ) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      applicationName: applicationName,
      environmentName: environmentName,
      instaceAction: instaceAction,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt22 = (uid, appUserUid) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      appUserUid: appUserUid,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt3 = (uid, feedbackId) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      feedbackId: feedbackId,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt4 = (uid, couponcodeId) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      id: couponcodeId,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt5 = (uid, searchKeyword) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      searchKeyword: searchKeyword,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt6 = (uid, appUserUid) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      appUserUid: appUserUid,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt24 = (uid, appUserUid) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      adminUserUid: appUserUid,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt7 = (uid, inputText) => {
    // Header

    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      inputText: inputText,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt8 = (uid, category) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      category: category,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt30 = (uid, id, valueId, newValue) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      appUserUid: id,
      valueId: valueId,
      newValue: newValue,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt15 = (uid, couponCodeText) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      couponCodeText: couponCodeText,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt27 = (feedbackAction, feedbackId) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      feedbackAction: feedbackAction,
      feedbackId: feedbackId,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt9 = (uid) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt10 = (uid, productionname) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      envName: productionname,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt11 = (
    uid,
    notificationTitle,
    notificationDescription,
    notificationFilter,
    userBackDate,
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      notificationTitle: notificationTitle,
      notificationDescription: notificationDescription,
      notificationFilter: notificationFilter,
      userBackDate: userBackDate,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt46 = (uid, uniqueDeviceId, platform) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      uniqueDeviceId,
      platform
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  }

  const createjwt47 = (uid, classGroupUniqueId) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      classGroupUniqueId
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  }

  const createjwt14 = (uid, id) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      id: id,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt16 = (uid, id, valueId, newValue) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      id: id,
      valueId: valueId,
      newValue: newValue,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt38 = (uid, classUniqueId, valueId, newValue) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      classUniqueId,
      valueId: valueId,
      newValue: newValue,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt17 = (uid, id, valueId, newValue) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      appUserUid: id,
      valueId: valueId,
      newValue: newValue,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };
  const createjwt25 = (applicationName, environmentName) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      applicationName: applicationName,
      environmentName: environmentName,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt31 = (uid, startingDate, endingDate) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      startingDate: startingDate,
      endingDate: endingDate,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt28 = (
    question,
    questionNumber,
    questionType,
    feedbackGroup,
    optionalFeedbackAnswerOptions,
    feedbackId,
    feedbackAction,
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload

    var data =
      questionType === "optionalFeedback"
        ? {
          feedbackId: feedbackId,
          question: question,
          questionType: questionType,
          feedbackGroup: feedbackGroup,
          optionalFeedbackAnswerOptions: optionalFeedbackAnswerOptions,
          questionNumber: questionNumber,
          feedbackAction: feedbackAction,
        }
        : {
          feedbackId: feedbackId,
          question: question,
          questionType: questionType,
          feedbackGroup: feedbackGroup,
          questionNumber: questionNumber,
          feedbackAction: feedbackAction,
        };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt26 = (
    question,
    questionType,
    feedbackGroup,
    feedbackAnsOpt,
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload

    var data =
      questionType === "optionalFeedback"
        ? {
          question: question,
          questionType: questionType,
          feedbackGroup: feedbackGroup,
          optionalFeedbackAnswerOptions: feedbackAnsOpt,
        }
        : {
          question: question,
          questionType: questionType,
          feedbackGroup: feedbackGroup,
        };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt23 = (uid, valueId, newValue) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      valueId: valueId,
      newValue: newValue,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt36 = (uid, classFile, classUniqueId) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      classFile: classFile,
      classUniqueId: classUniqueId,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt40 = (uid, CategoryName, SubCategoryName) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      CategoryName: CategoryName,
      SubCategoryName: SubCategoryName,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt41 = (uid, CategoryName, task, type) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      CategoryName: CategoryName,
      task,
      type
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt42 = (
    uid,
    CategoryName,
    SubCategoryName,
    Question,
    AnswerFirst,
    AnswerSecond,
    task
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      CategoryName: CategoryName,
      SubCategoryName,
      Question,
      AnswerFirst,
      AnswerSecond,
      task
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt43 = (
    uid,
    CategoryName,
    SubCategoryName,
    QuestionUniqueId,
    Question,
    AnswerFirst,
    AnswerSecond,
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      CategoryName: CategoryName,
      SubCategoryName,
      QuestionUniqueId,
      Question,
      AnswerFirst,
      AnswerSecond,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt29 = (testFile, thumbnail, testDate) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      testFile: testFile,
      thumbnail: thumbnail,
      testDate: testDate,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };
  const createjwt20 = (uid, action, uniqueId) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      action: action,
      uniqueId: uniqueId,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt32 = (
    uid,
    questionSection,
    firstGenralQuestion,
    firstGenralQuestionBestAnswer,
    secondGenralQuestion,
    secondGenralQuestionBestAnswer,
    thirdGenralQuestion,
    thirdGenralQuestionBestAnswer,
    forthGenralQuestion,
    forthGenralQuestionBestAnswer,
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      questionSection: questionSection,
      firstGenralQuestion: firstGenralQuestion,
      firstGenralQuestionBestAnswer: firstGenralQuestionBestAnswer,
      secondGenralQuestion: secondGenralQuestion,
      secondGenralQuestionBestAnswer: secondGenralQuestionBestAnswer,
      thirdGenralQuestion: thirdGenralQuestion,
      thirdGenralQuestionBestAnswer: thirdGenralQuestionBestAnswer,
      forthGenralQuestion: forthGenralQuestion,
      forthGenralQuestionBestAnswer: forthGenralQuestionBestAnswer,
    };
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt33 = (
    uid,
    questionSection,
    introductionQuestion,
    introductionBestAnswer,
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      questionSection: questionSection,
      introductionQuestion: introductionQuestion,
      introductionBestAnswer: introductionBestAnswer,
    };
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt37 = (
    uid,
    classFileUrl,
    classTitle,
    classCategory,
    classStatingTime,
    classEndTime,
    classNumber,
    classGroupUniqueId
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      classFileUrl: classFileUrl,
      classTitle: classTitle,
      categoryData: classCategory,
      classStatingTime,
      classEndTime,
      classNumber,
      classGroupUniqueId
    };
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt44 = (uid, WritingCategoryModel, NewWritingCategoryModel) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      WritingCategoryModel,
      NewWritingCategoryModel
    };
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    // signupdata(jwt);

    return jwt;
  }

  const createjwt35 = (
    uid,
    classFileUrl,
    classTitle,
    classCategory,
    classDuration,
    classGroupUniqueId
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid: uid,
      classFileUrl: classFileUrl,
      classTitle: classTitle,
      categoryData: classCategory,
      classDurationData: classDuration,
      classGroupUniqueId
    };
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt34 = (
    uid,
    questionSection,
    cueCardQuestion,
    cueCardQuestionBestAnswer,
    firstFollowUpQuestion,
    firstFollowUpQuestionBestAnswer,
    secondFollowUpQuestion,
    secondFollowUpQuestionBestAnswer,
    thirdFollowUpQuestion,
    thirdFollowUpQuestionBestAnswer,
    forthFollowUpQuestion,
    forthFollowUpQuestionBestAnswer,
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      uid,
      questionSection,
      cueCardQuestion,
      cueCardQuestionBestAnswer,
      firstFollowUpQuestion,
      firstFollowUpQuestionBestAnswer,
      secondFollowUpQuestion,
      secondFollowUpQuestionBestAnswer,
      thirdFollowUpQuestion,
      thirdFollowUpQuestionBestAnswer,
      forthFollowUpQuestion,
      forthFollowUpQuestionBestAnswer,
    };
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    // signupdata(jwt);

    return jwt;
  };

  const createjwt48 = (uid, classGroupName) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));
    var data = {
      uid,
      classGroupName
    };
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    // signupdata(jwt);

    return jwt;
  }

  const createjwt45 = (uid, WritingCategoryName, WritingSubCategoryName, WritingNewSubCategoryName) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));
    var data = {
      uid,
      WritingCategoryName, WritingSubCategoryName, WritingNewSubCategoryName
    };
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    // signupdata(jwt);

    return jwt;
  }

  const createjwt18 = (notificationAction, notificationUniqueId) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data = {
      notificationAction: notificationAction,
      notificationUniqueId: notificationUniqueId,
    };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };
  const createjwt19 = (
    notificationAction,
    notificationUniqueId,
    notificationTitle,
    NotificationMessage,
    notificationCategory,
    notificationStatus,
    type,
  ) => {
    var header = {
      alg: "HS256",
      typ: "JWT",
    };
    var header64 = getBase64Enc(JSON.stringify(header));

    //Playload
    var data =
      type === "Edit"
        ? {
          notificationAction: notificationAction,
          notificationUniqueId: notificationUniqueId,
          notificationTitle: notificationTitle,
          NotificationMessage: NotificationMessage,
          notificationCategory: notificationCategory,
          notificationStatus: notificationStatus,
        }
        : {
          notificationAction: notificationAction,
          notificationTitle: notificationTitle,
          NotificationMessage: NotificationMessage,
          notificationCategory: notificationCategory,
          notificationStatus: notificationStatus,
        };
    console.log("jwt", data);
    var data64 = getBase64Enc(JSON.stringify(data));
    var token = header64 + "." + data64;
    // secret token
    var secret = "120k%#n)^(don(omv4fg_-$8v+mm!(sy%#(h(=v%f+ywykd0(^";
    var signature = CryptoJS.HmacSHA256(token, secret);
    var sign64 = CryptoJS.enc.Base64.stringify(signature);
    var jwt = token + "." + sign64;
    setjwt(jwt);
    console.log(jwt);
    // signupdata(jwt);

    return jwt;
  };
  const value = {
    createjwt,
    jwtval,
    userConvertRateData,
    dailyAciveUserRateData,
    dailyRevenueData,
    notifications,
    feedbacks,
    handleFeedbackDetail,
    paymentbehaviourgraph,
    averageConvertRateForMonth,
    razorPayWalletAmount,
    couponcodedata,
    getHomeData,
    timetorefresh,
    settimetorefresh,
    updateViewData,
    home_page_intervalId,
    stophomepageIntervalId,
    getNotificationData,
    updateNotificationView,
    getFeedBackData,
    updateFeedBackData,
    getCouponCodeData,
    updateCouponCodeData,
    getUserBehaviourData,
    updateUserBehaviourData,
    handleCouponDetail,
    onClickNavLoader,
    setonClickNavLoader,
    paymentcontrolData,
    getPayMentControl,
    updatePayMentControl,
    speakingtestcontrolData,
    updatespeakingTestControl,
    getspeakingTestControl,
    bandcalculationData,
    getbandCalculationControl,
    updatebandCalculationControl,
    errorlogData,
    geterrorLogList,
    updateerrorLogList,
    listeningtestData,
    getlistenigTestList,
    updatelistenigTestList,
    feedbackControlData,
    getfeedbackControl,
    updatefeedbackControl,
    totalMoneyGetInBackDates,
    totalSuccessfullPaymentInBackDates,
    totalProfitgetInBackDatesBeforeRazorPayText,
    totalAmountSettleBeforeRazorPayTaxInBackDates,
    totalAmountSettleAfterRazorPayTaxInBackDates,
    getuserslastdays,
    daysAppUsers,
    settlePercentageByRazorPay,
    totalProfitgetInBackDatesBeforeRazorPayTextAfterGST,
    userComeOnAppInLast30Minute,
    profitAfterExpense,
    totalAmount,
    kotakCurrentAccount,
    iciciSavingAccount,
    iciciCurrentAccount,
    razorPayXAccount,
    totalExpense,
    getSearchusers,
    isClearSearch,
    handleAppUserDetails,
    isSearchButtonClicked,
    setisSearchButtonClicked,
    serverInfodata,
    paidunpaidretentiondata,
    paidunpaidavgtimespend,
    paidunpaidallfeaturesusage,
    paidunpaidpaidfeaturesusage,
    paidunpaidunpaidfeaturesusage,
    androidretentiondata,
    androidavgtimespend,
    androidallfeaturesusage,
    androidpaidfeaturesusage,
    androidunpaidfeaturesusage,
    iosretentiondata,
    iosavgtimespend,
    iosallfeaturesusage,
    iospaidfeaturesusage,
    iosunpaidfeaturesusage,
    combinedretentiondata,
    combinedavgtimespend,
    handleGrammarMistakefunc,
    androidConvertRateForMonth,
    ConvertRateTillNow,
    iosConvertRateForMonth,
    getQuestions,
    testingData,
    getTranscationList,
    transcationData,
    getCashBook,
    cashbooktranscation,
    clickedtrans,
    setclickedtrans,
    prodserverdetails,
    scheduleserverdetails,
    speechTotextserverdetails,
    getServerDetails,
    serverInstancedetails,
    handleCustomNoti,
    isfailednoti,
    setisfailednoti,
    closeSidebar,
    setcloseSidebar,
    notititle,
    setnotititle,
    notidesc,
    setnotidesc,
    paymentbehaviourday,
    expensesdata,
    setcashbooktranscation,
    getCashBookCreditCompany,
    getCashBookCreditPersonal,
    getCashBookCredit,
    getCashBookDebit,
    getCashBookDebitCompany,
    getCashBookDebitPersonal,
    noticategory,
    handleNotificationsCategory,
    getNotificationStoredData,
    updateNotificationStoredView,
    notistoredcategory,
    notistoreddata,
    handleNotificationsStoredCategory,
    maxiTickValue,
    allappleaveuserfeaturesusage,
    androidappleaveuserfeaturesusage,
    iosappleaveuserfeaturesusage,
    setserverInstancedetails,
    terminateInstance,
    isterminatesuccess,
    setisterminatesuccess,
    isterminateerror,
    setisterminateerror,
    environmentName,
    addInstanceOfaParticularServer,
    addinstancesuccess,
    setaddinstancesuccess,
    addinstanceerror,
    setaddinstanceerror,
    removeInstanceOfaParticularServer,
    removeinstancesuccess,
    setremoveinstancesuccess,
    removeinstanceerror,
    setremoveinstanceerror,
    width,
    homeapierr,
    sethomeapierr,
    singleCoupounDetail,
    getCashBookAllCompany,
    getCashBookAllPersonal,
    getCouponSpendDetails,
    couponspenddetail,
    getCouponSpendCredit,
    getCouponSpendWithdraw,
    getSearchCouponUsers,
    handleCouponEditSubmit,
    handleUsersDetailsForEdit,
    selectedUserdetail,
    handleEditUsers,
    getTranscationListSearchData,
    activeData,
    setactiveData,
    NotificationStoredUpdate,
    storednotititle,
    setstorednotititle,
    storednotidesc,
    setstorednotidesc,
    storedcategoryvalue,
    setstoredcategoryvalue,
    storedstatusvalue,
    setstoredstatusvalue,
    NotificationStoredEditAndAdd,
    RawsuggsetValue,
    updateLocalUserData,
    Rawsuggvalue,
    RawsuggfilteredValue,
    RawsuggsetfilteredValue,
    handleRawSuggFeedbackActions,
    clearErrorlogs,
    inapppurchasenoOfPayment,
    clearUserDetails,
    usergotDeleted,
    setusergotDeleted,
    handleEditPaymentControl,
    getAdminusers,
    daysAdminUsers,
    handleAdminUserDetails,
    adminUserDetail,
    checkServerLoadOfAParticularServer,
    checkserverstatusclick,
    setcheckserverstatusclick,
    setcheckserverstatuserr,
    checkserverstatuserr,
    handleEditAdminDetails,
    PaidUnpaidfeedbacktabs,
    PaidUnpaidsetfeedbacktabs,
    HandleAddFeedbacks,
    HandleEditFeedbacks,
    HandleEditOnlyFeedbacks,
    verifyUserAccountValid,
    checkingStatus,
    uploadListeningTestData,
    getReadingTestList,
    updateReadingTestList,
    readingtestData,
    addReadingTest,
    handleEditDataEntryPermission,
    handlecheckAccountAccessibility,
    checkingTextMsgCode,
    countdown,
    setCountdown,
    getPaymentBehaviourDetailsGraphs,
    planSellData,
    advertisementLinkData,
    monthlysaleData,
    getSpeakingTestData,
    updateSpeakingTestList,
    speakingtestData,
    addGeneralSpeakingTest,
    addIntroSpeakingTest,
    loader,
    pdfloader,
    setpdfloader,
    addQueCardSpeakingTest,
    handleLogout,
    getClassesData,
    classesCategory,
    classesData,
    selectclassesCategory,
    addClassesData,
    classDetailedData,
    getDetailsOfAClass,
    classRatingsData,
    classCommentsData,
    scheduleClassesData,
    handleEditClassData,
    adlinksdatebydate,
    adlinksdata,
    getwritingTest,
    updatewritingTest,
    writingListData,
    addsubcategoryWriting,
    EditCategoryWriting,
    singlewritinglistData,
    setsinglewritingListData,
    addcategoryWriting,
    addSubCategoryWritingQues,
    getQuestionsWritingOfSubCategory,
    questionlistData,
    writingquestion,
    writinganswer1,
    writinganswer2,
    setwritinganswer1,
    setwritingquestion,
    setwritinganswer2,
    singlewritingquestion,
    setsinglewritingquestion,
    editSubCategoryWritingQues,
    editsubcategoryWriting,
    selectedsubcategoryname, setselectedsubcategoryname,
    getClassesGrp,
    classesGrp,
    addClassGrp
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
