import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import ExpenseGraph from "../Charts/ExpenseGraph";
import { makeStyles } from "@material-ui/core";
import CompanyExpenseGraph from "../Charts/ExpenseGraphs/CompanyExpenseGraph";
import CompanyUnknownGraph from "../Charts/ExpenseGraphs/CompanyUnknownGraph";
import CompanySalaryGraph from "../Charts/ExpenseGraphs/CompanySalaryGraph";
import CompanyServerGraph from "../Charts/ExpenseGraphs/CompanyServerGraph";
import CompanyAdGraph from "../Charts/ExpenseGraphs/CompanyAdGraph";

const useStyles = makeStyles((theme) => ({
  style: {
    fontSize: "10px !important",
    padding: "0px 4px !important",
    minHeight: "14px !important",

    [theme.breakpoints.down("sm")]: {
      fontSize: "8px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
  },
  roots: {
    marginTop: "5px !important",
    minHeight: "22px !important",
  },
}));

const CompanyGraphTab = () => {
  const [value, setValue] = useState("five");

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          centered
          className={classes.roots}
        >
          <Tab value="five" label="All" className={classes.style} />

          <Tab value="one" label="Unknown" className={classes.style} />
          <Tab value="two" label="Salary" className={classes.style} />
          <Tab value="three" label="Server" className={classes.style} />
          <Tab value="four" label="Advertisement" className={classes.style} />
        </Tabs>
        <TabPanel value="five" style={{ padding: "0" }}>
          {" "}
          <CompanyExpenseGraph />
        </TabPanel>
        <TabPanel value="one" style={{ padding: "0" }}>
          {" "}
          <CompanyUnknownGraph />
        </TabPanel>
        <TabPanel value="two" style={{ padding: "0" }}>
          {" "}
          <CompanySalaryGraph />
        </TabPanel>
        <TabPanel value="three" style={{ padding: "0" }}>
          {" "}
          <CompanyServerGraph />
        </TabPanel>
        <TabPanel value="four" style={{ padding: "0" }}>
          {" "}
          <CompanyAdGraph />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default CompanyGraphTab;
