import React, { useState } from "react";
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { makeStyles } from "@material-ui/core";
import CustomDialogBox from "../CustomDialogBox";
import { useAuth } from "../../Providers/UserProvider";
import DragAndDropComp from "../UploadingVideoFormComp/DragAndDropComp/DragAndDropComp";
import { AddWritingCategoryDialogBodyTxt } from "../../MiddleWares/AddWritingCategoryDialogBodyTxt";

const useStyles = makeStyles({
  root: { height: "100vh" },
  paper: { borderRadius: 5, width: "100%" },
});

const AddCategoryWriting = ({ opendialog, handleClickClose }) => {
  const classes = useStyles();

  const [categorydata, setcategorydata] = useState("");
  const [value, setValue] = React.useState('');
  const [taskonevalue, settaskonevalue] = useState('')

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handletaskoneChange = (e) => {
    settaskonevalue(e.target.value)
  }
  const [open, setopen] = useState(false);
  const [thumbNailUpload, setthumbNailUpload] = useState({
    preview: "",
    raw: "",
  });

  const { addcategoryWriting } = useAuth();

  const handleSubmitOfDialog = () => {
    const Data = JSON.parse(localStorage.getItem("userData"));
    const task = value === 'task1' ? 1 : 2;
    const type = value === 'task2' ? 1 : taskonevalue === 'academic' ? 1 : 2;
    addcategoryWriting(Data, categorydata, thumbNailUpload.raw,task,type);
    handleClickCloseConfirm();
    handleClickClose();
  };

  const handleClickOpenConfirm = () => {
    setopen(true);
  };

  const handleClickCloseConfirm = () => {
    setopen(false);
  };

  return (
    <Dialog
      open={opendialog}
      onClose={handleClickClose}
      aria-labelledby="customized-dialog-title"
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
          paddingTop: "5vh",
        }}
      >
        {handleClickClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClickClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            style={{ position: "absolute" }}
          >
            <AiOutlineCloseCircle />
          </IconButton>
        ) : null}
      </DialogTitle>
      <div style={{ padding: "2vw" }}>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "5px",
            alignItems: "center",
            textAlign: "center",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          <DragAndDropComp
            thumbNailUpload={thumbNailUpload}
            setthumbNailUpload={setthumbNailUpload}
            type="Image"
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "5px",
            alignItems: "center",
            textAlign: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography>Category Name:-</Typography>
          <TextField
            value={categorydata}
            onChange={(e) => {
              setcategorydata(e.target.value);
            }}
          />
        </div>
        <div>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">Tasks</FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
              style={{ display: 'flex', gap: "5px", alignItems: "center", flexDirection: 'row' }}
            >
              <FormControlLabel value="task1" control={<Radio />} label="Task1" />
              <FormControlLabel value="task2" control={<Radio />} label="Task2" />
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          {
            value === "task1" && <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Type</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={taskonevalue}
                onChange={handletaskoneChange}
                style={{ display: 'flex', gap: "5px", alignItems: "center", flexDirection: 'row' }}
              >
                <FormControlLabel value="academic" control={<Radio />} label="Academic" />
                <FormControlLabel value="general" control={<Radio />} label="General" />
              </RadioGroup>
            </FormControl>
          }
        </div>
      </div>

      <DialogActions>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickOpenConfirm();
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <CustomDialogBox
        opendialog={open}
        handleClickClose={handleClickCloseConfirm}
        bodyText={
          <AddWritingCategoryDialogBodyTxt
            thumbnail={thumbNailUpload.preview}
            category={categorydata}
          />
        }
        confirmationText="Are You Sure Want To Add These Changes"
        handleSubmitOfDialog={handleSubmitOfDialog}
      />
    </Dialog>
  );
};

export default AddCategoryWriting;
