import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";

export default function PaymentBehaviourInADay() {
  const { paymentbehaviourday } = useAuth();

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "0px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
        >
          <p
            style={{ color: "#008390" }}
          >{`value : ${payload[0].payload.str}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`Today : ${payload[0].payload.today}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`OneDay : ${payload[0].payload.oneDay}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`TwoDay : ${payload[0].payload.twoDay}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`ThreeDay : ${payload[0].payload.threeDay}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart width={600} height={300} data={paymentbehaviourday}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="str" />

        <YAxis tickCount={12} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey="today"
          name="Today"
          stroke="blue"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="oneDay"
          name="Oneday"
          stroke="red"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="twoDay"
          name="Twoday"
          stroke="green"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="threeDay"
          name="Threeday"
          stroke="#AE5D00"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
