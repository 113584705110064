import React, { useEffect } from "react";
import LogInButton from "../Components/LogInButton";
import { useAuth } from "../Providers/UserProvider";
import { useNavigate } from "react-router-dom";

const LogIn = () => {
  // const { user } = useAuth();
  // let navigate = useNavigate();

  // useEffect(() => {
  //   if (user != null) navigate("/home");
  // }, [user,navigate]);
  return (
    <>
      {" "}
      <div className="centerDiv">
        <div>
          <h1 className="heading">BabyCode</h1>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <h4 style={{ marginTop: "0" }} className="secondheading">
              Admin Website
            </h4>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LogInButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default LogIn;
