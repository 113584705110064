import React, { useEffect } from "react";
import SidebarComp from "../Components/Sidebar";
import "../CSSFiles/home.css";
import { useAuth } from "../Providers/UserProvider";
import { Typography } from "@mui/material";
import SidebarMob from "../Components/SidebarMob";
import Navbar from "../Components/Navbar/Navbar";
import UserDetails from "../Components/LastDaysUsersComp/SelectedUserDetailEdit/UserDetails";

const UsersDetailsEdit = () => {
  const {
    timetorefresh,
    closeSidebar,
    handleUsersDetailsForEdit,
    // selectedUserdetail,
  } = useAuth();

  useEffect(() => {
    startCouponCodeDetail();
  }, []);

  const startCouponCodeDetail = () => {
    const selectedUser = sessionStorage.getItem("selectedUser");
    const userData = localStorage.getItem("userData");
    const Data = JSON.parse(userData);
    handleUsersDetailsForEdit(JSON.parse(selectedUser), Data);
  };

  // console.log(selectedUserdetail);

  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <UserDetails />
      </div>
    </>
  );
};

export default UsersDetailsEdit;
