import React from "react";
import "../../CSSFiles/tablecomp.css";

import Card from "@material-ui/core/Card";
import { useAuth } from "../../Providers/UserProvider";
import UsersSearchField from "./UsersSearchField";
import { MoonLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const AdminUsersTableComp = () => {
  const {
    daysAdminUsers,
    handleAdminUserDetails,
    isSearchButtonClicked,
    onClickNavLoader,
  } = useAuth();
  const changeDateFormate = (strDateTime) => {
    const date = new Date(strDateTime);
    var myDate = date.toLocaleString();
    return myDate;
  };

  const navigate = useNavigate();

  return (
    <>
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <MoonLoader
          color="green"
          loading={onClickNavLoader}
          size={60}
          cssOverride={{
            transform: "initial",
          }}
        />
      </div>
      <Card
        style={{ width: "100%" }}
        className={onClickNavLoader ? `card invisible` : `card`}
      >
        {/* <UsersSearchField type="Users" /> */}

        <table className="users_table_comp">
          <thead>
            <tr>
              <td align="center" style={{ fontWeight: "bold", border: "0" }}>
                Identifier
              </td>

              <td align="center" style={{ fontWeight: "bold", border: "0" }}>
                Created_at
              </td>
              <td align="center" style={{ fontWeight: "bold", border: "0" }}>
                User Id
              </td>
            </tr>
          </thead>
          <tbody>
            {isSearchButtonClicked ? (
              <div>
                <MoonLoader
                  color="rgba(54, 214, 84, 1)"
                  loading={true}
                  size={60}
                  cssOverride={{
                    transform: "initial",
                    position: "absolute",
                    top: "25vh",
                    left: "40vw",
                  }}
                />
              </div>
            ) : (
              daysAdminUsers?.map((row) => (
                <tr
                  key={row.uid}
                  onClick={(e) => {
                    e.preventDefault();
                    // const userData = localStorage.getItem("userData");
                    // const Data = JSON.parse(userData);
                    sessionStorage.setItem(
                      "selectedAdminUser",
                      JSON.stringify(row?.uid),
                    );
                    navigate("/adminUserDetails");
                    // handleAdminUserDetails(row.uid, Data);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td align="center">{row.email}</td>
                  <td align="center">
                    {changeDateFormate(row.accountStartingDateInFormat)}
                  </td>
                  <td align="center">{row.uid}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </Card>
    </>
  );
};

export default AdminUsersTableComp;
