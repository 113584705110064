import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { BsFillCalendarDateFill } from "react-icons/bs";
import "../CSSFiles/calendar_comp.css";

import "react-datepicker/dist/react-datepicker.css";

const CalendarComp = ({ calendarData, setcalendarDate, isReadonly }) => {
  return (
    <DatePicker
      showIcon={<BsFillCalendarDateFill />}
      selected={calendarData}
      onChange={(date) => setcalendarDate(date)}
      className="calendarComp"
      readOnly={isReadonly ? true : false}
    />
  );
};

export default CalendarComp;
