import React, { useState, useEffect } from "react";
import { useAuth } from "../../../Providers/UserProvider";

export default function ReadingTestPDFViewer({ pdf }) {
  const { setpdfloader } = useAuth();
  const [loading, setLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);

  // Function to handle the iframe onLoad event
  const handleLoad = () => {
    setLoading(false);
    setpdfloader(false);
  };

  // Function to handle error when PDF fails to load
  const handleLoadError = () => {
    setErrorOccurred(true);
    setLoading(false);
    setpdfloader(false);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setErrorOccurred(true);
      setLoading(false);
      setpdfloader(false);
    }, 60000); // 1 minute (60000 milliseconds)

    return () => {
      clearTimeout(timeoutId);
    };
  }, [setpdfloader]);

  return (
    <div className="pdf-container">
      {errorOccurred ? (
        <>
          <div>Error: PDF took too long to load</div>
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            here
          </a>
        </>
      ) : (
        <iframe
          title="PDF Viewer"
          type="application/pdf"
          src={`https://docs.google.com/gview?url=${pdf}&embedded=true`}
          width="100%"
          height="100%"
          onLoad={handleLoad}
          onError={handleLoadError}
        />
      )}
    </div>
  );
}
