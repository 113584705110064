import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "../../CSSFiles/feedbacks.css";

const PaidPageFeedbacks = ({ res }) => {
  return (
    <Card className="cardsub" style={{ marginBottom: "2vh" }}>
      <CardContent>
        <div>
          <div className="gridings">
            <div className="firsts">
              <Typography className="titles">Question :-</Typography>
              <Typography className="bodys">{res.question}</Typography>
            </div>
            <div className="seconds"></div>
            <div className="thirds">
              <Typography className="titles2">Question Category :-</Typography>
              <Typography className="bodys">{res.questionType}</Typography>
            </div>
          </div>
          {res.questionType === "YesNo" && (
            <div className="yesno">
              <div className="yes">
                <Typography className="bodys">Yes</Typography>
                <ProgressBar
                  labelClassName="progresslabel"
                  completed={res.userAnswerYesPercentage}
                />
              </div>
              <div className="no">
                <Typography className="bodys">No</Typography>
                <ProgressBar
                  labelClassName="progresslabel"
                  completed={res.userAnswerNoPercentage}
                />
              </div>
            </div>
          )}
          {res.questionType === "optionalFeedback" && (
            <div className="bottomoptions">
              {res?.getTotalSuggestion?.map((opt, key) => (
                <div className="options">
                  <Typography className="bodys">{opt.option}</Typography>
                  <ProgressBar
                    className="progwrapper"
                    labelClassName="progresslabel"
                    completed={opt.userChooseThisOption}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default PaidPageFeedbacks;
