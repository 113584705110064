import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell,
} from "recharts";
import { useAuth } from "../../Providers/UserProvider";

export default function ServerInfo() {
  const { serverInfodata } = useAuth();
  console.log(serverInfodata);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && label === "DB") {
      return (
        <div
          style={{
            padding: "5px",

            fontSize: "12px",
            background: "white",
          }}
          className="graph_tooltips"
        >
          <p>{`RDSCpuUtility : ${payload[0].payload.first}`}</p>
          <p>{`RDSFreeSpace : ${payload[0].payload.second}`}</p>
        </div>
      );
    } else if (active && payload && payload.length && label != "DB") {
      return (
        <div
          style={{
            padding: "5px",
            maxWidth: "250px",
            fontSize: "12px",
            background: "white",
          }}
          className="graph_tooltips"
        >
          <p>{`Ok : ${payload[0].payload.first}`}</p>
          <p>{`Degraded : ${payload[0].payload.InstancesHealth.Degraded}`}</p>
          <p>{`Info : ${payload[0].payload.InstancesHealth.Info}`}</p>
          <p>{`Pending : ${payload[0].payload.InstancesHealth.Pending}`}</p>
          <p>{`Severe : ${payload[0].payload.InstancesHealth.Severe}`}</p>
          <p>{`Unknown : ${payload[0].payload.InstancesHealth.Unknown}`}</p>
          <p>{`Warning : ${payload[0].payload.InstancesHealth.Warning}`}</p>

          <p>{`Causes : ${payload[0].payload.causesTxt}`}</p>
        </div>
      );
    }

    return null;
  };

  const getDataKey = (item) => {
    if (item?.EnvironmentName === "DB" && item?.second > 10) {
      return "";
    }
    return item?.second;
  };

  return (
    <ResponsiveContainer width="99%" height={300}>
      <BarChart data={serverInfodata}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="EnvironmentName" />

        <XAxis
          dataKey="HealthStatus"
          axisLine={false}
          tickLine={false}
          interval={0}
          height={30}
          xAxisId="quarter"
        />

        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="first" name="Ok">
          {serverInfodata.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                entry.EnvironmentName === "DB"
                  ? entry.first > 80
                    ? "red"
                    : "green"
                  : "green"
              }
            />
          ))}
        </Bar>

        <Bar dataKey={getDataKey} name="Others">
          {" "}
          {serverInfodata.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                entry.EnvironmentName === "DB"
                  ? entry.second <= 10
                    ? "red"
                    : "green"
                  : entry.Color
              }
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
