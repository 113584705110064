import { Typography } from "@mui/material";
import React from "react";

const AddAndEditSpeakingDialogBodyTxt = ({
  type,
  introques,
  introans,
  introvideo,
  generalques1,
  generalans1,
  generalvideo1,
  generalques2,
  generalans2,
  generalvideo2,
  generalques3,
  generalans3,
  generalvideo3,
  generalques4,
  generalans4,
  generalvideo4,
  queCardques,
  queCardans,
  queCardvideo,
  followupques1,
  followupans1,
  followupvideo1,
  followupques2,
  followupans2,
  followupvideo2,
  followupques3,
  followupans3,
  followupvideo3,
  followupques4,
  followupans4,
  followupvideo4,
}) => {
  return (
    <div style={{ padding: "2vw", overflowY: "scroll" }}>
      {type.value === 0 && (
        <>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Question Section :-</Typography>
            <Typography className="body">{type.label}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Question :-</Typography>
            <Typography className="body">{introques}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Answer :-</Typography>
            <Typography className="body">{introans}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">VideoFile :-</Typography>
            <Typography className="body">{introvideo}</Typography>
          </div>
        </>
      )}
      {type.value === 1 && (
        <>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Question Section :-</Typography>
            <Typography className="body">{type.label}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Question1 :-</Typography>
            <Typography className="body">{generalques1}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Answer1 :-</Typography>
            <Typography className="body">{generalans1}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">VideoFile1 :-</Typography>
            <Typography className="body">{generalvideo1}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Question2 :-</Typography>
            <Typography className="body">{generalques2}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Answer2 :-</Typography>
            <Typography className="body">{generalans2}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">VideoFile2 :-</Typography>
            <Typography className="body">{generalvideo2}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Question3 :-</Typography>
            <Typography className="body">{generalques3}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Answer3 :-</Typography>
            <Typography className="body">{generalans3}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">VideoFile3 :-</Typography>
            <Typography className="body">{generalvideo3}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Question4 :-</Typography>
            <Typography className="body">{generalques4}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Answer4 :-</Typography>
            <Typography className="body">{generalans4}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">VideoFile4 :-</Typography>
            <Typography className="body">{generalvideo4}</Typography>
          </div>
        </>
      )}
      {type.value === 2 && (
        <>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Question Section :-</Typography>
            <Typography className="body">{type.label}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Question :-</Typography>
            <Typography className="body">{queCardques}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">Answer :-</Typography>
            <Typography className="body">{queCardans}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">VideoFile :-</Typography>
            <Typography className="body">{queCardvideo}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp Question1 :-</Typography>
            <Typography className="body">{followupques1}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp Answer1 :-</Typography>
            <Typography className="body">{followupans1}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp VideoFile1 :-</Typography>
            <Typography className="body">{followupvideo1}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp Question2 :-</Typography>
            <Typography className="body">{followupques2}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp Answer2 :-</Typography>
            <Typography className="body">{followupans2}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp VideoFile2 :-</Typography>
            <Typography className="body">{followupvideo2}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp Question3 :-</Typography>
            <Typography className="body">{followupques3}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp Answer3 :-</Typography>
            <Typography className="body">{followupans3}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp VideoFile3 :-</Typography>
            <Typography className="body">{followupvideo3}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp Question4 :-</Typography>
            <Typography className="body">{followupques4}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp Answer4 :-</Typography>
            <Typography className="body">{followupans4}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              flexWrap: "wrap",
            }}
          >
            <Typography className="title">FollowUp VideoFile4 :-</Typography>
            <Typography className="body">{followupvideo4}</Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default AddAndEditSpeakingDialogBodyTxt;
