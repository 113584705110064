import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";

export default function CombinedRetentionData() {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "0px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
          className="graph_tooltips"
        >
          <p
            style={{ color: "#008390" }}
          >{`Xaxis : ${payload[0].payload.xaxis}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AllUsers/All : ${payload[0].payload.allalluseractivepercentage}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AllUsers/Paid : ${payload[0].payload.allpaiduseractivepercentage}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AllUsers/Unpaid : ${payload[0].payload.allunpaiduseractivepercentage}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`AllUsers/AppLeaveUsers : ${payload[0].payload.allappuserleaveactivepercentage}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`AndroidUsers/All : ${payload[0].payload.androidalluseractivepercentage}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AndroidUsers/Paid : ${payload[0].payload.androidpaiduseractivepercentage}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AndroidUsers/Unpaid : ${payload[0].payload.androidunpaiduseractivepercentage}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`AndroidUsers/AppLeaveUsers : ${payload[0].payload.androidappuserleaveactivepercentage}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`iosUsers/All : ${payload[0].payload.iosalluseractivepercentage}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`iosUsers/Paid : ${payload[0].payload.iospaiduseractivepercentage}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`iosUsers/Unpaid : ${payload[0].payload.iosunpaiduseractivepercentage}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`iosUsers/AppLeaveUsers : ${payload[0].payload.iosappuserleaveactivepercentage}`}</p>
        </div>
      );
    }

    return null;
  };

  const { combinedretentiondata } = useAuth();
  return (
    <ResponsiveContainer width="99%" height={300}>
      <LineChart data={combinedretentiondata}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="xaxis" />

        <YAxis tickCount={9} type="number" domain={["dataMin", "dataMax"]} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          name="All Users/All"
          dataKey="allalluseractivepercentage"
          stroke="#EE005F"
        />
        <Line
          type="monotone"
          name="All Users/Paid"
          dataKey="allpaiduseractivepercentage"
          stroke="#FF0000"
          activeDot={{ r: 8 }}
        />

        <Line
          type="monotone"
          name="All Users/Unpaid"
          dataKey="allunpaiduseractivepercentage"
          stroke="#B62A8F"
        />

        <Line
          type="monotone"
          name="All Users/AppLeaveUser"
          dataKey="allappuserleaveactivepercentage"
          stroke="#93697C"
        />

        <Line
          type="monotone"
          name="Android/All"
          dataKey="androidalluseractivepercentage"
          stroke="#00EA71"
        />
        <Line
          type="monotone"
          name="Android/Paid"
          dataKey="androidpaiduseractivepercentage"
          stroke="#00FF00"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          name="Android/Unpaid"
          dataKey="androidunpaiduseractivepercentage"
          stroke="#00D0B2"
        />
        <Line
          type="monotone"
          name="Android/AppLeaveUser"
          dataKey="androidappuserleaveactivepercentage"
          stroke="#008F00"
        />
        <Line
          type="monotone"
          name="Ios/All"
          dataKey="iosalluseractivepercentage"
          stroke="#0000FF"
        />
        <Line
          type="monotone"
          name="Ios/Paid"
          dataKey="iospaiduseractivepercentage"
          stroke="#8774A6"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          name="Ios/Unpaid"
          dataKey="iosunpaiduseractivepercentage"
          stroke="#362B48"
        />
        <Line
          type="monotone"
          name="Ios/AppLeaveUser"
          dataKey="iosappuserleaveactivepercentage"
          stroke="#008FFF"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
