import React, { useEffect, useState } from "react";
import SidebarComp from "../../Components/Sidebar";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import UsersTableComp from "../../Components/LastDaysUsersComp/UsersTableComp";
import SidebarMob from "../../Components/SidebarMob";
import AdminUsersTableComp from "../../Components/LastDaysUsersComp/AdminUsersTableComp";

const AdminUsers = () => {
  const location = useLocation();
  const { getAdminusers, timetorefresh, closeSidebar } = useAuth();

  useEffect(() => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    console.log("The user page is loading");
    getAdminusers(Data);
  }, [location]);

  return (
    // <div className="home">
    //   {/* <SidebarComp />
    //   {timetorefresh === true && (
    //     <Typography
    //       style={{
    //         margin: 0,
    //         top: 15,
    //         right: 20,
    //         bottom: 20,
    //         left: "auto",
    //         position: "fixed",
    //         zIndex: "1",
    //       }}
    //     >
    //       Refreshing...
    //     </Typography>
    //   )}
    // </div>
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />

        <AdminUsersTableComp />
      </div>
    </>
  );
};

export default AdminUsers;
