import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core";
import { Typography, IconButton, Button } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "../../../CSSFiles/coupon.css";
import ConfirmEditDialog from "./ConfirmEditDialog";

import "react-datepicker/dist/react-datepicker.css";
import CalendarComp from "../../../MiddleWares/CalendarComp";

const useStyles = makeStyles({
  root: {},
  paper: { borderRadius: 5, width: "100%" },
});

const EditDate = ({ opendialog, handleClickClose, dates, valueId, type }) => {
  const classes = useStyles();

  const [value, setValue] = useState(new Date(dates.value));
  const [open, setopen] = useState(false);

  const convertDateObject = (date) => {
    if (date) {
      var dt = new Date(date);

      return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
    }
  };

  const handleClickOpenConfirm = () => {
    setopen(true);
  };

  const handleClickCloseConfirm = () => {
    setopen(false);
  };

  useEffect(() => {
    setValue(new Date(dates.value));
  }, [dates.value]);

  return (
    <Dialog
      open={opendialog}
      onClose={handleClickClose}
      aria-labelledby="customized-dialog-title"
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
          paddingTop: "5vh",
        }}
      >
        {handleClickClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClickClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            style={{ position: "absolute" }}
          >
            <AiOutlineCloseCircle />
          </IconButton>
        ) : null}
      </DialogTitle>
      <div style={{ padding: "2vw" }}>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "5px",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography className="title">{dates.title}</Typography>
          {/* <DatePicker
            selected={value}
            onChange={(date) => setValue(date)}
            calendarClassName="custom-calendar"
          /> */}
          <CalendarComp calendarData={value} setcalendarDate={setValue} />
        </div>
      </div>

      <DialogActions style={{ zIndex: "-1" }}>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickOpenConfirm();
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <ConfirmEditDialog
        open={open}
        handleClose={handleClickCloseConfirm}
        handleClickClose={handleClickClose}
        text={convertDateObject(value)}
        title={dates.title}
        valueId={valueId}
        type={type}
      />
    </Dialog>
  );
};

export default EditDate;
