import React, { useEffect } from "react";
import { useAuth } from "../../../Providers/UserProvider";
import { Card, CardContent, Typography } from "@mui/material";
import { truncate } from "../../../Services/Constants";

const QueueQues = ({ handleAnswerClick }) => {
  const { getQuestions, testingData } = useAuth();
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const Data = JSON.parse(userData);
    getQuestions(Data, "queCardQuestions");
  }, []);
  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      {testingData?.map((data) => {
        return (
          <Card
            className="cardsub"
            style={{ marginBottom: "2vh" }}
            onClick={(e) => {
              e.preventDefault();
              handleAnswerClick(data.Answer);
            }}
          >
            <CardContent>
              <div
                style={{ display: "flex", alignItems: "center", gap: "13px" }}
              >
                <Typography className="title">Id:</Typography>

                <Typography className="body">{data.id}</Typography>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "13px" }}
              >
                <Typography className="title">Answer:</Typography>
                <Typography className="body">
                  {truncate(data.Answer, 300)}
                </Typography>{" "}
              </div>
            </CardContent>
          </Card>
        );
      })}
    </Card>
  );
};

export default QueueQues;
