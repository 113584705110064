import { Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import "../../../CSSFiles/userdetails.css";
import {
  changeDateFormate,
  changeTimeStamptoDate,
  IsProfileCompleted,
} from "../../../Services/Constants";
import { useAuth } from "../../../Providers/UserProvider";
import UserEditTextDialog from "./UserEditTextDialog";
import EditDate from "../../CouponComp/EditForms/EditDate";

const UserDetails = () => {
  const { selectedUserdetail } = useAuth();
  const [openeditdateDialog, setopeneditdateDialog] = useState(false);
  const [openedittextDialog, setopenedittextDialog] = useState(false);
  const [valueId, setvalueId] = useState("");

  const [text, settext] = useState({
    title: "",
    value: "",
  });

  const [dates, setdates] = useState({
    title: "",
    value: "",
  });

  const handleClickOpenEditDate = () => {
    setopeneditdateDialog(true);
  };

  const handleClickCloseDate = () => {
    setopeneditdateDialog(false);
  };

  const handleClickOpenEdit = () => {
    setopenedittextDialog(true);
  };

  const handleClickClose = () => {
    setopenedittextDialog(false);
  };

  const handleEditText = (title, value, valueId) => {
    settext({
      title: title,
      value: value,
    });
    setvalueId(valueId);
    handleClickOpenEdit();
  };

  const handleEditDates = (title, value, valueId) => {
    if (!value) var date = new Date();
    else var date = new Date(value);
    setdates({
      title: title,
      value: date,
    });
    setvalueId(valueId);
    handleClickOpenEditDate();
  };

  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Name", selectedUserdetail?.fullName, "1");
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Name :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.fullName}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Email", selectedUserdetail?.email, "2");
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Email :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.email}
            </Typography>
          </div>
        </CardContent>
      </Card>

      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("PhoneNumber", selectedUserdetail?.phoneNumber, "4");
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">PhoneNumber :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.phoneNumber}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Membership", selectedUserdetail?.memberShip, "5");
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Membership :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.memberShip}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditDates(
            "PlanExpireDate",
            changeTimeStamptoDate(selectedUserdetail?.planExpireDate),
            "6",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">PlanExpireDate :-</Typography>
            <Typography className="body">
              {changeTimeStamptoDate(selectedUserdetail?.planExpireDate)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("UID", selectedUserdetail?.uid, "7");
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">UID :-</Typography>
            <Typography className="body">{selectedUserdetail?.uid}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "UniqueDeviceId",
            selectedUserdetail?.uniqueDeviceId,
            "8",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">UniqueDeviceId :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.uniqueDeviceId}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("WalletAmount", selectedUserdetail?.walletAmount, "9");
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">WalletAmount :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.walletAmount}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "TotalTestForToday",
            selectedUserdetail?.totalTestForToday,
            "10",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">TotalTestForToday :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.totalTestForToday}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "CouponCodeText",
            selectedUserdetail?.couponCodeText,
            "11",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">CouponCodeText :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.couponCodeText}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "MoneyWithdrawSessionTime",
            selectedUserdetail?.moneyWithdrawSessionTime,
            "22",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">
              MoneyWithdrawSessionTime :-
            </Typography>
            <Typography className="body">
              {selectedUserdetail?.moneyWithdrawSessionTime}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "CurrentLocationLatitude",
            selectedUserdetail?.currentLocationLatitude,
            "12",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">
              CurrentLocationLatitude :-
            </Typography>
            <Typography className="body">
              {selectedUserdetail?.currentLocationLatitude}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "CurrentLocationLongitude",
            selectedUserdetail?.currentLocationLongitude,
            "13",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">
              CurrentLocationLongitude :-
            </Typography>
            <Typography className="body">
              {selectedUserdetail?.currentLocationLongitude}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Platform", selectedUserdetail?.platform, "14");
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Platform :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.platform}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("FCM token", selectedUserdetail?.fcmToken, "15");
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">FCM token :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.fcmToken}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "IsProfileCompleted",
            selectedUserdetail?.isProfileCompleted,
            "16",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">IsProfileCompleted :-</Typography>
            <Typography className="body">
              {IsProfileCompleted(selectedUserdetail?.isProfileCompleted)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Update At", selectedUserdetail?.updateAt, "17");
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">UpdateAt :-</Typography>
            <Typography className="body">
              {changeDateFormate(selectedUserdetail?.updateAt)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "AccountStartingDateFormat",
            selectedUserdetail?.accountStartingDateInFormat,
            "18",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">
              AccountStartingDateInFormat :-
            </Typography>
            <Typography className="body">
              {changeDateFormate(
                selectedUserdetail?.accountStartingDateInFormat,
              )}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "LastTestAttemptDateInFormat",
            selectedUserdetail?.lastTestAttemptDateInFormat,
            "19",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">
              LastTestAttemptDateInFormat :-
            </Typography>
            <Typography className="body">
              {changeDateFormate(
                selectedUserdetail?.lastTestAttemptDateInFormat,
              )}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "DataAvailableInSleepMode",
            selectedUserdetail?.dataAvailbleInSleepMode,
            "20",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">
              DataAvailbleInSleepMode :-
            </Typography>
            <Typography className="body">
              {selectedUserdetail?.dataAvailbleInSleepMode}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "FeedbackStatus",
            selectedUserdetail?.feedbackStatus,
            "21",
          );
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">FeedbackStatus :-</Typography>
            <Typography className="body">
              {selectedUserdetail?.feedbackStatus}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <UserEditTextDialog
        text={text}
        opendialog={openedittextDialog}
        handleClickClose={handleClickClose}
        valueId={valueId}
        type="Users"
      />
      <EditDate
        dates={dates}
        opendialog={openeditdateDialog}
        handleClickClose={handleClickCloseDate}
        type="Users"
        valueId={valueId}
      />
    </Card>
  );
};

export default UserDetails;
