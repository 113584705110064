import React, { useState } from "react";
import { Card, CardContent, TextField, Button } from "@mui/material";
import SelectOptions from "./SelectOptions";
import { useAuth } from "../../Providers/UserProvider";
import { useLocation } from "react-router-dom";
import CustomDialogBox from "../CustomDialogBox";
import { AddAndEditStoredNotiDialogBodyText } from "../../MiddleWares/DialogBodyAndConfirmationTxt";

export const categoryOptions = [
  { value: 0, label: "Morning" },
  { value: 1, label: "Brunch" },
  { value: 2, label: "Afternoon" },
  { value: 3, label: "PaidUser" },
  { value: 4, label: "EveningUnpaid" },
  { value: 5, label: "Night" },
];

export const statusOptions = [
  { value: 0, label: "Not Active" },
  { value: 1, label: "Active" },
];

const AddStoredNotiCard = () => {
  const {
    storednotititle,
    setstorednotititle,
    storednotidesc,
    setstorednotidesc,
    storedcategoryvalue,
    setstoredcategoryvalue,
    storedstatusvalue,
    setstoredstatusvalue,
    NotificationStoredEditAndAdd,
  } = useAuth();

  const location = useLocation();

  const handleChangeCategoryValue = (e) => {
    setstoredcategoryvalue(e);
  };

  const handleChangeStatusValue = (e) => {
    setstoredstatusvalue(e);
  };

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const handleSubmitOfDialog = () => {
    if (location?.state?.type === "Edit") {
      NotificationStoredEditAndAdd(
        3,
        location.state.notiuniqueId,
        storednotititle,
        storednotidesc,
        storedcategoryvalue?.label,
        storedstatusvalue?.value,
        "Edit",
      );
    } else {
      NotificationStoredEditAndAdd(
        4,
        "",
        storednotititle,
        storednotidesc,
        storedcategoryvalue?.label,
        storedstatusvalue?.value,
        "Add",
      );
    }
  };

  return (
    <Card className="card">
      <CardContent>
        <div className="main_con">
          <textarea
            rows="2"
            className="grammar_text_area"
            placeholder="Enter title here"
            value={storednotititle}
            onChange={(e) => {
              setstorednotititle(e.target.value);
            }}
          />
        </div>
        <div className="main_con" style={{ marginTop: "2vh" }}>
          <textarea
            rows="4"
            className="grammar_text_area"
            placeholder="Enter description here"
            value={storednotidesc}
            onChange={(e) => {
              setstorednotidesc(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
          }}
        >
          <SelectOptions
            options={categoryOptions}
            type="category"
            value={storedcategoryvalue}
            handleFunction={handleChangeCategoryValue}
          />
        </div>
        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
          }}
        >
          <SelectOptions
            options={statusOptions}
            type="status"
            value={storedstatusvalue}
            handleFunction={handleChangeStatusValue}
          />
        </div>
        <div style={{ textAlign: "center", transform: "unset" }}>
          <Button
            variant="outlined"
            className="grammar_buttons"
            style={{ zIndex: "0" }}
            onClick={(e) => {
              e.preventDefault();
              handleClickOpen();
            }}
          >
            {location?.state?.txt ? location?.state?.txt : `Add Notification`}
          </Button>
        </div>
        <CustomDialogBox
          opendialog={opendialog}
          handleClickClose={handleClickClose}
          bodyText={
            <AddAndEditStoredNotiDialogBodyText
              notititle={storednotititle}
              notidesc={storednotidesc}
              noticategory={storedcategoryvalue}
              notistatus={storedstatusvalue}
            />
          }
          confirmationText="Are You Sure Want To Edit These Changes"
          handleSubmitOfDialog={handleSubmitOfDialog}
        />
      </CardContent>
    </Card>
  );
};

export default AddStoredNotiCard;
