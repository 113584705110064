import React, { useEffect } from "react";
import { useAuth } from "../../Providers/UserProvider";

import Card from "@material-ui/core/Card";
import { Button, Box } from "@mui/material";
import TableComp from "../TableComp";
import { transcationlistcompheadings } from "../../Services/Constants";

const TranscationList = () => {
  const { getTranscationList, transcationData } = useAuth();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const Data = JSON.parse(userData);
    getTranscationList(Data);
  }, []);

  return (
    // <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
    //   <Box>
    //     <Table className={classes.root}>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell
    //             align="center"
    //             style={{ fontWeight: "bold", fontSize: "15px" }}
    //           >
    //             Payment Id
    //           </TableCell>
    //           <TableCell
    //             align="center"
    //             style={{ fontWeight: "bold", fontSize: "15px" }}
    //           >
    //             Amount
    //           </TableCell>
    //           <TableCell
    //             align="center"
    //             style={{ fontWeight: "bold", fontSize: "15px" }}
    //           >
    //             Email
    //           </TableCell>
    //           <TableCell
    //             align="center"
    //             style={{ fontWeight: "bold", fontSize: "15px" }}
    //           >
    //             Created At
    //           </TableCell>
    //           <TableCell
    //             align="center"
    //             style={{ fontWeight: "bold", fontSize: "15px" }}
    //           >
    //             Status
    //           </TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {transcationData?.map((row) => {
    //           return (
    //             <TableRow key={row.id}>
    //               <TableCell align="center">{row.paymentID}</TableCell>
    //               <TableCell align="center">{row.paymentAmount}</TableCell>
    //               <TableCell align="center">
    //                 {row.customerInformation}
    //               </TableCell>
    //               <TableCell align="center">
    //                 {changeDateFormateInTwelveHours(row.created_at)}
    //               </TableCell>
    //               <TableCell align="center">
    //                 <Button
    //                   variant="contained"
    //                   className={
    //                     row.status === 2 || row.status === 1
    //                       ? `trans settled`
    //                       : `trans failed`
    //                   }
    //                 >
    //                   {getTranscationStatus(row.status)}
    //                 </Button>
    //               </TableCell>
    //             </TableRow>
    //           );
    //         })}
    //       </TableBody>
    //     </Table>
    //   </Box>
    // </Card>
    <TableComp headings={transcationlistcompheadings} data={transcationData} />
  );
};

export default TranscationList;
