import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SelectOptions from "../NotificationsComp/SelectOptions";
import { useAuth } from "../../Providers/UserProvider";
import CustomDialogBox from "../CustomDialogBox";
import "../../CSSFiles/notifications.css";
import CalendarWithClockComp from "../../MiddleWares/CalendarWithClockComp";
import { compareDates } from "../../Pages/PaymentBehaviourDetailPage/PaymentBehaviourDetails";
import { toast } from "react-toastify";
import ScheduleClassesDialogBodyTxt from "../../MiddleWares/ScheduleClassesDialogBodyTxt";

export const formattedDateStr = (startingTime) => {
  const originalDate = new Date(startingTime);

  // Format the date to the desired format "year-month-date hour:minute AM/PM"
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, "0");
  const date = String(originalDate.getDate()).padStart(2, "0");
  const hours = String(originalDate.getHours() % 12 || 12).padStart(2, "0"); // Convert to 12-hour format
  const minutes = String(originalDate.getMinutes()).padStart(2, "0");
  const amPm = originalDate.getHours() >= 12 ? "PM" : "AM";

  const formattedDateStr = `${year}-${month}-${date} ${hours}:${minutes} ${amPm}`;

  return formattedDateStr;
};

const ScheduleIeltsClasses = ({ classGrpId }) => {
  const { selectclassesCategory, scheduleClassesData } = useAuth();
  const [classFileUrl, setclassFileUrl] = useState("");
  const [classTitle, setclassTitle] = useState("");
  const [storedcategoryvalue, setstoredcategoryvalue] = useState("");
  const [classNumber, setclassNumber] = useState("");
  const [startingTime, setstartingTime] = useState(new Date());
  const [endingTime, setendingTime] = useState(new Date());

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const handleChangeCategoryValue = (e) => {
    setstoredcategoryvalue(e);
  };

  const handleSubmitOfDialog = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    scheduleClassesData(
      userData,
      classFileUrl,
      classTitle,
      storedcategoryvalue.label,
      formattedDateStr(startingTime),
      formattedDateStr(endingTime),
      classNumber,
      classGrpId
    );
  };

  return (
    <Card className="card">
      <CardContent style={{ position: "relative" }}>
        <div className="main_con">
          <textarea
            rows="1"
            className="grammar_text_area"
            placeholder="Enter Class File Url here"
            value={classFileUrl}
            onChange={(e) => {
              setclassFileUrl(e.target.value);
            }}
          />
        </div>
        <div className="main_con" style={{ marginTop: "2vh" }}>
          <textarea
            rows="1"
            className="grammar_text_area"
            placeholder="Enter Class Title here"
            value={classTitle}
            onChange={(e) => {
              setclassTitle(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
            position: "relative",
            zIndex: "2",
          }}
        >
          <Typography className="teachertitle">Class Category</Typography>

          <SelectOptions
            options={selectclassesCategory}
            type="ieltsclasses"
            value={storedcategoryvalue}
            handleFunction={handleChangeCategoryValue}
          />
        </div>
        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
            position: "relative",
            zIndex: "1",
          }}
        >
          <Typography className="teachertitle">Class Number</Typography>

          <TextField
            type="number"
            value={classNumber}
            onChange={(e) => {
              setclassNumber(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
            display: "flex",
            flexWrap: "wrap",
            gap: "15px",
            position: "relative",
            zIndex: "1",
          }}
        >
          <div>
            <Typography className="teachertitle">Start Time</Typography>

            <div>
              <CalendarWithClockComp
                value={startingTime}
                onChange={setstartingTime}
              />
            </div>
          </div>
          <div>
            <Typography className="teachertitle">End Time</Typography>

            <div>
              <CalendarWithClockComp
                value={endingTime}
                onChange={setendingTime}
              />
            </div>
          </div>
        </div>

        <div style={{ textAlign: "center", transform: "unset" }}>
          <Button
            variant="outlined"
            className="grammar_buttons"
            style={{ zIndex: "0" }}
            onClick={(e) => {
              e.preventDefault();
              if (!compareDates(startingTime, endingTime)) {
                toast.error(
                  "End Time Should Always be greater than Start Time",
                );
                return;
              }
              if (!classNumber) {
                toast.error("Class Number is Mandatory");
                return;
              }
              handleClickOpen();
            }}
          >
            Schedule Class
          </Button>
        </div>
        <CustomDialogBox
          opendialog={opendialog}
          handleClickClose={handleClickClose}
          bodyText={
            <ScheduleClassesDialogBodyTxt
              classFileUrl={classFileUrl}
              classCategory={storedcategoryvalue.label}
              classTitle={classTitle}
              startingTime={formattedDateStr(startingTime)}
              endingTime={formattedDateStr(endingTime)}
              classNumber={classNumber}
            />
          }
          confirmationText="Are You Sure Want To Edit These Changes"
          handleSubmitOfDialog={handleSubmitOfDialog}
        />
      </CardContent>
    </Card>
  );
};

export default ScheduleIeltsClasses;
