import React, { useEffect, useState } from "react";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import YouTubePlayer from "../../ListeningTestComp/ListeningTestDetails/VideoPlayer";
import IeltsClassDetailFeature from "./IeltsClassDetailFeature";
import { useAuth } from "../../../Providers/UserProvider";
import { Tab, Tabs } from "@mui/material";
import { Button, makeStyles } from "@material-ui/core";
import { TabContext, TabPanel } from "@mui/lab";
import Ratings from "./Ratings";
import Comments from "./Comments";
import CustomDialogBox from "../../CustomDialogBox";

var styles = {
  root: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles((theme) => ({
  customButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px !important",
      padding: "0px 2px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "10px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "12px !important",
      minWidth: "0px !important",
      padding: "0px 6px !important",
    },
  },
  newcustomButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "7px !important",
      padding: "0px 2px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "10px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "12px !important",
      minWidth: "0px !important",
      padding: "0px 6px !important",
    },
  },
}));

const IeltsClassDetailCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    classDetailedData,
    getDetailsOfAClass,
    classRatingsData,
    classCommentsData,
    handleEditClassData,
  } = useAuth();
  const [categoryvalue, setcategoryValue] = useState("0");
  const [isLiveClass, setisLiveClass] = useState(true);
  const [value, setvalue] = useState("");

  const handleCategoryChange = (event, newValue) => {
    setcategoryValue(newValue);
  };

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const [opendialogForStartClass, setopendialogForStartClass] = useState(false);

  const handleClickOpenForStartClass = () => {
    setopendialogForStartClass(true);
  };

  const handleClickCloseForStartClass = () => {
    setisLiveClass((isLiveClass) => !isLiveClass);

    setopendialogForStartClass(false);
  };

  const handleSubmitOfDialog = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    handleEditClassData(
      userData,
      location.state.data.classUniqueId,
      2,
      value,
      location.state.data.classFile,
      "ScheduleClass",
    );
  };

  const handleSubmitOfStartClass = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    handleEditClassData(
      userData,
      location.state.data.classUniqueId,
      1,
      value,
      location.state.data.classFile,
      "ScheduleClass",
    );
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    getDetailsOfAClass(
      userData,
      location.state.data.classFile,
      location.state.data.classUniqueId,
    );
  }, []);

  useEffect(() => {
    setisLiveClass(classDetailedData?.teacherIsLiveNow);
  }, [classDetailedData]);

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div style={{ padding: "10px" }}>
        <FaRegArrowAltCircleLeft
          onClick={(e) => {
            e.preventDefault();
            handleNavigateBack();
          }}
          style={{ cursor: "pointer" }}
          size={35}
        />
      </div>
      <CustomDialogBox
        opendialog={opendialog}
        handleClickClose={handleClickClose}
        confirmationText="Are You Sure want to Cancel the Class??"
        handleSubmitOfDialog={handleSubmitOfDialog}
      />
      <CustomDialogBox
        opendialog={opendialogForStartClass}
        handleClickClose={handleClickCloseForStartClass}
        confirmationText={
          value
            ? "After this students will be enable to join this class"
            : "After this students will not be enable to join this class"
        }
        handleSubmitOfDialog={handleSubmitOfStartClass}
      />
      <IeltsClassDetailFeature
        data={classDetailedData}
        type={location.state.type}
      />
      {location.state.type === "Schedule" && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            gap: "20px",
            flexWrap: "wrap",
            marginTop: "25px",
          }}
        >
          <label class="toggle">
            <input
              class="toggle-checkbox"
              checked={isLiveClass}
              type="checkbox"
              onChange={(e) => {
                setvalue(e.target.checked);
                handleClickOpenForStartClass();
              }}
            />
            <div class="toggle-switch"></div>
            <span class="toggle-label">Start Class</span>
          </label>
          <Button
            variant="contained"
            className="teacherButtons"
            style={{ zIndex: "0" }}
            onClick={(e) => {
              e.preventDefault();
              handleClickOpen();
            }}
          >
            Cancel Class
          </Button>
        </div>
      )}

      <div
        style={{ display: "flex", justifyContent: "center", margin: "10vh" }}
      >
        <YouTubePlayer videoId={classDetailedData.classFile} />
      </div>

      <TabContext value={categoryvalue}>
        <Tabs
          value={categoryvalue}
          onChange={handleCategoryChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          centered
          style={styles.root}
        >
          <Tab
            value="0"
            label="Ratings"
            classes={{ root: classes.customButtonBaseRoot }}
          />

          <Tab
            value="1"
            label="Comments"
            classes={{ root: classes.customButtonBaseRoot }}
          />
        </Tabs>
        <TabPanel value="0">
          {classRatingsData?.map((data) => (
            <Ratings data={data} />
          ))}
        </TabPanel>
        <TabPanel value="1">
          {classCommentsData?.map((data) => (
            <Comments data={data} />
          ))}
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default IeltsClassDetailCard;
