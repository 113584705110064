import { Typography } from "@mui/material";
import React from "react";

const Comments = ({ data }) => {
  return (
    <div className="course-details-container">
      <div className="detail-row">
        <Typography className="detail-title">Unique Id:</Typography>
        <Typography className="detail-body">{data.uid}</Typography>
      </div>

      <div className="detail-row">
        <Typography className="detail-title">Comment:</Typography>
        <Typography className="detail-body">{data?.comment}</Typography>
      </div>
    </div>
  );
};

export default Comments;
