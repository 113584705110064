import React from "react";
import { Grid } from "@mui/material";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Providers/UserProvider";
import AllUsersFeaturesUsage from "../../../Components/Charts/AllUsersFeaturesUsage";
import FeaturesUsage from "../../../Components/Charts/FeaturesUsage";
import UnpaidFeaturesUsage from "../../../Components/Charts/UnpaidFeaturesUsage";

const IosCompMob = () => {
  const {
    iosallfeaturesusage,
    iospaidfeaturesusage,
    iosunpaidfeaturesusage,
    iosappleaveuserfeaturesusage,
  } = useAuth();
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  };
  return (
    <div style={{ overflowX: "hidden", position: "relative" }}>
      <div style={{ padding: "5px" }}>
        <FaRegArrowAltCircleLeft
          onClick={(e) => {
            e.preventDefault();
            handleNavigateBack();
          }}
          style={{ cursor: "pointer" }}
          size={35}
        />
      </div>
      <div>
        <Grid container style={{ textAlign: "-webkit-center" }} spacing={1}>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2 className="graph_headings">Ios All</h2>
            <AllUsersFeaturesUsage retentiondata={iosallfeaturesusage} />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2 className="graph_headings">Ios Paid</h2>

            <FeaturesUsage retentiondata={iospaidfeaturesusage} />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2 className="graph_headings">Ios Unpaid</h2>

            <UnpaidFeaturesUsage retentiondata={iosunpaidfeaturesusage} />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2 className="graph_headings">Ios App Leave Users</h2>

            <UnpaidFeaturesUsage retentiondata={iosappleaveuserfeaturesusage} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default IosCompMob;
