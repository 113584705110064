import React from "react";
import YouTube from "react-youtube";

const YouTubePlayer = ({ videoId }) => {
  // Options for the YouTube player
  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  // Media query for mobile devices
  const isMobileDevice = window.matchMedia("(max-width: 640px)").matches;

  if (isMobileDevice) {
    opts.height = "180"; // Height for mobile devices
    opts.width = "320"; // Width for mobile devices
  } else {
    opts.height = "480"; // Default height for larger screens
    opts.width = "720"; // Default width for larger screens
  }

  return <YouTube videoId={videoId} opts={opts} />;
};

export default YouTubePlayer;
