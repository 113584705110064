import { Button, Card, CardContent } from "@mui/material";
import React, { useState } from "react";
import { useRef, useEffect } from "react";
import SelectOptions from "../NotificationsComp/SelectOptions";
import DragAndDropComp from "../UploadingVideoFormComp/DragAndDropComp/DragAndDropComp";
import { toast } from "react-toastify";
import CustomDialogBox from "../CustomDialogBox";
import AddAndEditSpeakingDialogBodyTxt from "../../MiddleWares/AddAndEditSpeakingDialogBodyTxt";
import { useAuth } from "../../Providers/UserProvider";

export const categoryOptions = [
  { value: 0, label: "Introduction Questions" },
  { value: 1, label: "General Questions" },
  { value: 2, label: "QueCard Questions" },
];

export const QuestionField = ({ placeholder, state, setstate }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [state]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset the height to auto before calculating the actual height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleTextareaChange = (e) => {
    setstate(e.target.value);
  };

  return (
    <div className="main_con" style={{ marginTop: "2vh" }}>
      <textarea
        ref={textareaRef}
        rows="2"
        cols="2"
        className="grammar_text_area"
        placeholder={placeholder}
        value={state}
        onChange={handleTextareaChange}
      />
    </div>
  );
};

const CreateSpeakingQues = () => {
  const {
    addIntroSpeakingTest,
    addGeneralSpeakingTest,
    addQueCardSpeakingTest,
  } = useAuth();

  const [storedcategoryvalue, setstoredcategoryvalue] = useState("");

  //  Intro States
  const [introques, setintroques] = useState("");

  const [introvideo, setintrovideo] = useState({
    preview: "",
    raw: "",
  });

  const [introans, setintroans] = useState("");

  // General States
  const [generalques1, setgeneralques1] = useState("");
  const [generalques2, setgeneralques2] = useState("");
  const [generalques3, setgeneralques3] = useState("");
  const [generalques4, setgeneralques4] = useState("");

  const [generalans1, setgeneralans1] = useState("");
  const [generalans2, setgeneralans2] = useState("");
  const [generalans3, setgeneralans3] = useState("");
  const [generalans4, setgeneralans4] = useState("");

  const [generalvideo1, setgeneralvideo1] = useState({
    preview: "",
    raw: "",
  });
  const [generalvideo2, setgeneralvideo2] = useState({
    preview: "",
    raw: "",
  });
  const [generalvideo3, setgeneralvideo3] = useState({
    preview: "",
    raw: "",
  });
  const [generalvideo4, setgeneralvideo4] = useState({
    preview: "",
    raw: "",
  });

  // QueCard And Follow Up States

  const [queCardques, setqueCardques] = useState("");

  const [queCardvideo, setqueCardvideo] = useState({
    preview: "",
    raw: "",
  });

  const [queCardans, setqueCardans] = useState("");

  const [followupques1, setfollowupques1] = useState("");
  const [followupques2, setfollowupques2] = useState("");
  const [followupques3, setfollowupques3] = useState("");
  const [followupques4, setfollowupques4] = useState("");

  const [followupans1, setfollowupans1] = useState("");
  const [followupans2, setfollowupans2] = useState("");
  const [followupans3, setfollowupans3] = useState("");
  const [followupans4, setfollowupans4] = useState("");

  const [followupvideo1, setfollowupvideo1] = useState({
    preview: "",
    raw: "",
  });
  const [followupvideo2, setfollowupvideo2] = useState({
    preview: "",
    raw: "",
  });
  const [followupvideo3, setfollowupvideo3] = useState({
    preview: "",
    raw: "",
  });
  const [followupvideo4, setfollowupvideo4] = useState({
    preview: "",
    raw: "",
  });

  const handleChangeCategoryValue = (e) => {
    setstoredcategoryvalue(e);
  };

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    if (!storedcategoryvalue) return toast.error("Select Question Type");
    if (storedcategoryvalue.value === 0) {
      if (!introques || !introans || !introvideo.raw)
        return toast.error("Please fill all fields first");
    }
    if (storedcategoryvalue.value === 1) {
      if (
        !generalques1 ||
        !generalans1 ||
        !generalques2 ||
        !generalans2 ||
        !generalques3 ||
        !generalans3 ||
        !generalques4 ||
        !generalans4 ||
        !generalvideo1.raw ||
        !generalvideo2.raw ||
        !generalvideo3.raw ||
        !generalvideo4.raw
      )
        return toast.error("Please fill all fields first");
    }
    if (storedcategoryvalue.value === 2) {
      if (
        !queCardques ||
        !queCardans ||
        !queCardvideo.raw ||
        !followupques1 ||
        !followupans1 ||
        !followupques2 ||
        !followupans2 ||
        !followupques3 ||
        !followupans3 ||
        !followupques4 ||
        !followupans4 ||
        !followupvideo1.raw ||
        !followupvideo2.raw ||
        !followupvideo3.raw ||
        !followupvideo4.raw
      )
        return toast.error("Please fill all fields first");
    }

    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const handleSubmitOfDialog = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    if (storedcategoryvalue.value === 0) {
      addIntroSpeakingTest(
        Data,
        "introductionQuestions",
        introques,
        introans,
        introvideo.raw,
      );
    }
    if (storedcategoryvalue.value === 1) {
      addGeneralSpeakingTest(
        Data,
        "genralQuestions",
        generalques1,
        generalans1,
        generalvideo1.raw,
        generalques2,
        generalans2,
        generalvideo2.raw,
        generalques3,
        generalans3,
        generalvideo3.raw,
        generalques4,
        generalans4,
        generalvideo4.raw,
      );
    }
    if (storedcategoryvalue.value === 2) {
      addQueCardSpeakingTest(
        Data,
        "queCardQuestions",
        queCardques,
        queCardans,
        queCardvideo.raw,
        followupques1,
        followupans1,
        followupvideo1.raw,
        followupques2,
        followupans2,
        followupvideo2.raw,
        followupques3,
        followupans3,
        followupvideo3.raw,
        followupques4,
        followupans4,
        followupvideo4.raw,
      );
    }
  };

  return (
    <Card className="card">
      <CardContent>
        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
          }}
        >
          <SelectOptions
            options={categoryOptions}
            type="speakingQuesList"
            value={storedcategoryvalue}
            handleFunction={handleChangeCategoryValue}
          />
        </div>
        {storedcategoryvalue?.value === 0 && (
          <>
            <QuestionField
              placeholder="Enter Your Question"
              state={introques}
              setstate={setintroques}
            />
            <QuestionField
              placeholder="Enter your Answer here"
              state={introans}
              setstate={setintroans}
            />
            <div style={{ marginTop: "10px" }}>
              <DragAndDropComp
                thumbNailUpload={introvideo}
                setthumbNailUpload={setintrovideo}
                type="Video"
              />
            </div>
          </>
        )}
        {storedcategoryvalue?.value === 1 && (
          <>
            <QuestionField
              placeholder={`Enter Your Question 1`}
              state={generalques1}
              setstate={setgeneralques1}
            />
            <QuestionField
              placeholder={`Enter Your Answer 1`}
              state={generalans1}
              setstate={setgeneralans1}
            />
            <div style={{ marginTop: "10px" }}>
              <DragAndDropComp
                thumbNailUpload={generalvideo1}
                setthumbNailUpload={setgeneralvideo1}
                type="Video"
              />
            </div>
            <QuestionField
              placeholder={`Enter Your Question 2`}
              state={generalques2}
              setstate={setgeneralques2}
            />
            <QuestionField
              placeholder={`Enter Your Answer 2`}
              state={generalans2}
              setstate={setgeneralans2}
            />
            <div style={{ marginTop: "10px" }}>
              <DragAndDropComp
                thumbNailUpload={generalvideo2}
                setthumbNailUpload={setgeneralvideo2}
                type="Video"
              />
            </div>
            <QuestionField
              placeholder={`Enter Your Question 3`}
              state={generalques3}
              setstate={setgeneralques3}
            />
            <QuestionField
              placeholder={`Enter Your Answer 3`}
              state={generalans3}
              setstate={setgeneralans3}
            />
            <div style={{ marginTop: "10px" }}>
              <DragAndDropComp
                thumbNailUpload={generalvideo3}
                setthumbNailUpload={setgeneralvideo3}
                type="Video"
              />
            </div>
            <QuestionField
              placeholder={`Enter Your Question 4`}
              state={generalques4}
              setstate={setgeneralques4}
            />
            <QuestionField
              placeholder={`Enter Your Answer 4`}
              state={generalans4}
              setstate={setgeneralans4}
            />
            <div style={{ marginTop: "10px" }}>
              <DragAndDropComp
                thumbNailUpload={generalvideo4}
                setthumbNailUpload={setgeneralvideo4}
                type="Video"
              />
            </div>
          </>
        )}
        {storedcategoryvalue?.value === 2 && (
          <>
            <QuestionField
              placeholder="Enter Your QueCard Question"
              state={queCardques}
              setstate={setqueCardques}
            />
            <QuestionField
              placeholder="Enter your QueCard Answer here"
              state={queCardans}
              setstate={setqueCardans}
            />
            <div style={{ marginTop: "10px" }}>
              <DragAndDropComp
                thumbNailUpload={queCardvideo}
                setthumbNailUpload={setqueCardvideo}
                type="Video"
              />
            </div>
            <QuestionField
              placeholder={`Enter Your FollowUp Question 1`}
              state={followupques1}
              setstate={setfollowupques1}
            />
            <QuestionField
              placeholder={`Enter Your FollowUp Answer 1`}
              state={followupans1}
              setstate={setfollowupans1}
            />
            <div style={{ marginTop: "10px" }}>
              <DragAndDropComp
                thumbNailUpload={followupvideo1}
                setthumbNailUpload={setfollowupvideo1}
                type="Video"
              />
            </div>
            <QuestionField
              placeholder={`Enter Your FollowUp Question 2`}
              state={followupques2}
              setstate={setfollowupques2}
            />
            <QuestionField
              placeholder={`Enter Your FollowUp Answer 2`}
              state={followupans2}
              setstate={setfollowupans2}
            />
            <div style={{ marginTop: "10px" }}>
              <DragAndDropComp
                thumbNailUpload={followupvideo2}
                setthumbNailUpload={setfollowupvideo2}
                type="Video"
              />
            </div>
            <QuestionField
              placeholder={`Enter Your FollowUp Question 3`}
              state={followupques3}
              setstate={setfollowupques3}
            />
            <QuestionField
              placeholder={`Enter Your FollowUp Answer 3`}
              state={followupans3}
              setstate={setfollowupans3}
            />
            <div style={{ marginTop: "10px" }}>
              <DragAndDropComp
                thumbNailUpload={followupvideo3}
                setthumbNailUpload={setfollowupvideo3}
                type="Video"
              />
            </div>
            <QuestionField
              placeholder={`Enter Your FollowUp Question 4`}
              state={followupques4}
              setstate={setfollowupques4}
            />
            <QuestionField
              placeholder={`Enter Your FollowUp Answer 4`}
              state={followupans4}
              setstate={setfollowupans4}
            />
            <div style={{ marginTop: "10px" }}>
              <DragAndDropComp
                thumbNailUpload={followupvideo4}
                setthumbNailUpload={setfollowupvideo4}
                type="Video"
              />
            </div>
          </>
        )}
        <div
          style={{ textAlign: "center", marginTop: "10px", transform: "unset" }}
        >
          <Button
            variant="outlined"
            className="grammar_buttons"
            style={{ zIndex: "0" }}
            onClick={(e) => {
              e.preventDefault();
              handleClickOpen();
            }}
          >
            Create
          </Button>
        </div>

        <CustomDialogBox
          opendialog={opendialog}
          handleClickClose={handleClickClose}
          bodyText={
            <AddAndEditSpeakingDialogBodyTxt
              type={storedcategoryvalue}
              introques={introques}
              introans={introans}
              introvideo={introvideo.raw.name}
              generalques1={generalques1}
              generalans1={generalans1}
              generalvideo1={generalvideo1.raw.name}
              generalques2={generalques2}
              generalans2={generalans2}
              generalvideo2={generalvideo2.raw.name}
              generalques3={generalques3}
              generalans3={generalans3}
              generalvideo3={generalvideo3.raw.name}
              generalques4={generalques4}
              generalans4={generalans4}
              generalvideo4={generalvideo4.raw.name}
              queCardques={queCardques}
              queCardans={queCardans}
              queCardvideo={queCardvideo.raw.name}
              followupques1={followupques1}
              followupans1={followupans1}
              followupvideo1={followupvideo1.raw.name}
              followupques2={followupques2}
              followupans2={followupans2}
              followupvideo2={followupvideo2.raw.name}
              followupques3={followupques3}
              followupans3={followupans3}
              followupvideo3={followupvideo3.raw.name}
              followupques4={followupques4}
              followupans4={followupans4}
              followupvideo4={followupvideo4.raw.name}
            />
          }
          confirmationText="Are You Sure Want To Add These Changes"
          handleSubmitOfDialog={handleSubmitOfDialog}
        />
      </CardContent>
    </Card>
  );
};

export default CreateSpeakingQues;
