import React, { useEffect, useState } from "react";
import SidebarComp from "../../Components/Sidebar";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";
import { Fab, Typography } from "@mui/material";
import ErrorLogComp from "../../Components/ErrorComp/ErrorLogComp";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import CustomDialogBox from "../../Components/CustomDialogBox";

const ErrorLog = () => {
  const {
    geterrorLogList,
    updateerrorLogList,
    timetorefresh,
    closeSidebar,
    clearErrorlogs,
  } = useAuth();

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = (id) => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };
  useEffect(() => {
    StartFeedBackPage();
  }, []);

  const StartFeedBackPage = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    const errorLogList = sessionStorage.getItem("errorLogList");
    if (errorLogList) {
      updateerrorLogList();
    }
    geterrorLogList(Data);
  };
  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "5",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        {timetorefresh === true && (
          <Typography
            style={{
              margin: 0,
              top: 15,
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
              zIndex: "1",
            }}
          >
            Refreshing...
          </Typography>
        )}
        <ErrorLogComp />
        <Fab
          variant="extended"
          style={{
            margin: 0,
            right: 50,
            bottom: 80,
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#051e34",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClickOpen();
          }}
        >
          Clear Logs
        </Fab>

        <CustomDialogBox
          opendialog={opendialog}
          handleClickClose={handleClickClose}
          confirmationText="Are You Sure Want To Clear The Logs"
          handleSubmitOfDialog={() => {
            const userData = localStorage.getItem("userData");
            const Data = JSON.parse(userData);
            clearErrorlogs(Data);
          }}
        />
      </div>
    </>
  );
};

export default ErrorLog;
