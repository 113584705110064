import React from "react";
import { Grid } from "@mui/material";
import FeaturesUsage from "../Charts/FeaturesUsage";
import UnpaidFeaturesUsage from "../Charts/UnpaidFeaturesUsage";
import AllUsersFeaturesUsage from "../Charts/AllUsersFeaturesUsage";
import { useAuth } from "../../Providers/UserProvider";

const SeeMoreGraphComp = () => {
  const {
    paidunpaidallfeaturesusage,
    paidunpaidpaidfeaturesusage,
    paidunpaidunpaidfeaturesusage,
    allappleaveuserfeaturesusage,
  } = useAuth();
  return (
    <Grid container style={{ textAlign: "-webkit-center" }} spacing={1}>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>All Users/All</h2>

        <AllUsersFeaturesUsage retentiondata={paidunpaidallfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>All Users/Paid</h2>

        <FeaturesUsage retentiondata={paidunpaidpaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>All Users/Unpaid</h2>
        <UnpaidFeaturesUsage retentiondata={paidunpaidunpaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>All Users/App Leave User</h2>
        <UnpaidFeaturesUsage retentiondata={allappleaveuserfeaturesusage} />
      </Grid>
    </Grid>
  );
};

export default SeeMoreGraphComp;
