import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "../../CSSFiles/crashcourse.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/UserProvider";
import { FaArrowCircleRight } from "react-icons/fa";

const ListeningTestCard = ({ data, type, subtype, task1type }) => {
  const changeDateFormate = (strDateTime) => {
    const date = new Date(strDateTime);
    var myDate = date.toLocaleString();
    return myDate;
  };

  const navigate = useNavigate();
  const { setsinglewritingListData } = useAuth();

  return (
    <Card
      className="cardsub"
      style={{ marginBottom: "2vh" }}
      onClick={(e) => {
        e.preventDefault();
        if (type === "Listening") {
          navigate(`/listeningdata/${data.uniqueTestNumber}`, {
            state: {
              data: data,
            },
          });
        } else if (type === "Reading") {
          navigate(`/readingdata/${data.uniqueTestNumber}`, {
            state: {
              data: data,
            },
          });
        } else if (type === "Writing") {
          if (subtype === "task1") {
            e.preventDefault();
            sessionStorage.setItem("SelectedCategoryData", JSON.stringify(
              data.CategoryName
            ))


            navigate(`/writingdata/${data.CategoryName}/questions`, {
              state: {
                category: data.CategoryName,
                type: "task1",
                task1type: task1type
              },
            });
          } else {
            sessionStorage.setItem("singlewritingdata", JSON.stringify(data))
            setsinglewritingListData(data);
            navigate(`/writingdata/${data.id}`, {
              state: {
                data: data,
              },
            });
          }
        }
      }}
    >
      <CardContent style={{ position: "relative" }}>
        {/* <div className="coursetitle">
          <Typography className="title">TestFile :-</Typography>
          <Typography className="body">{data.testFile}</Typography>
        </div> */}
        <div className="coursegrid">
          <div className="courseimg">
            <img
              src={data.thumbnail}
              alt={data.uniqueTestNumber}
              className="ThumbnailImg"
            />
          </div>
          <div className="coursedetails">
            {/* <div className="coursetitle">
                            <Typography className="title">Test File :-</Typography>
                            <Typography className="body">{data.testFile}</Typography>
                        </div> */}
            {data.uniqueTestNumber && (
              <div className="coursetitle" style={{ marginTop: "1vh" }}>
                <Typography className="title">Unique Test Number :-</Typography>
                <Typography className="body">
                  {data.uniqueTestNumber}
                </Typography>
              </div>
            )}

            {data.testNumber && (
              <div className="coursetitle" style={{ marginTop: "1vh" }}>
                <Typography className="title">Test Number :-</Typography>
                <Typography className="body">{data.testNumber}</Typography>
              </div>
            )}
            {data.CategoryName && (
              <div className="coursetitle" style={{ marginTop: "1vh" }}>
                <Typography className="title">Category Name :-</Typography>
                <Typography className="body">{data.CategoryName}</Typography>
              </div>
            )}

            <div className="coursetitle" style={{ marginTop: "1vh" }}>
              <Typography className="title">Created At :-</Typography>
              <Typography className="body">
                {changeDateFormate(data.created_at)}
              </Typography>
            </div>
            <div className="coursetitle" style={{ marginTop: "1vh" }}>
              <Typography className="title">Updated At :-</Typography>
              <Typography className="body">
                {changeDateFormate(data.updated_at)}
              </Typography>
            </div>
            {type == "Writing" && (
              <div className="duplicate">
                <div>
                  <FaArrowCircleRight className="duplicate_icon" />
                </div>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ListeningTestCard;
