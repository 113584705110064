import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import {
  changeTimeStampinTwelveHours,
  getTranscationStatus,
} from "../../Services/Constants";

const PaymentsAttemptedByUser = ({ res }) => {
  return (
    <Card
      className="appuserdetailscard"
      style={{ marginBottom: "2vh", cursor: "pointer" }}
    >
      <CardContent>
        <div className="grid_of_two">
          <div className="text_value">
            <Typography className="title">PaymentAmount :-</Typography>
            <Typography className="body">{res?.paymentAmount}</Typography>
          </div>
          <div className="text_value">
            <Typography className="title">CouponCode :-</Typography>
            <Typography className="body">{res?.couponCode}</Typography>
          </div>
        </div>
        <div className="grid_of_two">
          <div className="text_value">
            <Typography className="title">Status :-</Typography>
            <Button
              variant="contained"
              className={
                res?.status === 2 || res?.status === 1
                  ? `trans settled`
                  : `trans failed`
              }
            >
              {getTranscationStatus(res?.status)}
            </Button>
          </div>

          <div className="text_value">
            <Typography className="title">PaymentId :-</Typography>
            <Typography className="body">{res?.paymentID}</Typography>
          </div>
        </div>
        <div className="text_value">
          <Typography className="title">PaymentDatetimeStamp :-</Typography>
          <Typography className="body">
            {changeTimeStampinTwelveHours(res?.paymentDatetimeStamp)}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default PaymentsAttemptedByUser;
