import { Fab, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import SidebarComp from "../../Components/Sidebar";
import { useAuth } from "../../Providers/UserProvider";
import IeltsClassesComp from "../../Components/IeltsClassesComp/IeltsClassesComp";
import { RiAddFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegClock } from "react-icons/fa";

const IeltsClasses = () => {
  const { getClassesData, timetorefresh, closeSidebar } = useAuth();

  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    startIeltsClassesPage();
  }, []);

  const startIeltsClassesPage = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    getClassesData(userData, "", "one", location.state.classGrp);
  };

  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <IeltsClassesComp classGrpUniqueId={location.state.classGrp} />
        <Fab
          aria-label="add"
          style={{
            margin: 0,
            right: 50,
            bottom: 160,
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#051e34",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            navigate("/scheduleieltsClasses", {
              state: {
                classGrpUniqueId: location.state.classGrp
              }
            });
          }}
        >
          <FaRegClock size={30} style={{ color: "white" }} />
        </Fab>
        <Fab
          aria-label="add"
          style={{
            margin: 0,
            right: 50,
            bottom: 80,
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#051e34",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            navigate("/addieltsClasses", {
              state: {
                classGrpUniqueId: location.state.classGrp
              }
            });
          }}
        >
          <RiAddFill size={30} style={{ color: "white" }} />
        </Fab>
      </div>
    </>
  );
};

export default IeltsClasses;
