import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import SidebarComp from "../../Components/Sidebar";
import { useAuth } from "../../Providers/UserProvider";
import { Card, CardContent, Fab, Tab, Tabs, Typography } from "@mui/material";
import { RiAddFill } from "react-icons/ri";
import AddSubCategoryQues from "../../Components/WritingTestComp/AddSubCategoryQues";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowCircleRight } from "react-icons/fa";
import { HiPencil } from "react-icons/hi";
import WritingDetailAddSubCatModal from "../../Components/WritingTestComp/WritingDetailAddSubCatModal";


const QuestionsWritingSubCategory = () => {
  const {
    closeSidebar,
    getQuestionsWritingOfSubCategory,
    questionlistData,
    setsinglewritingquestion,
    singlewritingquestion,
    selectedsubcategoryname
  } = useAuth();
  const location = useLocation();
  const [openAddModal, setopenAddModal] = useState(false);
  const [openEditCategory, setopenEditCategory] = useState(false);
  const singlecategoryNameData = JSON.parse(sessionStorage.getItem("SelectedCategoryData"))


  const handleOpenModel = () => {
    setopenAddModal(true);
  };

  const handleCloseModel = () => {
    setopenAddModal(false);
  };

  const handleEditModal = () => {
    setopenEditCategory(true)
  }

  const handleCloseEditModal = () => {
    setopenEditCategory(false)
  }

  const getQuestionsOfSubCategory = () => {
    const Data = JSON.parse(localStorage.getItem("userData"));
    getQuestionsWritingOfSubCategory(
      Data,
      singlecategoryNameData,
      selectedsubcategoryname,
    );
  };

  useEffect(() => {
    getQuestionsOfSubCategory();

  }, []);







  const navigate = useNavigate();



  return (
    <>
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <Card style={{ width: "100%" }} className="card">
          <CardContent>
            <Card className="cardsub" style={{ marginBottom: "2vh" }}>
              <CardContent style={{ position: "relative" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    marginBottom: "1vh",
                  }}
                >
                  <Typography className="title">Category :</Typography>
                  <Typography className="body">
                    {singlecategoryNameData}
                  </Typography>
                </div>

                {location?.state?.type !== "task1" && <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    marginBottom: "1vh",
                  }}
                >
                  <Typography className="title">SubCategoryName :</Typography>
                  <Typography className="body">
                    {selectedsubcategoryname}
                  </Typography>
                </div>}
                <div className="duplicate">
                  <HiPencil
                    className="duplicate_icon"
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditModal()
                    }}
                  />
                </div>
              </CardContent>
            </Card>
            {/*  */}

            {questionlistData?.WritingQuestionAnswerData?.map((res) => (
              <Card
                className="cardsub"
                style={{ marginBottom: "2vh" }}
                onClick={(e) => {
                  e.preventDefault();
                  setsinglewritingquestion(res);
                  navigate("/writingdata/questionDetails", {
                    state: {
                      data: res,
                      type: location?.state?.type,
                      task1type: location?.state?.task1type
                    },
                  });
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>{res.Question}</Typography>
                  <div>
                    <FaArrowCircleRight className="duplicate_icon" />
                  </div>
                </CardContent>
              </Card>
            ))}

          </CardContent>
        </Card>
      </div>
      <Fab
        aria-label="add"
        style={{
          margin: 0,
          right: 50,
          bottom: 80,
          position: "fixed",
          zIndex: "1",
          backgroundColor: "#051e34",
          color: "white",
        }}
        onClick={(e) => {
          e.preventDefault();
          handleOpenModel();
        }}
      >
        <RiAddFill size={30} style={{ color: "white" }} />
      </Fab>
      <WritingDetailAddSubCatModal
        opendialog={openEditCategory}
        handleClickClose={handleCloseEditModal}
        data={singlecategoryNameData}
        isEdit={true}
        subcategoryValue={selectedsubcategoryname}
      />
      <AddSubCategoryQues
        opendialog={openAddModal}
        handleClickClose={handleCloseModel}
        category={singlecategoryNameData}
        subcategory={selectedsubcategoryname}
        type="Add"
        task1type={location?.state?.task1type}
        taskType={location?.state?.type}
      />
    </>
  );
};

export default QuestionsWritingSubCategory;
