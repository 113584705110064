import React from "react";
import Select from "react-select";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    transform: "initial",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      zIndex: "2",
      transform: "initial",
    };
  },
};

const SelectOptions = ({ options, type, value, handleFunction }) => {
  const handleUpdate = (e) => {
    handleFunction(e);
  };

  const handlePlaceHolder = () => {
    if (type === "category") return "Select Category";
    else if (type === "status") return "Select Status";
    else if (type === "feedbackquesType") return "Select Question Type";
    else if (type === "feedbackquesGroup") return "Select Feedback Group";
    else if (type === "readingList") return "Select Reading Category";
    else if (type === "speakingQuesList")
      return "Select Speaking Question Category";
    else if (type === "ieltsclasses") return "Select Classes Category";
  };

  return (
    <Select
      options={options}
      value={value}
      onChange={(e) => {
        handleUpdate(e);
      }}
      isClearable
      styles={colourStyles}
      placeholder={handlePlaceHolder()}
    />
  );
};

export default SelectOptions;
