import { Button } from "@mui/material";
import React from "react";

const ButtonComp = ({ buttonText, handleClick }) => {
  return (
    <Button
      variant="outlined"
      className="grammar_buttons"
      onClick={handleClick}
      style={{ zIndex: "0" }}
    >
      {buttonText}
    </Button>
  );
};

export default ButtonComp;
