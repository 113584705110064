import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import "../../CSSFiles/errorlog.css";

const ErrorLogCard = ({ data }) => {
  const changeDateFormate = (strDateTime) => {
    const date = new Date(strDateTime);
    var myDate = date.toLocaleString();
    return myDate;
  };
  return (
    <Card className="cardsub" style={{ marginBottom: "2vh" }}>
      <CardContent
        style={{ position: "relative", transform: "translate3d(0, 0, 0)" }}
      >
        <div className="errorgrid">
          <div className="firstgrid">
            <Typography className="title">Uid :-</Typography>
            <Typography className="body">{data.uid}</Typography>
          </div>
          <div className="secondgrid">
            <Typography className="title">errorCode :-</Typography>
            <Typography className="body">{data.errorCode}</Typography>
          </div>
          <div className="thirdgrid">
            <Typography className="title">Created At :-</Typography>
            <Typography className="body">
              {changeDateFormate(data.created_at)}
            </Typography>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Typography className="body">{data.errorDescription}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default ErrorLogCard;
