import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";

export default function DailyActiveUser() {
  const { dailyAciveUserRateData } = useAuth();

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "0px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
          className="graph_tooltips"
        >
          <p
            style={{ color: "#008390" }}
          >{`date : ${payload[0].payload.date}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AllactiveUsers : ${payload[0].payload.activeUser}`}</p>
          <p
            style={{ color: "red" }}
          >{`AndroidactiveUsers : ${payload[0].payload.activeAndroidUserData}`}</p>
          <p
            style={{ color: "green" }}
          >{`IosactiveUsers: ${payload[0].payload.activeiOSUserData}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="99%" height={300}>
      <LineChart data={dailyAciveUserRateData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />

        <YAxis tickCount={8} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey="activeUser"
          stroke="blue"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          name="Android"
          dataKey="activeAndroidUserData"
          stroke="red"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          name="Ios"
          dataKey="activeiOSUserData"
          stroke="green"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
