import React, { useState } from "react";
import { useAuth } from "../../Providers/UserProvider";
import { Card, Box, CardContent } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import "../../CSSFiles/feedbacks.css";
import TabContext from "@mui/lab/TabContext";
import PaidPageFeedbacks from "./PaidPage";
import RawSuggestionFeedback from "./RawSuggestionFeedback";
import TotalSuggestionCard from "./TotalSuggestionCard";
import { Circles } from "react-loader-spinner";
import { makeStyles } from "@material-ui/core";
import UserLeaveAppFeedback from "./UserLeaveAppFeedback";
import RawSuggestionSubfilterPlatform from "./RawSuggestionSubfilterPlatform";
import PaidUserFeedBack from "./PaidUserFeedBackTabs";

const useStyles = makeStyles((theme) => ({
  tabPanelRoot: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  tabHeadings: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },
}));

const FeedbackComponent = () => {
  const {
    feedbacks,
    onClickNavLoader,
    RawsuggsetValue,
    Rawsuggvalue,
    RawsuggfilteredValue,
    RawsuggsetfilteredValue,
    feedbackControlData,
  } = useAuth();

  const { getFeedBackData, PaidUnpaidsetfeedbacktabs } = useAuth();

  const handleChange = (event, newValue) => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    if (newValue === "1") {
      PaidUnpaidsetfeedbacktabs("1");
    }
    if (newValue === "2") PaidUnpaidsetfeedbacktabs("1");
    getFeedBackData(Data);
    RawsuggsetValue(newValue);
  };

  const handleFilteredChange = (event, newValue) => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);

    getFeedBackData(Data);
    RawsuggsetfilteredValue(newValue);
  };

  var styles = {
    root: {
      position: "sticky",
      top: 0,
      background: "#EDF3E0",
      zIndex: "100",
    },
    secondFilter: {
      marginTop: "10px",
    },
  };

  const classes = useStyles();
  return (
    <>
      {/* <Circles
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="circles-loading"
        wrapperStyle={{
          position: "absolute",
          zIndex: "1",
          top: "50%",
          left: "50%",
          color: "#0081CF",
        }}
        wrapperClass=""
        visible={onClickNavLoader}
      /> */}
      <Card
        style={{ width: "100%" }}
        className={onClickNavLoader ? `card invisible` : `card`}
      >
        {/* <CardContent style={{position:'relative',transform:'translate3d(0, 0, 0)'}}> */}
        <TabContext value={Rawsuggvalue}>
          <Tabs
            value={Rawsuggvalue}
            onChange={handleChange}
            centered
            component={Box}
            boxShadow={3}
            variant="fullWidth"
            style={styles.root}
          >
            <Tab
              label={`PaidUser Feedback (${feedbacks?.PaidUserFeedbackFetch?.length})`}
              value="1"
              className={classes.tabHeadings}
            />
            <Tab
              label={`UnpaidUser Feedback (${feedbacks?.unpaidUserFeedbackFetch?.length})`}
              value="2"
              className={classes.tabHeadings}
            />
            <Tab
              label={`user who left app feedback (${feedbacks?.userFeedbackWhoLeaveApp?.length})`}
              value="3"
              className={classes.tabHeadings}
            />
            <Tab
              label={`Raw Suggestions Feedback (${feedbacks?.rawSuggestionGet?.length})`}
              value="4"
              className={classes.tabHeadings}
            />
          </Tabs>
          <TabPanel value="1" className={classes.tabPanelRoot}>
            {/* <div className="feedback_scroll">
              {feedbacks?.PaidUserFeedbackFetch?.map(
                (res, key) =>
                  (res.questionType === "optionalFeedback" ||
                    res.questionType === "YesNo") && (
                    <PaidPageFeedbacks res={res} key={key} />
                  )
              )}
              {feedbacks?.PaidUserFeedbackFetch?.map(
                (res, key) =>
                  res.questionType === "Suggestion" && (
                    <>
                      <TotalSuggestionCard res={res} key={key} />
                    </>
                  )
              )}
            </div> */}
            <PaidUserFeedBack
              tab2label="Paid Active Feedback"
              tab3label="Paid Unactive Feedback"
              feedbacks={feedbacks?.PaidUserFeedbackFetch}
              feedbackControlData={feedbackControlData?.paidActivefeedback}
              InactivefeedbackControlData={
                feedbackControlData?.paidNotActivefeedback
              }
              type="PaidFeedbackControls"
            />
          </TabPanel>
          <TabPanel value="2">
            <PaidUserFeedBack
              tab2label="UnPaid Active Feedback"
              tab3label="UnPaid Unactive Feedback"
              feedbacks={feedbacks?.unpaidUserFeedbackFetch}
              feedbackControlData={feedbackControlData?.unpaidActivefeedback}
              InactivefeedbackControlData={
                feedbackControlData?.unpaidNotActivefeedback
              }
              type="UnpaidFeedbackControls"
            />
          </TabPanel>
          <TabPanel value="3">
            {feedbacks?.userFeedbackWhoLeaveApp?.map((res, key) => (
              <UserLeaveAppFeedback data={res} key={key} />
            ))}
          </TabPanel>
          <TabPanel value="4">
            {/* {feedbacks?.rawSuggestionGet?.map((res, key) => (
              <RawSuggestionFeedback res={res} key={key} />
            ))} */}
            <TabContext value={RawsuggfilteredValue}>
              <Tabs
                value={RawsuggfilteredValue}
                onChange={handleFilteredChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                centered
                style={styles.secondFilter}
              >
                <Tab value={0} label="Pending" />
                <Tab value={1} label="Helpful" />
                <Tab value={2} label="Not Helpful" />
              </Tabs>
              <TabPanel value={0}>
                {/* {feedbacks?.rawSuggestionGet?.map((res, key) => (
                  <RawSuggestionFeedback res={res} key={key} />
                ))} */}
                <RawSuggestionSubfilterPlatform
                  data={feedbacks?.rawSuggestionGet?.filter(
                    (res) => res.helpfullOrNot === 0,
                  )}
                />
              </TabPanel>
              <TabPanel value={1}>
                {/* {feedbacks?.rawSuggestionGet
                  ?.filter((res) => res.helpfullOrNot === 1)
                  ?.map((res, key) => (
                    <RawSuggestionFeedback res={res} key={key} />
                  ))} */}
                <RawSuggestionSubfilterPlatform
                  data={feedbacks?.rawSuggestionGet?.filter(
                    (res) => res.helpfullOrNot === 1,
                  )}
                />
              </TabPanel>
              <TabPanel value={2}>
                {/* {feedbacks?.rawSuggestionGet
                  ?.filter((res) => res.helpfullOrNot === 2)
                  ?.map((res, key) => (
                    <RawSuggestionFeedback res={res} key={key} />
                  ))} */}
                <RawSuggestionSubfilterPlatform
                  data={feedbacks?.rawSuggestionGet?.filter(
                    (res) => res.helpfullOrNot === 2,
                  )}
                />
              </TabPanel>
            </TabContext>
          </TabPanel>
        </TabContext>
        {/* </CardContent> */}
      </Card>
    </>
  );
};

export default FeedbackComponent;
