import { Box, Card, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../Providers/UserProvider";
import { TabContext, TabPanel } from "@mui/lab";
import { makeStyles } from "@material-ui/core";
import IeltsClassesCard from "./IeltsClassesCard";

var styles = {
  root: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles((theme) => ({
  customButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px !important",
      padding: "0px 2px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "10px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "12px !important",
      minWidth: "0px !important",
      padding: "0px 6px !important",
    },
  },
  newcustomButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "7px !important",
      padding: "0px 2px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "10px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "12px !important",
      minWidth: "0px !important",
      padding: "0px 6px !important",
    },
  },
}));
const IeltsClassesComp = ({ classGrpUniqueId }) => {
  const { classesCategory, getClassesData, classesData } = useAuth();
  const classes = useStyles();
  const [categoryvalue, setcategoryValue] = useState("Speaking");
  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setcategoryValue("Speaking");
    getClassesData(userData, "Speaking", newValue, classGrpUniqueId);
    setValue(newValue);
  };

  const handleCategoryChange = (event, newValue) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setcategoryValue(newValue);
    getClassesData(userData, newValue, value, classGrpUniqueId);
  };

  console.log(classesCategory)

  return (
    <Card className="card">
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          component={Box}
          boxShadow={3}
          variant="fullWidth"
        >
          <Tab
            value="one"
            label="Live Classes"
            classes={{ root: classes.customButtonBaseRoot }}
          />
          <Tab
            value="two"
            label="Scheduled Classes"
            classes={{ root: classes.customButtonBaseRoot }}
          />
        </Tabs>
        <TabPanel value="one">
          <TabContext value={categoryvalue}>
            <Tabs
              value={categoryvalue}
              onChange={handleCategoryChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              centered
              style={styles.root}
            >
              {classesCategory?.map((res) => (
                <Tab
                  value={res}
                  label={res}
                  classes={{ root: classes.customButtonBaseRoot }}
                />
              ))}
            </Tabs>
            {classesCategory?.map((res) => {
              return (
                <TabPanel value={res} style={{ padding: "0" }}>
                  {classesData?.map((data) => {
                    return (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <IeltsClassesCard data={data} type="Live" />
                      </div>
                    );
                  })}
                </TabPanel>
              );
            })}
          </TabContext>
        </TabPanel>
        <TabPanel value="two">
          <TabContext value={categoryvalue}>
            <Tabs
              value={categoryvalue}
              onChange={handleCategoryChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              centered
              style={styles.root}
            >
              {classesCategory?.map((res) => (
                <Tab
                  value={res}
                  label={res}
                  classes={{ root: classes.customButtonBaseRoot }}
                />
              ))}
            </Tabs>
            {classesCategory?.map((res) => {
              return (
                <TabPanel value={res} style={{ padding: "0" }}>
                  {classesData?.map((data) => {
                    return (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <IeltsClassesCard data={data} type="Schedule" />
                      </div>
                    );
                  })}
                </TabPanel>
              );
            })}
          </TabContext>
        </TabPanel>
      </TabContext>
    </Card>
  );
};

export default IeltsClassesComp;
