import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "../../CSSFiles/coupon.css";

const CouponUsageCard = ({ res }) => {
  return (
    <Card
      className="cardsub"
      style={{ marginBottom: "2vh", cursor: "pointer" }}
    >
      <CardContent>
        <div style={{ display: "flex" }}>
          <Typography className="title">Number :-</Typography>
          <Typography className="body">{res.id}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography className="title">Id :-</Typography>
          <Typography className="body">{res.customerInformation}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography className="title">Money :-</Typography>
          <Typography className="body">₹ {res.paymentAmount}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography className="title">Date :-</Typography>
          <Typography className="body">{res.created_at_Date}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default CouponUsageCard;
