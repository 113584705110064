import { Button, Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import SelectOptions from "../NotificationsComp/SelectOptions";
import { feedbackGroup, questionTypes } from "./AddEditFeedbackComp";
import CustomDialogBox from "../CustomDialogBox";
import { AddAndEditFeedbackDialogBodyTxt } from "../../MiddleWares/AddAndEditFeedbackDialogBodyTxt";
import { useAuth } from "../../Providers/UserProvider";

const returnValueFromQuestionTypeLabel = (label) => {
  if (label === "YesNo") return questionTypes[0];
  else if (label === "optionalFeedback") return questionTypes[1];
  else if (label === "Suggestion") return questionTypes[2];
};

const returnValueFromFeedBackGroupLabel = (label) => {
  if (label === "PaidUser") return feedbackGroup[0];
  else if (label === "UnpaidUser") return feedbackGroup[1];
};

const EditFeedbackComp = () => {
  const location = useLocation();

  const { HandleEditOnlyFeedbacks } = useAuth();

  const [questionValue, setquestionValue] = useState(
    location.state.feedback.question,
  );
  const [questionNumber, setquestionNumber] = useState(
    location?.state?.feedback?.questionNumber,
  );

  const [questionTypeState, setquestionType] = useState(
    returnValueFromQuestionTypeLabel(location?.state?.feedback?.questionType),
  );

  const [feedbackGroupState, setfeedbackGroupState] = useState(
    returnValueFromFeedBackGroupLabel(location?.state?.feedback?.feedbackGroup),
  );

  const [feedbackAnsOpt, setfeedbackAnsOpt] = useState(
    location?.state?.feedback?.optionalFeedbackAnswerOptions,
  );

  const handleFeedbackChangeValue = (e) => {
    setfeedbackGroupState(e);
  };

  const handleChangeQuesValue = (e) => {
    setquestionType(e);
  };

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const handleSubmitOfDialog = () => {
    if (questionTypeState.value !== 1) {
      HandleEditOnlyFeedbacks(
        questionValue,
        questionNumber,
        questionTypeState.label,
        feedbackGroupState.label,
        location?.state?.feedback?.feedbackId,
      );
    } else {
      HandleEditOnlyFeedbacks(
        questionValue,
        questionNumber,
        questionTypeState.label,
        feedbackGroupState.label,
        location?.state?.feedback?.feedbackId,
        feedbackAnsOpt,
      );
    }
  };

  return (
    <Card className="card">
      <CardContent>
        <div style={{ marginBottom: "10px" }}>
          <Typography style={{ fontSize: "20px" }}>Question</Typography>
          <div className="main_con">
            <textarea
              rows="2"
              className="grammar_text_area"
              placeholder="Enter question here"
              value={questionValue}
              onChange={(e) => {
                setquestionValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Typography style={{ fontSize: "20px" }}>Question Number</Typography>
          <div className="main_con">
            <input
              className="grammar_text_area"
              placeholder="Enter question Number here"
              value={questionNumber}
              onChange={(e) => {
                setquestionNumber(e.target.value);
              }}
            />
          </div>
        </div>

        <Typography style={{ fontSize: "20px" }}>Question Type</Typography>
        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
          }}
        >
          <SelectOptions
            options={questionTypes}
            type="feedbackquesType"
            value={questionTypeState}
            handleFunction={handleChangeQuesValue}
          />
        </div>

        {questionTypeState?.value === 1 && (
          <div>
            <Typography
              style={{ fontSize: "20px", transform: "unset", zIndex: "-1" }}
            >
              Optional Feedback Answer Options
            </Typography>
            <div className="main_con">
              <textarea
                rows="2"
                className="grammar_text_area"
                placeholder="Enter Feedback Answer Options"
                value={feedbackAnsOpt}
                onChange={(e) => {
                  setfeedbackAnsOpt(e.target.value);
                }}
              />
            </div>
          </div>
        )}
        <div style={{ marginBottom: "10px" }}>
          <Typography style={{ fontSize: "20px" }}>Feedback Group</Typography>
          <div
            style={{
              marginTop: "2vh",
              marginBottom: "2vh",
              transform: "initial",
            }}
          >
            <SelectOptions
              options={feedbackGroup}
              type="feedbackquesGroup"
              value={feedbackGroupState}
              handleFunction={handleFeedbackChangeValue}
            />
          </div>
        </div>
        <div style={{ textAlign: "center", transform: "unset" }}>
          <Button
            variant="outlined"
            className="grammar_buttons"
            style={{ zIndex: "0" }}
            onClick={(e) => {
              e.preventDefault();
              handleClickOpen();
            }}
          >
            Save
          </Button>
        </div>
      </CardContent>
      <CustomDialogBox
        opendialog={opendialog}
        handleClickClose={handleClickClose}
        bodyText={
          <AddAndEditFeedbackDialogBodyTxt
            question={questionValue}
            questionNumber={questionNumber}
            questionType={questionTypeState}
            feedbackGroup={feedbackGroupState}
            feedbackAnsOpt={feedbackAnsOpt}
          />
        }
        confirmationText="Are You Sure Want To Add These Changes"
        handleSubmitOfDialog={handleSubmitOfDialog}
      />
    </Card>
  );
};

export default EditFeedbackComp;
