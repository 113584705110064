import React, { useState } from "react";
import "../../CSSFiles/home.css";
import { Grid } from "@mui/material";
import PaymentBehaviour from "../../Components/Charts/PaymentBehaviour";
import RetentionData from "../../Components/Charts/RetentionData";
import "react-datepicker/dist/react-datepicker.css";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import FeaturesUsage from "../../Components/Charts/FeaturesUsage";
import UnpaidFeaturesUsage from "../../Components/Charts/UnpaidFeaturesUsage";
import AllUsersFeaturesUsage from "../../Components/Charts/AllUsersFeaturesUsage";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../Providers/UserProvider";
import AvgTimeSpentByUserChart from "../Charts/AvgTimeSpentByUserChart";
import PaymentBehaviourInADay from "../Charts/PaymentBehaviourInADay";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  style: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
  },
}));

const AndroidBehComp = ({ handleFunction, value, setValue }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    androidretentiondata,
    androidavgtimespend,
    androidallfeaturesusage,
    getUserBehaviourData,
    androidpaidfeaturesusage,
    androidunpaidfeaturesusage,
    androidappleaveuserfeaturesusage,
    width,
  } = useAuth();

  const handleChange = (event, newValue) => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    getUserBehaviourData(Data);
    setValue(newValue);
    if (newValue === "five") {
      width < 660 ? navigate("/androidusersmobseeall") : handleFunction();
    }
  };

  return (
    <>
      <div>
        <Grid
          container
          style={{
            textAlign: "-webkit-center",
            paddingRight: "15px",
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2
              style={{ textAlign: "-webkit-center" }}
              className="graph_headings"
            >
              Payment Behaviour In A Day
            </h2>

            <PaymentBehaviourInADay />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ textAlign: "-webkit-center", padding: "5px" }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2
              style={{ textAlign: "-webkit-center" }}
              className="graph_headings"
            >
              Payment Behaviour Graph
            </h2>

            <PaymentBehaviour />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2
              style={{ textAlign: "-webkit-center" }}
              className="graph_headings"
            >
              Retention Data Report
            </h2>
            <RetentionData retentiondata={androidretentiondata} />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          style={{ textAlign: "-webkit-center", padding: "5px" }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2
              style={{ textAlign: "-webkit-center" }}
              className="graph_headings"
            >
              Average Time Spent By User
            </h2>
            <AvgTimeSpentByUserChart retentiondata={androidavgtimespend} />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2
              style={{ textAlign: "-webkit-center", margin: "0" }}
              className="graph_headings"
            >
              Features Usage
            </h2>

            <TabContext value={value}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                centered
                style={{ height: "25px" }}
              >
                <Tab
                  value="one"
                  label="All Android"
                  className={classes.style}
                />
                <Tab
                  value="two"
                  label="Paid Android"
                  className={classes.style}
                />
                <Tab
                  value="three"
                  label="Unpaid Android"
                  className={classes.style}
                />
                <Tab
                  value="four"
                  label="App Leave Users Android"
                  className={classes.style}
                />
                <Tab value="five" label="See All" className={classes.style} />
              </Tabs>
              <TabPanel value="one">
                <AllUsersFeaturesUsage
                  retentiondata={androidallfeaturesusage}
                />
              </TabPanel>
              <TabPanel value="two">
                <FeaturesUsage retentiondata={androidpaidfeaturesusage} />
              </TabPanel>
              <TabPanel value="three">
                <UnpaidFeaturesUsage
                  retentiondata={androidunpaidfeaturesusage}
                />
              </TabPanel>
              <TabPanel value="four">
                <UnpaidFeaturesUsage
                  retentiondata={androidappleaveuserfeaturesusage}
                />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AndroidBehComp;
