import { Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../../CSSFiles/userdetails.css";
import {
  changeDateFormate,
  changeTimeStamptoDate,
  IsProfileCompleted,
} from "../../Services/Constants";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Fab } from "@mui/material";

const AppUserDetailsCard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Card
      className="appuserdetailscard"
      style={{ marginBottom: "2vh", cursor: "pointer" }}
    >
      <CardContent style={{ position: "relative" }}>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">Id :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.id}
            </Typography>
          </div>

          <div className="text_value">
            <Typography className="title">Name :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.fullName}
            </Typography>
          </div>
          <div className="text_value">
            <Typography className="title">Email :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.email}
            </Typography>
          </div>
        </div>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">AccountType :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.accountType}
            </Typography>
          </div>

          <div className="text_value">
            <Typography className="title">PhoneNumber :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.phoneNumber}
            </Typography>
          </div>
          <div className="text_value">
            <Typography className="title">Membership :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.memberShip}
            </Typography>
          </div>
        </div>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">PlanExpireDate :-</Typography>
            <Typography className="body">
              {changeTimeStamptoDate(
                location?.state?.appUserDetail?.planExpireDate,
              )}
            </Typography>
          </div>

          <div className="text_value">
            <Typography className="title">UID :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.uid}
            </Typography>
          </div>
          <div className="text_value">
            <Typography className="title">UniqueDeviceId :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.uniqueDeviceId}
            </Typography>
          </div>
        </div>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">WalletAmount :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.walletAmount}
            </Typography>
          </div>

          <div className="text_value">
            <Typography className="title">TotalTestForToday :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.totalTestForToday}
            </Typography>
          </div>
          <div className="text_value">
            <Typography className="title">CouponCodeText :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.couponCodeText}
            </Typography>
          </div>
        </div>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">
              MoneyWithdrawSessionTime :-
            </Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.moneyWithdrawSessionTime}
            </Typography>
          </div>

          <div className="text_value">
            <Typography className="title">
              CurrentLocationLatitude :-
            </Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.currentLocationLatitude}
            </Typography>
          </div>
          <div className="text_value">
            <Typography className="title">
              CurrentLocationLongitude :-
            </Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.currentLocationLongitude}
            </Typography>
          </div>
        </div>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">Platform :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.platform}
            </Typography>
          </div>
          <div className="text_value">
            <Typography className="title">FCM token :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.fcmToken.substr(0, 20)}...
            </Typography>
          </div>
          <div className="text_value">
            <Typography className="title">IsProfileCompleted :-</Typography>
            <Typography className="body">
              {IsProfileCompleted(
                location?.state?.appUserDetail?.isProfileCompleted,
              )}
            </Typography>
          </div>
        </div>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">UpdateAt :-</Typography>
            <Typography className="body">
              {changeDateFormate(location?.state?.appUserDetail?.updateAt)}
            </Typography>
          </div>

          <div className="text_value">
            <Typography className="title">
              AccountStartingDateInFormat :-
            </Typography>
            <Typography className="body">
              {changeDateFormate(
                location?.state?.appUserDetail?.accountStartingDateInFormat,
              )}
            </Typography>
          </div>
          <div className="text_value">
            <Typography className="title">
              LastTestAttemptDateInFormat :-
            </Typography>
            <Typography className="body">
              {changeDateFormate(
                location?.state?.appUserDetail?.lastTestAttemptDateInFormat,
              )}
            </Typography>
          </div>
        </div>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">
              DataAvailbleInSleepMode :-
            </Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.dataAvailbleInSleepMode}
            </Typography>
          </div>

          <div className="text_value">
            <Typography className="title">FeedbackStatus :-</Typography>
            <Typography className="body">
              {location?.state?.appUserDetail?.feedbackStatus}
            </Typography>
          </div>
        </div>
        <Fab
          aria-label="add"
          style={{
            margin: 0,

            position: "absolute",
            top: "80%",
            left: "90%",
            zIndex: "1",
            backgroundColor: "#051e34",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            navigate("/userseditdetails");
          }}
        >
          <MdModeEdit size={28} style={{ color: "white" }} />
        </Fab>
      </CardContent>
    </Card>
  );
};

export default AppUserDetailsCard;
