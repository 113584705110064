import { Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../Providers/UserProvider";
import {
  changeDateFormate,
  getAccountLevelOfAdmins,
  getAccountStatusOfAdmins,
  getdataEntryPermission,
} from "../../Services/Constants";
import AdminEditTextDialog from "./SelectedUserDetailEdit/AdminEditTextDialog";
import { toast } from "react-toastify";

export function functionConvertIntegerToArray(num) {
  var myArr = String(num)
    .split("")
    .map((num) => {
      return Number(num);
    });
  return myArr;
}

const AdminUserDetailsCard = () => {
  const { adminUserDetail } = useAuth();

  const [text, settext] = useState({
    title: "",
    value: "",
  });
  const [openedittextDialog, setopenedittextDialog] = useState(false);

  const [valueId, setvalueId] = useState("");

  const handleClickOpenEdit = () => {
    setopenedittextDialog(true);
  };

  const handleClickClose = () => {
    setopenedittextDialog(false);
  };

  const handleEditText = (title, value, valueId) => {
    settext({
      title: title,
      value: value,
    });
    setvalueId(valueId);
    handleClickOpenEdit();
  };

  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      <CardContent style={{ position: "relative" }}>
        <div className="profileContainer">
          <img
            src={adminUserDetail?.profileImage}
            alt="ProfileImage"
            className="profileImage"
            onClick={(e) => {
              e.preventDefault();
              handleEditText(
                "Profile Picture",
                adminUserDetail?.profileImage,
                "6",
              );
            }}
          />
        </div>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">id :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {adminUserDetail?.id}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Uid :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {adminUserDetail?.uid}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">UniqueDeviceId :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {adminUserDetail?.uniqueDeviceId}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            handleEditText("Full Name", adminUserDetail?.fullName, "4");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Full Name :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {adminUserDetail?.fullName}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            handleEditText("Experience", adminUserDetail?.experiance, "5");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Experience :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {adminUserDetail?.experiance}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">FCM token :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {adminUserDetail?.fcmToken}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Email :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {adminUserDetail?.email}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            handleEditText("Account Level", adminUserDetail?.accountLevel, "2");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Account Level :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {getAccountLevelOfAdmins(adminUserDetail?.accountLevel)}
              </Typography>
            </div>
          </CardContent>
        </Card>
        {(adminUserDetail?.accountLevel === 3 ||
          adminUserDetail?.accountLevel === 43) && (
          <Card
            className="cardsub"
            style={{ marginBottom: "2vh" }}
            onClick={(e) => {
              e.preventDefault();
              handleEditText(
                "Data Entry Permission",
                adminUserDetail?.dataEntryPermission,
                "3",
              );
            }}
          >
            <CardContent>
              <div className="payment_data">
                <Typography className="title">
                  Data Entry Permission :
                </Typography>
                {/*  */}
                {functionConvertIntegerToArray(
                  adminUserDetail?.dataEntryPermission,
                )?.map((val) => (
                  <Typography
                    className="body"
                    style={{ lineBreak: "anywhere" }}
                  >
                    {getdataEntryPermission(val)}
                  </Typography>
                ))}
              </div>
            </CardContent>
          </Card>
        )}
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            handleEditText(
              "Account Status",
              adminUserDetail?.accountStatus,
              "1",
            );
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Account Status :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {getAccountStatusOfAdmins(adminUserDetail?.accountStatus)}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Account Starting Date :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {changeDateFormate(
                  adminUserDetail?.accountStartingDateInFormat,
                )}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <AdminEditTextDialog
          text={text}
          opendialog={openedittextDialog}
          handleClickClose={handleClickClose}
          valueId={valueId}
          type="Admins"
        />
      </CardContent>
    </Card>
  );
};

export default AdminUserDetailsCard;
