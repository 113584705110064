import React, { useEffect } from "react";
import CouponComponent from "../../Components/CouponComp/CouponComponent";
import SidebarComp from "../../Components/Sidebar";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";
import { Typography } from "@mui/material";
import SidebarMob from "../../Components/SidebarMob";
import Navbar from "../../Components/Navbar/Navbar";

const CouponCode = () => {
  const {
    getCouponCodeData,
    updateCouponCodeData,
    timetorefresh,
    closeSidebar,
  } = useAuth();

  useEffect(() => {
    StartFeedBackPage();
  }, []);

  const StartFeedBackPage = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    const couponCodeData = sessionStorage.getItem("couponCodeData");
    if (couponCodeData) {
      updateCouponCodeData();
    }
    getCouponCodeData(Data);
  };
  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />

        <CouponComponent />
      </div>
    </>
  );
};

export default CouponCode;
