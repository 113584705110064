import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../Providers/UserProvider";
import StoredNotiCard from "./StoredNotificationCard";

var styles = {
  root: {
    marginTop: "10px",
  },
};

const StoredNotificationSubFilters = ({ notidata, type }) => {
  const [filteredValue, setfilteredValue] = useState(1);

  const { activeData, setactiveData } = useAuth();

  const handleFilteredChange = (value) => {
    const data = notidata?.filter((data) => data.status === value);
    //   setfilteredData(data)
    setactiveData(data);
  };

  const handleChange = (e, value) => {
    setfilteredValue(value);
    handleFilteredChange(value);
  };

  return (
    <TabContext value={filteredValue}>
      <Tabs
        value={filteredValue}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        centered
        style={styles.root}
      >
        <Tab value={1} label="Active" />
        <Tab value={0} label="Not Active" />
      </Tabs>
      <TabPanel value={1}>
        {activeData &&
          activeData?.map((res, key) => (
            <StoredNotiCard key={key} res={res} data={type} side="1" />
          ))}
      </TabPanel>
      <TabPanel value={0}>
        {" "}
        {activeData &&
          activeData?.map((res, key) => (
            <StoredNotiCard key={key} res={res} data={type} side="0" />
          ))}
      </TabPanel>
    </TabContext>
  );
};

export default StoredNotificationSubFilters;
