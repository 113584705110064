import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../../CSSFiles/home.css";
import { useAuth } from "../../../Providers/UserProvider";

export default function CompanyUnknownGraph() {
  const { dailyRevenueData } = useAuth();

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "0px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
          className="graph_tooltips"
        >
          <p
            style={{ color: "#008390" }}
          >{`date : ${payload[0].payload.date}`}</p>

          <p
            style={{ color: "##5DB09A" }}
          >{`CompanyUnknownExpense : ${payload[0].payload.companyUnknownExpense}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="99%" height={250}>
      <LineChart data={dailyRevenueData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />

        <YAxis tickCount={9} type="number" />
        <Tooltip content={<CustomTooltip />} />
        <Legend />

        <Line type="monotone" dataKey="companyUnknownExpense" stroke="green" />
      </LineChart>
    </ResponsiveContainer>
  );
}
