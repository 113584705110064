import React, { useEffect } from "react";
import { useAuth } from "../../Providers/UserProvider";
import ServerInfoDetails from "../Charts/ServerInfoDetail";

const ProdServer = () => {
  const { getHomeData, prodserverdetails, getServerDetails } = useAuth();

  useEffect(() => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);

    getHomeData(Data);
    getServerDetails("IELTS-Production-env");
  }, []);

  return (
    <>
      <div className="server_graphs">
        <ServerInfoDetails serverInfodetails={prodserverdetails} />
      </div>
    </>
  );
};

export default ProdServer;
