import React, { useState, useEffect } from "react";
import SidebarComp from "../../Components/Sidebar";
import "../../CSSFiles/home.css";
import SidebarMob from "../../Components/SidebarMob";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../../Providers/UserProvider";
import { Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import Navbar from "../../Components/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import UserBehComp from "../../Components/UserBehaviourComps/UserBehComp";
import AndroidBehComp from "../../Components/UserBehaviourComps/AndroidBehComp";
import IosBehComp from "../../Components/UserBehaviourComps/IosBehComp";
import CombinedBehComp from "../../Components/UserBehaviourComps/CombinedBehComp";
import SeeMoreGraphComp from "../../Components/UserBehaviourComps/SeeMoreGraphComp";
import ModalComp from "../../Components/GrammarMistaken/ModalComp";
import AndroidSeeMoreGraphComp from "../../Components/UserBehaviourComps/AndroidSeeMoreGraphComp";
import IosSeeMoreGraphComp from "../../Components/UserBehaviourComps/IosSeeMoreGraphComp";
import CombineSeeMoreGraphComp from "../../Components/UserBehaviourComps/CombinedSeeMoreGraphComp";
import CalendarComp from "../../MiddleWares/CalendarComp";

const useStyles = makeStyles((theme) => ({
  customButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px !important",
      padding: "0px 2px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "12px !important",
      padding: "0px 6px !important",
    },
  },
}));

const UserBehaviour = () => {
  const [startDate, setStartDate] = useState(new Date());
  const {
    getUserBehaviourData,
    updateUserBehaviourData,
    timetorefresh,
    closeSidebar,
  } = useAuth();
  const classes = useStyles();
  const [valueall, setValueall] = useState("one");
  const [valueandroid, setValueandroid] = useState("one");
  const [valueios, setValueios] = useState("one");
  const [valuecombine, setValuecombine] = useState("one");

  useEffect(() => {
    StartUserBehaviourPage();
  }, []);
  const [IsModalScreen, setIsModalScreen] = useState(false);
  const [IsAndroidScreen, setIsAndroidScreen] = useState(false);
  const [IsIosScreen, setIsIosScreen] = useState(false);
  const [IsCombineScreen, setIsCombineScreen] = useState(false);
  const handleFunction = () => {
    setIsModalScreen(!IsModalScreen);
    setValueall("one");
  };
  const handleFunctionAndroid = () => {
    setIsAndroidScreen(!IsAndroidScreen);
    setValueandroid("one");
  };
  const handleFunctionIos = () => {
    setIsIosScreen(!IsIosScreen);
    setValueios("one");
  };

  const handleFunctionCombine = () => {
    setIsCombineScreen(!IsCombineScreen);
    setValuecombine("one");
  };

  const StartUserBehaviourPage = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    const userBehaviourData = sessionStorage.getItem("userBehaviourData");
    if (userBehaviourData) {
      updateUserBehaviourData();
    }
    getUserBehaviourData(Data);
  };
  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    getUserBehaviourData(Data);
    setValue(newValue);
  };

  return (
    <div className="scrolling">
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        {IsModalScreen && (
          <ModalComp
            handleFunction={handleFunction}
            IsModalScreen={IsModalScreen}
            children={<SeeMoreGraphComp />}
          />
        )}
        {IsAndroidScreen && (
          <ModalComp
            handleFunction={handleFunctionAndroid}
            IsModalScreen={IsAndroidScreen}
            children={<AndroidSeeMoreGraphComp />}
          />
        )}
        {IsIosScreen && (
          <ModalComp
            handleFunction={handleFunctionIos}
            IsModalScreen={IsIosScreen}
            children={<IosSeeMoreGraphComp />}
          />
        )}
        {IsCombineScreen && (
          <ModalComp
            handleFunction={handleFunctionCombine}
            IsModalScreen={IsCombineScreen}
            children={<CombineSeeMoreGraphComp />}
          />
        )}
        <div style={{ width: "100%", height: "100vh", overflow: "scroll" }}>
          {timetorefresh === true && (
            <Typography
              style={{
                margin: 0,
                top: 15,
                right: 20,
                bottom: 20,
                left: "auto",
                position: "fixed",
                zIndex: "1",
              }}
            >
              Refreshing...
            </Typography>
          )}

          <div
            style={{
              margin: "2vh",
              maxWidth: "100%",
              position: "relative",
              transform: "unset",
              zIndex: 10,
            }}
          >
            {/* <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            /> */}
            <CalendarComp
              calendarData={startDate}
              setcalendarDate={setStartDate}
            />
          </div>
          <div style={{ position: "relative" }}>
            <TabContext value={value}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                centered
              >
                <Tab
                  value="one"
                  label="All Users"
                  classes={{ root: classes.customButtonBaseRoot }}
                />
                <Tab
                  value="two"
                  label="Android"
                  classes={{ root: classes.customButtonBaseRoot }}
                />
                <Tab
                  value="three"
                  label="Ios"
                  classes={{ root: classes.customButtonBaseRoot }}
                />
                <Tab
                  value="four"
                  label="Combined"
                  classes={{ root: classes.customButtonBaseRoot }}
                />
              </Tabs>
              <TabPanel value="one" style={{ padding: "0" }}>
                <UserBehComp
                  handleFunction={handleFunction}
                  value={valueall}
                  setValue={setValueall}
                />
              </TabPanel>
              <TabPanel value="two" style={{ padding: "0" }}>
                <AndroidBehComp
                  handleFunction={handleFunctionAndroid}
                  value={valueandroid}
                  setValue={setValueandroid}
                />
              </TabPanel>
              <TabPanel value="three" style={{ padding: "0" }}>
                <IosBehComp
                  handleFunction={handleFunctionIos}
                  value={valueios}
                  setValue={setValueios}
                />
              </TabPanel>
              <TabPanel value="four" style={{ padding: "0" }}>
                <CombinedBehComp
                  handleFunction={handleFunctionCombine}
                  value={valuecombine}
                  setValue={setValuecombine}
                />
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBehaviour;
