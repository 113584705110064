import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import { useLocation, useNavigate } from "react-router-dom";
import CouponUsageCard from "./CouponUsageCard";
import { Grid } from "@mui/material";
import CouponCardsView from "../SubCardsComps/CouponCardsView";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useAuth } from "../../Providers/UserProvider";
import CouponCodeOwnerAppView from "./CouponCodeOwnerAppView";
import CouponCodeAdminView from "./CouponCodeAdminView";
import UsersSearchField from "../LastDaysUsersComp/UsersSearchField";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const CouponTable = () => {
  const classes = useStyles();

  const { singleCoupounDetail, handleCouponDetail } = useAuth();

  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const couponCodeId = sessionStorage.getItem("selectedCouponId");
    if (couponCodeId) {
      handleCouponDetail(JSON.parse(couponCodeId));
    }
  };

  // console.log(singleCoupounDetail,"pu")

  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      <Grid
        container
        spacing={1}
        rowSpacing={3}
        style={{
          paddingRight: "15px",
          paddingLeft: "10px",
          paddingTop: "10px",
        }}
      >
        <Grid item xs={12} sm={6} md={6} lg={6} xl={4} className="gridingcards">
          <CouponCardsView
            title={singleCoupounDetail.couponCodeRawData?.couponCodeText}
            value={Number(
              singleCoupounDetail.couponCodeRawData?.totalAmountInWallet,
            ).toLocaleString("en-IN")}
            title2="Total Earning"
            value2={Number(
              singleCoupounDetail.couponcodedataDetails?.totalEarning,
            ).toLocaleString("en-IN")}
            title3="Total Spend"
            value3={Number(
              singleCoupounDetail.couponcodedataDetails?.totalSpend,
            ).toLocaleString("en-IN")}
            cardType="CouponSpendDetail"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={4} className="gridingcards">
          <CouponCardsView
            title2="BabyCode Cut"
            value2={
              Number(
                singleCoupounDetail.couponCodeRawData
                  ?.BabyCodeCutOffInWalletAmount,
              ) === 0
                ? `Baby code will not cut any payment`
                : Number(
                    singleCoupounDetail.couponCodeRawData
                      ?.BabyCodeCutOffInWalletAmount,
                  ) < 0
                ? `Coupon Owner get every ${Math.abs(
                    Number(
                      singleCoupounDetail.couponCodeRawData
                        ?.BabyCodeCutOffInWalletAmount,
                    ),
                  )} payment`
                : `BabyCode will cut every ${Math.abs(
                    Number(
                      singleCoupounDetail.couponCodeRawData
                        ?.BabyCodeCutOffInWalletAmount,
                    ),
                  )} payment`
            }
            title3="BabyCode Cut Money Till Now"
            value3={Number(
              singleCoupounDetail.couponCodeRawData
                ?.BabyCodeCutOffInWalletMoneyTillNow,
            ).toLocaleString("en-IN")}
            title4="Till now Coupon Owner Get Money By CouponCode"
            value4={Number(
              singleCoupounDetail.couponcodedataDetails
                ?.tillNowCouponOwnerGetMoneyByCouponCode,
            ).toLocaleString("en-IN")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={4} className="gridingcards">
          <CouponCardsView
            title2="AppUserGetDiscount"
            value2={Number(
              singleCoupounDetail.couponCodeRawData?.discountPrice,
            ).toLocaleString("en-IN")}
            title3="CouponOwnerGetMoney"
            value3={Number(
              singleCoupounDetail.couponCodeRawData?.amountThatInfluncerWillGet,
            ).toLocaleString("en-IN")}
            title5="CouponCodeExpire"
            value5={singleCoupounDetail.couponCodeRawData?.couponExpireDate}
          />
        </Grid>
      </Grid>
      {/* <h2 style={{margin:'15px' }}>Balance : ₹ {location.state.coupondata.totalAmountInWallet}</h2> */}

      {/* <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              style={{ fontWeight: "bold", fontSize: "15px" }}
            >
              Date
            </TableCell>
            <TableCell
              align="center"
              style={{ fontWeight: "bold", fontSize: "15px" }}
            >
              Payment Count
            </TableCell>
            <TableCell
              align="center"
              style={{ fontWeight: "bold", fontSize: "15px" }}
            >
              Price(in Rupees)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody> */}
      {/* {location?.state?.coupontable?.map((row) => (
            <TableRow key={row.Date}>
              <TableCell align="center">{row.Date}</TableCell>
              <TableCell align="center">{row.PaymentCount}</TableCell>
              <TableCell align="center">{row.Price}</TableCell>
            </TableRow>
          ))} */}
      {/* </TableBody>
      </Table> */}
      {/* {location?.state?.couponcodedataDetails?.couponUsageList?.map((res) => {
        return <CouponUsageCard res={res} />;
      })} */}
      <div style={{ marginTop: "15px" }}>
        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            component={Box}
            boxShadow={3}
            variant="fullWidth"
          >
            <Tab value="one" label="Coupon Code Owner App View" />
            <Tab value="two" label="Admin View" />
          </Tabs>
          <TabPanel value="one">
            <CouponCodeOwnerAppView
              lastWeekData={singleCoupounDetail?.lastWeekDataForCouponCodeOwner}
            />

            {singleCoupounDetail?.couponcodedataDetails?.couponUsageList?.CouponCodeUsageListForOwner?.map(
              (res) => {
                return <CouponUsageCard res={res} />;
              },
            )}
          </TabPanel>
          <TabPanel value="two">
            <CouponCodeAdminView
              lastWeekData={singleCoupounDetail?.lastWeekDataForAdmin}
            />
            {singleCoupounDetail?.couponcodedataDetails?.couponUsageList?.couponCodeUsageForAdmin?.map(
              (res) => {
                return <CouponUsageCard res={res} />;
              },
            )}
          </TabPanel>
        </TabContext>
      </div>
      {/* <div
        style={{ position: "absolute", top: "90%", left: "90%", zIndex: "100" }}
      > */}
      {/* </div> */}
    </Card>
  );
};

export default CouponTable;
