import { Button } from "@mui/material";
import React from "react";
import {
  changeDateFormateInTwelveHours,
  getTranscationStatus,
} from "../Services/Constants";
import "../CSSFiles/tablecomp.css";
import { useNavigate } from "react-router-dom";

const TableComp = (props) => {
  const navigate = useNavigate();

  const handleNavigate = (data) => {
    navigate(`/transcation/${data?.uid}`, {
      state: { data: data },
    });
  };

  return (
    <div>
      <table className="table_comp">
        <thead>
          <tr className="tablerow">
            {props?.headings?.map((row) => (
              <th>{row}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props?.data?.map((row) => {
            return (
              <tr
                key={row.id}
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigate(row);
                }}
              >
                <td align="center">{row.paymentID}</td>
                <td align="center">{row.paymentAmount}</td>
                <td align="center">{row.customerInformation}</td>
                <td align="center">
                  {changeDateFormateInTwelveHours(row.created_at)}
                </td>
                <td align="center">
                  <Button
                    variant="contained"
                    className={
                      row.status === 2 || row.status === 1
                        ? `trans settled`
                        : `trans failed`
                    }
                  >
                    {getTranscationStatus(row.status)}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableComp;
