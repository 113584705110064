import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell,
} from "recharts";

export default function ServerInfoDetails({ serverInfodetails }) {
  console.log(serverInfodetails, "hu");
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && label === "DB") {
      return (
        <div
          style={{
            padding: "5px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
        >
          <p>{`RDSCpuUtility : ${payload[0].payload.first}`}</p>
          <p>{`RDSFreeSpace : ${payload[0].payload.second}`}</p>
        </div>
      );
    } else if (active && payload && payload.length && label != "DB") {
      return (
        <div
          style={{
            padding: "5px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
        >
          <p>{`Ok : ${payload[0].payload.first}`}</p>
          <p>{`Degraded : ${payload[0].payload.InstancesHealth.Degraded}`}</p>
          <p>{`Info : ${payload[0].payload.InstancesHealth.Info}`}</p>
          <p>{`Pending : ${payload[0].payload.InstancesHealth.Pending}`}</p>
          <p>{`Severe : ${payload[0].payload.InstancesHealth.Severe}`}</p>
          <p>{`Unknown : ${payload[0].payload.InstancesHealth.Unknown}`}</p>
          <p>{`Warning : ${payload[0].payload.InstancesHealth.Warning}`}</p>

          <p>{`Causes : ${payload[0].payload.causesTxt}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="99%" height={300}>
      <BarChart data={serverInfodetails} align="center">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="EnvironmentName" />

        <XAxis
          dataKey="HealthStatus"
          axisLine={false}
          tickLine={false}
          interval={0}
          height={30}
          xAxisId="quarter"
        />

        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="first" name="Ok" fill="green" />

        <Bar dataKey="second" name="Others">
          {" "}
          {serverInfodetails.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.Color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
