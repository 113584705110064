import React, { useEffect } from "react";
import FeedbackComponent from "../../Components/FeedbackComps/FeedbackComponent";
import SidebarComp from "../../Components/Sidebar";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";
import { Fab, Typography } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import { RiAddFill } from "react-icons/ri";

import SidebarMob from "../../Components/SidebarMob";
import { useNavigate } from "react-router-dom";

const Feedback = () => {
  const {
    getFeedBackData,
    updateFeedBackData,
    timetorefresh,
    closeSidebar,
    updatefeedbackControl,
    getfeedbackControl,
    PaidUnpaidfeedbacktabs,
  } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    StartFeedBackPage();
  }, []);

  const StartFeedBackPage = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    const feedbackData = sessionStorage.getItem("feedbackData");
    if (feedbackData) {
      updateFeedBackData();
    }
    getFeedBackData(Data);
    const feedbackControl = sessionStorage.getItem("feedbackControl");
    if (feedbackControl) {
      updatefeedbackControl();
    }
    getfeedbackControl(Data);
  };

  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />

        <FeedbackComponent />
        {(PaidUnpaidfeedbacktabs === "2" || PaidUnpaidfeedbacktabs === "3") && (
          <Fab
            aria-label="add"
            style={{
              margin: 0,
              right: 50,
              bottom: 80,
              position: "fixed",
              zIndex: "1",
              backgroundColor: "#051e34",
              color: "white",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/addFeedback");
            }}
          >
            <RiAddFill size={30} style={{ color: "white" }} />
          </Fab>
        )}
      </div>
    </>
  );
};

export default Feedback;
