import React from "react";

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "../CSSFiles/calendar_comp.css";

function CalendarWithClockComp({ value, onChange }) {
  return (
    <DateTimePicker
      onChange={onChange}
      value={value}
      className="calendar-clock-container"
    />
  );
}

export default CalendarWithClockComp;
