import { Card, CardContent, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import "../../CSSFiles/profile.css";
import {
  changeDateFormate,
  getAccountLevelOfAdmins,
  getAccountStatusOfAdmins,
  getdataEntryPermission,
} from "../../Services/Constants";
import { functionConvertIntegerToArray } from "../LastDaysUsersComp/AdminUserDetailsCard";
import { useAuth } from "../../Providers/UserProvider";
import AdminEditTextDialog from "../LastDaysUsersComp/SelectedUserDetailEdit/AdminEditTextDialog";
import { toast } from "react-toastify";

const ProfileComp = ({ user }) => {
  const { handleLogout } = useAuth();
  const [text, settext] = useState({
    title: "",
    value: "",
  });
  const [openedittextDialog, setopenedittextDialog] = useState(false);

  const [valueId, setvalueId] = useState("");

  const handleClickOpenEdit = () => {
    setopenedittextDialog(true);
  };

  const handleClickClose = () => {
    setopenedittextDialog(false);
  };

  const handleEditText = (title, value, valueId) => {
    settext({
      title: title,
      value: value,
    });
    setvalueId(valueId);
    handleClickOpenEdit();
  };

  return (
    <Card style={{ width: "100%" }} className="card">
      <CardContent style={{ position: "relative" }}>
        <div className="profileContainer">
          <img
            src={user.profileImage}
            alt="ProfileImage"
            className="profileImage"
            onClick={(e) => {
              e.preventDefault();
              handleEditText("Profile Picture", user?.profileImage, "6");
            }}
          />
        </div>

        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">id :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {user?.id}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Uid :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {user?.uid}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">UniqueDeviceId :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {user?.uniqueDeviceId}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            handleEditText("Full Name", user?.fullName, "4");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Full Name :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {user?.fullName}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            handleEditText("Experience", user?.experiance, "5");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Experience :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {user?.experiance}
              </Typography>
            </div>
          </CardContent>
        </Card>

        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">FCM token :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {user?.fcmToken}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Email :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {user?.email}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Account Level :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {getAccountLevelOfAdmins(user?.accountLevel)}
              </Typography>
            </div>
          </CardContent>
        </Card>
        {user?.accountLevel === 3 && (
          <Card
            className="cardsub"
            style={{ marginBottom: "2vh" }}
            onClick={(e) => {
              e.preventDefault();
              toast.error("You dont have access to edit this field");
            }}
          >
            <CardContent>
              <div className="payment_data">
                <Typography className="title">
                  Data Entry Permission :
                </Typography>
                {/*  */}
                {functionConvertIntegerToArray(user?.dataEntryPermission)?.map(
                  (val) => (
                    <Typography
                      className="body"
                      style={{ lineBreak: "anywhere" }}
                    >
                      {getdataEntryPermission(val)}
                    </Typography>
                  ),
                )}
              </div>
            </CardContent>
          </Card>
        )}
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Account Status :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {getAccountStatusOfAdmins(user?.accountStatus)}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className="cardsub"
          style={{ marginBottom: "2vh" }}
          onClick={(e) => {
            e.preventDefault();
            toast.error("You dont have access to edit this field");
          }}
        >
          <CardContent>
            <div className="payment_data">
              <Typography className="title">Account Starting Date :</Typography>
              <Typography className="body" style={{ lineBreak: "anywhere" }}>
                {changeDateFormate(user?.accountStartingDateInFormat)}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <div className="buttonContainer">
          <Button
            variant="outlined"
            className="grammar_buttons"
            style={{ zIndex: "0" }}
            onClick={(e) => {
              e.preventDefault();
              handleLogout();
            }}
          >
            LogOut
          </Button>
        </div>
        <AdminEditTextDialog
          text={text}
          opendialog={openedittextDialog}
          handleClickClose={handleClickClose}
          valueId={valueId}
          type="Admins"
        />
      </CardContent>
    </Card>
  );
};

export default ProfileComp;
