import { Typography } from "@mui/material";
import React from "react";

const AddAndEditClassesDialogBodyTxt = ({
  classFileUrl,
  classTitle,
  classCategory,
  classDuration,
}) => {
  return (
    <div style={{ padding: "2vw" }}>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "5px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="title">Class File Url :-</Typography>
        <Typography className="body">{classFileUrl}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "5px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="title">Class Title :-</Typography>
        <Typography className="body">{classTitle}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "5px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="title">Class Category :-</Typography>
        <Typography className="body">{classCategory}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "5px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="title">Class Duration :-</Typography>
        <Typography className="body">{classDuration}</Typography>
      </div>
    </div>
  );
};

export default AddAndEditClassesDialogBodyTxt;
