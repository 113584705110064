import React, { useState } from "react";
import { Card, CardContent, IconButton, Typography } from "@mui/material";
import "../../CSSFiles/feedbacks.css";
import "../../CSSFiles/notifications.css";
import { changeTimeStampinTwelveHours } from "../../Services/Constants";
import { FiMoreHorizontal } from "react-icons/fi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomDialogBox from "../CustomDialogBox";
import { useAuth } from "../../Providers/UserProvider";

const RawSuggestionFeedback = ({ res }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [opendialog, setopendialog] = useState(false);
  const [filteruniqueId, setfilteruniqueId] = useState("");

  const handleClickOpen = (id) => {
    setopendialog(true);
    setfilteruniqueId(id);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const { handleRawSuggFeedbackActions } = useAuth();

  return (
    <Card className="cardsub" style={{ marginBottom: "2vh" }}>
      <CardContent>
        <div className="rawview1">
          <div className="uidheadings1">
            <Typography className="title">Uid :-</Typography>
            <Typography className="body">{res.uid}</Typography>
          </div>
          <div className="platform">
            <Typography className="title">Platform :-</Typography>
            <Typography className="body">{res.platform}</Typography>
          </div>
          <div className="dateview1">
            <Typography className="title">Time :-</Typography>
            <Typography className="body">
              {changeTimeStampinTwelveHours(res.timeStamp)}
            </Typography>
          </div>
        </div>

        <div className="duplicate">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <FiMoreHorizontal className="duplicate_icon" />
          </IconButton>
        </div>

        <div style={{ marginTop: "10px" }}>
          <Typography className="title" style={{ textAlign: "center" }}>
            {res.userSuggestion}
          </Typography>
        </div>
      </CardContent>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            handleRawSuggFeedbackActions(1, res.uniqueId);
          }}
        >
          Helpful
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            handleRawSuggFeedbackActions(2, res.uniqueId);
          }}
        >
          Not Helpful
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            handleClickOpen(res.uniqueId);
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <CustomDialogBox
        opendialog={opendialog}
        handleClickClose={handleClickClose}
        confirmationText="Are You Sure Want To Delete"
        handleSubmitOfDialog={() => {
          handleRawSuggFeedbackActions(3, filteruniqueId);
        }}
      />
    </Card>
  );
};

export default RawSuggestionFeedback;
