import React, { useState } from "react";
import "../../CSSFiles/tablecomp.css";
import { Button } from "@mui/material";
import ConfirmDialog from "./TerminateServerConfirmDialog";
import { useAuth } from "../../Providers/UserProvider";
import { Alert } from "@mui/lab";
import AddInstanceConfirm from "./AddInstanceConfirm";
import RemoveInstanceConfirm from "./RemoveInstanceConfirm";

const ServerInstances = ({ res, value }) => {
  const [opendialog, setopendialog] = useState(false);
  const [addinstanceopendialog, setaddinstanceopendialog] = useState(false);
  const [removeinstanceopendialog, setremoveinstanceopendialog] =
    useState(false);
  const [partiinstanceid, setinstanceid] = useState("");
  const {
    isterminatesuccess,
    setisterminatesuccess,
    isterminateerror,
    setisterminateerror,
    addinstancesuccess,
    setaddinstancesuccess,
    addinstanceerror,
    setaddinstanceerror,
    removeinstancesuccess,
    setremoveinstancesuccess,
    removeinstanceerror,
    setremoveinstanceerror,
    checkServerLoadOfAParticularServer,
    checkserverstatusclick,
    setcheckserverstatusclick,
    setcheckserverstatuserr,
    checkserverstatuserr,
  } = useAuth();

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const openDialogBox = (id) => {
    setinstanceid(id);
    handleClickOpen();
  };

  const addInstancehandleClickOpen = () => {
    setaddinstanceopendialog(true);
  };

  const addInstancehandleClickClose = () => {
    setaddinstanceopendialog(false);
  };

  const openDialogBoxAddInstance = (id) => {
    addInstancehandleClickOpen();
  };

  const removeInstancehandleClickOpen = () => {
    setremoveinstanceopendialog(true);
  };

  const removeInstancehandleClickClose = () => {
    setremoveinstanceopendialog(false);
  };

  const openDialogBoxremoveInstance = (id) => {
    removeInstancehandleClickOpen();
  };

  const closeDialog = () => {
    setisterminatesuccess(false);
  };

  const closeError = () => {
    setisterminateerror(false);
  };

  const addInstancecloseDialog = () => {
    setaddinstancesuccess(false);
  };

  const addInstancecloseError = () => {
    setaddinstanceerror(false);
  };

  const removeInstancecloseDialog = () => {
    setremoveinstancesuccess(false);
  };

  const removeInstancecloseError = () => {
    setremoveinstanceerror(false);
  };

  const serverStatuscloseDialog = () => {
    setcheckserverstatusclick(false);
  };

  const serverStatuscloseError = () => {
    setcheckserverstatuserr(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          className="trans"
          style={{ margin: "5px" }}
          onClick={(e) => {
            e.preventDefault();
            openDialogBoxAddInstance();
          }}
        >
          Add Instance
        </Button>
        <Button
          variant="contained"
          className="trans"
          style={{ margin: "5px" }}
          onClick={(e) => {
            e.preventDefault();
            openDialogBoxremoveInstance();
          }}
        >
          Remove Instance
        </Button>
        <Button
          variant="contained"
          className="trans"
          style={{ margin: "5px" }}
          onClick={(e) => {
            e.preventDefault();
            if (value === "one") {
              checkServerLoadOfAParticularServer("IELTS_Production");
            } else if (value === "three") {
              checkServerLoadOfAParticularServer("Schedule task");
            } else if (value === "two") {
              checkServerLoadOfAParticularServer("speechtotext");
            }
          }}
        >
          Check Server Load
        </Button>
      </div>
      <table className="table_comp">
        <thead>
          <tr>
            <th>Number</th>
            <th>Instance Id</th>
            <th>Health Status</th>
          </tr>
        </thead>
        <tbody>
          {res.map((row, index) => (
            <tr key={row.InstanceId}>
              <td align="center">{index + 1}</td>
              <td align="center">{row.InstanceId}</td>
              <td align="center">
                <Button
                  variant="contained"
                  className="trans"
                  style={{ background: row?.Color }}
                >
                  {row.HealthStatus}
                </Button>
              </td>
              <td align="center">
                <Button
                  variant="contained"
                  className="trans"
                  style={{ background: "red" }}
                  onClick={(e) => {
                    e.preventDefault();
                    openDialogBox(row?.InstanceId);
                  }}
                >
                  Terminate
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isterminatesuccess && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="success"
            onClose={() => closeDialog()}
            style={{ textAlign: "center" }}
          >
            Terminate Instance Sucessfully
          </Alert>
        </div>
      )}
      {isterminateerror && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="error"
            onClose={() => closeError()}
            style={{ textAlign: "center" }}
          >
            Something went wrong
          </Alert>
        </div>
      )}
      {addinstancesuccess && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="success"
            onClose={() => addInstancecloseDialog()}
            style={{ textAlign: "center" }}
          >
            Added Instance Sucessfully
          </Alert>
        </div>
      )}
      {addinstanceerror && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="error"
            onClose={() => addInstancecloseError()}
            style={{ textAlign: "center" }}
          >
            Something went wrong
          </Alert>
        </div>
      )}
      {removeinstancesuccess && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="success"
            onClose={() => removeInstancecloseDialog()}
            style={{ textAlign: "center" }}
          >
            Removed Instance Sucessfully
          </Alert>
        </div>
      )}
      {removeinstanceerror && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="error"
            onClose={() => removeInstancecloseError()}
            style={{ textAlign: "center" }}
          >
            Something went wrong
          </Alert>
        </div>
      )}
      {checkserverstatusclick && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="success"
            onClose={() => serverStatuscloseDialog()}
            style={{ textAlign: "center" }}
          >
            Server Load Sucessful
          </Alert>
        </div>
      )}
      {checkserverstatuserr && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="error"
            onClose={() => serverStatuscloseDialog()}
            style={{ textAlign: "center" }}
          >
            Something went wrong
          </Alert>
        </div>
      )}
      <ConfirmDialog
        opendialog={opendialog}
        handleClickClose={handleClickClose}
        partiinstanceid={partiinstanceid}
      />
      <AddInstanceConfirm
        opendialog={addinstanceopendialog}
        handleClickClose={addInstancehandleClickClose}
        value={value}
      />
      <RemoveInstanceConfirm
        opendialog={removeinstanceopendialog}
        handleClickClose={removeInstancehandleClickClose}
        value={value}
      />
    </>
  );
};

export default ServerInstances;
