import React from "react";
import TranscationList from "../Components/HomeSubCat/TranscationList";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import UsersSearchField from "../Components/LastDaysUsersComp/UsersSearchField";

const TranscationListScreen = () => {
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div style={{ padding: "15px" }}>
        <FaRegArrowAltCircleLeft
          onClick={(e) => {
            e.preventDefault();
            handleNavigateBack();
          }}
          style={{ cursor: "pointer" }}
          size={45}
        />
      </div>

      <UsersSearchField type="TranscationList" />

      <TranscationList />
    </div>
  );
};

export default TranscationListScreen;
