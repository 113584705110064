import React from "react";
import SidebarComp from "../../Components/Sidebar";
import SidebarMob from "../../Components/SidebarMob";
import Navbar from "../../Components/Navbar/Navbar";
import { useAuth } from "../../Providers/UserProvider";
import AddStoredNotiCard from "../../Components/NotificationsComp/AddStoredNotiCard";

const AddNotiScreen = () => {
  const { closeSidebar } = useAuth();
  return (
    <>
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <AddStoredNotiCard />
      </div>
    </>
  );
};

export default AddNotiScreen;
