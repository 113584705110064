import { Typography } from "@mui/material";
import { getDateString } from "../Services/Constants";

export const AddWritingCategoryDialogBodyTxt = ({ thumbnail, category }) => {
  return (
    <div style={{ padding: "2vw" }}>
      <img src={thumbnail} width="200" height="200" className="thumbnail_img" />
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Category :-</Typography>
        <Typography className="body">{category}</Typography>
      </div>
    </div>
  );
};
