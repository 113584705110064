import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import SidebarComp from "../../Components/Sidebar";
import { useAuth } from "../../Providers/UserProvider";
import UploadvideoFormComp from "../../Components/UploadingVideoFormComp/UploadingvideoFormComp";
import CreateReadingTest from "../../Components/ReadingListComp/CreateReadingTest";

const UploadingReadingFormPage = () => {
  const { closeSidebar } = useAuth();
  return (
    <>
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <CreateReadingTest />
      </div>
    </>
  );
};

export default UploadingReadingFormPage;
