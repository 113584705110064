import { Typography } from "@mui/material";
import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import SidebarComp from "../../Components/Sidebar";
import { useAuth } from "../../Providers/UserProvider";
import ScheduleIeltsClasses from "../../Components/IeltsClassesComp/ScheduleIeltsClasses";
import { useLocation } from "react-router-dom";

const ScheduleClasses = () => {
  const { timetorefresh, closeSidebar } = useAuth();

  const location = useLocation()


  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <ScheduleIeltsClasses classGrpId={location.state.classGrpUniqueId} />
      </div>
    </>
  );
};

export default ScheduleClasses;
