import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core";
import { Typography, IconButton, TextField, Button } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";

import DragAndDropComp from "../UploadingVideoFormComp/DragAndDropComp/DragAndDropComp";
import { useAuth } from "../../Providers/UserProvider";

const useStyles = makeStyles({
    root: {},
    paper: { borderRadius: 5, width: "100%" },
});

const AddClassesGrp = ({
    opendialog,
    handleClickClose,
}) => {
    const classes = useStyles();

    const { addClassGrp } = useAuth()



    const [thumbNailUpload, setthumbNailUpload] = useState({
        preview: "",
        raw: "",
    });

    const [classGroupName, setclassGroupName] = useState("")

    const handleaddClassGrp = () => {
        const Data = JSON.parse(localStorage.getItem("userData"))
        addClassGrp(Data, classGroupName, thumbNailUpload.raw)
    }



    return (
        <Dialog
            open={opendialog}
            onClose={handleClickClose}
            aria-labelledby="customized-dialog-title"
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
        >
            <DialogTitle
                sx={{ m: 0, p: 2 }}
                style={{
                    paddingTop: "5vh",
                }}
            >
                {handleClickClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        style={{ position: "absolute" }}
                    >
                        <AiOutlineCloseCircle />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <div style={{ padding: "2vw" }}>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                        marginBottom: "5px",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                    }}
                >
                    <DragAndDropComp thumbNailUpload={thumbNailUpload}
                        setthumbNailUpload={setthumbNailUpload}
                        type="Image" />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                        marginBottom: "5px",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                        paddingTop: '10px'
                    }}
                >
                    <Typography>Class Group Name</Typography>
                    <TextField value={classGroupName} onChange={(e) => {
                        setclassGroupName(e.target.value)
                    }} />
                </div>
            </div>

            <DialogActions
                style={{ transform: "unset", zIndex: "-1", position: "relative" }}
            >
                <Button
                    variant="outlined"
                    className="grammar_buttons"
                    onClick={(e) => {
                        e.preventDefault();
                        // handleClickOpenConfirm();
                        handleaddClassGrp()
                        handleClickClose()
                    }}
                >
                    Submit
                </Button>

                <Button
                    variant="outlined"
                    className="grammar_buttons"
                    onClick={(e) => {
                        e.preventDefault();
                        handleClickClose();
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
            {/* <ConfirmEditDialog
                open={open}
                handleClose={handleClickCloseConfirm}
                handleClickClose={handleClickClose}

            /> */}
        </Dialog>
    );
};

export default AddClassesGrp;
