import React from "react";
import "../CSSFiles/login.css";
import { useAuth } from "../Providers/UserProvider";
import { signInWithGoogle } from "../Services/Firebase";
import axios from "axios";
import { api_url } from "../Services/Constants";
import { useNavigate } from "react-router-dom";

const LogInButton = () => {
  const { createjwt } = useAuth();
  let navigate = useNavigate();

  return (
    <div
      className="google-btn"
      onClick={(e) => {
        signInWithGoogle()
          .then((user) => {
            console.log(user);
            const jwt = createjwt(
              user.user.uid,
              user._tokenResponse.email,
              user.user.metadata.lastLoginAt,
              Date.now(),
            );
            console.log(jwt);
            var bodyFormData = new FormData();
            bodyFormData.append("encrptData", jwt);

            axios({
              method: "post",
              url: `${api_url}/adminLogin/`,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then((res) => {
                console.log(res, "response");
                if (res.status === 200) {
                  const data = JSON.stringify(res.data.data);
                  localStorage.setItem("userData", data);

                  navigate("/waiting");
                } else {
                  console.log("The status is not 200");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      }}
      style={{ cursor: "pointer" }}
    >
      <div className="google-icon-wrapper">
        <img
          className="google-icon"
          src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
          alt="google-icon"
        />
      </div>
      <p className="google-icon-text">Sign in with google</p>
    </div>
  );
};

export default LogInButton;
