import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core";
import { Typography, IconButton, TextField, Button } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Select, MenuItem } from "@mui/material";
import ConfirmEditDialog from "./ConfirmEditDialog";

const useStyles = makeStyles({
  root: {},
  paper: { borderRadius: 5, width: "100%" },
});

const EditText = ({ opendialog, handleClickClose, text, valueId, type }) => {
  const classes = useStyles();

  const [value, setValue] = useState(text.value);
  const [open, setopen] = useState(false);

  const [age, setAge] = useState(text.value);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClickOpenConfirm = () => {
    setopen(true);
  };

  const handleClickCloseConfirm = () => {
    setopen(false);
  };

  useEffect(() => {
    setValue(text.value);
    setAge(text.value);
  }, [text.value]);

  return (
    <Dialog
      open={opendialog}
      onClose={handleClickClose}
      aria-labelledby="customized-dialog-title"
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
          paddingTop: "5vh",
        }}
      >
        {handleClickClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClickClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            style={{ position: "absolute" }}
          >
            <AiOutlineCloseCircle />
          </IconButton>
        ) : null}
      </DialogTitle>
      <div style={{ padding: "2vw" }}>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "5px",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography className="title">{text.title}</Typography>
          {valueId === "5" && (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={"basic"}>basic</MenuItem>
              <MenuItem value={"advance"}>advance</MenuItem>
            </Select>
          )}
          {valueId !== "5" && (
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          )}
        </div>
      </div>

      <DialogActions>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickOpenConfirm();
            // handleClickClose()
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <ConfirmEditDialog
        open={open}
        handleClose={handleClickCloseConfirm}
        handleClickClose={handleClickClose}
        text={valueId === "5" ? age : value}
        title={text.title}
        valueId={valueId}
        type={type}
      />
    </Dialog>
  );
};

export default EditText;
