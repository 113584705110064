import React from "react";
import ButtonComp from "../../Components/CustomButtons/ButtonComp";

const AccountStatusHome = () => {
  return (
    <div className="centerDiv">
      <div>
        <h1 className="heading">BabyCode</h1>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <h4 style={{ marginTop: "0" }} className="secondheading">
            Admin Website
          </h4>
        </div>
        <div style={{ marginTop: "15px" }}>
          <ButtonComp buttonText="Check Account Status" />
        </div>
      </div>
    </div>
  );
};

export default AccountStatusHome;
