import React, { useState } from "react";
import SidebarComp from "../../Components/Sidebar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import ProdServer from "../../Components/ServerInfoDetails/ProdServer";
import ScheduleServer from "../../Components/ServerInfoDetails/ScheduleServer";
import SpeechToTextServer from "../../Components/ServerInfoDetails/SpeechToText";
import ServerInstances from "../../Components/ServerInfoDetails/ServerInstances";
import { useAuth } from "../../Providers/UserProvider";
import { Card, CardContent, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../../CSSFiles/serverinfo.css";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customButtonBaseRoot: {
    fontFamily: "Comfortaa",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      padding: "0px 2px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "12px",
      padding: "0px 6px",
    },
  },
}));

const ServerScreen = () => {
  const {
    serverInstancedetails,
    prodserverdetails,
    scheduleserverdetails,
    speechTotextserverdetails,
    closeSidebar,
    setserverInstancedetails,
    timetorefresh,
  } = useAuth();

  const theme = createTheme({
    components: {
      MuiTabPanel: {
        styleOverrides: { root: { padding: 5 } },
      },
    },
  });

  const classes = useStyles();

  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    setserverInstancedetails([]);
    setValue(newValue);
  };
  return (
    <div className="scrolling">
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "5",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}

        <SidebarComp />
        <div
          style={{
            width: "100%",
            height: "100vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          className="second_screen"
        >
          <ThemeProvider theme={theme}>
            <TabContext value={value}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                centered
              >
                <Tab
                  value="one"
                  label="IELTS-Production-env"
                  classes={{ root: classes.customButtonBaseRoot }}
                />
                <Tab
                  value="two"
                  label="Speechtotext-env"
                  classes={{ root: classes.customButtonBaseRoot }}
                />
                <Tab
                  value="three"
                  label="Scheduletask-env"
                  classes={{ root: classes.customButtonBaseRoot }}
                />
              </Tabs>
              <TabPanel value="one">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0",
                  }}
                >
                  <ProdServer />
                </div>
                <Card className="cardsub" style={{ marginBottom: "2vh" }}>
                  <CardContent style={{ paddingBottom: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "13px",
                      }}
                    >
                      <Typography className="title">Causes :</Typography>
                      <Typography className="body">
                        {prodserverdetails[0]?.causesTxt}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <ServerInstances res={serverInstancedetails} value="one" />
              </TabPanel>
              <TabPanel value="two">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SpeechToTextServer />
                </div>
                <Card className="cardsub" style={{ marginBottom: "2vh" }}>
                  <CardContent style={{ paddingBottom: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "13px",
                      }}
                    >
                      <Typography className="title">Causes :</Typography>
                      <Typography className="body">
                        {speechTotextserverdetails[0]?.causesTxt}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <ServerInstances res={serverInstancedetails} value="two" />
              </TabPanel>
              <TabPanel value="three">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ScheduleServer />
                </div>
                <Card className="cardsub" style={{ marginBottom: "2vh" }}>
                  <CardContent style={{ paddingBottom: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "13px",
                      }}
                    >
                      <Typography className="title">Causes :</Typography>
                      <Typography className="body">
                        {scheduleserverdetails[0]?.causesTxt}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <ServerInstances res={serverInstancedetails} value="three" />
              </TabPanel>
            </TabContext>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default ServerScreen;
