import { Button, Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import SelectOptions from "../NotificationsComp/SelectOptions";
import { useAuth } from "../../Providers/UserProvider";
import CustomDialogBox from "../CustomDialogBox";
import AddAndEditClassesDialogBodyTxt from "../../MiddleWares/AddAndEditClassesDialogBodyTxt";

const AddIeltsClasses = ({ classGrpId }) => {
  const { selectclassesCategory, addClassesData } = useAuth();
  const [classFileUrl, setclassFileUrl] = useState("");
  const [classTitle, setclassTitle] = useState("");
  const [storedcategoryvalue, setstoredcategoryvalue] = useState("");

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const handleChangeCategoryValue = (e) => {
    setstoredcategoryvalue(e);
  };
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const handleSubmitOfDialog = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    addClassesData(
      userData,
      classFileUrl,
      classTitle,
      storedcategoryvalue.label,
      hours + " hr " + minutes + " min",
      classGrpId
    );
  };

  const handleHoursChange = (event) => {
    const newHours = parseInt(event.target.value, 10);
    setHours(isNaN(newHours) ? 0 : newHours);
  };

  const handleMinutesChange = (event) => {
    const newMinutes = parseInt(event.target.value, 10);
    setMinutes(isNaN(newMinutes) ? 0 : newMinutes);
  };
  return (
    <Card className="card">
      <CardContent>
        <div className="main_con">
          <textarea
            rows="1"
            className="grammar_text_area"
            placeholder="Enter Class File Url here"
            value={classFileUrl}
            onChange={(e) => {
              setclassFileUrl(e.target.value);
            }}
          />
        </div>
        <div className="main_con" style={{ marginTop: "2vh" }}>
          <textarea
            rows="1"
            className="grammar_text_area"
            placeholder="Enter Class Title here"
            value={classTitle}
            onChange={(e) => {
              setclassTitle(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
          }}
        >
          <Typography className="teachertitle">Class Category</Typography>

          <SelectOptions
            options={selectclassesCategory}
            type="ieltsclasses"
            value={storedcategoryvalue}
            handleFunction={handleChangeCategoryValue}
          />
        </div>
        <Typography className="teachertitle">Class Duration</Typography>
        <div className="duration-input">
          <input
            type="number"
            min="0"
            value={hours}
            onChange={handleHoursChange}
          />
          <span> hours </span>
          <input
            type="number"
            min="0"
            max="59"
            value={minutes}
            onChange={handleMinutesChange}
          />
          <span> minutes</span>
        </div>
        <div style={{ textAlign: "center", transform: "unset" }}>
          <Button
            variant="outlined"
            className="grammar_buttons"
            style={{ zIndex: "0" }}
            onClick={(e) => {
              e.preventDefault();
              handleClickOpen();
            }}
          >
            Add Class
          </Button>
        </div>
        <CustomDialogBox
          opendialog={opendialog}
          handleClickClose={handleClickClose}
          bodyText={
            <AddAndEditClassesDialogBodyTxt
              classFileUrl={classFileUrl}
              classCategory={storedcategoryvalue.label}
              classTitle={classTitle}
              classDuration={hours + " hr " + minutes + " min"}
            />
          }
          confirmationText="Are You Sure Want To Edit These Changes"
          handleSubmitOfDialog={handleSubmitOfDialog}
        />
      </CardContent>
    </Card>
  );
};

export default AddIeltsClasses;
