import { makeStyles } from "@material-ui/core";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import PaidPageFeedbacks from "./PaidPage";
import TotalSuggestionCard from "./TotalSuggestionCard";
import FeedbackControlsCard from "./FeedbackControlsCard";
import { useAuth } from "../../Providers/UserProvider";

const useStyles = makeStyles((theme) => ({
  tabPanelRoot: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  tabHeadings: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },
}));

const PaidUserFeedBack = (props) => {
  const classes = useStyles();

  const { PaidUnpaidfeedbacktabs, PaidUnpaidsetfeedbacktabs } = useAuth();

  var styles = {
    root: {
      position: "sticky",
      top: 0,
      background: "#EDF3E0",
      zIndex: "100",
    },
    secondFilter: {
      marginTop: "10px",
    },
  };

  const handleChange = (e, newValue) => {
    PaidUnpaidsetfeedbacktabs(newValue);
  };

  return (
    <TabContext value={PaidUnpaidfeedbacktabs}>
      <Tabs value={PaidUnpaidfeedbacktabs} onChange={handleChange} centered>
        <Tab label={`FeedBacks`} value="1" className={classes.tabHeadings} />
        <Tab
          //   label={`UnpaidUser Feedback (${feedbacks?.unpaidUserFeedbackFetch?.length})`}
          label={props.tab2label}
          value="2"
          className={classes.tabHeadings}
        />
        <Tab
          //   label={`user who left app feedback (${feedbacks?.userFeedbackWhoLeaveApp?.length})`}
          label={props.tab3label}
          value="3"
          className={classes.tabHeadings}
        />
      </Tabs>
      <TabPanel value="1" className={classes.tabPanelRoot}>
        <div className="feedback_scroll">
          {props?.feedbacks?.map(
            (res, key) =>
              (res.questionType === "optionalFeedback" ||
                res.questionType === "YesNo") && (
                <PaidPageFeedbacks res={res} key={key} />
              ),
          )}
          {props?.feedbacks?.map(
            (res, key) =>
              res.questionType === "Suggestion" && (
                <>
                  <TotalSuggestionCard res={res} key={key} />
                </>
              ),
          )}
        </div>
      </TabPanel>
      <TabPanel value="2" className={classes.tabPanelRoot}>
        {props?.feedbackControlData?.map((res) => {
          return <FeedbackControlsCard res={res} statusType="Active" />;
        })}
      </TabPanel>
      <TabPanel value="3" className={classes.tabPanelRoot}>
        {props?.InactivefeedbackControlData?.map((res) => {
          return <FeedbackControlsCard res={res} statusType="NotActive" />;
        })}
      </TabPanel>
    </TabContext>
  );
};

export default PaidUserFeedBack;
