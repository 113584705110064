import { Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import "../../CSSFiles/notifications.css";
import { BiMessageDetail } from "react-icons/bi";
import { FiMoreHorizontal } from "react-icons/fi";
import { useAuth } from "../../Providers/UserProvider";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomDialogBox from "../CustomDialogBox";
import { categoryOptions, statusOptions } from "./AddStoredNotiCard";

const StoredNotiCard = ({ res, data, side }) => {
  const {
    setstorednotititle,
    setstorednotidesc,
    setstoredcategoryvalue,
    setstoredstatusvalue,
    NotificationStoredUpdate,
  } = useAuth();
  const navigate = useNavigate();
  const StatusFormate = (status) => {
    return status === 1 ? `Active` : `Not Active`;
  };

  const setCategoryOptionsAsObjs = (category) => {
    if (category === "Morning") return categoryOptions[0];
    else if (category === "Brunch") return categoryOptions[1];
    else if (category === "Afternoon") return categoryOptions[2];
    else if (categoryOptions === "PaidUser") return categoryOptions[3];
    else if (categoryOptions === "EveningUnpaid") return categoryOptions[4];
    else if (categoryOptions === "Night") return categoryOptions[5];
    else {
      const obj = { value: 100, label: category };
      return obj;
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const handleUpdate = (option) => {
    NotificationStoredUpdate(Number(option), res?.uniqueId, data, side);
    // console.log(data);
  };

  const handleSubmitOfDialog = () => {
    handleUpdate(0);
  };

  return (
    <Card className="cardsub" style={{ marginBottom: "2vh", marginTop: "2vh" }}>
      <CardContent className="noticards">
        {/* Get the first para */}
        <div>
          <div className="gridingstored">
            <div className="first">
              <div>
                <BiMessageDetail size={25} />
              </div>
              <div>
                <Typography className="title resp">{res.title}</Typography>
                <Typography className="body resp">{res.body}</Typography>
              </div>
            </div>
            <div className="second"></div>
            <div className="third">
              <Typography className="title resp">
                {StatusFormate(res.status)}
              </Typography>
              <Typography className="body resp">{res.category}</Typography>
            </div>
          </div>
        </div>
        {/* Get the second para */}
        <div className="duplicate">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <FiMoreHorizontal className="duplicate_icon" />
          </IconButton>
        </div>
        {/* <div className="bottomhalf">
          <div className="totalnoti">
            <Typography className="title resp">Total Notifications</Typography>
            <Typography className="body resp">
              {res.totalNotificationSend}
            </Typography>
          </div>
          <div className="succesnoti">
            <Typography className="title resp">
              Succesfully Delivered
            </Typography>
            <Typography className="body resp">
              {res.sucessfullyDelivered}
            </Typography>
          </div>
          <div className="userinterac">
            <Typography className="title resp">User Interactions</Typography>
            <Typography className="body resp">{res.userEngagement}</Typography>
          </div>
          <div className="enddatetime">
            <Typography className="title resp">End Time</Typography>
            <Typography className="body resp">
              {changeDateFormate(res.notificationEndTime)}
            </Typography>
          </div>
          <div className="category">
            <Typography className="title resp">Category</Typography>
            <Typography className="body resp">{res.category}</Typography>
          </div>
        </div> */}
      </CardContent>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            setstorednotititle(res?.title);
            setstorednotidesc(res?.body);
            var dataobj = setCategoryOptionsAsObjs(res?.category);
            setstoredcategoryvalue(dataobj);
            var statusobj;
            statusobj = res?.status === 1 ? statusOptions[1] : statusOptions[0];
            setstoredstatusvalue(statusobj);
            navigate("/addnotiscreen", {
              state: {
                txt: "Edit Notification",
                notiuniqueId: res?.uniqueId,
                type: "Edit",
              },
            });
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            handleClickOpen();
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            handleUpdate(1);
          }}
        >
          Activate
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            handleUpdate(2);
          }}
        >
          Deactivate
        </MenuItem>
      </Menu>
      <CustomDialogBox
        opendialog={opendialog}
        handleClickClose={handleClickClose}
        confirmationText="Are You Sure Want To Delete"
        handleSubmitOfDialog={handleSubmitOfDialog}
      />
    </Card>
  );
};

export default StoredNotiCard;
