import React, { useEffect, useState } from "react";
import ButtonComp from "../Components/CustomButtons/ButtonComp";
import { useAuth } from "../Providers/UserProvider";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const WaitingPage = () => {
  const {
    handlecheckAccountAccessibility,
    checkingStatus,
    checkingTextMsgCode,
  } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    handleClickEvent();
  }, []);

  const handleClickEvent = () => {
    const userData = localStorage.getItem("userData");
    handlecheckAccountAccessibility(JSON.parse(userData));
  };

  // console.log(checkingStatus)

  return (
    <>
      {" "}
      <div className="centerDiv">
        {checkingStatus === true && (
          <Typography
            style={{
              margin: 0,
              top: 15,
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
            }}
          >
            Checking...
          </Typography>
        )}
        <div>
          {checkingTextMsgCode === 0 && (
            <>
              <h1>Checking Your Account Status Level</h1>
            </>
          )}
          {checkingTextMsgCode === 1 && (
            <>
              <h1>
                Your Account is in <span>waiting</span> stage
              </h1>
            </>
          )}
          {checkingTextMsgCode === 2 && (
            <>
              <h1>You have not been provided access</h1>
            </>
          )}
          <div>
            <h4 style={{ marginTop: "0", fontSize: "25px" }}>
              Please wait or contact the admin
            </h4>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="bar"></div>
          </div>
          <div style={{ marginTop: "15px" }}>
            <ButtonComp
              buttonText="Refresh"
              handleClick={() => {
                handleClickEvent();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitingPage;
