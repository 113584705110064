import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const PayOutsAttemptedByUser = ({ res }) => {
  return (
    <Card
      className="appuserdetailscard"
      style={{ marginBottom: "2vh", cursor: "pointer" }}
    >
      <CardContent>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">Platform :-</Typography>
            <Typography className="body">{res?.Platform}</Typography>
          </div>

          <div className="text_value">
            <Typography className="title">Amount :-</Typography>
            <Typography className="body">{res?.amount}</Typography>
          </div>
          <div className="text_value">
            <Typography className="title">CouponCode :-</Typography>
            <Typography className="body">{res?.CouponCode}</Typography>
          </div>
        </div>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">Mode :-</Typography>
            <Typography className="body">{res?.mode}</Typography>
          </div>
          <div className="text_value">
            <Typography className="title">Event :-</Typography>
            <Typography className="body">{res?.event}</Typography>
          </div>
          <div className="text_value">
            <Typography className="title">NowUserWalletAmount :-</Typography>
            <Typography className="body">{res?.nowUserWalletAmount}</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PayOutsAttemptedByUser;
