import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogIn from "./Pages/LoginPage";
import AuthContextProvider from "./Providers/UserProvider";
import Home from "./Pages/Home";
import Notifications from "./Pages/UsersInteractions/Notifications";
import UserBehaviour from "./Pages/UsersAnalytics/UserBehaviour";
import WaitingPage from "./Pages/LoadingPage";
import Feedback from "./Pages/UsersInteractions/Feedback";
import SugggestionPage from "./Pages/SuggestionPage";
import CouponCode from "./Pages/AppControl/CouponCode";
import LandingPage from "./Pages/LandingPage";
import PayMentControl from "./Pages/AppControl/PayMentControl";
import SpeakingTestControl from "./Pages/AppControl/SpeakingTestControl";
import BandCalculation from "./Pages/AppControl/BandCalculation";
import ErrorLog from "./Pages/AppControl/ErrorLog";
import ListeningTest from "./Pages/DataEntry/ListeningTest";
import Users from "./Pages/UsersAnalytics/Users";
import UsersDetailPage from "./Pages/UsersAnalytics/UsersDetailPage";
import GrammarMistaken from "./Pages/TestingCoreTech/GrammarMistakenPage";
import CashBookScreen from "./Pages/CashBookScreen";
import ServerScreen from "./Pages/ServerScreen/ServerScreen";
import CustomNotiScreen from "./Pages/UsersInteractions/CustomNotiScreen";
import TranscationListMob from "./Pages/MobileScreen/TranscationListMob";
import TranscationListScreen from "./Pages/TranscationListScreen";
import AllCompMob from "./Pages/MobileScreen/SeeMoreScreens/AllCompMob";
import AndroidCompMob from "./Pages/MobileScreen/SeeMoreScreens/AndroidCompMob";
import IosCompMob from "./Pages/MobileScreen/SeeMoreScreens/IosCompMob";
import CombinedCompMob from "./Pages/MobileScreen/SeeMoreScreens/CombinedCompMob";
import DetailedCoupon from "./Components/CouponComp/DetailedCoupon";
import CouponSpendDetail from "./Pages/CouponSpendDetail/CouponSpendDetail";
import CouponDetailsEdit from "./Pages/CouponDetailsEdit";
import UsersDetailsEdit from "./Pages/UsersDetailsEdit";
import TranscationListDetail from "./Pages/TranscationListDetail";
import AddNotiScreen from "./Pages/UsersInteractions/AddNotiScreen";
import AdminUsers from "./Pages/UsersAnalytics/AdminUsers";
import AdminUserDetailsPage from "./Pages/UsersAnalytics/AdminUserDetailPage";
import AddEditFeedbacksScreen from "./Pages/UsersInteractions/AddEditFeedbacksScreen";
import EditFeedbackScreen from "./Pages/UsersInteractions/EditFeedbackScreen";
import UploadingVideoFormPage from "./Pages/UploadingVideoFormPage/UploadingVideoFormPage";
import Reading from "./Pages/DataEntry/Reading";
import UploadingReadingFormPage from "./Pages/UploadingVideoFormPage/UploadingReadingFormPage";
import AccountStatusHome from "./Pages/AccountStatusWaiting/AccountStatusHome";
import Speaking from "./Pages/DataEntry/Speaking";
import Writing from "./Pages/DataEntry/Writing";
import PaymentBehaviourDetails from "./Pages/PaymentBehaviourDetailPage/PaymentBehaviourDetails";
import Toast from "./MiddleWares/Toast";
import ListeningTestDetailsComp from "./Components/ListeningTestComp/ListeningTestDetails/ListeningTestDetailsComp";
import ReadingTestDetailsComp from "./Components/ReadingListComp/ReadingTestDetails/ReadingTestDetailsComp";
import SpeakingTestDetailsComp from "./Components/SpeakingQuesComp/SpeakingTestDetailsComp/SpeakingTestComp";
import UploadSpeakingQues from "./Pages/UploadingVideoFormPage/UpoloadingSpeakingQuesPage";
import ProfilePage from "./Pages/ProfilePage";
import IeltsClasses from "./Pages/TeachersPage/IeltsClasses";
import IeltsClassDetailCard from "./Components/IeltsClassesComp/IeltsClassDetails/IeltsClassDetailCard";
import AddClasses from "./Pages/TeachersPage/AddClasses";
import ScheduleClasses from "./Pages/TeachersPage/ScheduleClasses";
import ClassesDetailsEdit from "./Pages/TeachersPage/ClassesEditPages/ClassesDetailsEdit";
import WritingDetailComp from "./Components/WritingTestComp/WritingDetailComp";
import QuestionsWritingSubCategory from "./Pages/UploadingVideoFormPage/QuestionsWritingSubCategory";
import DetailedQuestionsWritingSubCategory from "./Pages/UploadingVideoFormPage/DetailedQuestionsWritingSubCategory";
import IeltsClassesGrp from "./Pages/TeachersPage/IeltsClassesGrp";
function App() {
  const [width, setwidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <div className="App">
      <BrowserRouter>
        <Toast />

        <AuthContextProvider>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/waiting" element={<WaitingPage />} />

            <Route path="/home" element={<Home />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/userbehaviour" element={<UserBehaviour />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/feedbackdetails" element={<SugggestionPage />} />
            <Route path="/couponcode" element={<CouponCode />} />
            <Route path="/couponcodedetails" element={<DetailedCoupon />} />
            <Route path="/paymentcontrol" element={<PayMentControl />} />
            <Route
              path="/speakingtestcontrol"
              element={<SpeakingTestControl />}
            />
            <Route path="/bandcalculation" element={<BandCalculation />} />
            <Route path="/errorlog" element={<ErrorLog />} />
            <Route path="/listeningtest" element={<ListeningTest />} />
            <Route path="/users" element={<Users />} />
            <Route path="/usersDetails" element={<UsersDetailPage />} />
            <Route path="/grammarmistaken" element={<GrammarMistaken />} />
            <Route path="/cashbook" element={<CashBookScreen />} />
            <Route path="/serverdetails" element={<ServerScreen />} />
            <Route path="/customnoti" element={<CustomNotiScreen />} />

            <Route
              path="/transcationlist"
              element={
                width < 660 ? <TranscationListMob /> : <TranscationListScreen />
              }
            />
            <Route path="/allusersmobseeall" element={<AllCompMob />} />
            <Route path="/androidusersmobseeall" element={<AndroidCompMob />} />
            <Route path="/iosusersmobseeall" element={<IosCompMob />} />
            <Route
              path="/combinedusersmobseeall"
              element={<CombinedCompMob />}
            />
            <Route path="/couponspendDetails" element={<CouponSpendDetail />} />
            <Route path="/couponeditdetails" element={<CouponDetailsEdit />} />
            <Route path="/userseditdetails" element={<UsersDetailsEdit />} />
            <Route
              path="/transcation/:detail"
              element={<TranscationListDetail />}
            />
            <Route path="/addnotiscreen" element={<AddNotiScreen />} />
            <Route path="/adminUsers" element={<AdminUsers />} />
            <Route
              path="/adminUserDetails"
              element={<AdminUserDetailsPage />}
            />
            <Route path="/addFeedback" element={<AddEditFeedbacksScreen />} />
            <Route path="/editFeedback" element={<EditFeedbackScreen />} />
            <Route path="/uploadTest" element={<UploadingVideoFormPage />} />
            <Route path="/reading" element={<Reading />} />
            <Route path="/speaking" element={<Speaking />} />
            <Route path="/writing" element={<Writing />} />
            <Route
              path="/uploadreadingTest"
              element={<UploadingReadingFormPage />}
            />
            <Route path="/checkAccountStatus" element={<AccountStatusHome />} />
            <Route
              path="/paymentBehaviourDetails"
              element={<PaymentBehaviourDetails />}
            />
            <Route
              path="/listeningdata/:detail"
              element={<ListeningTestDetailsComp />}
            />
            <Route
              path="/readingdata/:detail"
              element={<ReadingTestDetailsComp />}
            />
            <Route
              path="/speakingdata/:type/:detail"
              element={<SpeakingTestDetailsComp />}
            />
            <Route
              path="/uploadspeakingTest"
              element={<UploadSpeakingQues />}
            />
            <Route path="/profilePage" element={<ProfilePage />} />
            <Route path="/ieltsClasses/:classGrp" element={<IeltsClasses />} />
            <Route path="/addieltsClasses" element={<AddClasses />} />
            <Route path="/scheduleieltsClasses" element={<ScheduleClasses />} />
            <Route
              path="/class/:category/:detail"
              element={<IeltsClassDetailCard />}
            />
            <Route
              path="/:type/editClass/:uniqueId"
              element={<ClassesDetailsEdit />}
            />
            <Route
              path="/writingdata/:uniqueId"
              element={<WritingDetailComp />}
            />
            <Route
              path="/writingdata/:subcategory/questions"
              element={<QuestionsWritingSubCategory />}
            />
            <Route
              path="/writingdata/questionDetails"
              element={<DetailedQuestionsWritingSubCategory />}
            />
            <Route path="/ieltsClasses" element={<IeltsClassesGrp />} />
          </Routes>
        </AuthContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
