import React, { useEffect } from "react";
import CouponTable from "./CouponTable";
import { Typography } from "@mui/material";
import { useAuth } from "../../Providers/UserProvider";
import Navbar from "../Navbar/Navbar";
import SidebarComp from "../Sidebar";
import SidebarMob from "../SidebarMob";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Fab } from "@mui/material";

const DetailedCoupon = () => {
  const { timetorefresh, closeSidebar, handleCouponDetail } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    startCouponCodeDetail();
  }, []);

  const startCouponCodeDetail = () => {
    const couponCodeId = sessionStorage.getItem("selectedCouponId");
    if (couponCodeId) {
      handleCouponDetail(JSON.parse(couponCodeId));
    }
  };

  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <CouponTable />
        <Fab
          aria-label="add"
          style={{
            margin: 0,
            right: 50,
            bottom: 80,
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#051e34",
            color: "white",
          }}
          onClick={() => {
            navigate("/couponeditdetails");
          }}
        >
          <MdModeEdit size={28} style={{ color: "white" }} />
        </Fab>
      </div>
    </>
  );
};

export default DetailedCoupon;
