import React from "react";
import "../../CSSFiles/notifications.css";
import { Card } from "@mui/material";
import { useAuth } from "../../Providers/UserProvider";
import CouponCard from "./CouponCard";
import { Circles } from "react-loader-spinner";
import UsersSearchField from "../LastDaysUsersComp/UsersSearchField";

const CouponComponent = () => {
  const { couponcodedata, onClickNavLoader } = useAuth();

  return (
    <>
      <Circles
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="circles-loading"
        wrapperStyle={{
          position: "absolute",
          zIndex: "1",
          top: "50%",
          left: "50%",
          color: "#0081CF",
          transform: "initial",
        }}
        wrapperClass=""
        visible={onClickNavLoader}
      />
      <Card
        style={{ width: "100%" }}
        className={onClickNavLoader ? `card invisible` : `card`}
      >
        <UsersSearchField type="CouponUsers" />

        {couponcodedata &&
          couponcodedata?.map((res, key) => <CouponCard key={key} res={res} />)}
      </Card>
    </>
  );
};

export default CouponComponent;
