import React from "react";
import { Fab, Typography } from "@material-ui/core"; // Assuming you're using Material-UI
import "../../../CSSFiles/listening.css"; // Import the CSS file we created
import {
  changeDateFormate,
  getTypeOfReadingQues,
} from "../../../Services/Constants";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Providers/UserProvider";

const IeltsClassDetailFeature = ({ data, type }) => {
  // ... Your component code ...

  const navigate = useNavigate();

  return (
    <div className="course-details-container">
      <div className="detail-row">
        <Typography className="detail-title">Class Unique Id:</Typography>
        <Typography className="detail-body">{data.classUniqueId}</Typography>
      </div>
      <div className="detail-row">
        <Typography className="detail-title">Category:</Typography>
        <Typography className="detail-body">{data.category}</Typography>
      </div>
      <div className="detail-row">
        <Typography className="detail-title">Class Number:</Typography>
        <Typography className="detail-body">{data.classNumber}</Typography>
      </div>
      <div className="detail-row">
        <Typography className="detail-title">Class Title:</Typography>
        <Typography className="detail-body">{data.classTitle}</Typography>
      </div>
      <div className="detail-row">
        <Typography className="detail-title">Teacher Name:</Typography>
        <Typography className="detail-body">{data.teacherName}</Typography>
      </div>
      <div className="detail-row">
        <Typography className="detail-title">Teacher Experience:</Typography>
        <Typography className="detail-body">
          {data.teacherExperiance}
        </Typography>
      </div>
      <div className="detail-row">
        <Typography className="detail-title">Rating:</Typography>
        <Typography className="detail-body">{data.rating}</Typography>
      </div>
      {type === "Live" && (
        <div className="detail-row">
          <Typography className="detail-title">Class Duration:</Typography>
          <Typography className="detail-body">{data.classDuration}</Typography>
        </div>
      )}

      {type === "Schedule" && (
        <div className="detail-row">
          <Typography className="detail-title">Starting Time:</Typography>
          <Typography className="detail-body">
            {changeDateFormate(data?.classStatingTime)}
          </Typography>
        </div>
      )}

      {type === "Schedule" && (
        <div className="detail-row">
          <Typography className="detail-title">Ending Time:</Typography>
          <Typography className="detail-body">
            {changeDateFormate(data?.classEndTIme)}
          </Typography>
        </div>
      )}

      <div className="detail-row">
        <Typography className="detail-title">Created At:</Typography>
        <Typography className="detail-body">
          {changeDateFormate(data.created_at)}
        </Typography>
      </div>
      <div className="detail-row">
        <Typography className="detail-title">Updated At:</Typography>
        <Typography className="detail-body">
          {changeDateFormate(data.updated_at)}
        </Typography>
      </div>
      <Fab
        aria-label="add"
        style={{
          margin: 0,

          position: "absolute",
          top: "70%",
          left: "90%",
          zIndex: "1",
          backgroundColor: "#051e34",
          color: "white",
        }}
        onClick={(e) => {
          e.preventDefault();
          navigate(`/${type}/editClass/${data.classUniqueId}`, {
            state: {
              data: data,
              type: type,
            },
          });
        }}
      >
        <MdModeEdit size={28} style={{ color: "white" }} />
      </Fab>
    </div>
  );
};

export default IeltsClassDetailFeature;
