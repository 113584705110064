import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { AiFillStar } from "react-icons/ai";

const Ratings = ({ data }) => {
  console.log(data);
  return (
    <div className="course-details-container">
      <div className="detail-row">
        <Typography className="detail-title">Unique Id:</Typography>
        <Typography className="detail-body">{data.uid}</Typography>
      </div>

      <div className="detail-row">
        <Typography className="detail-title">Rating:</Typography>
        <Typography className="detail-body">{data?.rating}</Typography>
      </div>

      <div className="detail-row">
        <Typography className="detail-title">Feedback:</Typography>
        <Typography className="detail-body">{data?.feedback}</Typography>
      </div>
    </div>
  );
};

export default Ratings;
