import { Card, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../Providers/UserProvider";
import CashBookCard from "./CashBookCard";
import "../../CSSFiles/cashbook.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import CreditCashBookTab from "./CreditCashBookTab";
import DebitCashBookTab from "./DebitCashBookTab";
import AllCashBookTab from "./AllCashBookTab";

const CashBookComp = ({ handleFunction }) => {
  const {
    cashbooktranscation,
    totalExpense,
    expensesdata,
    getCashBookCredit,
    getCashBookDebit,
    getCashBook,
  } = useAuth();

  const handleClick = () => {
    handleFunction();
  };

  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "one") {
      const userData = localStorage.getItem("userData");
      if (userData) {
        getCashBook(userData);
      }
    }
    if (newValue === "two") {
      const userData = localStorage.getItem("userData");
      if (userData) {
        getCashBookCredit(userData);
      }
    }
    if (newValue === "three") {
      const userData = localStorage.getItem("userData");
      if (userData) {
        getCashBookDebit(userData);
      }
    }
  };

  return (
    <Card className="card">
      <div className="expenses_details">
        <div className="expense_cont">
          <div className="expenses_grids">
            <Typography className="cashbook_title">
              Total Expense :-{" "}
            </Typography>
            <Typography className="cashbook_body">
              {Number(totalExpense).toLocaleString("en-IN")}
            </Typography>
          </div>
          <div className="expenses_grids">
            <Typography className="cashbook_title">
              Company Expense :-{" "}
            </Typography>
            <Typography className="cashbook_body">
              {Number(expensesdata.companyExpense).toLocaleString("en-IN")}
            </Typography>
          </div>
          <div className="expenses_grids">
            <Typography className="cashbook_title">
              Personal Expense :-{" "}
            </Typography>
            <Typography className="cashbook_body">
              {Number(expensesdata.personalExpense).toLocaleString("en-IN")}
            </Typography>
          </div>
        </div>
      </div>
      {/*  */}

      {/* Tabs View */}

      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          component={Box}
          boxShadow={3}
          variant="fullWidth"
        >
          <Tab value="one" label="All" />
          <Tab value="two" label="Credit" />
          <Tab value="three" label="Withdraw" />
        </Tabs>
        <TabPanel value="one" style={{ padding: "0" }}>
          {/* {cashbooktranscation?.map((res) => (
            <CashBookCard res={res} handleClick={handleClick} />
          ))} */}
          <AllCashBookTab handleClick={handleClick} />
        </TabPanel>
        <TabPanel value="two" style={{ padding: "0" }}>
          <CreditCashBookTab handleClick={handleClick} />
        </TabPanel>
        <TabPanel value="three" style={{ padding: "0" }}>
          <DebitCashBookTab handleClick={handleClick} />
        </TabPanel>
      </TabContext>
    </Card>
  );
};

export default CashBookComp;
