import React, { useState } from "react";
import "../../CSSFiles/userssearchfield.css";
import { BsSearch } from "react-icons/bs";
import { useAuth } from "../../Providers/UserProvider";
import { IoClose } from "react-icons/io5";

const UsersSearchField = ({ type }) => {
  const [searchText, setsearchText] = useState("");
  const {
    getSearchusers,
    isClearSearch,
    getuserslastdays,
    setisSearchButtonClicked,
    getSearchCouponUsers,
    getCouponCodeData,
    getTranscationList,
    getTranscationListSearchData,
  } = useAuth();
  const handleSearch = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    setisSearchButtonClicked(true);
    if (type === "Users") {
      getSearchusers(searchText.replace(/\s/g, ""), Data);
    }
    if (type === "CouponUsers") {
      getSearchCouponUsers(searchText.replace(/\s/g, ""), Data);
    }
    if (type === "TranscationList") {
      getTranscationListSearchData(searchText.replace(/\s/g, ""), Data);
    }
  };

  const handleRemoveSearch = () => {
    setsearchText("");
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    if (type === "Users") {
      getuserslastdays(Data);
    }

    if (type === "CouponUsers") getCouponCodeData(Data);

    if (type === "TranscationList") getTranscationList(Data);
  };

  return (
    <div className="Search__Container">
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
          className="Search__Input"
        >
          <input
            type="text"
            placeholder="Search by email,Uid,platform"
            value={searchText}
            onChange={(e) => {
              setsearchText(e.target.value);
            }}
          />
          <div className="Search__Icon">
            {!isClearSearch ? (
              <BsSearch
                onClick={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
              />
            ) : (
              <IoClose
                onClick={(e) => {
                  e.preventDefault();
                  handleRemoveSearch();
                }}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UsersSearchField;
