import { Typography } from "@mui/material";
import React from "react";

const AddAndEditReadingDialogBodyTxt = ({ pdfName, type }) => {
  return (
    <div style={{ padding: "2vw" }}>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Pdf File Name :-</Typography>
        <Typography className="body">{pdfName}</Typography>
      </div>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Reading Test Type :-</Typography>
        <Typography className="body">{type}</Typography>
      </div>
    </div>
  );
};

export default AddAndEditReadingDialogBodyTxt;
