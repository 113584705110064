import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import SidebarComp from "../../Components/Sidebar";
import { useAuth } from "../../Providers/UserProvider";
import CreateSpeakingQues from "../../Components/SpeakingQuesComp/CreateSpeakingQues";
import { Typography } from "@mui/material";

const UploadSpeakingQues = () => {
  const { closeSidebar, loader } = useAuth();
  return (
    <>
      {loader === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Submitting Please Wait...
        </Typography>
      )}
      <div className="scrolling">
        {!closeSidebar && <Navbar />}
        <div className="home">
          {closeSidebar && <SidebarMob />}
          <SidebarComp />
          <CreateSpeakingQues />
        </div>
      </div>
    </>
  );
};

export default UploadSpeakingQues;
