import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core";
import { Typography, IconButton, TextField, Button } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAuth } from "../../../Providers/UserProvider";
import {
  getAccountLevelOfAdmins,
  getAccountStatusOfAdmins,
  getdataEntryPermission,
} from "../../../Services/Constants";

const useStyles = makeStyles({
  root: {},
  paper: { borderRadius: 5, width: "100%" },
});

const ConfirmEditDialog = ({
  open,
  handleClose,
  text,
  selectedName,
  title,
  handleClickClose,
  valueId,
  type,
  thumbNailUpload,
  ClassUniqueId,
  classFile,
}) => {
  const classes = useStyles();
  const {
    handleCouponEditSubmit,
    handleEditUsers,
    handleEditPaymentControl,
    handleEditAdminDetails,
    handleEditDataEntryPermission,
    handleEditClassData,
  } = useAuth();

  const handleSubmit = () => {
    const userData = localStorage.getItem("userData");
    const selectedCouponId = JSON.parse(
      sessionStorage.getItem("selectedCouponId"),
    );
    const selectedUserId = JSON.parse(sessionStorage.getItem("selectedUser"));
    const selectedAdminUser = JSON.parse(
      sessionStorage.getItem("selectedAdminUser"),
    );
    const Data = JSON.parse(userData);
    if (type === "Coupon")
      handleCouponEditSubmit(Data, Number(selectedCouponId?.id), valueId, text);
    else if (type === "PaymentControls") {
      handleEditPaymentControl(Data, valueId, text);
    } else if (type === "Admins") {
      if (valueId === "3") {
        handleEditDataEntryPermission(
          Data,
          selectedAdminUser,
          valueId,
          selectedName.join(""),
        );
      } else if (valueId === "6")
        handleEditAdminDetails(
          Data,
          selectedAdminUser,
          valueId,
          "",
          thumbNailUpload.raw,
        );
      else handleEditAdminDetails(Data, selectedAdminUser, valueId, text);
    } else if (type === "ScheduleClass" || type === "LiveClass") {
      handleEditClassData(Data, ClassUniqueId, valueId, text, classFile, type);
    } else handleEditUsers(Data, selectedUserId, valueId, text);
    handleClose();
    handleClickClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        handleClickClose();
      }}
      aria-labelledby="customized-dialog-title"
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
          paddingTop: "5vh",
        }}
      >
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose();
              handleClickClose();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            style={{ position: "absolute" }}
          >
            <AiOutlineCloseCircle />
          </IconButton>
        ) : null}
      </DialogTitle>
      <div style={{ padding: "2vw" }}>
        <div
        // style={{
        //   display: "flex",
        //   marginBottom: "5px",
        //   alignItems: "center",
        // }}
        >
          <Typography className="title">{title}</Typography>
          {type === "Admins" ? (
            <>
              {valueId === "1" && (
                <Typography className="value">
                  :- {getAccountStatusOfAdmins(text)}
                </Typography>
              )}
              {valueId === "2" && (
                <Typography className="value">
                  :- {getAccountLevelOfAdmins(text)}
                </Typography>
              )}
              {valueId === "3" && (
                <>
                  {selectedName &&
                    selectedName?.map((res) => (
                      <Typography className="value">
                        :- {getdataEntryPermission(res)}
                      </Typography>
                    ))}
                </>
              )}
              {(valueId === "4" || valueId === "5") && (
                <Typography className="value">:- {text}</Typography>
              )}
              {valueId === "6" && (
                <img
                  src={thumbNailUpload.preview}
                  width="300"
                  height="300"
                  alt="thumbnail"
                  style={{ objectFit: "contain" }}
                />
              )}
            </>
          ) : (
            <Typography className="value">:- {text}</Typography>
          )}
        </div>
      </div>
      <DialogContent>
        <DialogContentText className="title">Are You Sure?</DialogContentText>
        {valueId === "9" && type === "Coupon" && (
          <Typography className="title">
            {Number(text) === 0
              ? `Baby code will not cut any payment`
              : Number(text) < 0
              ? `Coupon Owner get every ${Math.abs(Number(text))} payment`
              : `BabyCode will cut every ${Math.abs(Number(text))} payment`}
          </Typography>
        )}
        {valueId === "2" && type === "Users" && (
          <Typography className="title">
            This will not create impact because account is connected with uid
          </Typography>
        )}
        {valueId === "7" && type === "Users" && (
          <Typography className="title">
            If you change user uid that mean chaging complete account and one
            uid can not be user in multiple account so not do that
          </Typography>
        )}
        {valueId === "3" && type === "Coupon" && (
          <Typography className="title">
            If you did not assign another coupon code to this user, then they
            will not be able to access the app.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            handleClickClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmEditDialog;
