import React from "react";
import { Grid } from "@mui/material";
import FeaturesUsage from "../Charts/FeaturesUsage";
import UnpaidFeaturesUsage from "../Charts/UnpaidFeaturesUsage";
import AllUsersFeaturesUsage from "../Charts/AllUsersFeaturesUsage";
import { useAuth } from "../../Providers/UserProvider";

const IosSeeMoreGraphComp = () => {
  const {
    iosallfeaturesusage,
    iospaidfeaturesusage,
    iosunpaidfeaturesusage,
    iosappleaveuserfeaturesusage,
  } = useAuth();
  return (
    <Grid container style={{ textAlign: "-webkit-center" }} spacing={1}>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Ios All</h2>
        <AllUsersFeaturesUsage retentiondata={iosallfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Ios Paid</h2>

        <FeaturesUsage retentiondata={iospaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Ios Unpaid</h2>

        <UnpaidFeaturesUsage retentiondata={iosunpaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Ios App Leave Users</h2>

        <UnpaidFeaturesUsage retentiondata={iosappleaveuserfeaturesusage} />
      </Grid>
    </Grid>
  );
};

export default IosSeeMoreGraphComp;
