import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";

export default function DailyRevenue() {
  const { dailyRevenueData, maxiTickValue } = useAuth();

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "0px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
          className="graph_tooltips"
        >
          <p
            style={{ color: "#008390" }}
          >{`date : ${payload[0].payload.date}`}</p>

          <p
            style={{ color: "##5DB09A" }}
          >{`TotalAmount : ${payload[0].payload.totalAmount}`}</p>

          <p
            style={{ color: "##5DB09A" }}
          >{`NumberOfSuccessfullPayment : ${payload[0].payload.numberOfSuccessfullPayment}`}</p>
          <p
            style={{ color: "##5DB09A" }}
          >{`NumberOfRefundPayment : ${payload[0].payload.numberOfRefundPayment}`}</p>
          <p
            style={{ color: "##5DB09A" }}
          >{`NumberOfFailedPayment : ${payload[0].payload.numberOfFailedPayment}`}</p>
          <p
            style={{ color: "##5DB09A" }}
          >{`TotalAmountSettle : ${payload[0].payload.totalAmountSettle}`}</p>
          <p
            style={{ color: "##5DB09A" }}
          >{`PendingSettleAmount : ${payload[0].payload.pendingSettleAmount}`}</p>
          <p
            style={{ color: "##5DB09A" }}
          >{`PendingSettlePayment : ${payload[0].payload.pendingSettlePayment}`}</p>
          <p
            style={{ color: "##5DB09A" }}
          >{`DailyWalletGive : ${payload[0].payload.dailyWalletGive}`}</p>
          <p
            style={{ color: "##5DB09A" }}
          >{`DailyWalletSave : ${payload[0].payload.dailyWalletSave}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="99%" height={300}>
      <LineChart data={dailyRevenueData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />

        <YAxis
          type="number"
          domain={[0, (dataMax) => Math.ceil(dataMax / 10000) * 10000]}
          tickCount={maxiTickValue}
          tickFormatter={(tick) => `${tick / 1000}k`}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey="totalAmount"
          stroke="blue"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="totalAmountSettle" stroke="#82ca9d" />
        <Line type="monotone" dataKey="dailyWalletGive" stroke="red" />
        <Line type="monotone" dataKey="dailyWalletSave" stroke="#744FB1" />
      </LineChart>
    </ResponsiveContainer>
  );
}
