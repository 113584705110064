import React from "react";
import { useNavigate } from "react-router-dom";

const CouponCardsView = (props) => {
  const navigate = useNavigate();

  const handleCardNavigation = () => {
    if (props.cardType === "CouponSpendDetail") navigate("/couponspendDetails");
  };

  return (
    <div
      className="subcards card-1"
      onClick={(e) => {
        e.preventDefault();
        handleCardNavigation();
      }}
    >
      {props.title && (
        <div
          className="view 1"
          style={{ display: "flex", gap: "13px", alignItems: "center" }}
        >
          <h3 className="card__value">{props.title} (Wallet) :</h3>
          <p className="card__value">{props.value}</p>
        </div>
      )}
      <div
        className="view 1"
        style={{ display: "flex", gap: "13px", alignItems: "center" }}
      >
        <h3 className="card__title">{props.title2} :</h3>
        <p className="card__title">{props.value2}</p>
      </div>
      <div
        className="view 1"
        style={{ display: "flex", gap: "10px", alignItems: "center" }}
      >
        <h3 className="card__title">{props.title3} :</h3>
        <p className="card__title">{props.value3}</p>
      </div>
      {props.title4 && (
        <div
          className="view 1"
          style={{ display: "flex", gap: "13px", alignItems: "center" }}
        >
          <h3 className="card__title1">{props.title4} :</h3>
          <p className="card__title1">{props.value4}</p>
        </div>
      )}
      {props.title5 && (
        <div
          className="view 1"
          style={{ display: "flex", gap: "13px", alignItems: "center" }}
        >
          <h3 className="card__title">{props.title5} :</h3>
          <p className="card__title">{props.value5}</p>
        </div>
      )}
    </div>
  );
};

export default CouponCardsView;
