import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useAuth } from "../../Providers/UserProvider";
import {
  changeDateFormateInTwelveHours,
  getCashBookBank,
  getCashBookpaymentCategory,
  getCashBookpaymentSource,
  getCashBookpaymentSubCategory,
  gettransactionStatus,
  getTranscationStatus,
} from "../../Services/Constants";

const TranscationDetail = () => {
  const { clickedtrans } = useAuth();

  console.log(clickedtrans, "data");
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2>Transcations Detail</h2>
      </div>
      <Card className="cardsub" style={{ marginBottom: "1vh" }}>
        <CardContent style={{ paddingBottom: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Id :</Typography>
            <Typography className="body">{clickedtrans?.id}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card className="cardsub" style={{ marginBottom: "1vh" }}>
        <CardContent style={{ paddingBottom: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">paymentId :</Typography>
            <Typography className="body">{clickedtrans?.paymentID}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card className="cardsub" style={{ marginBottom: "2vh" }}>
        <CardContent style={{ paddingBottom: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Payment Amount :</Typography>
            <Typography className="body">
              {clickedtrans?.paymentAmount}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card className="cardsub" style={{ marginBottom: "2vh" }}>
        <CardContent style={{ paddingBottom: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Created At :</Typography>
            <Typography className="body">
              {changeDateFormateInTwelveHours(clickedtrans?.created_at)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card className="cardsub" style={{ marginBottom: "2vh" }}>
        <CardContent style={{ paddingBottom: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Bank :</Typography>
            <Typography className="body">
              {getCashBookBank(clickedtrans?.bank)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card className="cardsub" style={{ marginBottom: "2vh" }}>
        <CardContent style={{ paddingBottom: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Transcation Status :</Typography>
            <Typography className="body">
              {gettransactionStatus(clickedtrans?.transactionStatus)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card className="cardsub" style={{ marginBottom: "2vh" }}>
        <CardContent style={{ paddingBottom: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">
              Balance After Transcation :
            </Typography>
            <Typography className="body">
              {clickedtrans?.balanceAfterTransaction}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card className="cardsub" style={{ marginBottom: "2vh" }}>
        <CardContent style={{ paddingBottom: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Payment Category :</Typography>
            <Typography className="body">
              {getCashBookpaymentCategory(clickedtrans?.paymentCategory)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      {clickedtrans?.transactionStatus === 0 &&
        clickedtrans?.paymentCategory === 1 && (
          <Card className="cardsub" style={{ marginBottom: "2vh" }}>
            <CardContent style={{ paddingBottom: "10px" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "13px" }}
              >
                <Typography className="title">Payment SubCategory :</Typography>
                <Typography className="body">
                  {getCashBookpaymentSubCategory(
                    clickedtrans?.paymentSubcategory,
                  )}
                </Typography>
              </div>
            </CardContent>
          </Card>
        )}
      {clickedtrans?.transactionStatus === 0 &&
        clickedtrans?.paymentCategory === 1 &&
        clickedtrans?.paymentSubcategory === 3 && (
          <Card className="cardsub" style={{ marginBottom: "2vh" }}>
            <CardContent style={{ paddingBottom: "10px" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "13px" }}
              >
                <Typography className="title">
                  Advertisement CouponCode :
                </Typography>
                <Typography className="body">
                  {clickedtrans?.advertisementCouponCode}
                </Typography>
              </div>
            </CardContent>
          </Card>
        )}
      <Card className="cardsub" style={{ marginBottom: "2vh" }}>
        <CardContent style={{ paddingBottom: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Payment Source :</Typography>
            <Typography className="body">
              {getCashBookpaymentSource(clickedtrans?.paymentSource)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card className="cardsub" style={{ marginBottom: "2vh" }}>
        <CardContent style={{ paddingBottom: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Comment :</Typography>
            <Typography className="body">{clickedtrans?.comment}</Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TranscationDetail;
