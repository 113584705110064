import { CardContent, Typography } from "@material-ui/core";
import { Card } from "@mui/material";
import React from "react";
import { changeDateFormate } from "../../Services/Constants";
import { useAuth } from "../../Providers/UserProvider";

const AllAcountsCreatedByUser = ({ res }) => {
  const { handleAppUserDetails } = useAuth();

  return (
    <Card
      className="appuserdetailscard"
      style={{ marginBottom: "2vh", cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        const userData = localStorage.getItem("userData");
        const Data = JSON.parse(userData);
        sessionStorage.setItem("selectedUser", JSON.stringify(res?.uid));

        handleAppUserDetails(res?.uid, Data);
      }}
    >
      <CardContent>
        <div className="secondgrid_of_three">
          <div className="text_value">
            <Typography className="title">Email :-</Typography>
            <Typography className="body">{res?.email}</Typography>
          </div>

          <div className="text_value">
            <Typography className="title">UniqueDeviceId :-</Typography>
            <Typography className="body">{res?.uniqueDeviceId}</Typography>
          </div>
          <div className="text_value">
            <Typography className="title">Membership :-</Typography>
            <Typography className="body">{res?.memberShip}</Typography>
          </div>
        </div>
        <div className="grid_of_three">
          <div className="text_value">
            <Typography className="title">WalletAmount :-</Typography>
            <Typography className="body">{res?.walletAmount}</Typography>
          </div>

          <div className="text_value">
            <Typography className="title">PhoneNumber :-</Typography>
            <Typography className="body">{res?.phoneNumber}</Typography>
          </div>
          <div className="text_value">
            <Typography className="title">Updated At :-</Typography>
            <Typography className="body">
              {changeDateFormate(res?.updateAt)}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default AllAcountsCreatedByUser;
