import React, { useRef, useState } from "react";
import "./component.css";
import { Typography } from "@mui/material";

const DragAndDropComp = ({ thumbNailUpload, setthumbNailUpload, type }) => {
  const [dragActive, setDragActive] = React.useState(false);

  const [upload, setupload] = useState(false);

  const inputRef = useRef();

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleFiles = (e) => {
    setupload(true);
    console.log(e);
    setthumbNailUpload({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });
  };

  const handleDropFiles = (e) => {
    setupload(true);

    setthumbNailUpload({
      preview: URL.createObjectURL(e[0]),
      raw: e[0],
    });
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      handleDropFiles(e.dataTransfer.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <>
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            onChange={handleFiles}
          />
          <label id="label-file-upload" htmlFor="input-file-upload">
            {type === "Image" && (
              <>
                {upload ? (
                  <img
                    src={thumbNailUpload.preview}
                    alt="thumbnail"
                    className="upload_img"
                  />
                ) : (
                  <div>
                    <p>Drag and Drop your image here or</p>
                    <button className="upload-button" onClick={onButtonClick}>
                      Upload an Image
                    </button>
                  </div>
                )}
              </>
            )}

            {type === "Video" && (
              <>
                {" "}
                {upload ? (
                  <video
                    src={thumbNailUpload.preview}
                    alt="thumbnail"
                    className="upload_img"
                  ></video>
                ) : (
                  <div>
                    <p>Drag and Drop your video here or</p>
                    <button className="upload-button" onClick={onButtonClick}>
                      Upload a Video
                    </button>
                  </div>
                )}{" "}
              </>
            )}
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </>
      </form>
    </div>
  );
};

export default DragAndDropComp;
