import { Typography } from "@mui/material";

export const AddWritingDialogBodyTxt = ({ category, subcategory }) => {
  return (
    <div style={{ padding: "2vw" }}>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Category :-</Typography>
        <Typography className="body">{category}</Typography>
      </div>
      {subcategory && <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">SubCategory :-</Typography>
        <Typography className="body">{subcategory}</Typography>
      </div>}
    </div>
  );
};
