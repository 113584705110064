import React from "react";
import { Tabs, Tab } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useState } from "react";
import { useAuth } from "../../Providers/UserProvider";
import Generalques from "./OptCategories/Generalques";
import IntroQue from "./OptCategories/IntroQue";
import QueueQues from "./OptCategories/QueueQue";
import FollowUpQue from "./OptCategories/FollowUpQue";

const FilterOpt = ({ handleAnswerClick }) => {
  const [value, setValue] = useState("two");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        centered
      >
        <Tab value="one" label="Introduction" />
        <Tab value="two" label="General" />
        <Tab value="three" label="QueCard" />
        <Tab value="four" label="Follow Up" />
      </Tabs>

      <TabPanel value="one">
        <IntroQue handleAnswerClick={handleAnswerClick} />
      </TabPanel>
      <TabPanel value="two">
        {" "}
        <Generalques handleAnswerClick={handleAnswerClick} />
      </TabPanel>
      <TabPanel value="three">
        <QueueQues handleAnswerClick={handleAnswerClick} />
      </TabPanel>
      <TabPanel value="four">
        <FollowUpQue handleAnswerClick={handleAnswerClick} />
      </TabPanel>
    </TabContext>
  );
};

export default FilterOpt;
