import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "../../CSSFiles/feedbacks.css";
import "../../CSSFiles/notifications.css";
import { useLocation } from "react-router-dom";

const SuggestionFeedbackComp = () => {
  const location = useLocation();
  const getDateAndTime = (strDateTime) => {
    var date = new Date(strDateTime);
    var myDate = date.toLocaleString();
    return myDate;
  };

  return (
    <Card style={{ width: "100%" }} className="card">
      <Card
        style={{
          marginBottom: "2vh",
          position: "sticky",
          top: 0,
          zIndex: "100",
          background: "#EDF3E0",
          textAlign: "center",
        }}
      >
        <CardContent>
          <div>
            <Typography className="titles">Question :-</Typography>
            <Typography className="bodys">{location.state.question}</Typography>
          </div>
        </CardContent>
      </Card>
      {location.state.feedbackdetails?.map((res, key) => (
        <Card className="cardsub" style={{ marginBottom: "2vh" }} key={key}>
          <CardContent>
            <div className="rawview1">
              <div className="uidheadings1">
                <Typography className="title">Uid :-</Typography>
                <Typography className="body">{res.uid}</Typography>
              </div>
              <div className="platform">
                <Typography className="title">Platform :-</Typography>
                <Typography className="body">{res.platform}</Typography>
              </div>
              <div className="dateview1">
                <Typography className="title">Time :-</Typography>
                <Typography className="body">
                  {getDateAndTime(res.feedbackDateTime)}
                </Typography>
              </div>
            </div>
            <div>
              <Typography className="bodys" style={{ textAlign: "center" }}>
                {res.userFeedback}
              </Typography>
            </div>
          </CardContent>
        </Card>
      ))}
    </Card>
  );
};

export default SuggestionFeedbackComp;
