import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SidebarComp from "../../Components/Sidebar";
import SidebarMob from "../../Components/SidebarMob";
import Navbar from "../../Components/Navbar/Navbar";
import { useAuth } from "../../Providers/UserProvider";
import CalendarComp from "../../MiddleWares/CalendarComp";
import "../../CSSFiles/payment_behaviour.css";
import { getDateString } from "../../Services/Constants";
import ComparingPlanSells from "../../Components/Charts/ComparingPlanSells";
import AdLinksClicks from "../../Components/Charts/AdLinksClicks";
import MonthlySaleRevenueData from "../../Components/Charts/MonthlySaleRevenueData";
import { toast } from "react-toastify";
import AdLinksDateByDate from "../../Components/Charts/AdLinksDateByDate";
import "../../CSSFiles/classes.css";

const getOneMonthPrevDate = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date;
};

export const compareDates = (d1, d2) => {
  if (d1 >= d2) return false;
  else return true;
};

const PaymentBehaviourDetails = () => {
  const { closeSidebar, timetorefresh, getPaymentBehaviourDetailsGraphs } =
    useAuth();

  const StartPaymentBehaviourDetails = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);

    const startingDate = getDateString(getOneMonthPrevDate());
    const endingDate = getDateString(new Date());
    getPaymentBehaviourDetailsGraphs(Data, startingDate, endingDate);
  };

  const [startDate, setStartDate] = useState(getOneMonthPrevDate());
  const [endDate, setEndDate] = useState(new Date());
  const [showdatebydate, setshowdatebydate] = useState(false);

  useEffect(() => {
    StartPaymentBehaviourDetails();
  }, []);

  const handleApplyChanges = () => {
    if (!compareDates(startDate, endDate)) {
      toast.error("End Date Should Always be greater than Start Date");
      return;
    }
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);

    getPaymentBehaviourDetailsGraphs(
      Data,
      getDateString(startDate),
      getDateString(endDate),
    );
  };

  return (
    <div className="scrolling">
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <div style={{ width: "100%", height: "100vh", overflow: "scroll" }}>
          {timetorefresh === true && (
            <Typography
              style={{
                margin: 0,
                top: 15,
                right: 20,
                bottom: 20,
                left: "auto",
                position: "fixed",
                zIndex: "1",
              }}
            >
              Refreshing...
            </Typography>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              zIndex: 10,
              position: "relative",
              flexWrap: "wrap",
              flexDirection: "row",
            }}
          >
            <div style={{ margin: "2vh", maxWidth: "100%" }}>
              <Typography className="DateHeadings">Start Date </Typography>
              <CalendarComp
                calendarData={startDate}
                setcalendarDate={setStartDate}
              />
            </div>
            <div style={{ margin: "2vh", maxWidth: "100%" }}>
              <Typography className="DateHeadings">End Date </Typography>
              <CalendarComp
                calendarData={endDate}
                setcalendarDate={setEndDate}
              />
            </div>
          </div>
          <div
            style={{
              margin: "2vh",
              maxWidth: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Button
              variant="outlined"
              className="grammar_buttons"
              onClick={(e) => {
                e.preventDefault();
                handleApplyChanges();
              }}
            >
              Apply Changes
            </Button>
          </div>
          <Grid
            container
            style={{
              textAlign: "center",
              paddingRight: "15px",
              paddingLeft: "10px",
              paddingTop: "10px",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center" }}
            >
              <h2 className="graph_headings" style={{ textAlign: "center" }}>
                Comparing Plan Sells
              </h2>

              <ComparingPlanSells />
            </Grid>
          </Grid>
          <div
            style={{
              textAlign: "center",
              paddingRight: "15px",
              paddingLeft: "10px",
              paddingTop: "10px",
            }}
          >
            <h2 className="graph_headings" style={{ textAlign: "center" }}>
              Advertisement Links Clicks
            </h2>
            {/*  */}
            {/* Switch To Toggle Data Day by Day */}

            <div style={{ float: "right", marginBottom: "10px" }}>
              <label className="toggle">
                <input
                  className="toggle-checkbox"
                  type="checkbox"
                  checked={showdatebydate}
                  onChange={(e) => {
                    setshowdatebydate(e.target.checked);
                  }}
                />
                <div className="toggle-switch"></div>
                <span className="toggle-label graph_headings">
                  Date By Date Data
                </span>
              </label>
            </div>
          </div>

          <Grid
            container
            style={{
              textAlign: "center",
              paddingRight: "15px",
              paddingLeft: "10px",
              paddingTop: "10px",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center" }}
            >
              {showdatebydate ? <AdLinksDateByDate /> : <AdLinksClicks />}
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              textAlign: "center",
              paddingRight: "15px",
              paddingLeft: "10px",
              paddingTop: "10px",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center" }}
            >
              <h2 style={{ textAlign: "center" }} className="graph_headings">
                Monthly Sale Responsibilty
              </h2>

              <MonthlySaleRevenueData />
            </Grid>
          </Grid>
        </div>
        {/* <ToastContainer /> */}
      </div>
    </div>
  );
};

export default PaymentBehaviourDetails;
