import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { MuiThemeProvider } from "@material-ui/core";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const theme = createTheme({
  typography: {
    fontFamily: `Comfortaa`,
  },
});
const muitheme = createTheme({
  typography: {
    fontFamily: `Comfortaa`,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={muitheme}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </ThemeProvider>,
);
