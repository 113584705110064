import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";

export default function CombinedTimeSpent() {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "0px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
          className="graph_tooltips"
        >
          <p
            style={{ color: "#008390" }}
          >{`Xaxis : ${payload[0].payload.xaxis}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AllUsers/All : ${payload[0].payload.allalluseruseravgTimeSpend}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AllUsers/Paid : ${payload[0].payload.allpaiduseruseravgTimeSpend}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AllUsers/Unpaid : ${payload[0].payload.allunpaiduseruseravgTimeSpend}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`AllUsers/AppLeaveUsers : ${payload[0].payload.allappleaveuseravgTimeSpend}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`AndroidUsers/All : ${payload[0].payload.androidalluseruseravgTimeSpend}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AndroidUsers/Paid : ${payload[0].payload.androidpaiduseruseravgTimeSpend}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AndroidUsers/Unpaid : ${payload[0].payload.androidunpaiduseruseravgTimeSpend}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`AndroidUsers/AppLeaveUsers : ${payload[0].payload.androidappleaveuseravgTimeSpend}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`iosUsers/All : ${payload[0].payload.iosalluseruseravgTimeSpend}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`iosUsers/Paid : ${payload[0].payload.iospaiduseruseravgTimeSpend}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`iosUsers/Unpaid : ${payload[0].payload.iosunpaiduseruseravgTimeSpend}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`iosUsers/AppLeaveUsers : ${payload[0].payload.iosappleaveuseravgTimeSpend}`}</p>
        </div>
      );
    }

    return null;
  };
  const { combinedavgtimespend } = useAuth();
  return (
    <ResponsiveContainer width="99%" height={300}>
      <LineChart data={combinedavgtimespend}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="xaxis" />

        <YAxis tickCount={9} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />

        <Line
          type="monotone"
          name="All Users/All"
          dataKey="allalluseruseravgTimeSpend"
          stroke="#EE005F"
        />
        <Line
          type="monotone"
          name="All Users/Paid"
          dataKey="allpaiduseruseravgTimeSpend"
          stroke="#FF0000"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          name="All Users/Unpaid"
          dataKey="allunpaiduseruseravgTimeSpend"
          stroke="#B62A8F"
        />

        <Line
          type="monotone"
          name="All Users/AppLeaveUser"
          dataKey="allappleaveuseravgTimeSpend"
          stroke="#93697C"
        />

        <Line
          type="monotone"
          name="Android/All"
          dataKey="androidalluseruseravgTimeSpend"
          stroke="#00EA71"
        />
        <Line
          type="monotone"
          name="Android/Paid"
          dataKey="androidpaiduseruseravgTimeSpend"
          stroke="#00FF00"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          name="Android/Unpaid"
          dataKey="androidunpaiduseruseravgTimeSpend"
          stroke="#00D0B2"
        />
        <Line
          type="monotone"
          name="Android/AppLeaveUser"
          dataKey="androidappleaveuseravgTimeSpend"
          stroke="#008F00"
        />
        <Line
          type="monotone"
          name="Ios/All"
          dataKey="iosalluseruseravgTimeSpend"
          stroke="#0000FF"
        />
        <Line
          type="monotone"
          name="Ios/Paid"
          dataKey="iospaiduseruseravgTimeSpend"
          stroke="#8774A6"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          name="Ios/Unpaid"
          dataKey="iosunpaiduseruseravgTimeSpend"
          stroke="#362B48"
        />
        <Line
          type="monotone"
          name="Ios/AppLeaveUser"
          dataKey="iosappleaveuseravgTimeSpend"
          stroke="#008FFF"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
