export const PaymentCardvalueIds = [
  { name: "appAmount", valueId: 1 },

  { name: "freeTestPerDay", valueId: 2 },

  { name: "trialDay", valueId: 3 },

  { name: "amountThatBasicUserGetForAppSharing", valueId: 4 },

  { name: "appDicountGetOnBasicPromoCode", valueId: 5 },

  { name: "razorPayKey", valueId: 6 },

  { name: "razorPayKeySecret", valueId: 7 },

  { name: "razorPayXAccountNumber", valueId: 8 },

  { name: "payoutSeesionTimerLimitInSecond", valueId: 9 },

  { name: "androidVersion", valueId: 10 },

  { name: "androidgoogleReviewVersion", valueId: 11 },

  { name: "iOSVersion", valueId: 12 },

  { name: "iOSAppleReviewVersion", valueId: 13 },

  { name: "runAds", valueId: 14 },

  { name: "freeVideoCallSecond", valueId: 15 },

  { name: "updateForcefully", valueId: 16 },

  {
    name: "callingWaitingMessage",
    valueId: 17,
  },

  { name: "tutorialVideoUrl", valueId: 18 },

  {
    name: "howToBuyMembershipVideoUrl",
    valueId: 19,
  },

  {
    name: "qrCodeTutorialVideoUrl",
    valueId: 20,
  },

  {
    name: "speakingTutorialVideoUrl",
    valueId: 21,
  },

  {
    name: "listeningTutorialVideoUrl",
    valueId: 22,
  },

  {
    name: "paymentFailTutorialVideoUrl",
    valueId: 23,
  },

  {
    name: "updateVersionChanges",
    valueId: 24,
  },

  { name: "crashCourseAvailable", valueId: 25 },

  {
    name: "speakingTutorialVideoThumbnail",
    valueId: 26,
  },

  {
    name: "listeningTutorialVideoThumbnail",
    valueId: 27,
  },

  {
    name: "paymentTutorialVideoThumbnail",
    valueId: 28,
  },

  { name: "gstEnable", valueId: 29 },

  { name: "saveAnswerFiles", valueId: 30 },

  { name: "speakingTestAnswerAudioFileFrequency", valueId: 31 },

  { name: "speakingTestAnswerAudioFileBitRate", valueId: 32 },

  {
    name: "speakingTestAnswerSubmitApiUrl",
    valueId: 33,
  },

  { name: "feedbackRequiredForcefully", valueId: 34 },

  { name: "completeProfile", valueId: 35 },

  { name: "membershipIsYearly", valueId: 36 },

  { name: "membershipDuration", valueId: 37 },

  { name: "membershipPlanAPrice", valueId: 38 },

  { name: "membershipPlanADuration", valueId: 39 },

  { name: "membershipPlanADaysMonthlyYearly", valueId: 40 },

  { name: "membershipPlanBPrice", valueId: 41 },

  { name: "membershipPlanBDuration", valueId: 42 },

  { name: "membershipPlanBDaysMonthlyYearly", valueId: 43 },

  { name: "membershipPlanCPrice", valueId: 44 },

  { name: "membershipPlanCDuration", valueId: 45 },

  { name: "membershipPlanCDaysMonthlyYearly", valueId: 46 },
];

export const MembershipHeading = [
  { membershipIsYearly: "false" },

  { membershipDuration: "1" },

  { appAmount: "399" },

  { membershipPlanAPrice: "249" },

  { membershipPlanADuration: "10" },

  { membershipPlanADaysMonthlyYearly: "1" },

  { membershipPlanBPrice: "399" },

  { membershipPlanBDuration: "1" },

  { membershipPlanBDaysMonthlyYearly: "2" },

  { membershipPlanCPrice: "499" },

  { membershipPlanCDuration: "1" },

  { membershipPlanCDaysMonthlyYearly: "3" },
];

export const AppVersionHeading = [
  { androidVersion: "104.0" },

  { androidgoogleReviewVersion: "105.0" },

  { iOSVersion: "57.0" },

  { iOSAppleReviewVersion: "58.0" },
];

export const PaymentControlHeading = [
  { amountThatBasicUserGetForAppSharing: "50" },

  { appDicountGetOnBasicPromoCode: "50" },

  { razorPayKey: "rzp_live_Yo0oGeUWlo16O6" },

  { razorPayKeySecret: "wHovZFWpDRCqJL5yfU7dLaRQ" },

  { razorPayXAccountNumber: "4564565056017830" },

  { payoutSeesionTimerLimitInSecond: "1800" },
];

export const AnswerControlHeading = [
  { speakingTestAnswerAudioFileFrequency: "16000" },

  { speakingTestAnswerAudioFileBitRate: "16" },
];

export const ElseHeading = [
  { freeTestPerDay: "1" },

  { trialDay: "1" },

  { runAds: "false" },

  { freeVideoCallSecond: "0" },

  { updateForcefully: "true" },

  {
    callingWaitingMessage:
      "No other person is available at the moment to join…ur friends so app network will get more stable...",
  },

  { tutorialVideoUrl: "https://youtu.be/hO5Pmxrr33A" },

  {
    howToBuyMembershipVideoUrl:
      "https://dif5arx3u83dk.cloudfront.net/appTutorial/buyMembership.mp4",
  },

  {
    qrCodeTutorialVideoUrl:
      "https://dif5arx3u83dk.cloudfront.net/appTutorial/qrVideo.mp4",
  },

  {
    speakingTutorialVideoUrl:
      "https://dif5arx3u83dk.cloudfront.net/appTutorial/englishSpeakinghelp.mp4",
  },

  {
    listeningTutorialVideoUrl:
      "https://dif5arx3u83dk.cloudfront.net/appTutorial/englishSpeakinghelp.mp4",
  },

  {
    paymentFailTutorialVideoUrl:
      "https://dif5arx3u83dk.cloudfront.net/appTutorial/buyMembership.mp4",
  },

  {
    updateVersionChanges:
      "Click on the update app button and install the lat…ore/app store search babycode and update the app.",
  },

  { crashCourseAvailable: "true" },

  {
    speakingTutorialVideoThumbnail:
      "https://dif5arx3u83dk.cloudfront.net/appImages/MustWatch.jpg",
  },

  {
    listeningTutorialVideoThumbnail:
      "https://dif5arx3u83dk.cloudfront.net/appImages/MustWatch.jpg",
  },

  {
    paymentTutorialVideoThumbnail:
      "https://dif5arx3u83dk.cloudfront.net/appImages/paymentTutorial-2.png",
  },

  { gstEnable: "false" },

  { saveAnswerFiles: "false" },

  {
    speakingTestAnswerSubmitApiUrl:
      "http://speechtotextv3-env.ap-south-1.elasticbeanstalk.com/convertor/",
  },

  { feedbackRequiredForcefully: "true" },

  { completeProfile: "1" },
];
