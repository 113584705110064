import React, { useEffect, useState } from "react";
import SidebarComp from "../Components/Sidebar";
import { useAuth } from "../Providers/UserProvider";
import PaidVsTotal from "../Components/Charts/PaidvsTotalUser";
import { Button, Fab, Grid, Typography } from "@mui/material";
import DailyActiveUser from "../Components/Charts/DailyActiveUser";
import DailyRevenue from "../Components/Charts/DailyRevenue";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/lab";
import CardView from "../Components/SubCardsComps/CardView";
import { usePageVisibility } from "../Components/VisibilityFunc";
import ServerInfo from "../Components/Charts/ServerInfo";
import ModalComp from "../Components/GrammarMistaken/ModalComp";
import TranscationList from "../Components/HomeSubCat/TranscationList";
import { IoArrowForwardCircle } from "react-icons/io5";
import Navbar from "../Components/Navbar/Navbar";
import SidebarMob from "../Components/SidebarMob";
import ExpenseGraph from "../Components/Charts/ExpenseGraph";
import PaymentBehaviourInADay from "../Components/Charts/PaymentBehaviourInADay";
import ExpenseGraphTabs from "../Components/ExpenseGraphTabs/ExpenseGraphTabs";
import { formatTime } from "../Services/Constants";
const Home = () => {
  const {
    loader,
    averageConvertRateForMonth,
    razorPayWalletAmount,
    getHomeData,
    timetorefresh,
    updateViewData,
    stophomepageIntervalId,
    totalMoneyGetInBackDates,
    totalSuccessfullPaymentInBackDates,
    totalProfitgetInBackDatesBeforeRazorPayText,
    totalAmountSettleBeforeRazorPayTaxInBackDates,
    totalAmountSettleAfterRazorPayTaxInBackDates,
    totalProfitgetInBackDatesBeforeRazorPayTextAfterGST,
    userComeOnAppInLast30Minute,
    profitAfterExpense,
    totalAmount,
    iciciCurrentAccount,
    kotakCurrentAccount,
    iciciSavingAccount,
    razorPayXAccount,
    totalExpense,
    androidConvertRateForMonth,
    iosConvertRateForMonth,
    closeSidebar,
    expensesdata,
    homeapierr,
    sethomeapierr,
    ConvertRateTillNow,
    inapppurchasenoOfPayment,
    countdown,
    // clearMyIntervalId
  } = useAuth();
  const navigate = useNavigate();

  const isVisible = usePageVisibility();

  // const [IsModalScreen, setIsModalScreen] = useState(false);

  // const handleFunction = () => {
  //   setIsModalScreen(!IsModalScreen);
  // };

  const closeDialog = () => {
    sethomeapierr(false);
  };
  useEffect(() => {
    if (isVisible) {
      StartHomePage();
    } else {
      localStorage.setItem("userExistsOnHomePage", false);
      console.log("Home page is not ready");
      stophomepageIntervalId();
      // clearMyIntervalId();
    }
    return () => {
      localStorage.setItem("userExistsOnHomePage", false);

      console.log("user leaves home page");
      stophomepageIntervalId();
      // clearMyIntervalId();
    };
    // eslint-disable-next-line
  }, [isVisible]);

  const StartHomePage = () => {
    localStorage.setItem("userExistsOnHomePage", true);
    const userData = localStorage.getItem("userData");
    if (userData) {
      const Data = JSON.parse(userData);

      const homedata = localStorage.getItem("homedata");
      if (homedata) {
        updateViewData();
      }

      getHomeData(Data);
    } else {
      navigate("/login");
    }
  };

  const handleClick = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    getHomeData(userData, true);
  };

  // const handleRefresh = () => {
  //   StartHomePage();
  // };

  return (
    <div className="scrolling">
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        {/* <PaidVsTotal/> */}
        {/* {IsModalScreen && (
          <ModalComp
            handleFunction={handleFunction}
            IsModalScreen={IsModalScreen}
            sethandleModalScreen={setIsModalScreen}
            children={<TranscationList />}
          />
        )} */}
        <div
          style={{ width: "100%", height: "100vh", overflow: "scroll" }}
          className="second_screen"
        >
          <Grid
            container
            spacing={1}
            rowSpacing={3}
            style={{
              paddingRight: "15px",
              paddingLeft: "10px",
              paddingTop: "10px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={4}
              className="gridingcards"
            >
              <CardView
                title="Wallet Amount"
                value={Number(razorPayWalletAmount).toLocaleString("en-IN")}
                title2="Razor Pay Total Amount"
                value2={Number(totalMoneyGetInBackDates).toLocaleString(
                  "en-IN",
                )}
                title3="Razor Pay Number Of Payment"
                value3={Number(
                  totalSuccessfullPaymentInBackDates,
                ).toLocaleString("en-IN")}
                title4="In App Purchase Number Of Payment"
                value4={inapppurchasenoOfPayment}
                // title5="In App Purchase Total Amount"
                // value5="0"
                cardType="TranscationList"
                // handleFunction={handleFunction}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={4}
              className="gridingcards"
            >
              <CardView
                title="Profit"
                value={Number(
                  totalProfitgetInBackDatesBeforeRazorPayText,
                ).toLocaleString("en-IN")}
                title2="Settle Before RazorPay Tax"
                value2={Number(
                  totalAmountSettleBeforeRazorPayTaxInBackDates,
                ).toLocaleString("en-IN")}
                title3="Settle After RazorPay Tax"
                value3={Number(
                  totalAmountSettleAfterRazorPayTaxInBackDates,
                ).toLocaleString("en-IN")}
                title4="Profit After GST"
                value4={Number(
                  totalProfitgetInBackDatesBeforeRazorPayTextAfterGST,
                ).toLocaleString("en-IN")}
                title5="Profit After Expense"
                value5={Number(profitAfterExpense).toLocaleString("en-IN")}
                // totalExpense={totalExpense}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={4}
              className="gridingcards"
            >
              <CardView
                title="Total Amount"
                value={Number(totalAmount).toLocaleString("en-IN")}
                title2="ICICI Current"
                value2={Number(iciciCurrentAccount).toLocaleString("en-IN")}
                title3="Kotak Current"
                value3={Number(kotakCurrentAccount).toLocaleString("en-IN")}
                title4="ICICI Saving"
                value4={Number(iciciSavingAccount).toLocaleString("en-IN")}
                title5="Razor Pay X"
                value5={Number(razorPayXAccount).toLocaleString("en-IN")}
                cardType="CashBookTranscationList"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={4}
              className="gridingcards"
            >
              <CardView
                title="Active user in 30 minutes"
                value={Number(userComeOnAppInLast30Minute).toLocaleString(
                  "en-IN",
                )}
                title2="Page refreshes after"
                value2={formatTime(countdown)}
                special={true}
                title3="Average Time Spent(Paid)"
                value3="0"
                title4="Average Time Spent(Unpaid)"
                value4="0"
              />
            </Grid>
          </Grid>

          {homeapierr && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: "90%",
                left: "42%",
                zIndex: "100",
              }}
            >
              <Alert
                severity="error"
                onClose={() => closeDialog()}
                style={{ textAlign: "center" }}
              >
                Something Went Wrong
              </Alert>
            </div>
          )}

          {timetorefresh === true && (
            <Typography
              style={{
                margin: 0,
                top: 15,
                right: 20,
                bottom: 20,
                left: "auto",
                position: "fixed",
              }}
            >
              Refreshing...
            </Typography>
          )}

          <Grid
            container
            style={{
              textAlign: "-webkit-center",
              paddingRight: "15px",
              paddingLeft: "10px",
              paddingTop: "10px",
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={6}
              style={{ textAlign: "-webkit-center" }}
            >
              <h2
                className="graph_headings"
                style={{ textAlign: "-webkit-center" }}
              >
                Daily Revenue
              </h2>

              <DailyRevenue />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={6}
              style={{ textAlign: "-webkit-center" }}
            >
              <div style={{ textAlign: "-webkit-center" }}>
                <h2
                  className="graph_headings"
                  style={{ textAlign: "-webkit-center" }}
                >
                  Payment Behaviour In a Day
                </h2>
                {/*  */}
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/paymentBehaviourDetails");
                }}
                style={{ position: "absolute", top: "3%", right: "0%" }}
              >
                <IoArrowForwardCircle size={40} />
              </div>

              <PaymentBehaviourInADay />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              textAlign: "-webkit-center",
              paddingRight: "15px",
              paddingLeft: "10px",
              paddingTop: "10px",
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={6}
              style={{ textAlign: "-webkit-center" }}
            >
              <h2
                className="graph_headings"
                style={{ textAlign: "-webkit-center" }}
              >
                Convert Rate(
                {averageConvertRateForMonth}% all {androidConvertRateForMonth}%
                android {iosConvertRateForMonth}% ios {ConvertRateTillNow}%Till
                now)
              </h2>

              <PaidVsTotal />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={6}
              style={{ textAlign: "-webkit-center", position: "relative" }}
            >
              <div style={{ textAlign: "-webkit-center" }}>
                <h2
                  className="graph_headings"
                  style={{ textAlign: "-webkit-center" }}
                >
                  Server Information
                </h2>
                {/*  */}
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/serverdetails");
                }}
                style={{ position: "absolute", top: "3%", right: "0%" }}
              >
                <IoArrowForwardCircle size={40} />
              </div>
              <ServerInfo />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              textAlign: "-webkit-center",
              paddingRight: "15px",
              paddingLeft: "10px",
              paddingTop: "10px",
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={6}
              style={{ textAlign: "-webkit-center" }}
            >
              <h2
                className="graph_headings"
                style={{ textAlign: "-webkit-center" }}
              >
                Daily Active User Graph
              </h2>

              <DailyActiveUser />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={6}
              style={{ textAlign: "-webkit-center" }}
            >
              {" "}
              <div>
                <h2
                  className="graph_headings"
                  style={{ textAlign: "-webkit-center" }}
                >
                  Expense Graph(
                  {Number(totalExpense).toLocaleString("en-IN")} Total&nbsp;
                  {Number(expensesdata.companyExpense).toLocaleString(
                    "en-IN",
                  )}{" "}
                  Company&nbsp;
                  {Number(expensesdata.personalExpense).toLocaleString(
                    "en-IN",
                  )}{" "}
                  Personal )
                </h2>
              </div>
              {/* <ExpenseGraph /> */}
              <ExpenseGraphTabs />
            </Grid>
          </Grid>
          <div style={{ margin: "10px" }}>
            <Button
              variant="contained"
              style={{ padding: "10px", width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                handleClick();
              }}
            >
              Force Calculation From Scratch
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
