import React, { useEffect, useState } from "react";
import NotificationComp from "../../Components/NotificationsComp/NotificationComp";
import SidebarComp from "../../Components/Sidebar";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";
import { Fab, Typography } from "@mui/material";
import { RiSendPlaneFill } from "react-icons/ri";
import { RiAddFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import SidebarMob from "../../Components/SidebarMob";
import Navbar from "../../Components/Navbar/Navbar";

const Notifications = () => {
  const {
    getNotificationData,
    updateNotificationView,
    timetorefresh,
    closeSidebar,
    setstorednotititle,
    setstorednotidesc,
    setstoredcategoryvalue,
    setstoredstatusvalue,
  } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    StartNotificationPage();
  }, []);

  const [value, setValue] = useState("one");

  const StartNotificationPage = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    const notificationData = sessionStorage.getItem("notificationData");
    if (notificationData) {
      updateNotificationView();
    }
    getNotificationData(Data);
  };

  return (
    // <div className="scrolling">
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />

        <NotificationComp value={value} setValue={setValue} />
        <Fab
          aria-label="add"
          style={{
            margin: 0,
            right: 50,
            bottom: 80,
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#051e34",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            if (value === "one") navigate("/customnoti");
            else {
              setstorednotititle("");
              setstoredstatusvalue("");
              setstorednotidesc("");
              setstoredcategoryvalue("");
              navigate("/addnotiscreen");
            }
          }}
        >
          {value === "one" ? (
            <RiSendPlaneFill size={30} />
          ) : (
            <RiAddFill size={30} style={{ color: "white" }} />
          )}
        </Fab>
      </div>
    </>
    // </div>
  );
};

export default Notifications;
