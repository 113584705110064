import { Typography } from "@mui/material";

export const AddAndEditStoredNotiDialogBodyText = ({
  notititle,
  notidesc,
  noticategory,
  notistatus,
}) => {
  return (
    <div style={{ padding: "2vw" }}>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Title :-</Typography>
        <Typography className="body">{notititle}</Typography>
      </div>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Desc :-</Typography>
        <Typography className="body">{notidesc}</Typography>
      </div>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Category :-</Typography>
        <Typography className="body">{noticategory?.label}</Typography>
      </div>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Status :-</Typography>
        <Typography className="body">{notistatus?.label}</Typography>
      </div>
    </div>
  );
};
