import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell,
} from "recharts";
import { useAuth } from "../../Providers/UserProvider";

export default function AdLinksClicks() {
  const { advertisementLinkData } = useAuth();

  return (
    <ResponsiveContainer width="99%" height={300}>
      <BarChart data={advertisementLinkData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="couponCode" />

        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="clicks" name="Total Clicks" fill="#008390" />
      </BarChart>
    </ResponsiveContainer>
  );
}
