import React from "react";
import { Typography } from "@material-ui/core"; // Assuming you're using Material-UI
import "../../../CSSFiles/listening.css"; // Import the CSS file we created
import {
  changeDateFormate,
  getTypeOfReadingQues,
} from "../../../Services/Constants";

const ListeningTestFeatures = ({ data }) => {
  // ... Your component code ...

  return (
    <div className="course-details-container">
      <div className="detail-row">
        <Typography className="detail-title">Unique Test Number:</Typography>
        <Typography className="detail-body">{data.uniqueTestNumber}</Typography>
      </div>
      {data.testNumber && (
        <div className="detail-row">
          <Typography className="detail-title">Unique Test Number:</Typography>
          <Typography className="detail-body">{data.testNumber}</Typography>
        </div>
      )}

      {data.testType !== undefined && (
        <div className="detail-row">
          <Typography className="detail-title">Test Type:</Typography>
          <Typography className="detail-body">
            {getTypeOfReadingQues(data.testType)}
          </Typography>
        </div>
      )}

      <div className="detail-row">
        <Typography className="detail-title">Created At:</Typography>
        <Typography className="detail-body">
          {changeDateFormate(data.created_at)}
        </Typography>
      </div>
      <div className="detail-row">
        <Typography className="detail-title">Updated At:</Typography>
        <Typography className="detail-body">
          {changeDateFormate(data.updated_at)}
        </Typography>
      </div>
    </div>
  );
};

export default ListeningTestFeatures;
