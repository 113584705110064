import React, { useState } from "react";
import "../../CSSFiles/notifications.css";
import { Card, Box } from "@mui/material";
import NotificationsCard from "./NotificationsCard";
import { useAuth } from "../../Providers/UserProvider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import { makeStyles } from "@material-ui/core";
import StoredNotiCard from "./StoredNotificationCard";
import StoredNotificationSubFilters from "./StoredNotificationSubFilters";

var styles = {
  root: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles((theme) => ({
  customButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px !important",
      padding: "0px 2px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "10px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "12px !important",
      minWidth: "0px !important",
      padding: "0px 6px !important",
    },
  },
  newcustomButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "7px !important",
      padding: "0px 2px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "10px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "12px !important",
      minWidth: "0px !important",
      padding: "0px 6px !important",
    },
  },
}));

const NotificationComp = ({ value, setValue }) => {
  const {
    notifications,
    noticategory,
    handleNotificationsCategory,
    updateNotificationView,

    getNotificationStoredData,
    updateNotificationStoredView,
    notistoredcategory,
    notistoreddata,
    handleNotificationsStoredCategory,
  } = useAuth();

  const classes = useStyles();

  const [categoryvalue, setcategoryValue] = useState("all");
  const [noticategoryvalue, setnoticategoryvalue] = useState("Morning");

  const handleChange = (event, newValue) => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    setValue(newValue);
    if (newValue === "one") updateNotificationView();
    if (newValue === "two") {
      const notificationStoredData = sessionStorage.getItem(
        "notificationStoredData",
      );
      if (notificationStoredData) updateNotificationStoredView("category");
      getNotificationStoredData(Data, "category");
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setcategoryValue(newValue);

    if (newValue !== "all") handleNotificationsCategory(newValue);
    else updateNotificationView();
  };

  const handleStoredCategoryChange = (event, newValue) => {
    setnoticategoryvalue(newValue);
    if (newValue !== "all") handleNotificationsStoredCategory(newValue);
    else updateNotificationStoredView();
  };

  return (
    <Card style={{ width: "100%" }} className="card">
      {/*  */}
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          component={Box}
          boxShadow={3}
          variant="fullWidth"
        >
          <Tab
            value="one"
            label="Notifications History"
            classes={{ root: classes.customButtonBaseRoot }}
          />
          <Tab
            value="two"
            label="Notifications Stored"
            classes={{ root: classes.customButtonBaseRoot }}
          />
        </Tabs>
        <TabPanel value="one" style={{ padding: "0" }}>
          <TabContext value={categoryvalue}>
            <Tabs
              value={categoryvalue}
              onChange={handleCategoryChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              centered
              style={styles.root}
            >
              <Tab
                value="all"
                label="All"
                classes={{ root: classes.customButtonBaseRoot }}
              />
              {noticategory?.map((res) => (
                <Tab
                  value={res}
                  label={res}
                  classes={{ root: classes.customButtonBaseRoot }}
                />
              ))}
            </Tabs>
            <TabPanel value="all" style={{ padding: "0" }}>
              {notifications &&
                notifications?.map((res, key) => (
                  <NotificationsCard key={key} res={res} />
                ))}
            </TabPanel>
            {notifications !== undefined &&
              noticategory?.map((data) => (
                <TabPanel value={data} style={{ padding: "0" }}>
                  {notifications &&
                    notifications?.map((res, key) => (
                      <NotificationsCard key={key} res={res} />
                    ))}
                </TabPanel>
              ))}
          </TabContext>
        </TabPanel>
        <TabPanel value="two" style={{ padding: "0" }}>
          <TabContext value={noticategoryvalue}>
            <Tabs
              value={noticategoryvalue}
              onChange={handleStoredCategoryChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              centered
              style={styles.root}
            >
              <Tab
                value="all"
                label="All"
                classes={{ root: classes.newcustomButtonBaseRoot }}
              />
              {notistoredcategory?.map((res) => (
                <Tab
                  value={res}
                  label={res}
                  classes={{ root: classes.newcustomButtonBaseRoot }}
                />
              ))}
            </Tabs>
            <TabPanel value="all" style={{ padding: "0" }}>
              <StoredNotificationSubFilters
                notidata={notistoreddata}
                type="all"
              />
            </TabPanel>
            {notistoreddata !== undefined &&
              notistoredcategory?.map((data) => (
                <TabPanel value={data} style={{ padding: "0" }}>
                  {/* {notistoreddata &&
                    notistoreddata?.map((res, key) => (
                      <StoredNotiCard key={key} res={res} />
                    ))} */}
                  <StoredNotificationSubFilters
                    notidata={notistoreddata}
                    type={data}
                  />
                </TabPanel>
              ))}
          </TabContext>
        </TabPanel>
      </TabContext>
    </Card>
  );
};

export default NotificationComp;
