import React from "react";
import SidebarComp from "../Components/Sidebar";
import SidebarMob from "../Components/SidebarMob";
import Navbar from "../Components/Navbar/Navbar";
import { useAuth } from "../Providers/UserProvider";
import { useLocation } from "react-router-dom";
import SingleTranscation from "../Components/TranscationDetail/SingleTranscation";

const TranscationListDetail = () => {
  const { closeSidebar } = useAuth;

  const location = useLocation();

  return (
    <>
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <SingleTranscation data={location.state.data} />
      </div>
    </>
  );
};

export default TranscationListDetail;
