import React from "react";
import { Card, CardContent, Button } from "@mui/material";
import "../../CSSFiles/grammar.css";
import { AiOutlineRight } from "react-icons/ai";
import { useRef } from "react";
import { useOutsideHook } from "../../Components/ClickOutside";

const ModalComp = ({ handleFunction, IsModalScreen, children }) => {
  const wrapperRef = useRef();
  useOutsideHook(wrapperRef, handleFunction);
  return (
    <Card
      className={
        IsModalScreen
          ? `modal_grammar_card slideIn`
          : `modal_grammar_card slideOut`
      }
      ref={wrapperRef}
    >
      <CardContent>
        <AiOutlineRight
          onClick={(e) => {
            e.preventDefault();
            handleFunction();
          }}
          style={{ cursor: "pointer" }}
        />
        <div style={{ padding: "25px" }}>{children}</div>
      </CardContent>
    </Card>
  );
};

export default ModalComp;
