import React from "react";
import { Grid } from "@mui/material";
import FeaturesUsage from "../Charts/FeaturesUsage";
import UnpaidFeaturesUsage from "../Charts/UnpaidFeaturesUsage";
import AllUsersFeaturesUsage from "../Charts/AllUsersFeaturesUsage";
import { useAuth } from "../../Providers/UserProvider";

const CombineSeeMoreGraphComp = () => {
  const {
    paidunpaidallfeaturesusage,
    paidunpaidpaidfeaturesusage,
    paidunpaidunpaidfeaturesusage,
    androidallfeaturesusage,
    androidpaidfeaturesusage,
    androidunpaidfeaturesusage,
    iosallfeaturesusage,
    iospaidfeaturesusage,
    iosunpaidfeaturesusage,
  } = useAuth();
  return (
    <Grid container style={{ textAlign: "-webkit-center" }} spacing={1}>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>All Users/ All</h2>
        <AllUsersFeaturesUsage retentiondata={paidunpaidallfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>All Users/ Paid</h2>

        <FeaturesUsage retentiondata={paidunpaidpaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>All Users/ Unpaid</h2>

        <UnpaidFeaturesUsage retentiondata={paidunpaidunpaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Android /All</h2>

        <AllUsersFeaturesUsage retentiondata={androidallfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Android /Paid</h2>

        <FeaturesUsage retentiondata={androidpaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Android /Unpaid</h2>

        <UnpaidFeaturesUsage retentiondata={androidunpaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Ios /All</h2>

        <AllUsersFeaturesUsage retentiondata={iosallfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Ios /Paid</h2>

        <FeaturesUsage retentiondata={iospaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Ios /Unpaid</h2>

        <UnpaidFeaturesUsage retentiondata={iosunpaidfeaturesusage} />
      </Grid>
    </Grid>
  );
};

export default CombineSeeMoreGraphComp;
