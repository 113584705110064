import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import { useAuth } from "../../Providers/UserProvider";
import { CardContent, Typography } from "@mui/material";
import "../../CSSFiles/app_control.css";
import {
  PaymentCardvalueIds,
  MembershipHeading,
  AppVersionHeading,
  PaymentControlHeading,
  AnswerControlHeading,
  ElseHeading,
} from "./Constant/StaticValueId";
import UserEditTextDialog from "../LastDaysUsersComp/SelectedUserDetailEdit/UserEditTextDialog";
import PaymentEditDialogBox from "./PaymentEditDialogBox";

const TableCell = withStyles({
  root: {
    borderCollapse: "separate",
  },
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const PayMentCard = () => {
  const classes = useStyles();
  const { paymentcontrolData } = useAuth();
  const [text, settext] = useState({
    title: "",
    value: "",
    valueId: "",
  });

  const [openedittextDialog, setopenedittextDialog] = useState(false);

  console.log(paymentcontrolData, "ji");

  const handleClickOpenEdit = () => {
    setopenedittextDialog(true);
  };

  const handleClickClose = () => {
    setopenedittextDialog(false);
  };

  const containsObject = (data) => {
    for (var i = 0; i < PaymentCardvalueIds.length; i++) {
      if (PaymentCardvalueIds[i].name === data[0]) return true;
    }
    return false;
  };

  const FindWhich = (val) => {
    if (val == 1) return "Day";
    else if (val == 2) return "Month";
    else return "Year";
  };

  const CheckCompleteProfile = (val) => {
    if (val == 0) return "Do not show complete profile alert on app screen";
    else if (val == 1) return "Show complete profile alert";
    else return "Complete profile forcefully";
  };

  const findObjectValues = (heading) => {
    for (var i = 0; i < paymentcontrolData.length; i++) {
      if (Object.keys(paymentcontrolData[i]) == heading[0])
        if (
          Object.keys(paymentcontrolData[i]) ==
            "membershipPlanADaysMonthlyYearly" ||
          Object.keys(paymentcontrolData[i]) ==
            "membershipPlanBDaysMonthlyYearly" ||
          Object.keys(paymentcontrolData[i]) ==
            "membershipPlanCDaysMonthlyYearly"
        )
          return FindWhich(Object.values(paymentcontrolData[i]));
        else if (Object.keys(paymentcontrolData[i]) == "completeProfile")
          return CheckCompleteProfile(Object.values(paymentcontrolData[i]));
        else return Object.values(paymentcontrolData[i]);
    }
    return "";
  };

  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      {/* {paymentcontrolData?.map((data) => {
        return (
          <Card
            className="cardsub"
            style={{ marginBottom: "2vh" }}
            onClick={(e) => {
              e.preventDefault();
              const dataval = containsObject(Object.keys(data));
              if (!dataval) return;
              const valueId = PaymentCardvalueIds.find(
                (res) => res.name === Object.keys(data)[0]
              );

              settext({
                title: Object.keys(data)[0],
                value: Object.values(data)[0],
                valueId: valueId.valueId,
              });
              handleClickOpenEdit();
            }}
          >
            <CardContent>
              <div className="payment_data">
                <Typography className="title">{Object.keys(data)} :</Typography>
                <Typography className="body" style={{ lineBreak: "anywhere" }}>
                  {Object.values(data)}
                </Typography>
              </div>
            </CardContent>
          </Card>
        );
      })} */}

      <div style={{ margin: "25px" }}>
        <Typography>Membership</Typography>
      </div>
      {MembershipHeading?.map((data) => {
        return (
          <Card
            className="cardsub"
            style={{ marginBottom: "2vh" }}
            onClick={(e) => {
              e.preventDefault();
              const dataval = containsObject(Object.keys(data));
              if (!dataval) return;
              const valueId = PaymentCardvalueIds.find(
                (res) => res.name === Object.keys(data)[0],
              );

              settext({
                title: Object.keys(data)[0],
                value: Object.values(data)[0],
                valueId: valueId.valueId,
              });
              handleClickOpenEdit();
            }}
          >
            <CardContent>
              <div className="payment_data">
                <Typography className="title">{Object.keys(data)} :</Typography>
                <Typography className="body" style={{ lineBreak: "anywhere" }}>
                  {findObjectValues(Object.keys(data))}
                </Typography>
              </div>
            </CardContent>
          </Card>
        );
      })}

      <div style={{ margin: "25px" }}>
        <Typography>App Version</Typography>
      </div>

      {AppVersionHeading?.map((data) => {
        return (
          <Card
            className="cardsub"
            style={{ marginBottom: "2vh" }}
            onClick={(e) => {
              e.preventDefault();
              const dataval = containsObject(Object.keys(data));
              if (!dataval) return;
              const valueId = PaymentCardvalueIds.find(
                (res) => res.name === Object.keys(data)[0],
              );

              settext({
                title: Object.keys(data)[0],
                value: Object.values(data)[0],
                valueId: valueId.valueId,
              });
              handleClickOpenEdit();
            }}
          >
            <CardContent>
              <div className="payment_data">
                <Typography className="title">{Object.keys(data)} :</Typography>
                <Typography className="body" style={{ lineBreak: "anywhere" }}>
                  {findObjectValues(Object.keys(data))}
                </Typography>
              </div>
            </CardContent>
          </Card>
        );
      })}

      <div style={{ margin: "25px" }}>
        <Typography>Payment Control</Typography>
      </div>

      {PaymentControlHeading?.map((data) => {
        return (
          <Card
            className="cardsub"
            style={{ marginBottom: "2vh" }}
            onClick={(e) => {
              e.preventDefault();
              const dataval = containsObject(Object.keys(data));
              if (!dataval) return;
              const valueId = PaymentCardvalueIds.find(
                (res) => res.name === Object.keys(data)[0],
              );

              settext({
                title: Object.keys(data)[0],
                value: Object.values(data)[0],
                valueId: valueId.valueId,
              });
              handleClickOpenEdit();
            }}
          >
            <CardContent>
              <div className="payment_data">
                <Typography className="title">{Object.keys(data)} :</Typography>
                <Typography className="body" style={{ lineBreak: "anywhere" }}>
                  {findObjectValues(Object.keys(data))}
                </Typography>
              </div>
            </CardContent>
          </Card>
        );
      })}
      <div style={{ margin: "25px" }}>
        <Typography>Answer Control</Typography>
      </div>

      {AnswerControlHeading?.map((data) => {
        return (
          <Card
            className="cardsub"
            style={{ marginBottom: "2vh" }}
            onClick={(e) => {
              e.preventDefault();
              const dataval = containsObject(Object.keys(data));
              if (!dataval) return;
              const valueId = PaymentCardvalueIds.find(
                (res) => res.name === Object.keys(data)[0],
              );

              settext({
                title: Object.keys(data)[0],
                value: Object.values(data)[0],
                valueId: valueId.valueId,
              });
              handleClickOpenEdit();
            }}
          >
            <CardContent>
              <div className="payment_data">
                <Typography className="title">{Object.keys(data)} :</Typography>
                <Typography className="body" style={{ lineBreak: "anywhere" }}>
                  {findObjectValues(Object.keys(data))}
                </Typography>
              </div>
            </CardContent>
          </Card>
        );
      })}

      {ElseHeading?.map((data) => {
        return (
          <Card
            className="cardsub"
            style={{ marginBottom: "2vh" }}
            onClick={(e) => {
              e.preventDefault();
              const dataval = containsObject(Object.keys(data));
              if (!dataval) return;
              const valueId = PaymentCardvalueIds.find(
                (res) => res.name === Object.keys(data)[0],
              );

              settext({
                title: Object.keys(data)[0],
                value: Object.values(data)[0],
                valueId: valueId.valueId,
              });
              handleClickOpenEdit();
            }}
          >
            <CardContent>
              <div className="payment_data">
                <Typography className="title">{Object.keys(data)} :</Typography>
                <Typography className="body" style={{ lineBreak: "anywhere" }}>
                  {findObjectValues(Object.keys(data))}
                </Typography>
              </div>
            </CardContent>
          </Card>
        );
      })}

      <PaymentEditDialogBox
        text={text}
        opendialog={openedittextDialog}
        handleClickClose={handleClickClose}
        valueId={text.valueId}
        type="PaymentControls"
      />
    </Card>
  );
};

export default PayMentCard;
