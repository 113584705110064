import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { Button, Typography, IconButton } from "@mui/material";
import { useAuth } from "../../Providers/UserProvider";

const useStyles = makeStyles({
  root: {},
  paper: { borderRadius: 5, width: "100%" },
});

const ConfirmDialog = ({ opendialog, handleClickClose, partiinstanceid }) => {
  const { terminateInstance } = useAuth();
  const classes = useStyles();
  return (
    <Dialog
      open={opendialog}
      onClose={handleClickClose}
      aria-labelledby="customized-dialog-title"
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
          paddingTop: "5vh",
        }}
      >
        {handleClickClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClickClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            style={{ position: "absolute" }}
          >
            <AiOutlineCloseCircle />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        <DialogContentText className="title">
          Are You Sure want to terminate {partiinstanceid}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            terminateInstance(partiinstanceid);
            handleClickClose();
          }}
        >
          Yes
        </Button>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
