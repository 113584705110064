import React from "react";

const CouponCodeAdminView = ({ lastWeekData }) => {
  return (
    <table className="users_table_comp">
      <thead>
        <tr>
          <td align="center" style={{ fontWeight: "bold", border: "0" }}>
            Date
          </td>
          <td align="center" style={{ fontWeight: "bold", border: "0" }}>
            Payment Count
          </td>
          <td align="center" style={{ fontWeight: "bold", border: "0" }}>
            Price(in Rupees)
          </td>
        </tr>
      </thead>
      <tbody>
        {lastWeekData &&
          lastWeekData?.map((row) => (
            <tr key={row.Date} style={{ cursor: "pointer" }}>
              <td align="center">{row.Date}</td>
              <td align="center">{row.PaymentCount}</td>

              <td align="center">{row.Price}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default CouponCodeAdminView;
