import React from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import {
  changeTimeStampinTwelveHours,
  getTranscationPaymentGateway,
  getTranscationStatus,
} from "../../Services/Constants";
import { useAuth } from "../../Providers/UserProvider";
import { MdForward } from "react-icons/md";

const SingleTranscation = ({ data }) => {
  const { handleAppUserDetails } = useAuth();

  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          const userData = localStorage.getItem("userData");
          const Data = JSON.parse(userData);
          sessionStorage.setItem("selectedUser", JSON.stringify(data?.uid));

          handleAppUserDetails(data?.uid, Data);
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Uid:</Typography>
            <Typography className="body">{data?.uid}</Typography>
            <MdForward />
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Coupon Code:</Typography>
            <Typography className="body">{data?.couponCode}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">CouponOwner Get Money:</Typography>
            <Typography className="body">
              {data?.couponOwnerGetMoney}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Customer Information:</Typography>
            <Typography className="body">
              {data?.customerInformation}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Discount Get:</Typography>
            <Typography className="body">{data?.discountGet}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">
              Entry Visible to Coupon Code Owner:
            </Typography>
            <Typography className="body">
              {(data?.entryVisibleToCouponCodeOwner).toString()}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">
              Is Money Withdraw by CouponCode Owner:
            </Typography>
            <Typography className="body">
              {(data?.isMoneyWithdrawByCouponOwner).toString()}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Payment Amount:</Typography>
            <Typography className="body">{data?.paymentAmount}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Payment Date TimeStamp:</Typography>
            <Typography className="body">
              {changeTimeStampinTwelveHours(data?.paymentDatetimeStamp)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Payment Gateway:</Typography>
            <Typography className="body">
              {getTranscationPaymentGateway(data?.paymentGateway)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Payment Id:</Typography>
            <Typography className="body">{data?.paymentID}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Plan Expire Date:</Typography>
            <Typography className="body">
              {changeTimeStampinTwelveHours(data?.planExpireDate)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Razor Pay Deduction:</Typography>
            <Typography className="body">{data?.razorPayDeduction}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">
              Razor Pay Settle Money In Account:
            </Typography>
            <Typography className="body">
              {data?.razorPaySettleMoneyInAccount}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Settlement Id:</Typography>
            <Typography className="body">{data?.settlementId}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Status:</Typography>
            <Button
              variant="contained"
              className={
                data?.status === 2 || data?.status === 1
                  ? `trans settled`
                  : `trans failed`
              }
            >
              {getTranscationStatus(data?.status)}
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Student Send Money:</Typography>
            <Typography className="body">{data?.studentSendMoney}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">
              Wallet Amount Of Coupon Owner:
            </Typography>
            <Typography className="body">
              {data?.walletAmountOfCouponOwner}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Wallet Condition:</Typography>
            <Typography className="body">{data?.walletCondition}</Typography>
          </div>
        </CardContent>
      </Card>
    </Card>
  );
};

export default SingleTranscation;
