import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import "../../CSSFiles/notifications.css";
import { BiMessageDetail } from "react-icons/bi";
import { HiDuplicate } from "react-icons/hi";
import { useAuth } from "../../Providers/UserProvider";
import { useNavigate } from "react-router-dom";

const NotificationsCard = ({ res }) => {
  const { setnotititle, setnotidesc } = useAuth();
  const navigate = useNavigate();
  const changeDateFormate = (strDateTime) => {
    const date = new Date(strDateTime);
    var myDate = date.toLocaleString();
    return myDate;
  };
  return (
    <Card className="cardsub" style={{ marginBottom: "2vh", marginTop: "2vh" }}>
      <CardContent className="noticards">
        {/* Get the first para */}
        <div>
          <div className="griding">
            <div className="first">
              <div>
                <BiMessageDetail size={25} />
              </div>
              <div>
                <Typography className="title resp">{res.title}</Typography>
                <Typography className="body resp">{res.body}</Typography>
              </div>
            </div>
            <div className="second"></div>
            <div className="third">
              <Typography className="title resp">Start Date Time</Typography>
              <Typography className="body resp">
                {changeDateFormate(res.notificationStartTime)}
              </Typography>
            </div>
          </div>
        </div>
        {/* Get the second para */}
        <div className="duplicate">
          <div
            onClick={(e) => {
              e.preventDefault();
              setnotititle(res.title);
              setnotidesc(res.body);
              navigate("/customnoti");
            }}
          >
            <HiDuplicate className="duplicate_icon" />
          </div>
        </div>
        <div className="bottomhalf">
          <div className="totalnoti">
            <Typography className="title resp">Total Notifications</Typography>
            <Typography className="body resp">
              {res.totalNotificationSend}
            </Typography>
          </div>
          <div className="succesnoti">
            <Typography className="title resp">
              Succesfully Delivered
            </Typography>
            <Typography className="body resp">
              {res.sucessfullyDelivered}
            </Typography>
          </div>
          <div className="userinterac">
            <Typography className="title resp">User Interactions</Typography>
            <Typography className="body resp">{res.userEngagement}</Typography>
          </div>
          <div className="enddatetime">
            <Typography className="title resp">End Time</Typography>
            <Typography className="body resp">
              {changeDateFormate(res.notificationEndTime)}
            </Typography>
          </div>
          <div className="category">
            <Typography className="title resp">Category</Typography>
            <Typography className="body resp">{res.category}</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default NotificationsCard;
