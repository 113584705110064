import { Button, Card, CardContent, Fab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaArrowCircleRight, FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { changeDateFormate } from "../../Services/Constants";
import { useAuth } from "../../Providers/UserProvider";
import WritingDetailAddSubCatModal from "./WritingDetailAddSubCatModal";
import "../../CSSFiles/listening.css";
import { RiAddFill } from "react-icons/ri";
import { HiPencil } from "react-icons/hi"
import WritingEditCatModal from "./EditFields/WritingEditCatModal";

const WritingDetailComp = () => {
  const navigate = useNavigate();
  const [openAddModal, setopenAddModal] = useState(false);
  const [openEditCategory, setopenEditCategory] = useState(false);
  const location = useLocation();

  const { getwritingTest, updatewritingTest, singlewritinglistData, setselectedsubcategoryname } =
    useAuth();


  const handleOpenModel = () => {
    setopenAddModal(true);
  };

  const handleCloseModel = () => {
    setopenAddModal(false);
  };

  const handleOpenEditModal = () => {
    setopenEditCategory(true);
  }

  const handleCloseEditModal = () => {
    setopenEditCategory(false);
  }

  useEffect(() => {
    StartFeedBackPage();
  }, []);

  const StartFeedBackPage = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    const writingTest = sessionStorage.getItem("writingTest");
    if (writingTest) {
      updatewritingTest();
    }
    getwritingTest(Data);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Card style={{ width: "100%", overflowY: "scroll", height: "95vh" }}>
        <CardContent style={{ position: "relative" }}>
          <div style={{ padding: "10px" }}>
            <FaRegArrowAltCircleLeft
              onClick={(e) => {
                e.preventDefault();
                handleNavigateBack();
              }}
              style={{ cursor: "pointer" }}
              size={35}
            />
          </div>
          <div className="category_writing">
            <div className="profileContainer">
              <img
                src={singlewritinglistData?.thumbnail}
                alt="ProfileImage"
                className="profileImage"
              />
            </div>
            <Fab
              aria-label="add"
              style={{
                margin: 0,
                right: 50,
                bottom: 80,
                position: "fixed",
                zIndex: "1",
                backgroundColor: "#051e34",
                color: "white",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleOpenEditModal();
              }}
            >
              <HiPencil size={30} style={{ color: "white" }} />
            </Fab>
            <div style={{ width: "100%" }}>
              <Card className="cardsub" style={{ marginBottom: "2vh" }}>
                <CardContent>
                  <div className="payment_data">
                    <Typography className="title">Category Name :</Typography>
                    <Typography
                      className="body"
                      style={{ lineBreak: "anywhere" }}
                    >
                      {singlewritinglistData?.CategoryName}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
              <Card className="cardsub" style={{ marginBottom: "2vh" }}>
                <CardContent>
                  <div className="payment_data">
                    <Typography className="title">Created At :</Typography>
                    <Typography
                      className="body"
                      style={{ lineBreak: "anywhere" }}
                    >
                      {changeDateFormate(singlewritinglistData?.created_at)}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
              <Card className="cardsub" style={{ marginBottom: "2vh" }}>
                <CardContent>
                  <div className="payment_data">
                    <Typography className="title">Updated At :</Typography>
                    <Typography
                      className="body"
                      style={{ lineBreak: "anywhere" }}
                    >
                      {changeDateFormate(singlewritinglistData?.updated_at)}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          <div
            style={{
              margin: "10px 25px",
            }}
          >
            <Typography style={{ fontWeight: "500", fontSize: "1.5rem" }}>
              Sub Category Lists
            </Typography>
          </div>
          {singlewritinglistData?.SubCategoryList?.map((res) => (
            <Card
              className="cardsub"
              style={{ marginTop: "2vh" }}
              onClick={(e) => {
                e.preventDefault();
                sessionStorage.setItem("SelectedCategoryData", JSON.stringify(
                  singlewritinglistData?.CategoryName,
                ))
                setselectedsubcategoryname(
                  res.SubCategoryName
                )

                navigate(`/writingdata/${res.SubCategoryName}/questions`, {
                  state: {
                    category: singlewritinglistData?.CategoryName,
                    subcategory: res.SubCategoryName,
                  },
                });
              }}
            >
              <CardContent style={{ position: "relative" }}>
                <div className="payment_data" style={{ marginBottom: "10px" }}>
                  <Typography className="title">Id :</Typography>
                  <Typography
                    className="body"
                    style={{ lineBreak: "anywhere" }}
                  >
                    {res.id}
                  </Typography>
                </div>
                <div className="duplicate">
                  <FaArrowCircleRight className="duplicate_icon" />
                </div>
                <div className="payment_data">
                  <Typography className="title">SubCategory :</Typography>
                  <Typography
                    className="body"
                    style={{ lineBreak: "anywhere" }}
                  >
                    {res.SubCategoryName}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          ))}
          <WritingEditCatModal
            opendialog={openEditCategory}
            handleClickClose={handleCloseEditModal}
            data={singlewritinglistData?.CategoryName}
          />
          <WritingDetailAddSubCatModal
            opendialog={openAddModal}
            handleClickClose={handleCloseModel}
            data={singlewritinglistData?.CategoryName}
          />
        </CardContent>
      </Card>
      <Fab
        aria-label="add"
        style={{
          margin: 0,
          right: 50,
          bottom: 80,
          position: "fixed",
          zIndex: "1",
          backgroundColor: "#051e34",
          color: "white",
        }}
        onClick={(e) => {
          e.preventDefault();
          handleOpenModel();
        }}
      >
        <RiAddFill size={30} style={{ color: "white" }} />
      </Fab>
    </>
  );
};

export default WritingDetailComp;
