import React, { useEffect, useState } from "react";
import { Dialog, FormControl } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core";
import { Typography, IconButton, TextField, Button } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Select, MenuItem } from "@mui/material";
import ConfirmEditDialog from "../../CouponComp/EditForms/ConfirmEditDialog";
import DragAndDropComp from "../../UploadingVideoFormComp/DragAndDropComp/DragAndDropComp";

const useStyles = makeStyles({
  root: {},
  paper: { borderRadius: 5, width: "100%" },
});

const data = [
  { value: "0", label: "No Permission Given" },
  { value: "1", label: "Speaking" },
  { value: "2", label: "Listening" },
  { value: "3", label: "Reading" },
  { value: "4", label: "Writing" },
];

const AdminEditTextDialog = ({
  opendialog,
  handleClickClose,
  text,
  valueId,
  type,
}) => {
  const classes = useStyles();

  const [value, setValue] = useState(text.value);
  const [open, setopen] = useState(false);
  const [thumbNailUpload, setthumbNailUpload] = useState({
    preview: "",
    raw: "",
  });

  const [age, setAge] = useState(text.value);

  const [selectedName, setselectedName] = useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClickOpenConfirm = () => {
    setopen(true);
  };

  const handleClickCloseConfirm = () => {
    setopen(false);
  };

  useEffect(() => {
    setValue(text.value);
    setAge(text.value);
    if (valueId == 3) {
      let stringing = text.value.toString();
      var arr = [];
      for (let i = 0; i < stringing.length; i++) {
        arr.push(stringing[i]);
      }
      setselectedName(arr);
    }
  }, [text.value]);

  return (
    <Dialog
      open={opendialog}
      onClose={handleClickClose}
      aria-labelledby="customized-dialog-title"
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
          paddingTop: "5vh",
        }}
      >
        {handleClickClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClickClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            style={{ position: "absolute" }}
          >
            <AiOutlineCloseCircle />
          </IconButton>
        ) : null}
      </DialogTitle>
      <div style={{ padding: "2vw" }}>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "5px",
            alignItems: "center",
            textAlign: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography className="title">{text.title} </Typography>
          {valueId === "1" && (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={0}>Under Review</MenuItem>
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={2}>Suspend</MenuItem>
              <MenuItem value={3}>Delete</MenuItem>
            </Select>
          )}
          {valueId === "2" && (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={0}>Super Admin</MenuItem>
              <MenuItem value={1}>Admin</MenuItem>
              <MenuItem value={3}>Data Entry</MenuItem>
              <MenuItem value={4}>Teacher</MenuItem>
              <MenuItem value={5}>Guest</MenuItem>
              <MenuItem value={43}>Teacher/DataEntry</MenuItem>
              <MenuItem value={6}>Developer</MenuItem>
            </Select>
          )}
          {valueId === "3" && (
            <FormControl sx={{ m: 1, width: 500 }}>
              <Select
                multiple
                value={selectedName}
                onChange={(e) => {
                  setselectedName(e.target.value);
                }}
              >
                {data.map((name) => (
                  <MenuItem key={name.value} value={name.value}>
                    {name.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {valueId === "4" && (
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          )}
          {valueId === "5" && (
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="number"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          )}
          {valueId === "6" && (
            <DragAndDropComp
              thumbNailUpload={thumbNailUpload}
              setthumbNailUpload={setthumbNailUpload}
              type="Image"
            />
          )}
        </div>
      </div>

      <DialogActions>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickOpenConfirm();
            // handleClickClose()
          }}
        >
          Submit
        </Button>

        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <ConfirmEditDialog
        open={open}
        handleClose={handleClickCloseConfirm}
        handleClickClose={handleClickClose}
        text={valueId === "4" || valueId === "5" ? value : age}
        selectedName={selectedName}
        title={text.title}
        valueId={valueId}
        type={type}
        thumbNailUpload={thumbNailUpload}
      />
    </Dialog>
  );
};

export default AdminEditTextDialog;
