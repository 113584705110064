import React, { useState } from "react";
import DragAndDropComp from "./DragAndDropComp/DragAndDropComp";
import "./DragAndDropComp/component.css";
import CalendarComp from "../../MiddleWares/CalendarComp";
import ButtonComp from "../CustomButtons/ButtonComp";
import MuiAlert from "@mui/material/Alert";
import CustomDialogBox from "../CustomDialogBox";
import { AddListeningTestDialogBodyTxt } from "../../MiddleWares/AddListeningTestDataDialogBodyTxt";
import { useAuth } from "../../Providers/UserProvider";
import { getDateString } from "../../Services/Constants";
import { Typography } from "@mui/material";

const UploadvideoFormComp = () => {
  const { uploadListeningTestData } = useAuth();
  const [thumbNailUpload, setthumbNailUpload] = useState({
    preview: "",
    raw: "",
  });

  const [errorClicked, seterrorClicked] = useState(false);

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const handleSubmitData = () => {
    seterrorClicked(true);
    if (
      thumbNailUpload.preview === "" ||
      testFileUrl === "" ||
      calendarData === ""
    )
      return;
    handleClickOpen();
  };

  const handleSubmitOfDialog = () => {
    let dataString = getDateString(calendarData);
    uploadListeningTestData(testFileUrl, thumbNailUpload.raw, dataString);
  };

  const [testFileUrl, settestFileUrl] = useState("");

  const [calendarData, setcalendarDate] = useState(new Date());

  return (
    <div id="parent-form-div">
      <DragAndDropComp
        thumbNailUpload={thumbNailUpload}
        setthumbNailUpload={setthumbNailUpload}
        type="Image"
      />
      <div style={{ margin: "15px", maxWidth: "100%" }}>
        <textarea
          rows="2"
          className="grammar_text_area"
          placeholder="Enter Test File Url"
          value={testFileUrl}
          onChange={(e) => {
            settestFileUrl(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          margin: "15px",
          maxWidth: "100%",
          position: "relative",
          transform: "unset",
        }}
      >
        <Typography>Test date that we show in thumbnail</Typography>
        <CalendarComp
          calendarData={calendarData}
          setcalendarDate={setcalendarDate}
        />
      </div>
      <div
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "center",
          transform: "unset",
        }}
      >
        <ButtonComp
          buttonText="Create"
          handleClick={() => {
            handleSubmitData();
          }}
        />
      </div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        {(thumbNailUpload.preview === "" ||
          testFileUrl === "" ||
          calendarData === "") &&
          errorClicked && (
            <MuiAlert
              severity="error"
              style={{ display: "inline-flex", justifyContent: "center" }}
            >
              Enter All Fields !!!
            </MuiAlert>
          )}
      </div>
      <CustomDialogBox
        opendialog={opendialog}
        handleClickClose={handleClickClose}
        bodyText={
          <AddListeningTestDialogBodyTxt
            thumbnail={thumbNailUpload.preview}
            testfileurl={testFileUrl}
            dateObj={calendarData}
          />
        }
        confirmationText="Are You Sure Want To Add These Changes"
        handleSubmitOfDialog={handleSubmitOfDialog}
      />
    </div>
  );
};

export default UploadvideoFormComp;
