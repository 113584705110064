import React, { useState } from "react";

import Card from "@material-ui/core/Card";
import { useAuth } from "../../Providers/UserProvider";
import ListeningTestCard from "../ListeningTestComp/ListeningTestCard";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@material-ui/core";

var styles = {
  root: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles((theme) => ({
  customButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px !important",
      padding: "0px 2px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "10px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "12px !important",
      minWidth: "0px !important",
      padding: "0px 6px !important",
    },
  },
  newcustomButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "7px !important",
      padding: "0px 2px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "10px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "12px !important",
      minWidth: "0px !important",
      padding: "0px 6px !important",
    },
  },
}));

const WritingTestTable = () => {
  const { writingListData, getwritingTest } = useAuth();
  const classes = useStyles()
  const [value, setValue] = useState("one");
  const [categoryvalue, setcategoryValue] = useState("academic");


  const handleChange = (event, newValue) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    getwritingTest(userData);

    setValue(newValue);
  };

  const handleCategoryChange = (event, newValue) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    getwritingTest(userData);
    setcategoryValue(newValue);
  };

  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      {/* {writingListData?.map((res) => (
        <ListeningTestCard data={res} type="Writing" />
      ))} */}
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          component={Box}
          boxShadow={3}
          variant="fullWidth"
        >
          <Tab
            value="one"
            label="Task 1"
            classes={{ root: classes.customButtonBaseRoot }}
          />
          <Tab
            value="two"
            label="Task 2"
            classes={{ root: classes.customButtonBaseRoot }}
          />
        </Tabs>
        <TabPanel value="one">
          <TabContext value={categoryvalue}>
            <Tabs
              value={categoryvalue}
              onChange={handleCategoryChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              centered
              style={styles.root}
            >
              <Tab
                value="academic"
                label="Academic"
                classes={{ root: classes.customButtonBaseRoot }}
              />
              <Tab
                value="general"
                label="General"
                classes={{ root: classes.customButtonBaseRoot }}
              />
            </Tabs>
            <TabPanel value="academic">
              {writingListData?.WritingCategoryTask1AcademicData?.map((res) => (
                <ListeningTestCard data={res} type="Writing" subtype="task1" task1type="academic" />
              ))}
            </TabPanel>
            <TabPanel value="general">
              {writingListData?.WritingCategoryTask1GenralData?.map((res) => (
                <ListeningTestCard data={res} type="Writing" subtype="task1" task1type="general" />
              ))}
            </TabPanel>
          </TabContext>
        </TabPanel>
        <TabPanel value="two">
          {writingListData?.WritingCategoryData?.map((res) => (
            <ListeningTestCard data={res} type="Writing" subtype="task2" />
          ))}
        </TabPanel>
      </TabContext>
    </Card>
  );
};

export default WritingTestTable;
