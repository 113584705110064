import React, { useState } from "react";
import FilterOpt from "../../Components/GrammarMistaken/FilterOpt";
import GrammarComp from "../../Components/GrammarMistaken/GrammarComp";
import ModalComp from "../../Components/GrammarMistaken/ModalComp";
import SidebarComp from "../../Components/Sidebar";

const GrammarMistaken = () => {
  const [IsModalScreen, sethandleModalScreen] = useState(false);
  const [textvalue, settextvalue] = useState("");

  const handleFunction = () => {
    console.log("clicked");
    sethandleModalScreen(!IsModalScreen);
  };

  const handleAnswerClick = (e) => {
    handleFunction();
    settextvalue(e);
  };

  return (
    <div className="home">
      <SidebarComp />
      <GrammarComp
        handleFunction={handleFunction}
        textvalue={textvalue}
        settextvalue={settextvalue}
      />
      {IsModalScreen && (
        <ModalComp
          handleFunction={handleFunction}
          IsModalScreen={IsModalScreen}
          sethandleModalScreen={sethandleModalScreen}
          children={<FilterOpt handleAnswerClick={handleAnswerClick} />}
        />
      )}
    </div>
  );
};

export default GrammarMistaken;
