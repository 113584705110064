import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import "../../CSSFiles/feedbacks.css";
import TabContext from "@mui/lab/TabContext";
import RawSuggestionFeedback from "./RawSuggestionFeedback";
import { useAuth } from "../../Providers/UserProvider";

var styles = {
  secondFilter: {
    marginTop: "10px",
  },
};

const RawSuggestionSubfilterPlatform = ({ data }) => {
  const [filteredValue, setfilteredValue] = useState(0);
  const { getFeedBackData } = useAuth();

  const handleFilteredChange = (event, newValue) => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);

    getFeedBackData(Data);
    setfilteredValue(newValue);
  };

  return (
    <TabContext value={filteredValue}>
      <Tabs
        value={filteredValue}
        onChange={handleFilteredChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        centered
        style={styles.secondFilter}
      >
        <Tab value={0} label="All" />
        <Tab value={1} label="Android" />
        <Tab value={2} label="Ios" />
      </Tabs>
      <TabPanel value={0}>
        {data?.map((res, key) => (
          <RawSuggestionFeedback res={res} key={key} />
        ))}
      </TabPanel>
      <TabPanel value={1}>
        {data
          ?.filter((res) => res.platform === "android")
          ?.map((res, key) => (
            <RawSuggestionFeedback res={res} key={key} />
          ))}
      </TabPanel>
      <TabPanel value={2}>
        {data
          ?.filter((res) => res.platform === "iOS")
          ?.map((res, key) => (
            <RawSuggestionFeedback res={res} key={key} />
          ))}
      </TabPanel>
    </TabContext>
  );
};

export default RawSuggestionSubfilterPlatform;
