import React from "react";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import SpeakingTestFeatures from "./SpeakingTestFeatures";
import { useAuth } from "../../../Providers/UserProvider";
import { Typography } from "@material-ui/core";

const SpeakingTestDetailsComp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { speakingtestData } = useAuth();

  const handleNavigateBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div style={{ padding: "10px" }}>
        <FaRegArrowAltCircleLeft
          onClick={(e) => {
            e.preventDefault();
            handleNavigateBack();
          }}
          style={{ cursor: "pointer" }}
          size={35}
        />
      </div>
      <SpeakingTestFeatures
        data={location.state.data}
        type={location.state.type}
      />
      <div
        style={{ display: "flex", justifyContent: "center", margin: "10vh" }}
      >
        <video width="640" height="400" controls>
          <source src={location.state.data.videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {location.state.type === "QueCard" && (
        <>
          {speakingtestData?.followUpQues?.filter(
            (res) => res.queCardId === location.state.data.uniqueQuestionId,
          )?.length > 0 && (
            <div style={{ margin: "10px" }}>
              <Typography className="detail-title">
                Follow Up Questions
              </Typography>
            </div>
          )}
          {speakingtestData?.followUpQues
            ?.filter(
              (res) => res.queCardId === location.state.data.uniqueQuestionId,
            )
            ?.map((res) => (
              <>
                <SpeakingTestFeatures data={res} type="Follow Up Questions" />
              </>
            ))}
        </>
      )}
    </div>
  );
};

export default SpeakingTestDetailsComp;
