import { Button, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/UserProvider";
import {
  changeDateFormateInTwelveHours,
  getTranscationStatus,
} from "../../Services/Constants";
import UsersSearchField from "../../Components/LastDaysUsersComp/UsersSearchField";

const TranscationListMob = () => {
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const { getTranscationList, transcationData } = useAuth();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const Data = JSON.parse(userData);
    getTranscationList(Data);
  }, []);

  const handleNavigate = (data) => {
    navigate(`/transcation/${data?.uid}`, {
      state: { data: data },
    });
  };

  return (
    <div style={{ overflowX: "hidden", position: "relative" }}>
      <div style={{ padding: "5px" }}>
        <FaRegArrowAltCircleLeft
          onClick={(e) => {
            e.preventDefault();
            handleNavigateBack();
          }}
          style={{ cursor: "pointer" }}
          size={35}
        />
      </div>
      <UsersSearchField type="TranscationList" />

      <div>
        {transcationData?.map((row) => {
          return (
            <Card
              className="subcards"
              style={{ marginBottom: "1vh" }}
              onClick={(e) => {
                e.preventDefault();
                handleNavigate(row);
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "4px",
                  alignItems: "center",
                }}
              >
                <Typography className="title">Payment Id :</Typography>
                <Typography className="body">{row.paymentID}</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "4px",
                  alignItems: "center",
                }}
              >
                <Typography className="title">Amount :</Typography>
                <Typography className="body">{row.paymentAmount}</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "4px",
                  alignItems: "center",
                }}
              >
                <Typography className="title">Email :</Typography>
                <Typography className="body">
                  {row.customerInformation}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "4px",
                  alignItems: "center",
                }}
              >
                <Typography className="title">CreatedAt :</Typography>
                <Typography className="body">
                  {changeDateFormateInTwelveHours(row.created_at)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "4px",
                  alignItems: "center",
                }}
              >
                <Typography className="title">Status :</Typography>
                <Button
                  variant="contained"
                  className={
                    row.status === 2 || row.status === 1
                      ? `mobibutton settled`
                      : `mobibutton failed`
                  }
                >
                  {getTranscationStatus(row.status)}
                </Button>
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default TranscationListMob;
