import React, { useEffect } from "react";
import SidebarComp from "../../../Components/Sidebar";
import SidebarMob from "../../../Components/SidebarMob";
import { useAuth } from "../../../Providers/UserProvider";
import Navbar from "../../../Components/Navbar/Navbar";
import { useLocation } from "react-router-dom";
import EditScheduleClassDetail from "../../../Components/IeltsClassesComp/IeltsClassDetails/EditScheduleClass";
import EditLiveClassCard from "../../../Components/IeltsClassesComp/IeltsClassDetails/EditLiveClassCard";

const ClassesDetailsEdit = () => {
  const { closeSidebar, getDetailsOfAClass, classDetailedData } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    getDetailsOfAClass(
      userData,
      location.state.data.classFile,
      location.state.data.classUniqueId,
    );
  }, []);

  return (
    <>
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />

        {location.state.type === "Schedule" ? (
          <EditScheduleClassDetail data={classDetailedData} />
        ) : (
          <EditLiveClassCard data={classDetailedData} />
        )}
      </div>
    </>
  );
};

export default ClassesDetailsEdit;
