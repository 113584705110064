import React, { useEffect, useState } from "react";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CouponSpentDetailComp from "../../Components/CouponComp/CouponSpentDetailComp";
import { useAuth } from "../../Providers/UserProvider";
import TranscationDetail from "../../Components/CashBookTranscation/TranscationDetail";
import ModalComp from "../../Components/GrammarMistaken/ModalComp";

const CouponSpendDetail = () => {
  const navigate = useNavigate();
  const { getCouponSpendDetails } = useAuth();
  const [IsModalScreen, setIsModalScreen] = useState(false);

  const handleFunction = () => {
    setIsModalScreen(!IsModalScreen);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    startCouponSpendDetail();
  }, []);

  const startCouponSpendDetail = () => {
    const couponData = sessionStorage.getItem("selectedCouponId");
    const userData = localStorage.getItem("userData");
    getCouponSpendDetails(JSON.parse(userData), JSON.parse(couponData));
  };

  return (
    <div>
      {IsModalScreen && (
        <ModalComp
          handleFunction={handleFunction}
          IsModalScreen={IsModalScreen}
          sethandleModalScreen={setIsModalScreen}
          children={<TranscationDetail />}
        />
      )}
      <div style={{ padding: "5px" }}>
        <FaRegArrowAltCircleLeft
          onClick={(e) => {
            e.preventDefault();
            handleNavigateBack();
          }}
          style={{ cursor: "pointer" }}
          size={45}
        />
      </div>

      <CouponSpentDetailComp handleFunction={handleFunction} />
    </div>
  );
};

export default CouponSpendDetail;
