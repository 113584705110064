import React from "react";
import { Card, CardContent, TextField, Button } from "@mui/material";
import "../../CSSFiles/notifications.css";
import Select from "react-select";
import { useState, useEffect } from "react";
import { useAuth } from "../../Providers/UserProvider";
import { Alert } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core";
import { Typography, IconButton } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";

const options = [
  { value: "0", label: "All Users" },
  { value: "1", label: "Paid Users" },
  { value: "2", label: "Unpaid Users" },
];

const useStyles = makeStyles({
  root: {},
  paper: { borderRadius: 5, width: "100%" },
});

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    transform: "initial",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      zIndex: 1,
      transform: "initial",
    };
  },
};

const CustomNotiCard = () => {
  const {
    handleCustomNoti,
    isfailednoti,
    setisfailednoti,
    notititle,
    setnotititle,
    notidesc,
    setnotidesc,
  } = useAuth();

  const [filteropt, setfilteropt] = useState("");
  const [userBackdate, setuserBackdate] = useState(120);
  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const openDialogBox = () => {
    handleClickOpen();
  };

  const handleSubmit = () => {
    const user = localStorage.getItem("userData");
    const userdata = JSON.parse(user);
    handleCustomNoti(
      userdata,
      notititle,
      notidesc,
      filteropt.value,
      userBackdate,
    );
  };

  const closeError = () => {
    setisfailednoti(false);
  };

  const classes = useStyles();

  return (
    <Card className="card">
      <CardContent>
        <div className="main_con">
          <textarea
            rows="2"
            className="grammar_text_area"
            placeholder="Enter title here"
            value={notititle}
            onChange={(e) => {
              setnotititle(e.target.value);
            }}
          />
        </div>
        <div className="main_con" style={{ marginTop: "2vh" }}>
          <textarea
            rows="4"
            className="grammar_text_area"
            placeholder="Enter description here"
            value={notidesc}
            onChange={(e) => {
              setnotidesc(e.target.value);
            }}
          />
        </div>
        <div style={{ marginTop: "2vh" }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={userBackdate}
            className="notidistext"
            onChange={(e) => {
              setuserBackdate(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
          }}
        >
          <Select
            options={options}
            value={filteropt}
            onChange={(e) => {
              setfilteropt(e);
            }}
            isClearable
            styles={colourStyles}
          />
        </div>
        <div style={{ textAlign: "center", transform: "unset" }}>
          <Button
            variant="outlined"
            className="grammar_buttons"
            style={{ zIndex: "0" }}
            onClick={(e) => {
              e.preventDefault();
              openDialogBox();
            }}
          >
            Submit
          </Button>
        </div>
      </CardContent>

      {isfailednoti && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="error"
            onClose={() => closeError()}
            style={{ textAlign: "center" }}
          >
            Something went wrong
          </Alert>
        </div>
      )}

      {/* Dialog Box */}

      <Dialog
        open={opendialog}
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{
            paddingTop: "5vh",
          }}
        >
          {handleClickClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClickClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              style={{ position: "absolute" }}
            >
              <AiOutlineCloseCircle />
            </IconButton>
          ) : null}
        </DialogTitle>
        <div style={{ padding: "2vw" }}>
          <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
            <Typography className="title">Title :-</Typography>
            <Typography className="body">{notititle}</Typography>
          </div>
          <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
            <Typography className="title">Desc :-</Typography>
            <Typography className="body">{notidesc}</Typography>
          </div>
          <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
            <Typography className="title">Payback Date :-</Typography>
            <Typography className="body">{userBackdate}</Typography>
          </div>
          <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
            <Typography className="title">Filter User :-</Typography>
            <Typography className="body">{filteropt?.label}</Typography>
          </div>
        </div>
        <DialogContent>
          <DialogContentText className="title">Are You Sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className="grammar_buttons"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            className="grammar_buttons"
            onClick={(e) => {
              e.preventDefault();
              handleClickClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default CustomNotiCard;
