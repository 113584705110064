import React, { useEffect } from "react";
import { useAuth } from "../../Providers/UserProvider";
import { Typography } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import SidebarComp from "../../Components/Sidebar";
import AdminUserDetailsCard from "../../Components/LastDaysUsersComp/AdminUserDetailsCard";

const AdminUserDetailsPage = () => {
  const { timetorefresh, closeSidebar, handleAdminUserDetails } = useAuth();

  const startAdminDetailPage = () => {
    const userData = localStorage.getItem("userData");
    const Data = JSON.parse(userData);

    const selectedAdminUser = sessionStorage.getItem("selectedAdminUser");
    handleAdminUserDetails(JSON.parse(selectedAdminUser), Data);
  };

  useEffect(() => {
    startAdminDetailPage();
  }, []);

  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <AdminUserDetailsCard />
      </div>
    </>
  );
};

export default AdminUserDetailsPage;
