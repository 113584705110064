import { Typography } from "@mui/material";
import React from "react";

export const AddWritingQuesSubCatDialogBody = ({
  category,
  subcategory,
  ques,
  ans1,
  ans2,
}) => {
  return (
    <div style={{ padding: "2vw" }}>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Category :-</Typography>
        <Typography className="body">{category}</Typography>
      </div>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">SubCategory :-</Typography>
        <Typography className="body">{subcategory}</Typography>
      </div>
     
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Question :-</Typography>
        <Typography className="body">{ques}</Typography>
      </div>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Answer1 :-</Typography>
        <Typography className="body">{ans1}</Typography>
      </div>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Answer2 :-</Typography>
        <Typography className="body">{ans2}</Typography>
      </div>
    </div>
  );
};
