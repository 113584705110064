import { Typography } from "@mui/material";
import { getDateString } from "../Services/Constants";

export const AddListeningTestDialogBodyTxt = ({
  thumbnail,
  testfileurl,
  dateObj,
}) => {
  return (
    <div style={{ padding: "2vw" }}>
      <img src={thumbnail} width="200" height="200" className="thumbnail_img" />
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Test File Url :-</Typography>
        <Typography className="body">{testfileurl}</Typography>
      </div>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Date :-</Typography>
        <Typography className="body">{getDateString(dateObj)}</Typography>
      </div>
    </div>
  );
};
