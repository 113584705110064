export const api_url = "https://ieltspro.babycode.org";
// export const api_url = "https://46af-203-115-73-202.in.ngrok.io"
export const dummy_url = "https://01fa-2401-4900-1c0e-8fff-00-1-4fd7.ngrok.io";
export const new_api_url =
  "http://scheduletask-env.ap-south-1.elasticbeanstalk.com";

export const changeDateFormate = (strDateTime) => {
  const date = new Date(strDateTime);
  var myDate = date.toLocaleString();
  return myDate;
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const changeDateFormateInTwelveHours = (strDateTime) => {
  const date = new Date(strDateTime);
  var myDate = date.toLocaleString([], { hour12: true });
  return myDate;
};

export const changeTimeStampinTwelveHours = (strDateTime) => {
  const date = new Date(strDateTime * 1000);
  var myDate = date.toLocaleString([], { hour12: true });
  return myDate;
};

export const changeTimeStamptoDate = (strDateTime) => {
  if (!strDateTime) return;
  var d = new Date(strDateTime * 1000),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getDateString = (strDate) => {
  var d = new Date(strDate),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

export const truncate = (source, size) => {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
};

export const getTranscationStatus = (status) => {
  if (status === 0) {
    return "Payment Authorizer";
  } else if (status === 1) {
    return "Capture";
  } else if (status === 2) {
    return "Settle";
  } else if (status === 3) {
    return "Failed";
  } else if (status === 4) {
    return "Refund";
  } else if (status === 5) {
    return "Unknown";
  }
};

export const getCashBookBank = (bankstatus) => {
  if (bankstatus === 0) {
    return "ICICI current account";
  } else if (bankstatus === 1) {
    return "KOTAK current account";
  } else if (bankstatus === 2) {
    return "ICICI Saving";
  } else if (bankstatus === 3) {
    return "Razor pay x";
  }
};

export const gettransactionStatus = (transactionStatus) => {
  if (transactionStatus === 0) {
    return "Withdraw";
  } else {
    return "Credit";
  }
};

export const getCashBookpaymentCategory = (paymentCategory) => {
  if (paymentCategory === 0) {
    return "Personal Expense";
  } else {
    return "Company Expense";
  }
};

export const IsProfileCompleted = (value) => {
  if (value === 0) return "Not Complete";
  else if (value === 1) return "Complete";
  else return "Cancel";
};

export const getCashBookpaymentSubCategory = (paymentCategory) => {
  if (paymentCategory === 0) {
    return "Unknown";
  } else if (paymentCategory === 1) {
    return "Salary";
  } else if (paymentCategory === 2) {
    return "Server";
  } else return "Advertisement";
};

export const getCashBookpaymentSource = (paymentSource) => {
  if (paymentSource === 0) {
    return "Personal";
  } else {
    return "Razor Pay settlement";
  }
};

export const transcationlistcompheadings = [
  "PaymentId",
  "Amount",
  "Email",
  "CreatedAt",
  "Status",
];

export const dateFormate = (date) => {
  var day = date.getDate();
  var month = date.getMonth() + 1;
  return `${day}/${month}`;
};

export const getLast30daysFromCurr = () => {
  var currentDate = new Date();
  var curr = new Date();
  var priorDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
  var arr = [];
  while (priorDate <= curr) {
    var date_obj = new Date(priorDate);
    arr.push(date_obj);
    priorDate.setDate(priorDate.getDate() + 1);
  }
  return arr;
};

export const getTranscationPaymentGateway = (gateway) => {
  if (gateway === 0) return "RazorPay";
  else if (gateway === 1) return "Google In App Purchase";
  else if (gateway === 2) return "BabyCode Wallet";
  else if (gateway === 3) return "Apple Pays";
};

export const getAccountStatusOfAdmins = (data) => {
  if (data === 0) return "Under Review";
  else if (data === 1) return "Active";
  else if (data === 2) return "Suspend";
  else return "Delete";
};

export const getAccountLevelOfAdmins = (data) => {
  if (data === 0) return "Super Admin";
  else if (data === 1) return "Admin";
  else if (data === 2) return "Sub Admin";
  else if (data === 3) return "Data Entry";
  else if (data === 4) return "Teacher";
  else if (data === 5) return "Guest";
  else if (data === 43) return "Teacher/DataEntry";
  else if (data === 6) return "Developer";
};

export const getdataEntryPermission = (data) => {
  if (data == 0) return "You have not been given acess";
  else if (data == 1) return "Speaking";
  else if (data == 2) return "Listening";
  else if (data == 3) return "Reading";
  else if (data == 4) return "Writing";
};

export const getTypeOfReadingQues = (data) => {
  if (data === 0) return "Academic Tests";
  else if (data === 1) return "General Tests";
};
