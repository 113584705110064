import React, { useState } from "react";
import "../../CSSFiles/notifications.css";
import { Card, Box } from "@mui/material";
import { useAuth } from "../../Providers/UserProvider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import { makeStyles } from "@material-ui/core";
import SpeakingQuesCard from "./SpeakingQuesCard";

var styles = {
  root: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles((theme) => ({
  customButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px !important",
      padding: "0px 2px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "10px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "12px !important",
      minWidth: "0px !important",
      padding: "0px 6px !important",
    },
  },
  newcustomButtonBaseRoot: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "7px !important",
      padding: "0px 2px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "10px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "12px !important",
      minWidth: "0px !important",
      padding: "0px 6px !important",
    },
  },
}));

const SpeakingQuesComp = () => {
  const [value, setValue] = useState("one");

  const classes = useStyles();
  const { speakingtestData, getSpeakingTestData } = useAuth();

  const handleChange = (event, newValue) => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    getSpeakingTestData(Data);

    setValue(newValue);
  };
  return (
    <Card style={{ width: "100%" }} className="card">
      {/*  */}
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          component={Box}
          boxShadow={3}
          variant="fullWidth"
        >
          <Tab value="one" label="Introduction Questions" />
          <Tab value="two" label="General Questions" />
          <Tab value="three" label="QueCard Questions" />
        </Tabs>
        <TabPanel value="one" style={{ padding: "0" }}>
          {speakingtestData?.intro?.map((res) => (
            <SpeakingQuesCard data={res} type="Intro" />
          ))}
        </TabPanel>
        <TabPanel value="two" style={{ padding: "0" }}>
          {speakingtestData?.general?.map((res) => (
            <SpeakingQuesCard data={res} type="General" />
          ))}
        </TabPanel>
        <TabPanel value="three" style={{ padding: "0" }}>
          {speakingtestData?.queCard?.map((res) => (
            <SpeakingQuesCard data={res} type="QueCard" />
          ))}
        </TabPanel>
      </TabContext>
    </Card>
  );
};

export default SpeakingQuesComp;
