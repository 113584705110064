import React, { useState } from "react";
import "../../CSSFiles/home.css";
import { Grid } from "@mui/material";
import PaymentBehaviour from "../../Components/Charts/PaymentBehaviour";
import "react-datepicker/dist/react-datepicker.css";
import FeaturesUsage from "../../Components/Charts/FeaturesUsage";
import UnpaidFeaturesUsage from "../../Components/Charts/UnpaidFeaturesUsage";
import AllUsersFeaturesUsage from "../../Components/Charts/AllUsersFeaturesUsage";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";

import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../Providers/UserProvider";
import CombinedRetentionData from "../Charts/CombinedRetentionData";
import CombinedTimeSpent from "../Charts/CombinedTimeSpent";
import { AiOutlineArrowRight } from "react-icons/ai";
import PaymentBehaviourInADay from "../Charts/PaymentBehaviourInADay";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  style: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px !important",
      padding: "0px 4px !important",
      minWidth: "0px !important",
    },
  },
}));

const CombinedBehComp = ({ handleFunction, value, setValue }) => {
  const classes = useStyles();
  const {
    getUserBehaviourData,
    paidunpaidallfeaturesusage,
    paidunpaidpaidfeaturesusage,
    paidunpaidunpaidfeaturesusage,
    allappleaveuserfeaturesusage,
    width,
  } = useAuth();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    getUserBehaviourData(Data);
    setValue(newValue);
    if (newValue === "five") {
      width < 660 ? navigate("/combinedusersmobseeall") : handleFunction();
    }
  };

  return (
    <>
      <div>
        <Grid
          container
          style={{
            textAlign: "-webkit-center",
            paddingRight: "15px",
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2
              style={{ textAlign: "-webkit-center" }}
              className="graph_headings"
            >
              Payment Behaviour In A Day
            </h2>

            <PaymentBehaviourInADay />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ textAlign: "-webkit-center", padding: "5px" }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2
              style={{ textAlign: "-webkit-center" }}
              className="graph_headings"
            >
              Payment Behaviour Graph
            </h2>

            <PaymentBehaviour />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2
              style={{ textAlign: "-webkit-center" }}
              className="graph_headings"
            >
              Retention Data Report
            </h2>
            <CombinedRetentionData />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          style={{ textAlign: "-webkit-center", padding: "5px" }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2
              style={{ textAlign: "-webkit-center" }}
              className="graph_headings"
            >
              Average Time Spent By User
            </h2>
            <CombinedTimeSpent />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "-webkit-center" }}
          >
            <h2
              style={{ textAlign: "-webkit-center", margin: "0" }}
              className="graph_headings"
            >
              Features Usage
            </h2>

            <TabContext value={value}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                centered
                style={{ height: "25px" }}
              >
                <Tab value="one" label="All" className={classes.style} />
                <Tab value="two" label="Paid" className={classes.style} />
                <Tab value="three" label="Unpaid" className={classes.style} />
                <Tab
                  value="four"
                  label="App Leave User"
                  className={classes.style}
                />
                <Tab value="five" label="See All" className={classes.style} />
              </Tabs>
              <TabPanel value="one" style={{ padding: "0" }}>
                <AllUsersFeaturesUsage
                  retentiondata={paidunpaidallfeaturesusage}
                />
              </TabPanel>
              <TabPanel value="two" style={{ padding: "0" }}>
                <FeaturesUsage retentiondata={paidunpaidpaidfeaturesusage} />
              </TabPanel>
              <TabPanel value="three" style={{ padding: "0" }}>
                <UnpaidFeaturesUsage
                  retentiondata={paidunpaidunpaidfeaturesusage}
                />
              </TabPanel>
              <TabPanel value="four" style={{ padding: "0" }}>
                <UnpaidFeaturesUsage
                  retentiondata={allappleaveuserfeaturesusage}
                />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CombinedBehComp;
