import { Typography } from "@mui/material";

export const AddAndEditFeedbackDialogBodyTxt = ({
  question,
  questionType,
  feedbackGroup,
  feedbackAnsOpt,
  questionNumber,
}) => {
  return (
    <div style={{ padding: "2vw" }}>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Question :-</Typography>
        <Typography className="body">{question}</Typography>
      </div>
      {questionNumber && (
        <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
          <Typography className="title">Question Number :-</Typography>
          <Typography className="body">{questionNumber}</Typography>
        </div>
      )}

      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Question Type :-</Typography>
        <Typography className="body">{questionType?.label}</Typography>
      </div>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">
          Optional Feedback Answer Options :-
        </Typography>
        <Typography className="body">{feedbackAnsOpt}</Typography>
      </div>
      <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
        <Typography className="title">Feedback Group :-</Typography>
        <Typography className="body">{feedbackGroup?.label}</Typography>
      </div>
    </div>
  );
};
