//import useState hook to create menu collapse state
import React, { useState, useEffect } from "react";

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

//import icons from react icons
import { AiOutlineEye } from "react-icons/ai";
import { FiArrowLeftCircle } from "react-icons/fi";
import { AiFillHome } from "react-icons/ai";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "../CSSFiles/sidebarmob.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../Providers/UserProvider";

const SidebarMob = () => {
  //create initial menuCollapse state using useState hook
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dataEntryPageAccessibility = JSON.parse(
    localStorage.getItem("dataEntryPageAccessibility"),
  );
  const { closeSidebar, setcloseSidebar } = useAuth();
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  //create a custom function that will change menucollapse state from false to true and true to false
  // eslint-disable-next-line

  useEffect(() => {
    var value = JSON.parse(localStorage.getItem("values"));
    if (value != null) setData(value);
  }, []);

  function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].elementName == obj.elementName) {
        return true;
      }
    }

    return false;
  }

  const handleOnclick = (e, url) => {
    setcloseSidebar(false);
    var json_str = JSON.parse(localStorage.getItem("values"));
    const obj = {
      elementName: e,
      url: url,
    };
    if (json_str == null) {
      var arr = [];
      arr.push(obj);
      var store_jsn = JSON.stringify(arr);
      localStorage.setItem("values", store_jsn);

      navigate(url);
    } else {
      if (containsObject(obj, json_str)) {
        navigate(url);
        return;
      }
      json_str.push(obj);
      var store_jsn1 = JSON.stringify(json_str);

      localStorage.setItem("values", store_jsn1);

      navigate(url);
    }
  };

  const handleNavigate = (e) => {
    navigate(e.url);
  };

  function containsObject2(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].elementName == obj.elementName) {
        return i;
      }
    }

    return -1;
  }

  const handleRemoveShortcuts = (e) => {
    var json_str = JSON.parse(localStorage.getItem("values"));
    var index = containsObject2(e, json_str);
    if (index !== -1) {
      json_str.splice(index, 1);
    }
    var store_jsn1 = JSON.stringify(json_str);

    localStorage.setItem("values", store_jsn1);
    var values = JSON.parse(localStorage.getItem("values"));
    setData(values);
  };

  return (
    <>
      <div id="header_mob">
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar>
          <SidebarHeader>
            <div className="closemenu">
              <FiArrowLeftCircle
                size={20}
                onClick={(e) => {
                  e.preventDefault();
                  setcloseSidebar(false);
                }}
              />
            </div>
          </SidebarHeader>

          <SidebarContent style={{ overflowY: "auto" }}>
            <Menu iconShape="square">
              {userData.accountLevel === 3 ||
              userData.accountLevel === 4 ||
              userData.accountLevel === 5 ||
              userData.accountLevel === 43 ||
              userData.accountLevel === 6 ? (
                <></>
              ) : (
                <MenuItem>
                  <div>
                    <Link
                      to="/home"
                      style={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                      }}
                    >
                      <AiFillHome style={{ color: "white" }} size="25" />

                      <div className="menu-driven">Home</div>
                    </Link>
                  </div>
                </MenuItem>
              )}
              <hr style={{ color: "#F2ECFF" }} />
              <MenuItem className="project-categories">
                Project Categories
              </MenuItem>
              {data != null &&
                data?.map((data) => (
                  <MenuItem className="project-categories">
                    <div className="menu-driven">
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavigate(data);
                        }}
                      >
                        {data?.elementName}
                      </div>
                      <AiOutlineEye
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemoveShortcuts(data);
                        }}
                      />
                    </div>
                  </MenuItem>
                ))}
              <hr />

              {userData.accountLevel === 3 ||
              userData.accountLevel === 4 ||
              userData.accountLevel === 43 ? (
                <></>
              ) : (
                <SubMenu title="App User Data">
                  {userData.accountLevel !== 5 && (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleOnclick("Users", "/users");
                      }}
                      className="link"
                    >
                      Users
                    </MenuItem>
                  )}
                  {userData.accountLevel !== 5 && (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleOnclick("User Behaviour", "/userbehaviour");
                      }}
                      className="link"
                    >
                      User Behaviour
                    </MenuItem>
                  )}
                  {userData.accountLevel !== 5 && (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleOnclick("Error Log", "/errorlog");
                      }}
                      className="link"
                    >
                      Error Log
                    </MenuItem>
                  )}
                  {userData.accountLevel !== 5 && (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleOnclick("Notifications", "/notifications");
                      }}
                      className="link"
                    >
                      Notifications
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick("Feedback", "/feedback");
                    }}
                    className="link"
                  >
                    Feedback
                  </MenuItem>
                </SubMenu>
              )}
              {userData.accountLevel === 3 ||
              userData.accountLevel === 4 ||
              userData.accountLevel === 5 ||
              userData.accountLevel === 43 ||
              userData.accountLevel === 6 ? (
                <></>
              ) : (
                <SubMenu title="App Control">
                  {userData.accountLevel === 0 && (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleOnclick("Admin Users", "/adminUsers");
                      }}
                      className="link"
                    >
                      Admin Users
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick("Payment Controls", "/paymentcontrol");
                    }}
                    className="link"
                  >
                    Payment Controls
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick("Coupon Code", "/couponcode");
                    }}
                    className="link"
                  >
                    Coupon Code
                  </MenuItem>

                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick(
                        "Speaking Test Control",
                        "/speakingtestcontrol",
                      );
                    }}
                    className="link"
                  >
                    Speaking Test Control
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick("Band Calculation", "/bandcalculation");
                    }}
                    className="link"
                  >
                    Band Calculation
                  </MenuItem>
                </SubMenu>
              )}
              {userData.accountLevel === 3 ||
              userData.accountLevel === 4 ||
              userData.accountLevel === 5 ||
              userData.accountLevel === 43 ||
              userData.accountLevel === 6 ? (
                <></>
              ) : (
                <SubMenu title="Testing Core Tech">
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick(
                        "Grammar Mistake Detection",
                        "/grammarmistaken",
                      );
                    }}
                    className="link"
                  >
                    Grammar Mistake Detection
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick("Feedback Controls", "/feedbackcontrols");
                    }}
                    className="link"
                  >
                    3D Avatar
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick("Feedback Controls", "/feedbackcontrols");
                    }}
                    className="link"
                  >
                    Speech To Text
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick("Feedback Controls", "/feedbackcontrols");
                    }}
                    className="link"
                  >
                    Image To Text
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick("Feedback Controls", "/feedbackcontrols");
                    }}
                    className="link"
                  >
                    Call
                  </MenuItem>
                </SubMenu>
              )}
              {userData.accountLevel === 4 ||
              userData.accountLevel === 5 ||
              userData.accountLevel === 6 ? (
                <></>
              ) : (
                <SubMenu title="Data Entry">
                  {(dataEntryPageAccessibility?.includes(2) ||
                    (userData.accountLevel !== 3 &&
                      userData.accountLevel !== 43)) && (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleOnclick("Listening Test", "/listeningtest");
                      }}
                      className="link"
                    >
                      Listening Test
                    </MenuItem>
                  )}
                  {(dataEntryPageAccessibility?.includes(3) ||
                    (userData.accountLevel !== 3 &&
                      userData.accountLevel !== 43)) && (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleOnclick("Reading", "/reading");
                      }}
                    >
                      Reading
                    </MenuItem>
                  )}
                  {(dataEntryPageAccessibility?.includes(1) ||
                    (userData.accountLevel !== 3 &&
                      userData.accountLevel !== 43)) && (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleOnclick("Speaking Question", "/speaking");
                      }}
                    >
                      Speaking Question
                    </MenuItem>
                  )}
                  {(dataEntryPageAccessibility?.includes(4) ||
                    (userData.accountLevel !== 3 &&
                      userData.accountLevel !== 43)) && (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleOnclick("Writing", "/writing");
                      }}
                    >
                      Writing
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {userData.accountLevel === 3 ||
              userData.accountLevel === 5 ||
              userData.accountLevel === 6 ? (
                <></>
              ) : (
                <SubMenu title="Teacher">
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnclick("Ielts Classes", "/ieltsClasses");
                    }}
                  >
                    Ielts Classes
                  </MenuItem>
                </SubMenu>
              )}
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  handleOnclick("Profile", "/profilePage");
                }}
              >
                Profile
              </MenuItem>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    </>
  );
};

export default SidebarMob;
