import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { changeTimeStampinTwelveHours } from "../../Services/Constants";

const UserLeaveAppFeedback = ({ data }) => {
  return (
    <Card className="cardsub" style={{ marginBottom: "2vh" }}>
      <CardContent>
        <div className="errorgrid">
          <div className="firstgrid">
            <Typography className="title">Uid :-</Typography>
            <Typography className="body">{data.uid}</Typography>
          </div>
          <div className="secondgrid">
            <Typography className="title">Suggestion Type :-</Typography>
            <Typography className="body">{data.suggestionType}</Typography>
          </div>
          <div className="thirdgrid">
            <Typography className="title">Time :-</Typography>
            <Typography className="body">
              {changeTimeStampinTwelveHours(data.timeStamp)}
            </Typography>
          </div>
        </div>
        <div style={{ paddingTop: "12px" }}>
          <Typography className="title" style={{ textAlign: "center" }}>
            {data.userSuggestion}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default UserLeaveAppFeedback;
