import React, { useEffect } from "react";
import SidebarComp from "../Components/Sidebar";
import "../CSSFiles/home.css";

import SidebarMob from "../Components/SidebarMob";
import Navbar from "../Components/Navbar/Navbar";
import { useAuth } from "../Providers/UserProvider";

import { Typography } from "@material-ui/core";
import ProfileComp from "../Components/ProfileSection/ProfileComp";

const ProfilePage = () => {
  const {
    handleAdminUserDetails,
    timetorefresh,
    closeSidebar,
    adminUserDetail,
    loader,
  } = useAuth();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    sessionStorage.setItem("selectedAdminUser", JSON.stringify(userData?.uid));

    handleAdminUserDetails(userData?.uid, userData);
  }, []);

  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {loader === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Submitting Please Wait...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <ProfileComp user={adminUserDetail} />
      </div>
    </>
  );
};

export default ProfilePage;
