import React, { useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "../../CSSFiles/feedbacks.css";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiMoreHorizontal } from "react-icons/fi";
import { useAuth } from "../../Providers/UserProvider";
import { useNavigate } from "react-router-dom";

const FeedbackControlsCard = ({ res, statusType }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const { HandleEditFeedbacks } = useAuth();

  return (
    <Card className="cardsub" style={{ marginBottom: "2vh" }}>
      <CardContent>
        <div className="feedbackcontrolfirstgrid">
          <div>
            <Typography className="title">Feedback Id :-</Typography>
            <Typography className="body">{res.feedbackId}</Typography>
          </div>
          <div>
            <Typography className="title">Question :-</Typography>
            <Typography className="body">{res.question}</Typography>
          </div>
          <div>
            <Typography className="title">Question Type :-</Typography>
            <Typography className="body">{res.questionType}</Typography>
          </div>
        </div>
        <div className="feedbackcontrolsecondgrid">
          <div>
            <Typography className="title">Feedback Group :-</Typography>
            <Typography className="body">{res.feedbackGroup}</Typography>
          </div>
          <div>
            <Typography className="title">Status :-</Typography>
            <Typography className="body">{res.status}</Typography>
          </div>
          <div>
            <Typography className="title">Total Feedback Get :-</Typography>
            <Typography className="body">{res.totalFeedbackGet}</Typography>
          </div>
          <div>
            <Typography className="title">Question Number :-</Typography>
            <Typography className="body">{res.questionNumber}</Typography>
          </div>
        </div>
        <Typography className="body" style={{ textAlign: "center" }}>
          {res.optionalFeedbackAnswerOptions}
        </Typography>
        <div className="duplicate">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <FiMoreHorizontal className="duplicate_icon" />
          </IconButton>
        </div>
      </CardContent>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            navigate("/editFeedback", {
              state: {
                feedback: res,
              },
            });
          }}
        >
          Edit
        </MenuItem>
        {statusType === "NotActive" && (
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              HandleEditFeedbacks(3, res.feedbackId);
            }}
          >
            Delete
          </MenuItem>
        )}
        {statusType === "Active" && (
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              HandleEditFeedbacks(1, res.feedbackId);
            }}
          >
            Deactivate
          </MenuItem>
        )}
        {statusType === "NotActive" && (
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              HandleEditFeedbacks(0, res.feedbackId);
            }}
          >
            Activate
          </MenuItem>
        )}
      </Menu>
    </Card>
  );
};

export default FeedbackControlsCard;
