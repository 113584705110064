import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "../../CSSFiles/crashcourse.css";
import { useNavigate } from "react-router-dom";

const SpeakingQuesCard = ({ data, type }) => {
  const changeDateFormate = (strDateTime) => {
    const date = new Date(strDateTime);
    var myDate = date.toLocaleString();
    return myDate;
  };

  const navigate = useNavigate();

  return (
    <Card
      className="cardsub"
      style={{ marginBottom: "2vh" }}
      onClick={(e) => {
        e.preventDefault();
        navigate(`/speakingdata/${type}/${data.id}`, {
          state: {
            data: data,
            type: type,
          },
        });
      }}
    >
      <CardContent>
        {/* <div className="coursetitle">
          <Typography className="title">TestFile :-</Typography>
          <Typography className="body">{data.testFile}</Typography>
        </div> */}

        <div className="coursedetails">
          {/* <div className="coursetitle">
                            <Typography className="title">Test File :-</Typography>
                            <Typography className="body">{data.testFile}</Typography>
                        </div> */}
          <div className="coursetitle" style={{ marginTop: "1vh" }}>
            <Typography className="title">Id :-</Typography>
            <Typography className="body">{data.id}</Typography>
          </div>

          <div className="coursetitle" style={{ marginTop: "1vh" }}>
            <Typography className="title">Question :-</Typography>
            <Typography className="body">{data.question}</Typography>
          </div>

          <div className="coursetitle" style={{ marginTop: "1vh" }}>
            <Typography className="title">Created At :-</Typography>
            <Typography className="body">
              {changeDateFormate(data.created_at)}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SpeakingQuesCard;
