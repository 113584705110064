import { Button, Card, CardContent, Grid } from "@mui/material";
import React, { useState } from "react";
import "../../CSSFiles/grammar.css";
import { useAuth } from "../../Providers/UserProvider";

const GrammarComp = ({ handleFunction, textvalue, settextvalue }) => {
  const { handleGrammarMistakefunc } = useAuth();

  return (
    <>
      <Card className="card">
        <CardContent>
          <div className="main_con">
            <textarea
              rows="8"
              className="grammar_text_area"
              placeholder="Enter text here"
              value={textvalue}
              onChange={(e) => settextvalue(e.target.value)}
            />
          </div>
          <div className="main_buttons">
            <Button
              variant="outlined"
              className="grammar_buttons"
              onClick={(e) => {
                e.preventDefault();
                const userData = localStorage.getItem("userData");
                const Data = JSON.parse(userData);
                handleGrammarMistakefunc(Data, textvalue);
              }}
            >
              Test Value
            </Button>
            <Button
              variant="outlined"
              className="grammar_buttons"
              onClick={(e) => {
                e.preventDefault();
                handleFunction();
              }}
            >
              Get Testing Value
            </Button>
          </div>
          <Grid
            container
            spacing={1}
            rowSpacing={3}
            style={{ padding: "5px", margin: "15px" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="gridingcards"
            >
              Card 1
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="gridingcards"
            >
              Card 2
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default GrammarComp;
