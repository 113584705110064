import React, { useState } from "react";
import { Card, CardContent, TextField, Button } from "@mui/material";
import { useAuth } from "../../Providers/UserProvider";
import { useLocation } from "react-router-dom";
import CustomDialogBox from "../CustomDialogBox";
import { AddAndEditStoredNotiDialogBodyText } from "../../MiddleWares/DialogBodyAndConfirmationTxt";
import SelectOptions from "../NotificationsComp/SelectOptions";
import { AddAndEditFeedbackDialogBodyTxt } from "../../MiddleWares/AddAndEditFeedbackDialogBodyTxt";

export const questionTypes = [
  { value: 0, label: "YesNo" },
  { value: 1, label: "optionalFeedback" },
  { value: 2, label: "Suggestion" },
];

export const feedbackGroup = [
  { value: 0, label: "PaidUser" },
  { value: 1, label: "UnpaidUser" },
];

const AddEditFeedbackComp = () => {
  const location = useLocation();

  const { HandleAddFeedbacks } = useAuth();

  const [questionValue, setquestionValue] = useState("");
  const [feedbackAnsOpt, setfeedbackAnsOpt] = useState("");

  const [questionTypeState, setquestionType] = useState("");

  const handleChangeQuesValue = (e) => {
    setquestionType(e);
    setfeedbackAnsOpt("");
  };

  const [feedbackGroupState, setfeedbackGroupState] = useState("");

  const handleFeedbackChangeValue = (e) => {
    setfeedbackGroupState(e);
  };

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const handleSubmitOfDialog = () => {
    if (questionTypeState.value !== 1) {
      HandleAddFeedbacks(
        questionValue,
        questionTypeState.label,
        feedbackGroupState.label,
      );
    } else {
      HandleAddFeedbacks(
        questionValue,
        questionTypeState.label,
        feedbackGroupState.label,
        feedbackAnsOpt,
      );
    }
  };

  return (
    <Card className="card">
      <CardContent>
        <div className="main_con">
          <textarea
            rows="2"
            className="grammar_text_area"
            placeholder="Enter question here"
            value={questionValue}
            onChange={(e) => {
              setquestionValue(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
          }}
        >
          <SelectOptions
            options={questionTypes}
            type="feedbackquesType"
            value={questionTypeState}
            handleFunction={handleChangeQuesValue}
          />
        </div>
        {questionTypeState?.value === 1 && (
          <div className="main_con">
            <textarea
              rows="2"
              className="grammar_text_area"
              placeholder="Enter Feedback Answer Options"
              value={feedbackAnsOpt}
              onChange={(e) => {
                setfeedbackAnsOpt(e.target.value);
              }}
            />
          </div>
        )}
        <div
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
            transform: "initial",
          }}
        >
          <SelectOptions
            options={feedbackGroup}
            type="feedbackquesGroup"
            value={feedbackGroupState}
            handleFunction={handleFeedbackChangeValue}
          />
        </div>
        <div style={{ textAlign: "center", transform: "unset" }}>
          <Button
            variant="outlined"
            className="grammar_buttons"
            style={{ zIndex: "0" }}
            onClick={(e) => {
              e.preventDefault();
              handleClickOpen();
            }}
          >
            Save
          </Button>
        </div>
      </CardContent>
      <CustomDialogBox
        opendialog={opendialog}
        handleClickClose={handleClickClose}
        bodyText={
          <AddAndEditFeedbackDialogBodyTxt
            question={questionValue}
            questionType={questionTypeState}
            feedbackGroup={feedbackGroupState}
            feedbackAnsOpt={feedbackAnsOpt}
          />
        }
        confirmationText="Are You Sure Want To Add These Changes"
        handleSubmitOfDialog={handleSubmitOfDialog}
      />
    </Card>
  );
};

export default AddEditFeedbackComp;
