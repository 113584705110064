import React from "react";
import SuggestionFeedbackComp from "../Components/FeedbackComps/SuggestionFeedbackComp";
import SidebarComp from "../Components/Sidebar";
import "../CSSFiles/home.css";

const SugggestionPage = () => {
  return (
    <div className="home">
      <SidebarComp />
      <SuggestionFeedbackComp />
    </div>
  );
};

export default SugggestionPage;
