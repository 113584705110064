import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import {
  changeDateFormateInTwelveHours,
  getCashBookBank,
  getCashBookpaymentCategory,
  getCashBookpaymentSource,
  gettransactionStatus,
} from "../../Services/Constants";
import "../../CSSFiles/cashbook.css";
import { useAuth } from "../../Providers/UserProvider";

const CashBookCard = ({ res, handleClick }) => {
  const { setclickedtrans } = useAuth();
  return (
    <Card
      className="cardsub"
      style={{ marginBottom: "1vh", cursor: "pointer", marginTop: "1vh" }}
      onClick={(e) => {
        e.preventDefault();
        setclickedtrans(res);
        handleClick();
      }}
    >
      <CardContent
        style={{
          paddingBottom: "0",
          position: "relative",
          transform: "translate3d(0, 0, 0)",
        }}
      >
        <div className="cashBookgriding">
          <Typography className="title">{getCashBookBank(res.bank)}</Typography>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <Typography className="title">
              {Number(res.paymentAmount).toLocaleString("en-IN")}
            </Typography>
            <Typography
              className={
                res.transactionStatus === 0 ? `title withdraw` : `title credit`
              }
            >
              {gettransactionStatus(res.transactionStatus)}{" "}
            </Typography>
          </div>
          <Typography className="title">
            {changeDateFormateInTwelveHours(res.created_at)}
          </Typography>
        </div>
        <div className="cashBookgriding">
          <Typography className="body">
            {getCashBookpaymentCategory(res.paymentCategory)}
          </Typography>

          <Typography className="body">
            {getCashBookpaymentSource(res.paymentSource)}
          </Typography>

          <Typography className="body">
            {Number(res.balanceAfterTransaction).toLocaleString("en-IN")}
          </Typography>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Typography className="body">{res.comment}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default CashBookCard;
