import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";

export default function PaymentBehaviour() {
  const { paymentbehaviourgraph } = useAuth();

  console.log(paymentbehaviourgraph);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "0px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
        >
          <p
            style={{ color: "#008390" }}
          >{`value : ${payload[0].payload.xaxis}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`allconvertUserPercentage : ${payload[0].payload.alluserconvert}`}</p>
          <p
            style={{ color: "red" }}
          >{`androidconvertUserPercentage : ${payload[0].payload.androiduserconvert}`}</p>
          <p
            style={{ color: "green" }}
          >{`iosconvertUserPercentage : ${payload[0].payload.iOSuserconvert}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart width={600} height={300} data={paymentbehaviourgraph}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="xaxis" />

        <YAxis type="number" domain={[0, 100]} tickCount={12} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey="alluserconvert"
          name="All Users"
          stroke="blue"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="androiduserconvert"
          stroke="red"
          name="Android"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="iOSuserconvert"
          stroke="green"
          name="IOS"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
