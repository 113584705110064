import React from "react";

import Card from "@material-ui/core/Card";
import { useAuth } from "../../Providers/UserProvider";
import ListeningTestCard from "./ListeningTestCard";

const ListeningTestTable = () => {
  const { listeningtestData } = useAuth();

  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      {/* <table className="users_table_comp">
        <TableHead>
          <tr>
            <td
              align="center"
              style={{ fontWeight: "bold",border:'0'}}
            >
              UniqueTestNumber
            </td>
            <td
              align="center"
              style={{ fontWeight: "bold",border:'0'}}
            >
              TestFile
            </td>
            <td
              align="center"
              style={{ fontWeight: "bold",border:'0'}}
            >
              Created_at
            </td>
            <td
              align="center"
              style={{ fontWeight: "bold",border:'0'}}
            >
              Updated_at
            </td>
          </tr>
        </TableHead>
        <tbody>
          {listeningtestData.map((row) => (
            <tr key={row.uniqueTestNumber}>
              <td align="center">{row.uniqueTestNumber}</td>
              <td align="center">{row.testFile}</td>
              <td align="center">{changeDateFormate(row.created_at)}</td>
              <td align="center">{changeDateFormate(row.updated_at)}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
      {listeningtestData?.map((res) => (
        <ListeningTestCard data={res} type="Listening" />
      ))}
    </Card>
  );
};

export default ListeningTestTable;
