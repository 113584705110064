import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { makeStyles } from "@material-ui/core";
import CustomDialogBox from "../CustomDialogBox";
import { AddWritingDialogBodyTxt } from "../../MiddleWares/AddWritingDialogBodyTxt";
import { useAuth } from "../../Providers/UserProvider";

const useStyles = makeStyles({
  root: { height: "100vh" },
  paper: { borderRadius: 5, width: "100%" },
});

const WritingDetailAddSubCatModal = ({
  opendialog,
  handleClickClose,
  data,
  isEdit,
  subcategoryValue
}) => {
  const classes = useStyles();
  const { addsubcategoryWriting, editsubcategoryWriting } = useAuth();

  const [subcategorydata, setsubcategorydata] = useState("");
  const [open, setopen] = useState(false);

  const handleSubmitOfDialog = () => {
    const Data = JSON.parse(localStorage.getItem("userData"));
    if (isEdit) editsubcategoryWriting(Data, data, subcategoryValue, subcategorydata)
    else
      addsubcategoryWriting(Data, data, subcategorydata);
    handleClickCloseConfirm();
    handleClickClose();
  };

  useEffect(() => {
    if (isEdit) {
      setsubcategorydata(subcategoryValue)
    }
  }, [])

  const handleClickOpenConfirm = () => {
    setopen(true);
  };

  const handleClickCloseConfirm = () => {
    setopen(false);
  };

  return (
    <Dialog
      open={opendialog}
      onClose={handleClickClose}
      aria-labelledby="customized-dialog-title"
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
          paddingTop: "5vh",
        }}
      >
        {handleClickClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClickClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            style={{ position: "absolute" }}
          >
            <AiOutlineCloseCircle />
          </IconButton>
        ) : null}
      </DialogTitle>
      <div style={{ padding: "2vw" }}>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "5px",
            alignItems: "center",
            textAlign: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography>Category :-</Typography>
          <Typography>{data}</Typography>
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "5px",
            alignItems: "center",
            textAlign: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography>Subcategory Name:-</Typography>
          <TextField
            value={subcategorydata}
            onChange={(e) => {
              setsubcategorydata(e.target.value);
            }}
          />
        </div>
      </div>

      <DialogActions>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickOpenConfirm();
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <CustomDialogBox
        opendialog={open}
        handleClickClose={handleClickCloseConfirm}
        bodyText={
          <AddWritingDialogBodyTxt
            category={data}
            subcategory={subcategorydata}
          />
        }
        confirmationText="Are You Sure Want To Add These Changes"
        handleSubmitOfDialog={handleSubmitOfDialog}
      />
    </Dialog>
  );
};

export default WritingDetailAddSubCatModal;
