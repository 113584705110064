import React from "react";
import "../../CSSFiles/notifications.css";
import { Card } from "@mui/material";
import { useAuth } from "../../Providers/UserProvider";
import ErrorLogCard from "./ErrorLogCard";

const ErrorLogComp = () => {
  const { errorlogData } = useAuth();

  console.log(errorlogData);

  return (
    <Card style={{ overflow: "scroll" }} className="card">
      {errorlogData?.map((data) => {
        return <ErrorLogCard data={data} />;
      })}
    </Card>
  );
};

export default ErrorLogComp;
