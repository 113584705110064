import React from "react";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import ListeningTestFeatures from "../../ListeningTestComp/ListeningTestDetails/ListeningTestFeatures";
import ReadingTestPDFViewer from "./ReadingTestPDFviewer";
import { useAuth } from "../../../Providers/UserProvider";
import { Typography } from "@mui/material";

const ReadingTestDetailsComp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const { pdfloader } = useAuth();

  return (
    <div>
      {pdfloader && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
          }}
        >
          Loading Data...
        </Typography>
      )}
      <div style={{ padding: "10px" }}>
        <FaRegArrowAltCircleLeft
          onClick={(e) => {
            e.preventDefault();
            handleNavigateBack();
          }}
          style={{ cursor: "pointer" }}
          size={35}
        />
      </div>
      <ListeningTestFeatures data={location.state.data} />
      <ReadingTestPDFViewer pdf={location.state.data.testFile} />
    </div>
  );
};

export default ReadingTestDetailsComp;
