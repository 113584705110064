import { Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { changeDateFormate } from "../../../Services/Constants";
import ClassEditTextDialog from "./ClassEditTextDialog";

const EditScheduleClassDetail = ({ data }) => {
  const [text, settext] = useState({
    title: "",
    value: "",
  });
  const [openedittextDialog, setopenedittextDialog] = useState(false);

  const [valueId, setvalueId] = useState("");

  const handleClickOpenEdit = () => {
    setopenedittextDialog(true);
  };

  const handleClickClose = () => {
    setopenedittextDialog(false);
  };

  const handleEditText = (title, value, valueId) => {
    settext({
      title: title,
      value: value,
    });
    setvalueId(valueId);
    handleClickOpenEdit();
  };
  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Class Title", data?.classTitle, 4);
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Class Title :-</Typography>
            <Typography className="body">{data?.classTitle}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Class Url", data?.classFile, 7);
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Class Url :-</Typography>
            <Typography className="body">{data?.classFile}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Class Category", data?.category, 3);
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Class Category :-</Typography>
            <Typography className="body">{data?.category}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Start Time", data?.classStatingTime, 5);
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Start Time :-</Typography>
            <Typography className="body">
              {changeDateFormate(data?.classStatingTime)}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("End Time", data?.classEndTIme, 6);
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">End Time :-</Typography>
            <Typography className="body">
              {changeDateFormate(data?.classEndTIme)}
            </Typography>
          </div>
        </CardContent>
      </Card>

      <ClassEditTextDialog
        text={text}
        opendialog={openedittextDialog}
        handleClickClose={handleClickClose}
        valueId={valueId}
        type="ScheduleClass"
        ClassUniqueId={data?.classUniqueId}
        classFile={data?.classFile}
      />
    </Card>
  );
};

export default EditScheduleClassDetail;
