import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell,
} from "recharts";
import { useAuth } from "../../Providers/UserProvider";

export default function ComparingPlanSells() {
  const { planSellData } = useAuth();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "0px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
          className="graph_tooltips"
        >
          {payload[0].payload?.data0 && (
            <p
              style={{ color: "#008390" }}
            >{`TotalSales_totalPayment : ${payload[0].payload?.data0?.totalPayment}`}</p>
          )}

          {payload[0].payload?.data0 && (
            <p
              style={{ color: "#5DB09A" }}
            >{`TotalSales_paymentCount : ${payload[0].payload?.data0?.paymentCount}`}</p>
          )}
          {payload[0].payload?.data0 && (
            <p
              style={{ color: "red" }}
            >{`TotalSales_percent : ${payload[0].payload?.data0?.percentage}`}</p>
          )}
          <p
            style={{ color: "#008390" }}
          >{`WithoutCouponCode_totalPayment : ${payload[0].payload?.data1?.totalPayment}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`WithoutCouponCode_paymentCount : ${payload[0].payload?.data1?.paymentCount}`}</p>
          <p
            style={{ color: "red" }}
          >{`WithoutCouponCode_percent : ${payload[0].payload?.data1?.percentage}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="99%" height={300}>
      <BarChart data={planSellData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="xaxis" />

        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="percent0" name="Total Sales" fill="#0081CF" />

        <Bar dataKey="percent1" name="Without Coupon Code" fill="green" />
      </BarChart>
    </ResponsiveContainer>
  );
}
