import React, { useEffect } from "react";
import SidebarComp from "../Components/Sidebar";
import "../CSSFiles/home.css";
import { useAuth } from "../Providers/UserProvider";
import { Typography } from "@mui/material";
import SidebarMob from "../Components/SidebarMob";
import Navbar from "../Components/Navbar/Navbar";
import CouponEditSection from "../Components/CouponComp/CouponEditSection";

const CouponDetailsEdit = () => {
  const { timetorefresh, closeSidebar, handleCouponDetail } = useAuth();

  useEffect(() => {
    startCouponCodeDetail();
  }, []);

  const startCouponCodeDetail = () => {
    const couponCodeId = sessionStorage.getItem("selectedCouponId");
    if (couponCodeId) {
      handleCouponDetail(JSON.parse(couponCodeId));
    }
  };

  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <CouponEditSection />
      </div>
    </>
  );
};

export default CouponDetailsEdit;
