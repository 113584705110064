import React, { useEffect, useState } from "react";
import SidebarComp from "../../Components/Sidebar";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";
import { Fab, Typography } from "@mui/material";
import ListeningTestTable from "../../Components/ListeningTestComp/ListeningTestTable";
import SidebarMob from "../../Components/SidebarMob";
import Navbar from "../../Components/Navbar/Navbar";
import { RiAddFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import WritingTestTable from "../../Components/WritingTestComp/WritingTestTable";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import AddCategoryWriting from "../../Components/WritingTestComp/AddCategory";

const Writing = () => {
  const { getwritingTest, updatewritingTest, timetorefresh, closeSidebar } =
    useAuth();
  const [openAddModal, setopenAddModal] = useState(false);

  const handleOpenModel = () => {
    setopenAddModal(true);
  };

  const handleCloseModel = () => {
    setopenAddModal(false);
  };

  useEffect(() => {
    StartFeedBackPage();
  }, []);

  const navigate = useNavigate();

  const StartFeedBackPage = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    const writingTest = sessionStorage.getItem("writingTest");
    if (writingTest) {
      updatewritingTest();
    }
    getwritingTest(Data);
  };
  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <WritingTestTable />
        <Fab
          aria-label="add"
          style={{
            margin: 0,
            right: 50,
            bottom: 80,
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#051e34",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleOpenModel();
          }}
        >
          <RiAddFill size={30} style={{ color: "white" }} />
        </Fab>
        {/* <Fab
                    aria-label="add"
                    style={{
                        margin: 0,
                        right: 50,
                        bottom: 80,
                        position: "fixed",
                        zIndex: "1",
                        backgroundColor: "#051e34",
                        color: "white",
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        navigate("/uploadTest")
                    }}
                >

                    <HiOutlineViewGridAdd size={30} style={{ color: "white" }} />


                </Fab> */}
      </div>
      <AddCategoryWriting
        opendialog={openAddModal}
        handleClickClose={handleCloseModel}
      />
    </>
  );
};

export default Writing;
