import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  const loadData = () => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      navigate("/waiting");
    } else {
      navigate("/login");
    }
  };
  const stopInteval = () => {
    clearInterval(id);
  };
  var id;

  const verifyUser = () => {
    id = setInterval(() => {
      console.log("Waiting to verify user");
      stopInteval();
      loadData();
    }, 100);
  };

  return (
    <div className="centerDiv">
      <div>
        <h1 className="heading">BabyCode</h1>
      </div>
      {verifyUser()}
    </div>
  );
};

export default LandingPage;
