import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import "../../CSSFiles/home.css";

export default function AllUsersFeaturesUsage({ retentiondata }) {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "0px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
        >
          <p
            style={{ color: "#008390" }}
          >{`averagSpeakingTestPerUser : ${payload[0].payload.averagSpeakingTestPerUser}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`userSpeakingTestPercentage : ${payload[0].payload.userSpeakingTestPercentage}`}</p>
          <p
            style={{ color: "#008390" }}
          >{`averagSpeakingCallPerUser : ${payload[0].payload.averagSpeakingCallPerUser}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`userSpeakingCallPercentage : ${payload[0].payload.userSpeakingCallPercentage}`}</p>
          <p
            style={{ color: "#008390" }}
          >{`averagListeningTestPerUser : ${payload[0].payload.averagListeningTestPerUser}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`userListeningTestPercentage : ${payload[0].payload.userListeningTestPercentage}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer width="99%" height={300}>
      <BarChart data={retentiondata}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="xaxis" />

        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar
          name="Speaking Call"
          dataKey="userSpeakingCallPercentage"
          fill="#8884d8"
        />
        <Bar
          name="Speaking Test"
          dataKey="userSpeakingTestPercentage"
          fill="#82ca9d"
        />
        <Bar
          name="Listening Test"
          dataKey="userListeningTestPercentage"
          fill="red"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
