import React, { useState } from "react";
import { Card, Typography, CardContent } from "@mui/material";
import EditText from "./EditForms/EditText";
import EditDate from "./EditForms/EditDate";
import { useAuth } from "../../Providers/UserProvider";

const CouponEditSection = () => {
  const [openedittextDialog, setopenedittextDialog] = useState(false);
  const [openeditdateDialog, setopeneditdateDialog] = useState(false);
  const { singleCoupounDetail } = useAuth();
  const [text, settext] = useState({
    title: "",
    value: "",
  });
  const [dates, setdates] = useState({
    title: "",
    value: "",
  });

  const handleClickOpenEdit = () => {
    setopenedittextDialog(true);
  };

  const handleClickClose = () => {
    setopenedittextDialog(false);
  };

  const handleClickOpenEditDate = () => {
    setopeneditdateDialog(true);
  };

  const handleClickCloseDate = () => {
    setopeneditdateDialog(false);
  };

  const [valueId, setvalueId] = useState("");

  const handleEditText = (title, value, valueId) => {
    settext({
      title: title,
      value: value,
    });
    setvalueId(valueId);
    handleClickOpenEdit();
  };

  const handleEditDates = (title, value, valueId) => {
    const date = new Date(value);
    setdates({
      title: title,
      value: date,
    });
    setvalueId(valueId);

    handleClickOpenEditDate();
  };

  // const singleCoupounDetail = JSON.parse(sessionStorage.getItem("selectedCouponId"));

  // console.log(singleCoupounDetail)

  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "Coupon Code Text",
            singleCoupounDetail?.couponCodeRawData?.couponCodeText,
            "1",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Coupon Code Text:</Typography>
            <Typography className="body">
              {singleCoupounDetail?.couponCodeRawData?.couponCodeText}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "Uid",
            singleCoupounDetail?.couponCodeRawData?.uid,
            "3",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Uid:</Typography>
            <Typography className="body">
              {singleCoupounDetail?.couponCodeRawData?.uid}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditDates(
            "Coupon Code Expiriy",
            singleCoupounDetail?.couponCodeRawData?.couponExpireDate,
            "4",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Coupon Code Expiriy:</Typography>
            <Typography className="body">
              {singleCoupounDetail?.couponCodeRawData?.couponExpireDate}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "Discount Price",
            singleCoupounDetail?.couponCodeRawData?.discountPrice,
            "2",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Discount Price:</Typography>
            <Typography className="body">
              {singleCoupounDetail?.couponCodeRawData?.discountPrice}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "Free Days",
            singleCoupounDetail?.couponCodeRawData?.freeDays,
            "6",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Free Days:</Typography>
            <Typography className="body">
              {singleCoupounDetail?.couponCodeRawData?.freeDays}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditDates(
            "Free days Offer valid till date",
            singleCoupounDetail?.couponCodeRawData?.freeDaysOfferValidDate,
            "7",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">
              Free days Offer valid till date:
            </Typography>
            <Typography className="body">
              {singleCoupounDetail?.couponCodeRawData?.freeDaysOfferValidDate}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "Coupon Type",
            singleCoupounDetail?.couponCodeRawData?.couponType,
            "5",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Coupon Type:</Typography>
            <Typography className="body">
              {singleCoupounDetail?.couponCodeRawData?.couponType}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "Amount That Influncer Will Get",
            singleCoupounDetail?.couponCodeRawData?.amountThatInfluncerWillGet,
            "8",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">
              Amount That Influncer Will Get:
            </Typography>
            <Typography className="body">
              {
                singleCoupounDetail?.couponCodeRawData
                  ?.amountThatInfluncerWillGet
              }
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "BabyCode CutOff In Wallet Amount",
            singleCoupounDetail?.couponCodeRawData
              ?.BabyCodeCutOffInWalletAmount,
            "9",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">
              BabyCode CutOff In Wallet Amount:
            </Typography>
            <Typography className="body">
              {
                singleCoupounDetail?.couponCodeRawData
                  ?.BabyCodeCutOffInWalletAmount
              }
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "BabyCode CutOff In Wallet Money Till Now",
            singleCoupounDetail?.couponCodeRawData
              ?.BabyCodeCutOffInWalletMoneyTillNow,
            "10",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">
              BabyCode CutOff In Wallet Money Till Now:
            </Typography>
            <Typography className="body">
              {Number(
                singleCoupounDetail?.couponCodeRawData
                  ?.BabyCodeCutOffInWalletMoneyTillNow,
              ).toLocaleString("en-IN")}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText(
            "Total Amount In Wallet",
            singleCoupounDetail?.couponCodeRawData?.totalAmountInWallet,
            "11",
          );
        }}
      >
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Typography className="title">Total Amount In Wallet:</Typography>
            <Typography className="body">
              {Number(
                singleCoupounDetail?.couponCodeRawData?.totalAmountInWallet,
              ).toLocaleString("en-IN")}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <EditText
        text={text}
        opendialog={openedittextDialog}
        handleClickClose={handleClickClose}
        valueId={valueId}
        type="Coupon"
      />
      <EditDate
        dates={dates}
        opendialog={openeditdateDialog}
        handleClickClose={handleClickCloseDate}
        valueId={valueId}
        type="Coupon"
      />
    </Card>
  );
};

export default CouponEditSection;
