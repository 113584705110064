import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "../../CSSFiles/coupon.css";
import { useNavigate } from "react-router-dom";

const CouponCard = ({ res }) => {
  const navigate = useNavigate();

  return (
    <Card
      className="cardsub"
      style={{ marginBottom: "2vh", cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        // handleCouponDetail(res);
        sessionStorage.setItem("selectedCouponId", JSON.stringify(res));
        navigate("/couponcodedetails");
      }}
    >
      <CardContent>
        <div>
          <div className="coupongridings">
            <div className="couponfirsts">
              <Typography className="titles2">CouponCode :-</Typography>
              <Typography className="coupontext">
                {res.couponCodeText}
              </Typography>
            </div>
            <div className="couponfirstsgrid">
              <div className="grida">
                <Typography className="coupontitle">
                  TotalAmountInWallet
                </Typography>
                <Typography className="couponbody">
                  :-{res.totalAmountInWallet}
                </Typography>
              </div>
              <div className="gridb">
                <Typography className="coupontitle">
                  Influencer Amount
                </Typography>
                <Typography className="couponbody">
                  :-{res.amountThatInfluncerWillGet}
                </Typography>
              </div>
            </div>
            <div className="couponfirstsgrid">
              <div className="grida">
                <Typography className="coupontitle">
                  BabyCodeCutOffInWalletAmount
                </Typography>
                <Typography className="couponbody">
                  :-{res.BabyCodeCutOffInWalletAmount}
                </Typography>
              </div>
              <div className="gridb">
                <Typography className="coupontitle">User Discount</Typography>
                <Typography className="couponbody">
                  :-{res.discountPrice}
                </Typography>
              </div>
            </div>
            <div className="couponfirstsgrid">
              <div className="grida">
                <Typography className="coupontitle">
                  BabyCodeCutOffInWalletMoneyTillNow
                </Typography>
                <Typography className="couponbody">
                  :-{res.BabyCodeCutOffInWalletMoneyTillNow}
                </Typography>
              </div>
              <div className="gridb">
                <Typography className="coupontitle">Expire Date</Typography>
                <Typography className="couponbody">
                  :-{res.couponExpireDate}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CouponCard;
