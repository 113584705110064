import React from "react";
import { Grid } from "@mui/material";
import FeaturesUsage from "../Charts/FeaturesUsage";
import UnpaidFeaturesUsage from "../Charts/UnpaidFeaturesUsage";
import AllUsersFeaturesUsage from "../Charts/AllUsersFeaturesUsage";
import { useAuth } from "../../Providers/UserProvider";

const AndroidSeeMoreGraphComp = () => {
  const {
    androidallfeaturesusage,
    androidpaidfeaturesusage,
    androidunpaidfeaturesusage,
    androidappleaveuserfeaturesusage,
  } = useAuth();
  return (
    <Grid container style={{ textAlign: "-webkit-center" }} spacing={1}>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Android All</h2>

        <AllUsersFeaturesUsage retentiondata={androidallfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Android Paid</h2>

        <FeaturesUsage retentiondata={androidpaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Android Unpaid</h2>

        <UnpaidFeaturesUsage retentiondata={androidunpaidfeaturesusage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        xl={6}
        style={{ textAlign: "-webkit-center" }}
      >
        <h2>Android App Leave Users</h2>

        <UnpaidFeaturesUsage retentiondata={androidappleaveuserfeaturesusage} />
      </Grid>
    </Grid>
  );
};

export default AndroidSeeMoreGraphComp;
