import React, { useEffect, useState } from "react";
import CashBookComp from "../Components/CashBookTranscation/CashBookComp";
import TranscationDetail from "../Components/CashBookTranscation/TranscationDetail";
import ModalComp from "../Components/GrammarMistaken/ModalComp";
import SidebarComp from "../Components/Sidebar";
import { useAuth } from "../Providers/UserProvider";
import { Typography } from "@mui/material";
import Navbar from "../Components/Navbar/Navbar";
import SidebarMob from "../Components/SidebarMob";

const CashBookScreen = () => {
  const { getCashBook, timetorefresh, closeSidebar } = useAuth();
  const [IsModalScreen, setIsModalScreen] = useState(false);

  const handleFunction = () => {
    setIsModalScreen(!IsModalScreen);
  };

  useEffect(() => {
    startCashBook();
  }, []);

  const startCashBook = () => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      getCashBook(userData);
    }
  };

  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "5",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        {IsModalScreen && (
          <ModalComp
            handleFunction={handleFunction}
            IsModalScreen={IsModalScreen}
            sethandleModalScreen={setIsModalScreen}
            children={<TranscationDetail />}
          />
        )}

        <CashBookComp handleFunction={handleFunction} />
      </div>
    </>
  );
};

export default CashBookScreen;
