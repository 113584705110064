import React, { useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { makeStyles } from "@material-ui/core";
import CustomDialogBox from "../CustomDialogBox";
import { useAuth } from "../../Providers/UserProvider";
import { QuestionField } from "../SpeakingQuesComp/CreateSpeakingQues";
import { AddWritingQuesSubCatDialogBody } from "../../MiddleWares/AddWritingQuesSubCatDialogBody";
import { toast } from "react-toastify";
import Toast from "../../MiddleWares/Toast";
import DragAndDropComp from "../UploadingVideoFormComp/DragAndDropComp/DragAndDropComp";


const useStyles = makeStyles({
  root: {},
  paper: { borderRadius: 5, width: "100%" },
});

const AddSubCategoryQues = ({
  opendialog,
  handleClickClose,
  category,
  subcategory,
  type,
  QuestionUniqueId,
  taskType,
  quesImage,
  task1type
}) => {
  const classes = useStyles();
  const {
    addSubCategoryWritingQues,
    writingquestion,
    writinganswer1,
    writinganswer2,
    setwritinganswer1,
    setwritingquestion,
    setwritinganswer2,
    editSubCategoryWritingQues,
  } = useAuth();

  console.log(taskType, task1type)
  const [open, setopen] = useState(false);

  const handleSubmitOfDialog = () => {
    const Data = JSON.parse(localStorage.getItem("userData"));
    const task = taskType === 'task1' ? 1 : 0;
    console.log(task1type)
    if (type === "Add") {
      task === 1 && task1type === 'academic' ? addSubCategoryWritingQues(
        Data,
        category,
        subcategory,
        writingquestion,
        writinganswer1,
        writinganswer2,
        task, thumbNailUpload.raw, task1type
      ) : addSubCategoryWritingQues(
        Data,
        category,
        subcategory,
        writingquestion,
        writinganswer1,
        writinganswer2,
        task, "", task1type
      );
    } else {
      editthumbNailUpload?.preview ? editSubCategoryWritingQues(
        Data,
        category,
        subcategory,
        QuestionUniqueId,
        writingquestion,
        writinganswer1,
        writinganswer2,
        editthumbNailUpload.raw
      ) : editSubCategoryWritingQues(
        Data,
        category,
        subcategory,
        QuestionUniqueId,
        writingquestion,
        writinganswer1,
        writinganswer2,
      );
    }
    // console.log(writinganswer1);
    handleClickCloseConfirm();
    setwritingquestion("");
    setwritinganswer1("");
    setwritinganswer2("");
    handleClickClose();
  };

  const handleClickOpenConfirm = () => {
    setopen(true);
  };

  const handleClickCloseConfirm = () => {
    setopen(false);
  };

  const [thumbNailUpload, setthumbNailUpload] = useState({
    preview: "",
    raw: "",
  });

  const [editthumbNailUpload, seteditthumbNailUpload] = useState({
    preview: "",
    raw: "",
  })

  return (
    <Dialog
      open={opendialog}
      onClose={() => {
        setwritingquestion("");
        setwritinganswer1("");
        setwritinganswer2("");
        handleClickClose();
      }}
      aria-labelledby="customized-dialog-title"
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
          paddingTop: "5vh",
        }}
      >
        {handleClickClose ? (
          <IconButton
            aria-label="close"
            onClick={() => {
              setwritingquestion("");
              setwritinganswer1("");
              setwritinganswer2("");
              handleClickClose();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            style={{ position: "absolute" }}
          >
            <AiOutlineCloseCircle />
          </IconButton>
        ) : null}
      </DialogTitle>
      <div style={{ padding: "2vw", overflowY: "scroll" }}>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "10px",
            alignItems: "center",
            textAlign: "center",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          <Typography>Category :-</Typography>
          <Typography>{category}</Typography>
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "10px",
            alignItems: "center",
            textAlign: "center",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          <Typography>SubCategory :-</Typography>
          <Typography>{subcategory}</Typography>
        </div>
        <div style={{

          marginBottom: "10px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}>

        </div>
        {type === "Edit" && (
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginBottom: "10px",
              alignItems: "center",
              textAlign: "center",
              flexWrap: "wrap",
              marginBottom: "10px",
            }}
          >
            <Typography>Question Unique Id :-</Typography>
            <Typography>{QuestionUniqueId}</Typography>
          </div>
        )}
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <Typography>Question:-</Typography>
          <QuestionField
            placeholder="Enter Your Question"
            state={writingquestion}
            setstate={setwritingquestion}
          />
        </div>
        {
          type === "Edit" && taskType === 'task1' && task1type === 'academic' && (
            <div style={{
              display: "flex",
              gap: "5px",
              marginBottom: "5px",
              alignItems: "center",
              textAlign: "center",
              marginBottom: "10px",
            }}>
              <div className="profileContainer">
                <img
                  src={quesImage}
                  alt="ProfileImage"
                  width="150"
                  height="150"
                />
              </div>
              <DragAndDropComp
                thumbNailUpload={editthumbNailUpload}
                setthumbNailUpload={seteditthumbNailUpload}
                type="Image"
              />
            </div>
          )
        }
        {type==="Add" && taskType === 'task1' && task1type === 'academic' && <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "5px",
            alignItems: "center",
            textAlign: "center",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          <DragAndDropComp
            thumbNailUpload={thumbNailUpload}
            setthumbNailUpload={setthumbNailUpload}
            type="Image"
          />
        </div>}
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <Typography>Answer1:-</Typography>
          <QuestionField
            placeholder="Enter Your Answer1"
            state={writinganswer1}
            setstate={setwritinganswer1}
          />
        </div>
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <Typography>Answer2:-</Typography>
          <QuestionField
            placeholder="Enter Your Answer2"
            state={writinganswer2}
            setstate={setwritinganswer2}
          />
        </div>
      </div>
      <Toast />

      <DialogActions>
        {type === "Edit" ? (
          <Button
            variant="outlined"
            className="grammar_buttons"
            onClick={(e) => {
              e.preventDefault();

              handleClickOpenConfirm();
            }}
          >
            Save Changes
          </Button>
        ) : (
          <Button
            variant="outlined"
            className="grammar_buttons"
            onClick={(e) => {
              e.preventDefault();
              if (!writingquestion || !writinganswer1 || !writinganswer2)
                return toast.error("Please provide all the fields");

              handleClickOpenConfirm();
            }}
          >
            Submit
          </Button>
        )}
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            setwritingquestion("");
            setwritinganswer1("");
            setwritinganswer2("");
            handleClickClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <CustomDialogBox
        opendialog={open}
        handleClickClose={handleClickCloseConfirm}
        bodyText={
          <AddWritingQuesSubCatDialogBody
            category={category}
            subcategory={subcategory}
            ques={writingquestion}
            ans1={writinganswer1}
            ans2={writinganswer2}
          />
        }
        confirmationText="Are You Sure Want To Add These Changes"
        handleSubmitOfDialog={handleSubmitOfDialog}
      />
    </Dialog>
  );
};

export default AddSubCategoryQues;
