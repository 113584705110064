import { Typography } from "@material-ui/core";
import { Grid, Card, Button } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import AllAcountsCreatedByUser from "./AllAcountsCreatedByUser";
import AppUserActivity from "./AppUserActivity";
import AppUserDetailsCard from "./AppUserDetailsCard";
import PaymentsAttemptedByUser from "./PaymentsAttemptedByUser";
import "../../CSSFiles/notifications.css";
import PayOutsAttemptedByUser from "./PayOutsAttemptedByUser";
import AppUserActivityChart from "../Charts/AppUserActivityChart";
import CustomDialogBox from "../CustomDialogBox";
import { useAuth } from "../../Providers/UserProvider";

const UsersDetailsComp = () => {
  const location = useLocation();
  const [opendialog, setopendialog] = useState(false);

  const { clearUserDetails } = useAuth();

  const handleClickOpen = (id) => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };
  return (
    <div style={{ width: "100%", height: "100vh", overflowY: "scroll" }}>
      <AppUserDetailsCard />
      <div style={{ margin: "25px" }}>
        <Typography>All Accounts Created By User</Typography>
      </div>
      {location?.state?.userAllAcountCreatedBy?.map((res) => {
        return <AllAcountsCreatedByUser res={res} />;
      })}

      <Card className="cardview">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div style={{ margin: "25px" }}>
              <Typography>Payments Attempted By User</Typography>
            </div>

            {location?.state?.paymentAttemptByUser?.map((res) => (
              <PaymentsAttemptedByUser res={res} />
            ))}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div style={{ margin: "25px" }}>
              <Typography>PayOuts Attempted By user</Typography>
            </div>

            {location?.state?.payoutAttemptByUser?.map((res) => (
              <PayOutsAttemptedByUser res={res} />
            ))}
          </Grid>
        </Grid>
      </Card>

      <div
        style={{
          margin: "0px 25px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography>App User Activity</Typography>
        <Button
          variant="outlined"
          className="grammar_buttons"
          onClick={(e) => {
            e.preventDefault();
            handleClickOpen();
          }}
        >
          Clear User Details
        </Button>
      </div>
      {/* 
      {location?.state?.appUserActivity?.map((res) => {
        return <AppUserActivity res={res} />;
      })} */}
      <AppUserActivityChart retentiondata={location?.state?.appUserActivity} />
      <CustomDialogBox
        opendialog={opendialog}
        handleClickClose={handleClickClose}
        confirmationText="Are you sure you want to clear this user's all question answer data?"
        handleSubmitOfDialog={() => {
          const userData = localStorage.getItem("userData");
          const Data = JSON.parse(userData);
          clearUserDetails(Data, location.state.appUserDetail.uid);
        }}
      />
    </div>
  );
};

export default UsersDetailsComp;
