import React from "react";
import CustomNotiCard from "../../Components/NotificationsComp/CustomNotiCard";
import SidebarComp from "../../Components/Sidebar";

const CustomNotiScreen = () => {
  return (
    <>
      <div className="home">
        <SidebarComp />
        <CustomNotiCard />
      </div>
    </>
  );
};

export default CustomNotiScreen;
