import React from "react";
import UsersDetailsComp from "../../Components/LastDaysUsersComp/UsersDetailsComp";
import SidebarComp from "../../Components/Sidebar";
import SidebarMob from "../../Components/SidebarMob";
import Navbar from "../../Components/Navbar/Navbar";
import "../../CSSFiles/home.css";
import { Typography } from "@mui/material";
import { useAuth } from "../../Providers/UserProvider";
import { Alert } from "@mui/lab";

const UsersDetailPage = () => {
  const { timetorefresh, closeSidebar, usergotDeleted, setusergotDeleted } =
    useAuth();
  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <UsersDetailsComp />
        {usergotDeleted && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: "90%",
              left: "42%",
              zIndex: "100",
            }}
          >
            <Alert
              severity="error"
              onClose={() => setusergotDeleted(false)}
              style={{ textAlign: "center" }}
            >
              User Details Deleted
            </Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default UsersDetailPage;
