import { Card, CardContent, Fab, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import SidebarComp from "../../Components/Sidebar";
import { useAuth } from "../../Providers/UserProvider";
import IeltsClassesComp from "../../Components/IeltsClassesComp/IeltsClassesComp";
import { RiAddFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FaRegClock } from "react-icons/fa";
import AddClassesGrp from "../../Components/IeltsClassesComp/AddClassesGrp";

const IeltsClassesGrp = () => {
    const { getClassesGrp, timetorefresh, closeSidebar, classesGrp } = useAuth();

    const [opendialog, setopendialog] = useState(false)

    const handleClickOpen = () => {
        setopendialog(true)
    }

    const handleClickClose = () => {
        setopendialog(false)
    }

    const navigate = useNavigate();

    useEffect(() => {
        startIeltsClassesPage();
    }, []);

    const startIeltsClassesPage = () => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        getClassesGrp(userData, "webuid", "webview");
    };


    return (
        <>
            {timetorefresh === true && (
                <Typography
                    style={{
                        margin: 0,
                        top: 15,
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "1",
                    }}
                >
                    Refreshing...
                </Typography>
            )}
            {!closeSidebar && <Navbar />}
            <div className="home">
                {closeSidebar && <SidebarMob />}
                <SidebarComp />
                <Card className="card">
                    <CardContent>
                        <Grid container
                            spacing={1}
                            rowSpacing={3}
                            style={{
                                paddingRight: "15px",
                                paddingLeft: "10px",
                                paddingTop: "10px",
                            }}>
                            {classesGrp?.map((res) => (
                                <Grid item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={4}
                                    className="gridingcards"
                                >
                                    <img
                                        src={res?.classGroupImage}
                                        alt="Teacher Image"
                                        className="grpingImage"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            sessionStorage.setItem("classGroupUniqueId", JSON.stringify(res?.classGroupUniqueId))
                                            navigate(`/ieltsClasses/${res?.classGroupUniqueId}`, {
                                                state: {
                                                    classGrp: res?.classGroupUniqueId
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
                {/* <Fab
          aria-label="add"
          style={{
            margin: 0,
            right: 50,
            bottom: 160,
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#051e34",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            navigate("/scheduleieltsClasses");
          }}
        >
          <FaRegClock size={30} style={{ color: "white" }} />
        </Fab>*/}
                <Fab
                    aria-label="add"
                    style={{
                        margin: 0,
                        right: 50,
                        bottom: 80,
                        position: "fixed",
                        zIndex: "1",
                        backgroundColor: "#051e34",
                        color: "white",
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        // navigate("/addieltsClasses");
                        handleClickOpen()
                    }}
                >
                    <RiAddFill size={30} style={{ color: "white" }} />
                </Fab>
                <AddClassesGrp opendialog={opendialog} handleClickClose={handleClickClose} />
            </div >
        </>
    );
};

export default IeltsClassesGrp;
