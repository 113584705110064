import React from "react";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import ListeningTestFeatures from "./ListeningTestFeatures";
import YouTubePlayer from "./VideoPlayer";

const ListeningTestDetailsComp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div style={{ padding: "10px" }}>
        <FaRegArrowAltCircleLeft
          onClick={(e) => {
            e.preventDefault();
            handleNavigateBack();
          }}
          style={{ cursor: "pointer" }}
          size={35}
        />
      </div>
      <ListeningTestFeatures data={location.state.data} />
      <div
        style={{ display: "flex", justifyContent: "center", margin: "10vh" }}
      >
        <YouTubePlayer videoId={location.state.data.testFile} />
      </div>
    </div>
  );
};

export default ListeningTestDetailsComp;
