import React from "react";
import { CardContent, Typography } from "@mui/material";

import Card from "@material-ui/core/Card";
import { useAuth } from "../../Providers/UserProvider";

const SpeakingTestCard = () => {
  const { speakingtestcontrolData } = useAuth();
  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      {speakingtestcontrolData?.map((data) => {
        return (
          <Card className="cardsub" style={{ marginBottom: "2vh" }}>
            <CardContent>
              <div
                style={{ display: "flex", alignItems: "center", gap: "13px" }}
              >
                <Typography className="title">{Object.keys(data)} :</Typography>
                <Typography className="body">{Object.values(data)}</Typography>
              </div>
            </CardContent>
          </Card>
        );
      })}
    </Card>
  );
};

export default SpeakingTestCard;
