import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import SidebarComp from "../../Components/Sidebar";
import { useAuth } from "../../Providers/UserProvider";
import UploadvideoFormComp from "../../Components/UploadingVideoFormComp/UploadingvideoFormComp";

const UploadingVideoFormPage = () => {
  const { closeSidebar } = useAuth();
  return (
    <>
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />

        <UploadvideoFormComp />
      </div>
    </>
  );
};

export default UploadingVideoFormPage;
