import React, { useEffect } from "react";
import SidebarComp from "../../Components/Sidebar";
import "../../CSSFiles/home.css";
import { useAuth } from "../../Providers/UserProvider";
import { Typography } from "@mui/material";
import BandCalculationCard from "../../Components/BandCalculationComp/BandCalculationCard";
import SidebarMob from "../../Components/SidebarMob";
import Navbar from "../../Components/Navbar/Navbar";

const BandCalculation = () => {
  const {
    getbandCalculationControl,
    updatebandCalculationControl,
    timetorefresh,
    closeSidebar,
  } = useAuth();

  useEffect(() => {
    StartFeedBackPage();
  }, []);

  const StartFeedBackPage = () => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);
    const bandCalculationControl = sessionStorage.getItem(
      "bandCalculationControl",
    );
    if (bandCalculationControl) {
      updatebandCalculationControl();
    }
    getbandCalculationControl(Data);
  };
  return (
    <>
      {timetorefresh === true && (
        <Typography
          style={{
            margin: 0,
            top: 15,
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            zIndex: "1",
          }}
        >
          Refreshing...
        </Typography>
      )}
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />

        <BandCalculationCard />
      </div>
    </>
  );
};

export default BandCalculation;
