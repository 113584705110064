import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import "../../CSSFiles/feedbacks.css";
import { useAuth } from "../../Providers/UserProvider";

const TotalSuggestionCard = ({ res }) => {
  const { handleFeedbackDetail } = useAuth();
  return (
    <Card
      className="cardsub"
      style={{ marginBottom: "2vh", cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        handleFeedbackDetail(res.feedbackId, res.question);
      }}
    >
      <CardContent>
        <div>
          <div className="gridings">
            <div className="firsts">
              <Typography className="titles">Question :-</Typography>
              <Typography className="bodys">{res.question}</Typography>
            </div>
            <div className="seconds"></div>
            <div className="thirds">
              <Typography className="titles2">Question Category :-</Typography>
              <Typography className="bodys">{res.questionType}</Typography>
            </div>
          </div>

          <div className="suggestionsoptions">
            <Typography className="titles">Total Suggestions : -</Typography>
            <Typography className="bodys">{res.getTotalSuggestion}</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default TotalSuggestionCard;
