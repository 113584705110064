import React, { useState } from "react";
import "./component.css";
import SelectOptions from "../NotificationsComp/SelectOptions";
import { Button, Card, CardContent } from "@mui/material";
import ButtonComp from "../CustomButtons/ButtonComp";
import CustomDialogBox from "../CustomDialogBox";
import AddAndEditReadingDialogBodyTxt from "../../MiddleWares/AddAndEditReadingDialogBodyTxt";
import { useAuth } from "../../Providers/UserProvider";
export const categoryOptions = [
  { value: 0, label: "academicTests" },
  { value: 1, label: "generalTests" },
];

const CreateReadingTest = () => {
  const [thumbNailUpload, setthumbNailUpload] = useState("");
  const [upload, setupload] = useState(false);

  const { addReadingTest } = useAuth();

  const [opendialog, setopendialog] = useState(false);

  const handleClickOpen = () => {
    setopendialog(true);
  };

  const handleClickClose = () => {
    setopendialog(false);
  };

  const handlePDFfilesChange = (e) => {
    setupload(true);
    setthumbNailUpload(e.target.files[0]);
  };

  const [storedcategoryvalue, setstoredcategoryvalue] = useState("");

  const handleChangeCategoryValue = (e) => {
    setstoredcategoryvalue(e);
  };

  const handleSubmitOfDialog = () => {
    addReadingTest(thumbNailUpload, storedcategoryvalue?.value);
  };
  return (
    <Card className="card">
      <CardContent>
        <div id="parent-form-div">
          <div class="file-input">
            <label class="file-input-label">Choose a file</label>
            {upload && <span>{thumbNailUpload.name}</span>}
            <input
              type="file"
              accept="application/pdf"
              onChange={handlePDFfilesChange}
            />
          </div>
          {/* <div
                        style={{
                            marginTop: "2vh",
                            marginBottom: "2vh",
                            transform: "initial",
                        }}
                    >
                        <SelectOptions
                            options={categoryOptions}
                            type="readingList"
                            value={storedcategoryvalue}
                            handleFunction={handleChangeCategoryValue}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', transform: 'unset', }}>
                    <ButtonComp buttonText="Create" />
                </div> */}
          <div
            style={{
              marginTop: "2vh",
              marginBottom: "2vh",
              transform: "initial",
            }}
          >
            <SelectOptions
              options={categoryOptions}
              type="readingList"
              value={storedcategoryvalue}
              handleFunction={handleChangeCategoryValue}
            />
          </div>
          <div style={{ textAlign: "center", transform: "unset" }}>
            <Button
              variant="outlined"
              className="grammar_buttons"
              style={{ zIndex: "0" }}
              onClick={(e) => {
                e.preventDefault();
                handleClickOpen();
              }}
            >
              Create
            </Button>
          </div>
          <CustomDialogBox
            opendialog={opendialog}
            handleClickClose={handleClickClose}
            bodyText={
              <AddAndEditReadingDialogBodyTxt
                pdfName={thumbNailUpload?.name}
                type={storedcategoryvalue?.label}
              />
            }
            confirmationText="Are You Sure Want To Add These Changes"
            handleSubmitOfDialog={handleSubmitOfDialog}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default CreateReadingTest;
