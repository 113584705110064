import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import { useAuth } from "../../Providers/UserProvider";
import CashBookCard from "./CashBookCard";

const DebitCashBookTab = ({ handleClick }) => {
  const [value, setValue] = useState("one");
  const {
    cashbooktranscation,

    getCashBookDebit,
    getCashBookDebitCompany,
    getCashBookDebitPersonal,
  } = useAuth();

  var styles = {
    root: {
      marginTop: "10px",
    },
  };

  const handleClick2 = () => {
    handleClick();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "one") {
      const userData = localStorage.getItem("userData");
      if (userData) {
        getCashBookDebit(userData);
      }
    }
    if (newValue === "two") {
      const userData = localStorage.getItem("userData");
      if (userData) {
        getCashBookDebitCompany(userData);
      }
    }
    if (newValue === "three") {
      const userData = localStorage.getItem("userData");
      if (userData) {
        getCashBookDebitPersonal(userData);
      }
    }
  };

  return (
    <TabContext value={value}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        centered
        style={styles.root}
      >
        <Tab value="one" label="All" />
        <Tab value="two" label="Company" />
        <Tab value="three" label="Personal" />
      </Tabs>
      <TabPanel value="one" style={{ padding: "0" }}>
        {" "}
        {cashbooktranscation?.map((res) => (
          <CashBookCard res={res} handleClick={handleClick2} />
        ))}
      </TabPanel>
      <TabPanel value="two" style={{ padding: "0" }}>
        {" "}
        {cashbooktranscation?.map((res) => (
          <CashBookCard res={res} handleClick={handleClick2} />
        ))}
      </TabPanel>
      <TabPanel value="three" style={{ padding: "0" }}>
        {" "}
        {cashbooktranscation?.map((res) => (
          <CashBookCard res={res} handleClick={handleClick2} />
        ))}
      </TabPanel>
    </TabContext>
  );
};

export default DebitCashBookTab;
