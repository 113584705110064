import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/UserProvider";

const CardView = (props) => {
  const { settlePercentageByRazorPay, totalExpense } = useAuth();
  const navigate = useNavigate();

  const handleCardViewClick = () => {
    if (props.cardType === "TranscationList") {
      navigate("/transcationlist");
    } else if (props.cardType === "CashBookTranscationList") {
      navigate("/cashbook");
    }
  };

  return (
    <div
      className="subcards card-1"
      onClick={(e) => {
        e.preventDefault();
        handleCardViewClick();
      }}
    >
      <div
        className="view 1"
        style={{ display: "flex", gap: "13px", alignItems: "center" }}
      >
        <h3 className="card__value">{props.title} :</h3>
        <p className="card__value">{props.value}</p>
      </div>
      <div
        className="view 1"
        style={{ display: "flex", gap: "13px", alignItems: "center" }}
      >
        <h3 className="card__title">{props.title2} :</h3>
        <p className={props.special ? `card__title imp` : `card__title`}>
          {props.value2}
        </p>
      </div>
      {props.title3 === "Settle After RazorPay Tax" ? (
        <div
          className="view 1"
          style={{ display: "flex", gap: "13px", alignItems: "center" }}
        >
          <h3 className="card__title">{props.title3} :</h3>
          <p className="card__title">
            {props.value3} ({settlePercentageByRazorPay}% deduct)
          </p>
        </div>
      ) : (
        <div
          className="view 1"
          style={{ display: "flex", gap: "13px", alignItems: "center" }}
        >
          <h3 className="card__title">{props.title3} :</h3>
          <p className="card__title">{props.value3}</p>
        </div>
      )}
      <div
        className="view 1"
        style={{ display: "flex", gap: "13px", alignItems: "center" }}
      >
        <h3 className="card__title">
          {" "}
          {props.title4 ? `${props?.title4} :` : ""}
        </h3>
        <p className="card__title">{props?.value4}</p>
      </div>
      <div
        className="view 1"
        style={{ display: "flex", gap: "13px", alignItems: "center" }}
      >
        <h3 className="card__title">
          {props.title5 ? `${props?.title5} :` : ""}
        </h3>
        <p className="card__title">{props.value5}</p>
      </div>
    </div>
  );
};

export default CardView;
