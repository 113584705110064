import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../CSSFiles/home.css";

export default function AvgTimeSpentByUserChart({ retentiondata }) {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "0px",
            border: "1px solid #E2E8D7",
            fontSize: "12px",
            background: "white",
          }}
          className="graph_tooltips"
        >
          <p
            style={{ color: "#008390" }}
          >{`Xaxis : ${payload[0].payload.xaxis}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`PaidUsers : ${payload[0].payload.paiduseravgTimeSpend}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`AllUsers : ${payload[0].payload.alluseravgTimeSpend}`}</p>

          <p
            style={{ color: "#5DB09A" }}
          >{`UnpaidUsers : ${payload[0].payload.unpaiduseravgTimeSpend}`}</p>
          <p
            style={{ color: "#5DB09A" }}
          >{`AppLeaveUsers : ${payload[0].payload.appleaveuseravgTimeSpend}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="99%" height={300}>
      <LineChart data={retentiondata}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="xaxis" />

        <YAxis tickCount={9} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          name="PaidUsers"
          dataKey="paiduseravgTimeSpend"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          name="AllUsers"
          dataKey="alluseravgTimeSpend"
          stroke="#82ca9d"
        />
        <Line
          type="monotone"
          name="UnpaidUsers"
          dataKey="unpaiduseravgTimeSpend"
          stroke="red"
        />
        <Line
          type="monotone"
          name="AppLeaveUser"
          dataKey="appleaveuseravgTimeSpend"
          stroke="brown"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
