import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  ComposedChart,
  Line,
} from "recharts";
import { useAuth } from "../../Providers/UserProvider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function MonthlySaleRevenueData() {
  const { monthlysaleData } = useAuth();

  // State variables to keep track of selected checkboxes
  const [showBasicCode, setShowBasicCode] = useState(false);
  const [showWithoutCouponCode, setShowWithoutCouponCode] = useState(true);
  const [showraman0007, setShowraman007] = useState(true);
  const [showielts50, setShowielts50] = useState(true);
  const [showget50, setShowget50] = useState(false);

  // Function to handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    // Update the state based on the checkbox name
    if (name === "basicCode") {
      setShowBasicCode(checked);
    } else if (name === "withoutCouponCode") {
      setShowWithoutCouponCode(checked);
    } else if (name === "showraman0007") {
      setShowraman007(checked);
    } else if (name === "showget50") {
      setShowget50(checked);
    } else if (name === "showielts50") {
      setShowielts50(checked);
    }
  };

  return (
    <div>
      {/* Material UI checkboxes */}
      <FormControlLabel
        control={
          <Checkbox
            checked={showBasicCode}
            onChange={handleCheckboxChange}
            name="basicCode"
          />
        }
        label="Basic Code"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={showWithoutCouponCode}
            onChange={handleCheckboxChange}
            name="withoutCouponCode"
          />
        }
        label="Without Coupon Code"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={showraman0007}
            onChange={handleCheckboxChange}
            name="showraman0007"
          />
        }
        label="raman0007"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={showget50}
            onChange={handleCheckboxChange}
            name="showget50"
          />
        }
        label="get50"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={showielts50}
            onChange={handleCheckboxChange}
            name="showielts50"
          />
        }
        label="ielts50"
      />

      <ResponsiveContainer width="99%" height={300}>
        <ComposedChart data={monthlysaleData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="totalAmount" stroke="brown" />

          {/* Conditionally render the bars based on checkbox state */}
          {showBasicCode && (
            <Bar dataKey="basic_Code" name="basic_Code" fill="#FF9671" />
          )}
          {showWithoutCouponCode && (
            <Bar
              dataKey="withoutCouponCode"
              name="WithoutCouponCode"
              fill="#B39CD0"
            />
          )}

          {showget50 && (
            <Bar dataKey="couponcodeSellDataget50" name="get50" fill="red" />
          )}
          {showielts50 && (
            <Bar
              dataKey="couponcodeSellDataielts50"
              name="ielts50"
              fill="green"
            />
          )}
          {showraman0007 && (
            <Bar
              dataKey="couponcodeSellDataraman0007"
              name="raman007"
              fill="#0081CF"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
