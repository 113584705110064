import { Typography } from "@mui/material";
import React from "react";

const ScheduleClassesDialogBodyTxt = ({
  classFileUrl,
  classTitle,
  classCategory,
  startingTime,
  endingTime,
  classNumber,
}) => {
  return (
    <div style={{ padding: "2vw" }}>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "5px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="title">Class File Url :-</Typography>
        <Typography className="body">{classFileUrl}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "5px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="title">Class Title :-</Typography>
        <Typography className="body">{classTitle}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "5px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="title">Class Category :-</Typography>
        <Typography className="body">{classCategory}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "5px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="title">Class Number :-</Typography>
        <Typography className="body">{classNumber}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "5px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="title">Start Time :-</Typography>
        <Typography className="body">{startingTime}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "5px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="title">End Time :-</Typography>
        <Typography className="body">{endingTime}</Typography>
      </div>
    </div>
  );
};

export default ScheduleClassesDialogBodyTxt;
