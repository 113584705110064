import React, { useEffect } from "react";
import { useAuth } from "../../Providers/UserProvider";
import ServerInfoDetails from "../Charts/ServerInfoDetail";

const ScheduleServer = () => {
  const { getHomeData, scheduleserverdetails, getServerDetails } = useAuth();

  useEffect(() => {
    const userData = localStorage.getItem("userData");

    const Data = JSON.parse(userData);

    getHomeData(Data);
    getServerDetails("Scheduletask-env");
  }, []);

  return (
    <div className="server_graphs">
      <ServerInfoDetails serverInfodetails={scheduleserverdetails} />
    </div>
  );
};

export default ScheduleServer;
