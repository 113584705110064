import React from "react";
import { Typography } from "@material-ui/core"; // Assuming you're using Material-UI
import "../../../CSSFiles/listening.css"; // Import the CSS file we created
import { changeDateFormate } from "../../../Services/Constants";

const SpeakingTestFeatures = ({ data, type }) => {
  // ... Your component code ...

  return (
    <div className="speaking-details-container">
      <div className="detail-row">
        <Typography className="detail-title">Id:</Typography>
        <Typography className="detail-body">{data?.id}</Typography>
      </div>
      {data.uniqueQuestionId && (
        <div className="detail-row">
          <Typography className="detail-title">QueCard Id:</Typography>
          <Typography className="detail-body">
            {data?.uniqueQuestionId}
          </Typography>
        </div>
      )}
      {data.queCardId && (
        <div className="detail-row">
          <Typography className="detail-title">QueCard Id:</Typography>
          <Typography className="detail-body">{data?.queCardId}</Typography>
        </div>
      )}
      <div className="detail-row">
        <Typography className="detail-title">Question:</Typography>
        <Typography className="detail-body">{data?.question}</Typography>
      </div>
      <div className="detail-row">
        <Typography className="detail-title">Best Answer:</Typography>
        <Typography className="detail-body">{data?.bestAnswer}</Typography>
      </div>

      <div className="detail-row">
        <Typography className="detail-title">Question Type:</Typography>
        <Typography className="detail-body">{type}</Typography>
      </div>

      <div className="detail-row">
        <Typography className="detail-title">Created At:</Typography>
        <Typography className="detail-body">
          {changeDateFormate(data?.created_at)}
        </Typography>
      </div>
    </div>
  );
};

export default SpeakingTestFeatures;
