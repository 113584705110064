import { Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { changeDateFormate } from "../../../Services/Constants";
import LiveClassEditTextDialog from "./LiveClassEditTextDialog";

const EditLiveClassCard = ({ data }) => {
  const [text, settext] = useState({
    title: "",
    value: "",
  });
  const [openedittextDialog, setopenedittextDialog] = useState(false);

  const [valueId, setvalueId] = useState("");

  const handleClickOpenEdit = () => {
    setopenedittextDialog(true);
  };

  const handleClickClose = () => {
    setopenedittextDialog(false);
  };

  const handleEditText = (title, value, valueId) => {
    settext({
      title: title,
      value: value,
    });
    setvalueId(valueId);
    handleClickOpenEdit();
  };
  return (
    <Card className="card" style={{ marginBottom: "2vh", cursor: "pointer" }}>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Class Title", data?.classTitle, 2);
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Class Title :-</Typography>
            <Typography className="body">{data?.classTitle}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Class Url", data?.classFile, 1);
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Class Url :-</Typography>
            <Typography className="body">{data?.classFile}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Class Category", data?.category, 3);
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Class Category :-</Typography>
            <Typography className="body">{data?.category}</Typography>
          </div>
        </CardContent>
      </Card>
      <Card
        className="cardsub"
        style={{ marginBottom: "2vh" }}
        onClick={(e) => {
          e.preventDefault();
          handleEditText("Class Duration", data?.classDuration, 4);
        }}
      >
        <CardContent style={{ position: "relative" }}>
          <div className="text_value">
            <Typography className="title">Duration :-</Typography>
            <Typography className="body">{data?.classDuration}</Typography>
          </div>
        </CardContent>
      </Card>

      <LiveClassEditTextDialog
        text={text}
        opendialog={openedittextDialog}
        handleClickClose={handleClickClose}
        valueId={valueId}
        type="LiveClass"
        ClassUniqueId={data?.classUniqueId}
        classFile={data?.classFile}
      />
    </Card>
  );
};

export default EditLiveClassCard;
