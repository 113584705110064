import { Card, CardContent, Typography, Button, Chip } from "@mui/material";
import React from "react";
import "../../CSSFiles/classes.css";
import { BsArrowUpRight } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { changeDateFormate } from "../../Services/Constants";

const IeltsClassesCard = ({ data, type }) => {
  const navigate = useNavigate();

  const checkIsLive = () => {
    let currentDate = new Date();
    let startDate = new Date(data?.classStatingTime);
    let endDate = new Date(data?.classEndTIme);

    if (currentDate >= startDate && currentDate <= endDate) {
      return true;
    }
    return false;
  };

  const getTheTimeForStreaming = () => {
    let currentDate = new Date();
    let startDate = new Date(data?.classStatingTime);
    let endDate = new Date(data?.classEndTIme);

    let time = moment(startDate).fromNow();
    let endTime = moment(startDate).fromNow();
    let finishTime = moment(endDate).fromNow();

    if (currentDate >= startDate && currentDate <= endDate) {
      return `Started Streaming since ${time}`;
    } else if (currentDate < startDate) {
      return `Stream will start  ${endTime} (${changeDateFormate(startDate)})`;
    } else {
      return `Stream ended ${finishTime}`;
    }
  };

  return (
    <Card className="teacherCard">
      <CardContent>
        <div className="classes_first_sec">
          <img
            src={data?.teacherImage}
            alt="Teacher Image"
            className="teacherImage"
          />
          <div>
            <div style={{ textAlign: "left" }}>
              <div
                className="teacherDetails"
                style={{
                  display: "flex",
                  gap: "50px",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography className="teachertitle">
                    {data?.teacherName}
                  </Typography>
                  <Typography className="teacherbody">
                    {data?.teacherExperiance} yrs experience
                  </Typography>
                </div>

                {type === "Live" && (
                  <>
                    {!data?.rating ? (
                      <div>
                        <Typography
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          Rating <AiFillStar style={{ color: "yellow" }} />{" "}
                          <span className="title">--</span>
                        </Typography>
                      </div>
                    ) : (
                      <div>
                        <Typography
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          Rating <AiFillStar style={{ color: "yellow" }} />{" "}
                          <span className="title">{data?.rating}</span>
                        </Typography>
                      </div>
                    )}
                  </>
                )}
                {type === "Schedule" && checkIsLive() && (
                  <Chip
                    label="Live"
                    style={{
                      background: "#FF006F",
                      fontSize: "14px",
                      color: "white",
                      padding: "5px",
                    }}
                  />
                )}
              </div>
            </div>
            <div className="classes_second_sec">
              <Typography>{`${data?.category} >  ${
                data?.classNumber ? data?.classNumber : ""
              }`}</Typography>
            </div>
            <div className="classes_second_sec">
              <Typography className="teachertitle">
                {data?.classTitle}
              </Typography>
            </div>
            {type === "Live" && (
              <div className="classes_time_duration">
                <Typography className="body">{data?.classDuration}</Typography>
              </div>
            )}
            <div
              className="classes_second_sec"
              style={{
                display: "flex",
                gap: "15px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {type === "Schedule" && (
                <div className="classes_time_duration">
                  <Typography className="title">
                    {getTheTimeForStreaming()}
                  </Typography>
                </div>
              )}
              {type === "Live" ? (
                <Button
                  variant="contained"
                  className="teacherButtons"
                  style={{ zIndex: "0" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(
                      `/class/${data?.category}/${data?.classUniqueId}`,
                      {
                        state: {
                          data: data,
                          type: "Live",
                        },
                      },
                    );
                  }}
                >
                  Watch Now <BsArrowUpRight />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="teacherButtons"
                  style={{ zIndex: "0" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(
                      `/class/${data?.category}/${data?.classUniqueId}`,
                      {
                        state: {
                          data: data,
                          type: "Schedule",
                        },
                      },
                    );
                  }}
                >
                  Join Live <BsArrowUpRight />
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* <div className="classes_second_sec">
                    <span>{data?.category}</span>
                </div> */}
      </CardContent>
    </Card>
  );
};

export default IeltsClassesCard;
