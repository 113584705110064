import React, { useState } from "react";
import { useAuth } from "../../Providers/UserProvider";
import Navbar from "../../Components/Navbar/Navbar";
import SidebarMob from "../../Components/SidebarMob";
import SidebarComp from "../../Components/Sidebar";
import { Card, CardContent, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { HiPencil } from "react-icons/hi";
import AddSubCategoryQues from "../../Components/WritingTestComp/AddSubCategoryQues";
import "../../CSSFiles/listening.css"

const DetailedQuestionsWritingSubCategory = () => {
  const {
    closeSidebar,
    setwritingquestion,
    setwritinganswer1,
    setwritinganswer2,
    singlewritingquestion,
  } = useAuth();
  const location = useLocation();
  const [openAddModal, setopenAddModal] = useState(false);
  const handleOpenModel = () => {
    setwritingquestion(singlewritingquestion.Question);
    setwritinganswer1(singlewritingquestion.FirstAnswer);
    setwritinganswer2(singlewritingquestion.SecondAnswer);
    setopenAddModal(true);
  };

  const handleCloseModel = () => {
    setopenAddModal(false);
  };

  console.log(singlewritingquestion)
  return (
    <>
      {!closeSidebar && <Navbar />}
      <div className="home">
        {closeSidebar && <SidebarMob />}
        <SidebarComp />
        <Card style={{ width: "100%" }} className="card">
          <CardContent>
            <Card className="cardsub" style={{ marginBottom: "2vh" }}>
              <CardContent style={{ position: "relative" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    marginBottom: "1vh",
                  }}
                >
                  <Typography className="title">Category :</Typography>
                  <Typography className="body">
                    {singlewritingquestion.CategoryName}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    marginBottom: "1vh",
                  }}
                >
                  <Typography className="title">SubCategoryName :</Typography>
                  <Typography className="body">
                    {singlewritingquestion.SubCategoryName}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    marginBottom: "1vh",
                  }}
                >
                  <Typography className="title">Question :</Typography>
                  <Typography className="body newline">
                    {singlewritingquestion.Question}
                  </Typography>
                </div>

                <div className="duplicate">
                  <HiPencil
                    className="duplicate_icon"
                    onClick={(e) => {
                      e.preventDefault();
                      handleOpenModel();
                    }}
                  />
                </div>
              </CardContent>
            </Card>
            {
              singlewritingquestion?.image && <div className="profileContainer">
                <img
                  src={singlewritingquestion?.image}
                  alt="ProfileImage"
                  width="150"
                  height="150"
                />
              </div>
            }
            <Card className="cardsub" style={{ marginBottom: "2vh" }}>
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                  <Typography className="title">Answer First :</Typography>
                  <Typography className="body newline">
                    {singlewritingquestion.FirstAnswer}
                  </Typography>
                </div>
              </CardContent>
            </Card>
            <Card className="cardsub" style={{ marginBottom: "2vh" }}>
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                  <Typography className="title">Answer Second :</Typography>
                  <Typography className="body newline">
                    {singlewritingquestion.SecondAnswer}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </CardContent>
          <AddSubCategoryQues
            opendialog={openAddModal}
            handleClickClose={handleCloseModel}
            category={singlewritingquestion.CategoryName}
            subcategory={singlewritingquestion.SubCategoryName}
            QuestionUniqueId={singlewritingquestion.QuestionUniqueId}
            quesImage={singlewritingquestion.image}
            type="Edit"
            taskType={location?.state?.type}
            task1type={location?.state?.task1type}
          />
        </Card>
      </div>
    </>
  );
};

export default DetailedQuestionsWritingSubCategory;
